import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';

import useBrowserWidth from '../../utilities/responsiveHook';
import { userProfileTypes } from '../profile/store';

type Props = {
  data?: any;
  handleEdit: (val: string) => void;
  readOnly?: boolean;
};
export default function ProfessionalInformation(props: Props) {
  const { data, handleEdit, readOnly } = props;
  const [viewMode, setViewMode] = useState({
    current_salary: false,
  });
  const currentSalary = data?.current_salary;
  const formattedCurrentSalary =
    Math.abs(currentSalary) > 999
      ? Math.sign(currentSalary) *
          (Math.round(Math.abs(currentSalary) / 100) / 10) +
        'K'
      : Math.sign(currentSalary) * Math.abs(currentSalary);
  const { t } = useTranslation();
  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <div
      className={`flex flex-col gap-4 bg-bgWhite border-b border-formBorder ${
        isMobile ? 'p-4' : 'px-7 py-5'
      }`}
    >
      <div className="flex justify-between items-center">
        {isMobile ? (
          <div className="flex flex-col">
            <p className="text-lg font-bold text-primaryText">
              {t('professional_info') ?? ''}
            </p>
            {/* <span className="text-xs text-primary font-medium">
              ({t("private_to_you") ?? ""})
            </span> */}
          </div>
        ) : (
          <p className="text-lg font-bold text-primaryText">
            {t('professional_info') ?? ''}

            {/* <span className="text-xs text-primary font-medium">
              ({t("private_to_you") ?? ""})
            </span> */}
          </p>
        )}
        <div className="flex items-center gap-4">
          {!readOnly && (
            <IconButton
              disableRipple
              onClick={() => handleEdit(userProfileTypes.PROFESSIONAL_INFO)}
              className="p-0  "
            >
              <BiEdit className="p-0  text-primaryText w-5" />
            </IconButton>
          )}
        </div>
      </div>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-3 md:col-span-2  lg:col-span-2">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {data?.year_of_experience ?? '- -'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('years_of_experience_label') ?? ''}
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {data?.work_authorisation_status ?? '- -'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('work_authorisation_status') ?? ''}
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {data?.employment_status ?? '- -'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('employment_status_label') ?? ''}
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {viewMode.current_salary
                ? data?.current_salary == null
                  ? '- -'
                  : ` OMR ${data?.current_salary} ` ?? '- -'
                : '######'}
              {/* `OMR ${formattedCurrentSalary} ` ??  */}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('current_salary_label') ?? ''}
            <span
              className="cursor-pointer"
              onClick={() =>
                setViewMode({
                  ...viewMode,
                  current_salary: !viewMode.current_salary,
                })
              }
            >
              {viewMode.current_salary ? (
                <AiFillEye className="w-4 h-4 text-[#A9A8A8]" />
              ) : (
                <AiFillEyeInvisible className="w-4 h-4 text-[#A9A8A8]" />
              )}
            </span>
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {data?.notice_period_text ?? '- -'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('notice_period_label') ?? ''}
          </label>
        </div>
      </div>
    </div>
  );
}
