import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ProfileSchema } from "../../common/userSchema";
import Dropzone from "../../customComponents/Drag&Drop";
import { portfolioDelete, resumeDelete } from "../../pages/profile/apis/api";

type Props = {
  registerName: any;
  title: string;
  fromDialog?: boolean;
  handleRefresh: () => void;
};

export default function FileUpload(props: Props) {
  const { title, registerName, fromDialog, handleRefresh } = props;
  const [count, setCount] = useState(1);
  const [file, setFile] = useState<any>([]);
  const { t } = useTranslation();

  const {
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext<ProfileSchema>();

  const handleFile = () => {
    if (file?.length > 0) {
      setValue(registerName, file, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };
  useEffect(() => {
    setValue(registerName, file);
  }, [count]);
  const handleDeleteFile = async (fileId: string, index?: number) => {
    if (registerName == "portfolios") {
      portfolioDelete(fileId)
        .then((res) => {
          const data = getValues("portfolioData");

          if (index && data && data?.length > 1) {
            const item = data?.filter((e: any) => e?.id !== fileId);
            setValue("portfolioData", item);
            setCount(count + 1);
          } else {
            setValue("portfolioData", []);
            setCount(count + 1);
          }
        })
        .catch((err) => {});
    } else if (registerName == "attachments") {
      resumeDelete(fileId)
        .then((res) => {
          const data = getValues("attachmentData");
          if (index && data && data?.length > 1) {
            const item = data?.filter((e: any) => e?.id !== fileId);
            setValue("attachmentData", item);
            setCount(count + 1);
          } else {
            setValue("attachmentData", []);
            setCount(count + 1);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    handleFile();
  }, [file]);
  useEffect(() => {
    handleUploadedFile();
  }, [getValues(registerName)]);

  const handleUploadedFile = () => {
    let fileValue: any = [];
    registerName == "attachments" &&
      (fileValue = getValues("attachmentData") ?? []);
    registerName == "portfolios" &&
      (fileValue = getValues("portfolioData") ?? []);

    const fileNames: any = [];
    let fileName = "";
    let filePath = "";

    fileValue.map((item: any) => {
      if (item?.actual_file_name) {
        fileName = item.actual_file_name;
        filePath = item.actual_file_path;
      }
      // else {
      //   fileName = item
      //     ? item?.actual_file_Path?.substring(
      //         item.actual_file_Path.lastIndexOf("/") + 1
      //       )
      //     : "";
      // }
      fileName !== undefined &&
        fileNames?.push({
          id: item.id,
          name: fileName,
          path: filePath,
        });
    });

    if (fileNames) {
      return fileNames;
    } else {
      return [];
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {!props.fromDialog && (
        <p className="text-base font-bold text-primaryText">{title}</p>
      )}
      <div className="grid grid-cols-1 gap-4">
        <div>
          <Dropzone
            file={file}
            setFile={setFile}
            // key={JSON.stringify(handleUploadedFile())}
            fileType="DOC, DOCX, PDF"
            size="5 mb"
            buttonText={t("browse&upload_button") ?? ""}
            multiple={true}
            updateFile={count}
            uploadedFile={handleUploadedFile()}
            handleRemoveUploadedFile={handleDeleteFile}
            // error={
            //   registerName == "portfolios"
            //     ? errors?.portfolios && errors?.portfolios[0]?.message
            //     : errors?.attachments && errors?.attachments[0]?.message
            // }
            error={
              file?.length !== 0
                ? registerName == "portfolios"
                  ? errors?.portfolios && errors?.portfolios[0]?.message
                  : errors?.attachments && errors?.attachments[0]?.message
                : ""
            }
            fileInfo={t("upload_resume_heading") ?? ""}
          />
        </div>
      </div>
    </div>
  );
}
