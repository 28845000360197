import { z } from "zod";

export const loginSchema = z.object({
  email: z
    .string()
    .email({ message: "enter_a_valid_email_femsg" })
    .min(1, { message: "email_no_empty_femsg" }),
});

export type LoginSchema = z.infer<typeof loginSchema>;
