import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlus } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';

import DeleteModal from '../../components/dialogComponents/deleteComponent';
import MenuDropDown from '../../customComponents/menudropdown/index';
import { deleteBankDetails, updateProfile } from '../../pages/profile/apis/api';
import { profileStore } from '../../store/profileStore';
import useBrowserWidth from '../../utilities/responsiveHook';
import AddBankDetails from './addBanckAccount/addBankDetails';
import { BankAccountSchema } from './addBanckAccount/schema';

type Props = {
  handleRefresh: () => void;
  bankdetails: BankAccountSchema[] | any[];
  editMode?: boolean;
  readOnly?: boolean;
};
const menuData = [
  { title: 'edit_button', id: '1', action: 'edit' },
  { title: 'set_primary_option', id: '2', action: 'setPrimary' },
  { title: 'delete_button', id: '3', action: 'delete', divider: true },
];

const primaryMenuData = [
  { title: 'edit_button', id: '1', action: 'edit' },
  { title: 'delete_button', id: '3', action: 'delete', divider: true },
];

export default function UserPaymentInfo(props: Props) {
  const { handleRefresh, bankdetails, editMode, readOnly } = props;
  const { openBankInfoModel, setBankInfoModel } = profileStore();
  const [anchorEl, setAnchorel] = useState<any>(null);
  const [bankId, setbankId] = useState<string>('');
  const [bankDetailData, setBankDetailData] = useState<any>([]);
  const { t } = useTranslation();
  const [mode, setMode] = useState<any>('Add');
  const [deleteModal, setDeleteModal] = useState(false);
  const [menu, setMenu] = useState(menuData);
  const { isBrowser, isMobile } = useBrowserWidth();

  const handleOpen = () => {
    setBankInfoModel(true);
    setMode('Add');
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    is_primary: boolean
  ) => {
    setAnchorel(e.currentTarget);
    setbankId(id);
    if (is_primary) {
      setMenu(primaryMenuData);
    } else {
      setMenu(menuData);
    }
  };
  const handleEdit = () => {
    const data =
      bankdetails && bankdetails?.find((item: any) => item?.id === bankId);
    setBankDetailData(data);
    setMode('Edit');
    setBankInfoModel(true);
  };
  const handleClose = () => {
    setAnchorel(null);
  };
  const handleSetPrimary = async () => {
    const data = {
      ...(bankdetails && bankdetails?.find((item: any) => item?.id === bankId)),
      is_primary: true,
    };
    const form = new FormData();
    form.append('bank_details', JSON.stringify(data));
    const res = await updateProfile(form);
    if (res.status) {
      enqueueSnackbar(res.message, {
        variant: 'success',
      });
      setBankInfoModel(false);
      props.handleRefresh();
    }
  };
  const handleDelete = async () => {
    deleteBankDetails(bankId)
      .then((res) => {
        props.handleRefresh();
        setDeleteModal(false);
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: 'error',
        });
      });
  };
  const handleMenuActions = (type: string) => {
    handleClose();
    switch (type) {
      case 'edit':
        return handleEdit();
      case 'setPrimary':
        return handleSetPrimary();
      case 'delete':
        return setDeleteModal(true);
      default:
        return null;
    }
  };
  return (
    <>
      {!editMode ? (
        <div className="flex items-center gap-5 justify-between">
          <p className="text-base font-bold text-primaryText">
            {t('freelance_payment_information_headings') ?? ''}
          </p>
          <IconButton onClick={(e) => handleOpen()}>
            <AiOutlinePlus className="text-primary" />
          </IconButton>
        </div>
      ) : (
        <div className="flex justify-between items-center mb-4">
          <p className="text-lg font-bold text-primaryText">
            {t('freelance_payment_information_headings') ?? ''}
          </p>
          <div className="flex items-center gap-4">
            {!readOnly && (
              <IconButton
                disableRipple
                onClick={(e) => handleOpen()}
                className="p-0  "
              >
                <BiPlus className="p-0  text-primaryText w-5" />
              </IconButton>
            )}
          </div>
        </div>
      )}
      {bankdetails &&
        bankdetails?.length > 0 &&
        bankdetails?.map((item: any) => (
          <div
            key={item.id}
            className={` border-solid border-2 p-3 flex flex-col rounded-lg mb-3 border-[#D6D8DC] ${
              isMobile ? '' : 'gap-1'
            }`}
          >
            <div className="flex items-center justify-between">
              <p className="text-sm font-semibold	"> {item.bank_name}</p>
              <div className="flex items-center">
                {item.is_primary && (
                  <div className="tagStyle primaryTag border-t border-b border-primary bg-activeStateBg">
                    <span className="tag text-[10px] text-primaryText font-semibold">
                      {t('primary_badge') ?? ''}
                    </span>
                  </div>
                )}
                {!readOnly && (
                  <IconButton
                    className="pr-0"
                    disableRipple
                    onClick={(e) => handleOpenMenu(e, item.id, item.is_primary)}
                  >
                    <BsThreeDotsVertical className="p-0  text-primaryText w-4 h-4" />
                  </IconButton>
                )}
              </div>
            </div>
            <div className="flex flex-wrap text-sm  gap-x-2">
              <p>{item.branch_name}</p> <span>|</span>
              <p>{item.bank_address} </p>
            </div>
            <div className={`flex gap-x-2 ${isMobile ? 'flex-col' : ''}`}>
              <div className="flex gap-2 text-xs font-semibold items-center text-textColor">
                {t('account_number_label') ?? ''}:{' '}
                <p className="text-sm text-blackColor font-medium">
                  {item.account_number}{' '}
                </p>
                {isBrowser ? (
                  <span className="text-lg font-normal">|</span>
                ) : (
                  ''
                )}
              </div>

              <div className="flex gap-2 text-xs items-center font-semibold text-textColor">
                {t('profile_name_of_account_label') ?? ''} :{' '}
                <p className="text-sm  text-blackColor font-medium">
                  {item.account_holder_name}{' '}
                </p>
              </div>
            </div>
            <div
              className={`flex  gap-3 text-sm  ${
                isMobile ? 'flex-col items-start mt-3' : 'items-center'
              }`}
            >
              <div
                className={`flex justify-between w-2/4 items-center p-1 rounded-sm bg-[#F8F8F8] ${
                  isMobile ? 'flex-1 w-full' : ''
                }`}
              >
                <p className="flex text-xs text-textColor font-semibold">
                  {t('swift_code_label') ?? ''}
                </p>
                <p className="flex text-sm text-primaryText font-medium">
                  {item.swift_code}
                </p>
              </div>
              <div
                className={`flex justify-between w-2/4 items-center p-1 rounded-sm bg-[#F8F8F8] ${
                  isMobile ? 'flex-1 w-full' : ''
                }`}
              >
                <p className="flex text-xs text-textColor font-semibold">
                  {t('iban_number_label') ?? ''}
                </p>
                <p className="flex text-sm text-primaryText font-medium">
                  {item.IBAN_number}
                </p>
              </div>
            </div>
          </div>
        ))}
      <MenuDropDown
        anchorEl={anchorEl}
        handleClose={handleClose}
        menuData={menu}
        handleMenuActions={handleMenuActions}
      />
      <AddBankDetails
        // detailId={id as string}
        handleRefresh={handleRefresh}
        defaultValues={bankDetailData}
        mode={mode}
      />
      <DeleteModal
        open={deleteModal}
        setOpen={setDeleteModal}
        title={t('delete_bank_account_label') ?? ''}
        subTitle={t('delete_bank_account_details_shorttext') ?? ''}
        description={t('delete_account_retrieve_msg') ?? ''}
        handleRefresh={handleRefresh}
        handleDelete={handleDelete}
      />
    </>
  );
}
