import { useFilter } from '../store/filterStore';
import useBrowserWidth from '../utilities/responsiveHook';
import Header from './header';

import '../styles/style.css';

export default function Layout({ children }: any) {
  const { openFilter, setOpenFilter } = useFilter();
  const { isBrowser, isMobile, inlineWidth } = useBrowserWidth();
  console.log(inlineWidth);
  return (
    <>
      <Header />
      {/* {(openFilter == "jobs" || openFilter == "findWorks") && <FilterHead />} */}

      <div className="h-screen block mainScreen">
        <main
          className={`flex flex-col w-full  bg-mainBgColor ${
            isMobile ? 'py-0' : 'py-6'
          }`}
        >
          <div
            className={`${
              inlineWidth < 1200 && inlineWidth > 470
                ? 'w-5/6'
                : 'lg:w-3/4 2xl:w-2/3  w-full '
            }  flex items-center justify-center mx-auto`}
          >
            {children}
          </div>
        </main>
      </div>
    </>
  );
}
