import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import { AiOutlineClose } from "react-icons/ai";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
type Props = {
  open: boolean;
  title: string;
  subtext?: string;
  handleClose: () => void;
  maxWidth: "xs" | "sm" | "md" | "lg" | "xl" | false | undefined;
  children: React.ReactNode;
  primaryButton?: {
    title?: string;
    action: () => void;
  };
  secondaryButton?: {
    title?: string;
    action: () => void;
  };
  dialogActions?: React.ReactNode;
  className?: string;
};
export default function CustomDialogBox(props: Props) {
  const {
    open,
    handleClose,
    title,
    subtext,
    children,
    secondaryButton,
    primaryButton,
    maxWidth,
    className,
    dialogActions,
  } = props;
  const trimLang: string = (
    localStorage.getItem("i18nextLng") ?? "en"
  ).substring(0, 2);
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={maxWidth}
        aria-describedby="alert-dialog-slide-description"
        className={`${className} ${
          maxWidth == "md" ? "modalLarge" : ""
        } dialogoverflow`}
        dir={trimLang === "Ar" || trimLang === "ar" ? "rtl" : ""}
      >
        <div className="flex flex-col gap-5 commonModalBlock p-0">
          <DialogTitle className="flex justify-between items-start text-base p-5 pb-0 leading-none	gap-3">
            <div className="flex flex-col">
              <h2 className="grow text-xl font-bold m-0 text-titleColor">
                {title}
              </h2>
              <p className="text-sm text-textColor">{subtext}</p>
            </div>
            <IconButton
              className="text-base p-0 items-center"
              disableRipple
              onClick={() => handleClose()}
            >
              <AiOutlineClose className="w-5 h-5 text-titleColor" />
            </IconButton>
          </DialogTitle>
          <DialogContent className="px-5 py-0 overflow-y-auto">
            {children}
          </DialogContent>

          {dialogActions && (
            <DialogActions className="p-5 pt-0">{dialogActions}</DialogActions>
          )}
        </div>
      </Dialog>
    </div>
  );
}
