import { getData, postData } from "../api.helpers";
import apiUrl from "../api.url";

export const login = (data: any) =>
  postData(apiUrl.LOGIN_URL, data).then((res) => res);

export const registerApi = (data: any) =>
  postData(apiUrl.REGISTER, data).then((res) => res);
export const verifyUserName = (data: string) =>
  getData(`${apiUrl.DUPLICATE_USER}${data}`).then((res) => res.data);
export const forgotPassword = (data: any) =>
  postData(apiUrl.FORGOTPASSWORD, data).then((res) => res.data);

export const resetPassword = (data: any) =>
  postData(apiUrl.RESET_PASSWORD, data).then((res) => res);
export const emailVerification = (data: any) =>
  postData(apiUrl.VERIFY_EMAIL, data).then((res) => res);
export const googleLogin = () =>
  getData(`${apiUrl.GOOGLLOGIN}`).then((res) => res);
export const googleCallBack = (data: any) =>
  getData(`${apiUrl.GOOGLECALLBACK}${data}`).then((res) => res);
