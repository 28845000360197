import { useTranslation } from "react-i18next";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import CustomDialogBox from "../../customComponents/dialogbox";
import { useUserManagement } from "../../store/authStore";

export default function ShareDetails() {
  const { t } = useTranslation();

  const { setOpenShare, openShare, setOpenModel } = useUserManagement();
  const handleClose = () => {
    setOpenShare(false);
  };
  return (
    <>
      <CustomDialogBox
        open={openShare.open}
        title={t("share_heading" ?? "") ?? ""}
        maxWidth={"xs"}
        handleClose={() => handleClose()}
      >
        <div className="flex p-5 pt-0 gap-3 items-center justify-center">
          <FacebookShareButton
            url={openShare.link}
            onClick={() => handleClose()}
          >
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton
            url={openShare.link}
            onClick={() => handleClose()}
          >
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
          <EmailShareButton url={openShare.link} onClick={() => handleClose()}>
            <EmailIcon size={40} round={true} />
          </EmailShareButton>
        </div>
      </CustomDialogBox>
    </>
  );
}
