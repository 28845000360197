import { deleteData, getData, postData } from "../../../apis/api.helpers";
import apiUrl from "./apiUrl";

export const updateProfile = (data: any) =>
  postData(apiUrl.PROFILE_UPDATE_URL, data).then((res) => res);

export const getProfileData = () =>
  getData(`${apiUrl.PROFILE}`).then((res) => res);

export const deleteBankDetails = (id: string) =>
  deleteData(`${apiUrl.USER_BANK_DETAILS_DELETE}/${id}`).then((res) => res);
  
  export const portfolioImgDelete = () =>
  deleteData(`${apiUrl.PROFILE_IMG_DELETE}`).then((res) => res);

  export const portfolioDelete = (id: string) =>
  deleteData(`${apiUrl.PORTFOLIO_DELETE}/${id}`).then((res) => res);

export const identityDocumentDelete = (id: string) =>
  deleteData(`${apiUrl.IDENTITY_DOCUMENT_DELETE}/${id}`).then((res) => res);

export const resumeDelete = (id: string) =>
  deleteData(`${apiUrl.RESUME_DELETE}/${id}`).then((res) => res);

export const setAsPrimary = (id: string) =>
  getData(`${apiUrl.SET_AS_PRIMARY}/${id}`).then((res) => res);

export const deleteUserEducation = (id: string) =>
  deleteData(`${apiUrl.QUALIFICATION_DELETE}/${id}`).then((res) => res);

export const deleteUserExperience = (id: string) =>
  deleteData(`${apiUrl.EXPERIENCE_DELETE}/${id}`).then((res) => res);
  