import { Divider, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillInfoCircle } from "react-icons/ai";

import { getUserProfileVerification } from "../../apis/commonapis/apis";
import { ProfileVerification } from "../../common/types";
import CustomButton from "../../customComponents/button";
import CustomDialogBox from "../../customComponents/dialogbox";
import {
  getAddonPurchase,
  getPlanPurchase,
} from "../../pages/subscription/apis/apis";
import { useUserManagement } from "../../store/authStore";
import { useCompanySwitchStore } from "../../store/companySwitch";
import { usePaymentStore } from "../../store/paymentStore";
import ChooseProfileDropdown from "../chooseProfile/chooseProfileDropdown";

type Props = {
  open: boolean;
  title: string;
  subtext?: string;
  handleClose: (value: boolean) => void;
  className?: string;
  itemDetails?: any;
  detailedText?: string;
  detailedSubText?: string;
  from: string;
  type?: string;
  detailsId?: string;
};

export default function PlanPurchaseModal(props: Props) {
  const {
    open,
    handleClose,
    title,
    subtext,
    className,
    itemDetails,
    detailedText,
    detailedSubText,
    from,
    detailsId,
    type,
  } = props;
  const [dropData, setDropData] = useState<ProfileVerification[]>();
  const [selectedData, setSelectedData] = useState<ProfileVerification | null>(
    null
  );
  const { companyDetail, setCompanyDetail } = useCompanySwitchStore();

  const { t } = useTranslation();
  const handleProfileVerificationData = () => {
    getUserProfileVerification()
      .then((res) => {
        setDropData(res?.data?.users);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    handleProfileVerificationData();
  }, []);
  const handleMenuClick = (item: ProfileVerification, index: number) => {
    const data = dropData;
    data?.forEach((item, ind) => {
      if (ind === index) {
        item["active"] = true;
      } else {
        item["active"] = false;
      }
    });
    if (item.is_user) {
      setCompanyDetail(null);
    } else {
      setCompanyDetail(item);
    }
    setDropData(data);
    setSelectedData(item);
  };
  const { setOpenEmailVerifyModel } = useUserManagement();
  const handleSelectedData = () => {
    if (dropData && dropData?.length > 0) {
      let isUser = true;
      dropData?.forEach((item) => {
        if (companyDetail && companyDetail?.id == item?.id) {
          setSelectedData(item);
          isUser = false;
        }
      });
      if (isUser) {
        const userData = dropData.find((item) => item?.is_user);
        setSelectedData(userData ?? dropData[0]);
      }
    }
  };
  const handleDropData = () => {
    if (dropData) {
      const userData = dropData?.find((item) => item?.is_user);
      if (userData?.is_email_verified) {
        handleSelectedData();
      } else {
        handleClose(false);
        setOpenEmailVerifyModel(true);
      }
    }
  };
  useEffect(() => {
    if (dropData && dropData?.length > 0) {
      handleDropData();
    }
  }, [dropData]);
  const { setCurrentToken } = usePaymentStore();
  const handlePayment = async () => {
    const baseUrl = window.location.origin;
    if (type === "projectAddon") {
      setCurrentToken(detailsId);
    } else {
      setCurrentToken(itemDetails?.id);
    }
    if (from === "addon") {
      const params = {
        addon_id: itemDetails?.id,
        purchase_company_id: !selectedData?.is_user ? selectedData?.id : null,
        success_url:
          type === "projectAddon"
            ? `${baseUrl}/addon-payment-success/${detailsId}`
            : `${baseUrl}/payment-success/${itemDetails?.id}`,
        cancel_url:
          type === "projectAddon"
            ? `${baseUrl}/payment-deposit-error/${detailsId}`
            : `${baseUrl}/payment-error/${detailsId}`,
        // ? `${baseUrl}/payment-deposit-error/${itemDetails?.id}`
        // : `${baseUrl}/payment-error/${itemDetails?.id}`,
      };
      const { data } = await getAddonPurchase(params);
      if (data?.session_url) {
        window.open(data?.session_url, "_self");
      }
    } else {
      const params = {
        plan_id: itemDetails?.id,
        purchase_company_id: !selectedData?.is_user ? selectedData?.id : null,
        success_url: `${baseUrl}/payment-success/${itemDetails?.id}`,
        cancel_url: `${baseUrl}/payment-error/${itemDetails?.id}`,
      };
      const { data } = await getPlanPurchase(params);
      if (data?.session_url) {
        window.open(data?.session_url, "_self");
      }
    }
  };
  return (
    <>
      {selectedData && Object.keys(selectedData)?.length > 0 && (
        <CustomDialogBox
          open={open}
          title={title}
          subtext={subtext}
          maxWidth={"xs"}
          handleClose={() => handleClose(false)}
          className="chooseProfileModal"
          dialogActions={
            <div className="flex items-center justify-end gap-3 flex-shrink-0 w-full">
              <CustomButton
                title={t("cancel_button") ?? ""}
                className=" secondaryButton flex-1"
                onClick={() => handleClose(false)}
              />
              <CustomButton
                title={t("proceed_payment_btn") ?? ""}
                className=" primaryButton flex-1"
                onClick={() => handlePayment()}
              />
            </div>
          }
        >
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-5">
              <ChooseProfileDropdown
                selectedData={selectedData}
                dropData={dropData}
                handleMenuClick={handleMenuClick}
              />
              {from == "addon" ? (
                <div className=" shadow-cardShadow bg-bgWhite rounded-xl p-3 flex flex-col gap-3">
                  <div className="bg-dropzoneBg rounded-lg p-3 flex flex-col">
                    <p className=" text-sm font-bold text-primaryText">
                      {itemDetails?.addon_type?.title}
                    </p>
                    <p className=" text-xs text-success font-semibold">
                      {t("buy_addons_featur_label") ?? ""} : +
                      {itemDetails?.value} {t("count_other") ?? ""}
                    </p>
                    <p className="text-xs font-medium text-primaryText">
                      {itemDetails?.user_type}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <p className=" text-sm text-textColor">
                        {t("add_on_cost") ?? ""}
                      </p>
                      <p className=" text-sm text-textColor">
                        {`${itemDetails?.currency ?? "- -"} ${
                          itemDetails?.price_formatted ?? "- -"
                        }`}
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className=" text-sm text-textColor">
                        {t("sub_tax_label") ?? ""}
                      </p>
                      <p className=" text-sm text-textColor">
                        {`${itemDetails?.currency ?? "- -"} ${
                          itemDetails?.tax_amount ?? "- -"
                        }`}
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className=" text-sm text-textColor">
                        {t("transaction_charges_label") ?? ""}
                      </p>
                      <p className=" text-sm text-textColor">
                        {`${itemDetails?.currency ?? "- -"} ${
                          itemDetails?.transaction_charge ?? "- -"
                        }`}
                      </p>
                    </div>
                    <Divider />
                    <div className="flex justify-between items-center">
                      <p className=" text-base text-primaryText font-bold">
                        {t("sub_total_label") ?? ""}
                      </p>
                      <p className=" text-base text-primaryText font-bold">
                        {`${itemDetails?.currency ?? "- -"} ${
                          itemDetails?.total_price ?? "- -"
                        }`}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center border border-formBorder rounded-lg p-3 gap-4">
                    <p className="flex-1 text-xs text-primaryText max-w-[160px]">
                      {t("payment_by_cards_message" ?? "")}
                    </p>
                    <div className="flex items-center gap-3 justify-end">
                      <span>
                        <img src="/assets/mastercard.png" />
                      </span>
                      <span>
                        <img src="/assets/visa.png" />
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" shadow-cardShadow bg-bgWhite rounded-xl p-3 flex flex-col gap-3">
                  <div className="bg-dropzoneBg rounded-lg p-3 flex flex-col">
                    <p className=" text-sm font-bold text-primaryText">
                      {itemDetails?.title}
                    </p>

                    <p className=" text-xs text-primaryText">{`${t(
                      "validity_label" ?? ""
                    )} : ${itemDetails?.validity_in_days}  ${
                      t("days_text") ?? ""
                    }`}</p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <p className=" text-sm text-textColor">
                        {t("plan_cost_label") ?? ""}
                      </p>
                      <p className=" text-sm text-textColor">
                        {`${itemDetails?.currency ?? "- -"} ${
                          itemDetails?.price ?? "- -"
                        }`}
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className=" text-sm text-textColor">
                        {t("sub_tax_label") ?? ""}
                      </p>
                      <p className=" text-sm text-textColor">
                        {`${itemDetails?.currency ?? "- -"} ${
                          itemDetails?.tax_amount ?? "- -"
                        }`}
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className=" text-sm text-textColor">
                        {t("transaction_charges_label") ?? ""}
                      </p>
                      <p className=" text-sm text-textColor">
                        {`${itemDetails?.currency ?? "- -"} ${
                          itemDetails?.transaction_charge ?? "- -"
                        }`}
                      </p>
                    </div>
                    <Divider />
                    <div className="flex justify-between items-center">
                      <p className=" text-base text-primaryText font-bold">
                        {t("sub_total_label") ?? ""}
                      </p>
                      <p className=" text-base text-primaryText font-bold">
                        {`${itemDetails?.currency ?? "- -"} ${
                          itemDetails?.total_price ?? "- -"
                        }`}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center border border-formBorder rounded-lg p-3 gap-4">
                    <p className="flex-1 text-xs text-primaryText max-w-[160px]">
                      {t("payment_by_cards_message") ?? ""}
                    </p>
                    <div className="flex items-center gap-3 justify-end">
                      <span>
                        <img src="/assets/mastercard.png" />
                      </span>
                      <span>
                        <img src="/assets/visa.png" />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className=" bg-activeStateBg rounded-lg flex items-start p-4 gap-2">
                <IconButton className="p-0">
                  <AiFillInfoCircle className=" text-primary w-5 h-5" />
                </IconButton>
                <div className="flex flex-col gap-1">
                  <p className=" text-primary text-sm font-bold">
                    {t("terms_and_conditions") ?? ""}
                  </p>
                  <div className="flex flex-col gap-3">
                    <p className="text-primary text-xs font-medium">
                      {detailedText}
                    </p>
                    <p className="text-primary text-xs font-medium">
                      {detailedSubText}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomDialogBox>
      )}
    </>
  );
}
