import { MenuItem } from "@mui/material";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";

type Props = {
  menuData: {
    id: string;
    title: string;
    action: string;
    divider?: boolean;
  }[];
  anchorEl: any;
  handleClose: () => void;
  handleMenuActions: (type: string) => void;
};
export default function MenuDropDown(props: Props) {
  const { menuData, anchorEl, handleClose, handleMenuActions } = props;
  const { t } = useTranslation();

  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className=""
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      {menuData.map((item) => (
        <>
          {item.divider && <Divider />}
          {item.action == "delete" || item.action == "dispute" ? (
            <MenuItem
              key={item.id}
              onClick={() => handleMenuActions(item.action)}
              className="text-sm text-warning"
            >
              <span>{t(item.title ?? "")}</span>
            </MenuItem>
          ) : (
            <MenuItem
              key={item.id}
              onClick={() => handleMenuActions(item.action)}
              className="text-sm"
            >
              <span>{t(item.title ?? "")}</span>
            </MenuItem>
          )}
        </>
      ))}
    </Menu>
  );
}
