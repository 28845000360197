import { Popover, Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { TfiAngleLeft } from 'react-icons/tfi';
import { useLocation } from 'react-router-dom';

import useBrowserWidth from '../utilities/responsiveHook';

type Props = {
  label?: string;
  value?: string | number;
  onChange?: (e: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  search?: boolean;
  name?: string;
  type?: string;
  register?: any;
  placeholder?: string;
  disabled?: boolean;
  textarea?: boolean;
  className?: string;
  error?: any;
  direction?: string;
};
export default function LanguageSwitcher(props: Props) {
  const {
    label,

    required,
  } = props;
  const [langDesc, setLangDesc] = useState(window.navigator.language);
  const { t } = useTranslation();
  const dropItem = [
    {
      name: 'English',
      href: '##',
      lang: 'En',
    },
    {
      name: 'Arabic',
      href: '##',
      // lang: "ع",
      lang: 'ar',
    },
  ];
  const lang: string = localStorage.getItem('i18nextLng') ?? 'en';
  useEffect(() => {
    setLangDesc(
      lang ? (localStorage.getItem('i18nextLng') ?? 'en').substring(0, 2) : lang
    );
  }, []);
  const { isBrowser, isMobile } = useBrowserWidth();

  const location = useLocation();
  const handleChangeLang = (lang: string) => {
    setLangDesc(lang);
    localStorage.setItem('i18nextLng', lang);
    window.open(location.pathname, '_self');
  };
  // const closePopover = (close()) => {
  //   setLangDesc(lang);
  // };
  return (
    <>
      {label && (
        <label className="block justify-start labelText">
          {required ? `${label} *` : label}
        </label>
      )}

      <div className="flex relative items-center ">
        <div className="flex items-center">
          <div>
            <Popover className="relative ">
              {({ open, close }) => (
                <>
                  <Popover.Button className={`relative border-0 outline-0 `}>
                    {isMobile ? (
                      <div className="flex gap-2.5">
                        <span className=" min-w-[20px] flex items-center justify-center text-sm capitalize text-primaryText font-semibold ">
                          {langDesc}
                        </span>
                        <span className="text-sm">
                          {t('language_filter_label') ?? ''}
                        </span>
                      </div>
                    ) : (
                      <span className=" min-w-[40px] flex items-center justify-center text-sm capitalize text-primaryText font-semibold ">
                        {langDesc}
                      </span>
                    )}
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      className={` transform ${
                        isMobile
                          ? 'fixed left-0 z-50 top-[55px] h-[calc(100%-111px)] w-full'
                          : 'absolute right-0 z-10 mt-2.5'
                      }`}
                    >
                      <div
                        className={`overflow-hidden bg-bgWhite shadow-footerShadow p-2 ${
                          isMobile
                            ? 'w-full h-full rounded-none'
                            : 'w-[200px]  rounded-lg'
                        }`}
                      >
                        {isMobile && (
                          <div className="flex items-center justify-start bg-bgWhite h-10 z-40 customWidth ">
                            <>
                              <button
                                className="p-0 w-7 h-7 absolute left-2 flex justify-center rtl:right-2 rtl:left-auto"
                                onClick={() => close()}
                              >
                                <TfiAngleLeft className="w-4  text-primaryText rtl:rotate-180" />
                              </button>
                              <p className=" text-primaryText font-medium text-base flex-1 text-center">
                                Profile
                              </p>
                            </>
                          </div>
                        )}
                        <div className="relative flex flex-col">
                          {dropItem.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              onClick={() => handleChangeLang(item.lang)}
                              className=" flex items-start rounded-lg p-2.5 gap-2.5 transition hover:bg-menuHover duration-150 ease-in-out  focus:outline-none "
                            >
                              <div className="flex flex-col">
                                <p className="text-sm font-medium text-primaryText">
                                  {item.name}
                                </p>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
}
