// import { useFormContext } from "../../common/components/profile/userSchema";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ProfileSchema } from "../../common/userSchema";
import TextareaInput from "../../customComponents/textfield/textarea";

type Props = {
  fromDialog?: boolean;
};
export default function AboutDescription(props: any) {
  const {
    register,
    formState: { errors },
  } = useFormContext<ProfileSchema>();
    const { t } = useTranslation();


  return (
    <div className="flex flex-col gap-5">
      {!props.fromDialog && (
        <p className="text-base font-bold text-primaryText">
          {t("aboutme_tab") ?? ""}
        </p>
      )}

      <div className="grid grid-cols-1 gap-4">
        <div className="col-span-1">
          <TextareaInput
            label={t("description_label") ?? ""}
            placeholder={t("jobprofile_description_placeholder") ?? ""}
            textarea={true}
            className="customTextarea"
            register={register("description")}
            error={errors.description?.message}
          />
        </div>
      </div>
    </div>
  );
}
