import { Tab } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddOnList from '../../components/subscriptionComponent/addOnList';
import useBrowserWidth from '../../utilities/responsiveHook';
import { getAddonList } from './apis/apis';

export default function SubscriptionAddOn() {
  const [addonData, setAddonData] = useState<any>();
  const [addOnType, setAddOnType] = useState<string>('project');
  const { t } = useTranslation();
  const { isBrowser, isMobile } = useBrowserWidth();

  const handleAddonList = async (type: string) => {
    const { data } = await getAddonList(type);
    setAddonData(data);
  };
  useEffect(() => {
    handleAddonList(addOnType);
  }, [addOnType]);
  return (
    <>
      <Tab.Group>
        <div
          className={` flex flex-col items-center justify-center w-full gap-4 ${
            isMobile && 'pb-14'
          }`}
        >
          <div
            className={`customCard customWidth  flex flex-col gap-3 ${
              isMobile ? 'p-4' : 'p-5 px-6'
            }`}
          >
            <div
              className={`flex justify-between gap-3 ${
                isMobile ? 'flex-col items-start' : 'items-center'
              }`}
            >
              <div className="relative flex-1">
                <p className="text-xl font-bold text-primaryText">
                  {t('add_ons_heading') ?? ''}
                </p>
                <p className="text-sm font-semibold text-secondaryText">
                  {/* Change your plan based on your needs */}
                  {t('buy_addons_caption') ?? ''}
                </p>
              </div>
              <div className="flex-1 flex items-center justify-end">
                <Tab.List className="customTab">
                  <Tab
                    className="customTabItem"
                    onClick={() => setAddOnType('project')}
                  >
                    {t('project_add_ons') ?? ''}
                  </Tab>
                  <Tab
                    className="customTabItem"
                    disabled={true}
                    onClick={() => setAddOnType('job')}
                  >
                    {t('job_add_ons') ?? ''}
                  </Tab>
                </Tab.List>
              </div>
            </div>
            <Tab.Panels>
              <Tab.Panel>
                {addonData &&
                  Object.keys(addonData)?.map((item) => (
                    <AddOnList
                      title={item}
                      data={addonData ? addonData[item] : {}}
                    />
                  ))}
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </div>
      </Tab.Group>
    </>
  );
}
