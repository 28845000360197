import serverApi from "./core";

const handleData = () => {
  const data =
    localStorage.getItem("switched_company") &&
    JSON.parse(localStorage.getItem("switched_company") ?? "");
  return data?.state?.companyDetail;
};
export const getData = (url: string) =>
  serverApi
    .get(url, {
      headers: {
        "Company-id": handleData()?.id ?? null,
      },
    })
    .then((res) => res.data);

export const postData = (url: string, params: any) =>
  serverApi
    .post(url, params, {
      headers: {
        "Company-id": handleData()?.id ?? null,
      },
    })
    .then((res) => res.data);

export const postJsonData = (url: string, params: any) =>
  serverApi
    .post(url, params, {
      headers: {
        "Company-id": handleData()?.id ?? null,
      },
    })
    .then((res) => res.data);
export const updateData = (url: string, params: any) =>
  serverApi
    .put(url, params, {
      headers: {
        "Company-id": handleData()?.id ?? null,
      },
    })
    .then((res) => res.data);

export const deleteData = (url: string) =>
  serverApi
    .delete(url, {
      headers: {
        "Company-id": handleData()?.id ?? null,
      },
    })
    .then((res) => res.data);
