import moment from "moment";
import { string, z } from "zod";

const currentYear = moment().year();

const MAX_FILE_SIZE = 5000000;
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];
const ACCEPTED_FILE_TYPES = [
  "application/msword",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const profileSchema = z.object({
  // Basic Information
  profile_image: z
    .instanceof(File)
    .refine(
      (files: File) => files.size <= MAX_FILE_SIZE,
      // `Max image size is 5MB.`
      { message: "max_file_size_is_5mb_femsg" }
    )
    .refine(
      (files: File) => ACCEPTED_IMAGE_TYPES.includes(files?.type),
      // "Only .jpg, .jpeg, .png and .pdf formats are supported."
      { message: "comp_reg_doc_formats_femsg" }
    )
    .array()
    .optional()
    .nullable(),
  name: z
    .string({ invalid_type_error: "name_is_required_femsg" })
    .min(3, { message: "name_least_characters_femsg" })
    .nullable(),
  user_name: z
    .string({ invalid_type_error: "user_name_required_femsg" })
    .min(1, { message: "user_name_required_femsg" }),
  // current_designation: z
  //   .string({ invalid_type_error: "Required" })
  //   .min(1, { message: "Required" }),
  current_designation: z
    .string({ invalid_type_error: "current_designation_required_femsg" })
    .optional()
    .nullable(),
  profile_headline: z.string().optional().nullable(),
  identity_document_type: z
    .string({ invalid_type_error: "identity_docno_required_femsg" })
    .min(1, { message: "identity_document_required_femsg" })
    .optional()
    .nullable(),
  identity_document_number: z
    .string({ invalid_type_error: "identity_docno_required_femsg" })
    .optional()
    .nullable(),

  identity_file_details: z
    .object({
      identity_document_type_id: z.string().min(1, { message: "Required" }),
      identity_document_number: z.string().min(1, { message: "identity_document_number_exist_message" }),
      id: z.string().optional().nullable(),
    })
    .array(),

  identity_documents: z
    .instanceof(File)
    // z.any()
    // .refine((files) => !files, "Required."
    // )
    .refine(
      (files: File) => files.size <= MAX_FILE_SIZE,
      // `Max file size allowed is 5MB.`
      { message: "max_filesize_allowed_5mb_femsg" }
    )
    .refine(
      (files: File) => ACCEPTED_FILE_TYPES.includes(files?.type),
      // "Only .doc, .docx, and .pdf formats are supported."
      { message: "useridenity_doc_formats_femsg" }
    )
    .optional()
    .array()
    .nullable(),


  identity_document_data: z.instanceof(File).array().optional().nullable(),

  pancard: z
    .string({ invalid_type_error: "Required" })
    .regex(new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}/), {
      message: "invalid_pan_number_femsg",
    }),

  aadhaar: z
    .string({ invalid_type_error: "Required" })
    .regex(
      new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/),
      "Invalid aadhaar Number"
    ),

  // Personal Information
  email: z
    .string({ invalid_type_error: "enter_a_valid_email_femsg" })
    .trim()
    .email({ message: "enter_a_valid_email_femsg" })
    .min(1, { message: "email_is_required_femsg" }),
  phone: z
    .string({
      required_error: "mobile_number_required_femsg",
      invalid_type_error: "mobile_number_required_femsg",
    })
    .trim()
    .min(6, { message: "phone_atleast_8characters_femsg" })
    .max(15, { message: "enter_valid_mobile_number_femsg" })
    .regex(
      new RegExp(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      ),
      // "Enter a valid mobile number"
      { message: "enter_valid_mobile_number_femsg" }
    ),
  gender_id: z.string().optional().nullable(),
  date_of_birth: z
    .string({ invalid_type_error: "date_of_birth_required_femsg" })
    .min(1, { message: "date_of_birth_required_femsg" }),
  age: z.number().optional(),
  // nationality_id: z
  //   .string({ invalid_type_error: "nationality_required_femsg" })
  //   .min(1, { message: "nationality_required_femsg" }),
    // nationality_id: z
    // .object({ id: z.string(), name: z.string({ invalid_type_error: "nationality_required_femsg" }) }),
    nationality: z
    .object({ id: z.string(), name: z.string({ invalid_type_error: "nationality_required_femsg" }) }),
  state_id: z.string().optional().nullable(),
  // city: z
  //   .string({ invalid_type_error: "Required" })
  //   .min(1, { message: "Required" }),
  city: z
    .string({ invalid_type_error: "city_required_femsg" })
    .optional()
    .nullable(),
  is_handicapped: z.boolean().optional().nullable(),
  current_location: z.string().optional().nullable(),
  place_id: z.string().optional().nullable(),

  // Professional Info
  year_of_experience: z
    .number({ invalid_type_error: "enter_only_numbers_femsg" })
    .optional()
    .nullable()
    .transform((e) => (e ? e : null)),
  work_authorisation_status_id: z.string().optional().nullable(),
  employment_status_id: z.string().optional().nullable(),
  notice_period: z.string().optional().nullable(),
  current_salary: z
    // .number({ required_error: "current_salary_required_femsg",invalid_type_error: "current_salary_required_femsg" })
    // .min(1, { message: "current_salary_required_femsg" })
    // .transform((e) => (e ? e : undefined)),
    .string({ required_error: "current_salary_required_femsg",invalid_type_error: "current_salary_required_femsg" }).regex(
      new RegExp(
        /^\d+$/
      ),
      {message:"enter_only_numbers_femsg"}
    )
    .refine((val) => parseInt(val) !== 0, {
      message: "number_greaterthan_zero"
    }),

  // About me
  description: z.string().optional().nullable(),
  // Skill preference

  skill_sets: z
    .object({ id: z.string(), name: z.string() })
    .array()
    .optional()
    .nullable(),
  // Experience

  job_details: z
    .object({
      id: z.string().optional(),
      designation: z
        .string({required_error: "designation_required_femsg"})
        .trim()
        .min(1, { message: "designation_required_femsg" }),
      company: z
        .string()
        .trim()
        .min(1, { message: "company_name_required_femsg" }),
      employment_type: z.string().optional().nullable(),
      start_date: z
        .string()
        .trim()
        .min(1, { message: "start_date_required_femsg" }),
      end_date: z
        .string()
        .trim()
        .min(1, { message: "end_date_required_femsg" }),
      start_year: z.string().optional(),
      end_year: z.string().optional(),
      location: string().optional().nullable(),
      job_role_description: string().optional().nullable(),
      // industry_id: string().optional().nullable(),
      industry: z
      .object({ id: z.string(), name: z.string() })
      .optional().nullable(),
      total_experience: z.string().optional().nullable(),
    })
    .superRefine((data, ctx) => {
      if (data?.start_date && data?.end_date) {
        if (Date.parse(data?.end_date) < Date.parse(data?.start_date)) {
          ctx.addIssue({
            message: "end_date_no_greater_start_date_femsg",
            code: z.ZodIssueCode.custom,
            path: ["end_date"],
          });
        }
      }
    })
    .array()
    .optional(),

  qualifications: z
    .object({
      qualification_id: string().min(1, {
        message: "qualification_name_required_femsg",
      }),
      institution: string().min(1, { message: "schoolcollege_required_femsg" }),
      // specialisation: string().min(1, {
      //   message: "specialation_required_femsg",
      // }),
      qualification_specialisation: z
      .object({ id: z.string(), name: z.string({ invalid_type_error: "specialation_required_femsg" }) })
      .optional().nullable(),
      year_of_passing: string()
        .min(4, {
          message: "passing_year_required_femsg",
        })
        .refine((val: string) => parseInt(val) <= currentYear, {
          message: "enter_a_valid_year_femsg",
        }),
    })
    .array()
    .optional(),

  //  Preference
  languages: z
    .object({ id: z.string(), name: z.string() })
    .array()
    .optional()
    .nullable(),
  work_sectors: z
    .object({ id: z.string(), name: z.string() })
    .array()
    .optional()
    .nullable(),
  work_locations: z
    .object({ id: z.string(), name: z.string() })
    .array()
    .optional()
    .nullable(),

  recommendationTags: z
    .object({ id: z.string(), name: z.string() })
    .array()
    .optional()
    .nullable(),

  // Social media pre

  social_media_details: z
    .object({
      name: z.string().optional(),
      link: z
        .union([
          z.string().length(0, { message: "enter_valid_url_femsg" }),
          z
            .string({ invalid_type_error: "" })
            .regex(
              new RegExp(
                /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
              ),
              "enter_valid_url_femsg"
            ),
        ])
        .optional()
        .transform((e) => (e === "" ? undefined : e))
        .nullable(),
      checked: z.boolean().optional(),
    })
    .superRefine((value, ctx) => {
      if (value.checked && !value.link) {
        ctx.addIssue({
          message: "enter_valid_url_femsg",
          path: ["link"],
          code: "custom",
        });
      }
      return value;
    })
    .array()
    .nullable(),

  //Upload portfolio
  portfolioData: z.instanceof(File).array().optional().nullable(),
  attachmentData: z.instanceof(File).array().optional().nullable(),

  portfolios: z
    .instanceof(File)
    .refine(
      (files: File) => files.size <= MAX_FILE_SIZE,
      {message: "max_filesize_allowed_5mb_femsg"}
    )
    .refine(
      (files: File) => ACCEPTED_FILE_TYPES.includes(files?.type),
      // "Only .doc, .docx and .pdf formats are supported."
      { message: "useridenity_doc_formats_femsg" }
    )
    .array()
    .optional()
    .nullable(),
  //Upload resume
  attachments: z
    .instanceof(File)
    .refine(
      (files: File) => files.size <= MAX_FILE_SIZE,
      {message: "max_filesize_allowed_5mb_femsg"}
    )
    .refine(
      (files: File) => ACCEPTED_FILE_TYPES.includes(files?.type),
      // "Only .doc, .docx  and .pdf formats are supported."
      { message: "useridenity_doc_formats_femsg" }
    )
    .array()
    .optional()
    .nullable(),

  // Bank Details
  bankdetails: z
    .object({
      bank_name: string({ invalid_type_error: "bank_name_required_femsg" }).min(
        1,
        {
          message: "bank_name_required_femsg",
        }
      ),
      branch_name: string({
        invalid_type_error: "branch_name_required_femsg",
      }).min(1, {
        message: "branch_name_required_femsg",
      }),
      account_number: string({
        invalid_type_error: "account_number_required_femsg",
      }).min(1, {
        message: "account_number_required_femsg",
      }),
      bank_address: string().optional().nullable(),
      IBAN_number: z
        .string()
        // .string({ invalid_type_error: "iban_number_required_femsg" })
        // .min(1, { message: "iban_number_required_femsg" })
        // .regex(
        //   new RegExp(
        //     /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/
        //   ),
        //   {message: "invalid_iban_code_femsg"}
        // )
        .optional()
        .transform((e) => (e === "" ? undefined : e))
        .nullable(),
        

      swift_code: z
        .string({ invalid_type_error: "swift_number_required_femsg" })
        .min(1, { message: "swift_number_required_femsg" })
        .regex(
          new RegExp(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/),
          {message:"invalid_swift_code_femsg"}
        ),
    }).superRefine((value, ctx) => {
      if (value?.IBAN_number) {
        if ( !(/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(value.IBAN_number)) ) {
            ctx.addIssue({
              message: "invalid_iban_code_femsg",
              code: z.ZodIssueCode.custom,
              path: ["IBAN_number"],
            });
        }
      }
    })
    .array()
    .optional(),

  // tax
  pan_number: z
    .union([
      z.string().length(0, { message: "invalid_pan_number_femsg" }),
      z
        .string()
        .max(10)
        .regex(
          new RegExp(/^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/),
          {message:"invalid_pan_number_femsg"}
        ),
    ])
    .transform((e) => (e === null || e === "" ? "" : e))
    .nullable(),
  gst_number: z
    .union([
      z.string().length(0, { message: "invalid_gst_number_femsg" }),
      z
        .string()
        .regex(
          new RegExp(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
          ),
{ message: "invalid_gst_number_femsg" }        ),
    ])
    .transform((e) => (e === null || e === "" ? "" : e))
    .nullable(),

  // trn_number: z
  //   .string({ invalid_type_error: "Required" })
  //   .min(1, { message: "Required" }).nullable(),
  trn_number: z
    .string({ invalid_type_error: "Required" })
    .optional()
    .nullable(),
});

export type ProfileSchema = z.infer<typeof profileSchema>;
