import { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { getDropDownData } from "../../apis/dropdowns/dropDown.api";
import { ProfileSchema } from "../../common/userSchema";
import Dropzone from "../../customComponents/Drag&Drop";
import SelectField from "../../customComponents/selectfield";
import CustomInput from "../../customComponents/textfield";
import { identityDocumentDelete } from "../../pages/profile/apis/api";
import { UserContext } from "../detailComponent/dataContext";

const dropKeys = ["identity-document-type"];
type Props = {
  fromDialog?: boolean;
  handleRefresh?: () => void;
};
export default function BasicInformation(props: any) {
  const [file, setFile] = useState<any>([]);
  const { t } = useTranslation();

  const {
    register,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext<ProfileSchema>();

  const { dropData, setDropData } = useContext(UserContext);

  const handleDropData = async (key: string) => {
    let drop = dropData;
    const { data, error } = await getDropDownData(key);
    drop = { [key]: data?.data, ...drop };

    setDropData((prevState: any) => ({
      ...prevState,
      [key]: data?.data,
    }));
  };
  useEffect(() => {
    dropKeys.forEach((item) => {
      handleDropData(item);
    });
  }, []);
  const handleFile = () => {
    setValue("identity_documents", file, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleDeleteFile = async (fileId: string) => {
    const { data, error } = await identityDocumentDelete(fileId);
    props.handleRefresh && props.handleRefresh();
  };

  useEffect(() => {
    handleFile();
  }, [file]);
  useEffect(() => {
    handleUploadedFile();
  }, [getValues("identity_documents")]);

  const handleUploadedFile = () => {
    const fileValue = getValues("identity_document_data") ?? [];
    const fileNames: any = [];
    let fileName = "";
    let filePath = "";

    fileValue.map((item: any) => {
      if (item?.actual_file_name) {
        fileName = item.actual_file_name;
        filePath = item.actual_file_path;
      } else {
        fileName = item
          ? item?.actual_file_Path?.substring(
              item.actual_file_Path.lastIndexOf("/") + 1
            )
          : "";
      }
      fileName !== undefined &&
        fileNames?.push({
          id: item.id,
          name: fileName,
          path: filePath,
        });
    });
    //

    if (fileNames) {
      return fileNames;
    } else {
      return [];
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {!props.fromDialog && (
        <p className="text-base font-bold text-primaryText">
          {t("basic_information") ?? ""}
        </p>
      )}

      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 sm:col-span-1 lg:col-span-1">
          <CustomInput
            label={t("full_name_label") ?? ""}
            register={register("name")}
            placeholder={t("full_name_plcaeholder") ?? ""}
            error={t(errors.name?.message ?? "")}
            required={true}
          />
        </div>
        <div className="col-span-2 sm:col-span-1 lg:col-span-1">
          <CustomInput
            label={t("username_label") ?? ""}
            placeholder="@lailaharthi"
            register={register("user_name")}
            error={t(errors.user_name?.message ?? "")}
            required={true}
          />
        </div>
        <div className="col-span-2 sm:col-span-1 lg:col-span-1">
          <CustomInput
            label={t("designation_label") ?? ""}
            // placeholder="UI UX Designer "
            placeholder={t("designation_placeholder") ?? ""}
            register={register("current_designation")}
            error={t(errors.current_designation?.message ?? "")}
            // required={true}
          />
        </div>
        <div className="col-span-2 sm:col-span-1 lg:col-span-1">
          <CustomInput
            label={t("profile_headline_label") ?? ""}
            placeholder={t("profile_headline_plcaeholder") ?? ""}
            // placeholder={t("profile_headline_plcaeholder") ?? ""}
            register={register("profile_headline")}
            error={t(errors.profile_headline?.message ?? "")}
          />
        </div>
        <div className="col-span-2 sm:col-span-1 lg:col-span-1">
          <Controller
            control={control}
            name="identity_document_type"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <SelectField
                onChange={onChange}
                data={(dropData && dropData["identity-document-type"]) ?? [{}]}
                value={value ?? ""}
                label={t("identity_doc_type_label") ?? ""}
                placeholder={t("identity_doc_type_label") ?? ""}
                // placeholder="Select Identity Document Type"
                fullWidth
                className="customSelectField"
                error={t(errors.identity_document_type?.message ?? "") ?? ""}
                required={false}
              />
            )}
          />
        </div>
        <div className="col-span-2 sm:col-span-1 lg:col-span-1">
          <CustomInput
            label={t("identity_doc_number_label") ?? ""}
            placeholder={t("identity_doc_number_plcaeholder") ?? ""}
            register={register("identity_document_number")}
            error={t(errors.identity_document_number?.message ?? "")}
            required={false}
          />
        </div>
        <div className="col-span-2">
          <Dropzone
            file={file}
            setFile={setFile}
            key={JSON.stringify(handleUploadedFile())}
            fileType="DOC, DOCX, PDF"
            size="5 mb"
            buttonText={t("browse&upload_button") ?? ""}
            multiple={false}
            uploadedFile={handleUploadedFile()}
            handleRemoveUploadedFile={handleDeleteFile}
            disableDelete={handleUploadedFile()?.length > 0 ? true : false}
            error={
              errors?.identity_documents
                ? t(errors?.identity_documents?.message ?? "") ??
                  t(errors?.identity_documents[0]?.message ?? "")
                : ""
            }
            // required={false}
            fileInfo={t("identity_document_file_basicinfo" ?? "") ?? ""}
          />
        </div>
      </div>
    </div>
  );
}
