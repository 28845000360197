import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { profileSchema, ProfileSchema } from '../../../common/userSchema';
import CustomButton from '../../../customComponents/button/index';
import CustomDialogBox from '../../../customComponents/dialogbox/index';
import { updateProfile } from '../../../pages/profile/apis/api';
import useBrowserWidth from '../../../utilities/responsiveHook';
import { userProfileTypes, userProfileTypesTitle } from '../store';
import { handleUserData } from './handleUserData';
import SwitchComponents from './SwitchComponents';

type Props = {
  type: keyof typeof userProfileTypesTitle;
  openUserModel: boolean;
  closeUserModel: (value: boolean) => void;
  handleRefresh: (value?: any) => void;
  defaultValues: ProfileSchema;
};
export default function UserProfileUpdate(props: Props) {
  const { type, closeUserModel, handleRefresh, defaultValues, openUserModel } =
    props;

  const { enqueueSnackbar } = useSnackbar();
  const { isBrowser, isMobile } = useBrowserWidth();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<z.infer<typeof profileSchema>>({
    mode: 'onChange',
    resolver: zodResolver(profileSchema),
    defaultValues: {
      ...defaultValues,
      identity_document_number:
        defaultValues?.identity_file_details[0]?.identity_document_number,
      identity_document_type:
        defaultValues?.identity_file_details[0]?.identity_document_type_id,
      identity_document_data: defaultValues?.identity_documents,
      identity_documents: undefined,
      portfolioData: defaultValues?.portfolios,
      portfolios: null,
      attachmentData: defaultValues?.attachments,
      attachments: null,
    },
  });
  useEffect(() => {
    formMethods.reset({
      ...defaultValues,
      identity_document_number:
        defaultValues?.identity_file_details[0]?.identity_document_number,
      identity_document_type:
        defaultValues?.identity_file_details[0]?.identity_document_type_id,
      identity_document_data: defaultValues?.identity_documents,
      identity_documents: undefined,
      portfolioData: defaultValues?.portfolios,
      portfolios: null,
      attachmentData: defaultValues?.attachments,
      attachments: null,
    });
  }, [defaultValues]);

  const handleClose = () => {
    formMethods.reset({});
    formMethods.reset({
      ...defaultValues,
      identity_document_data: defaultValues?.identity_documents,
      identity_documents: undefined,
      portfolioData: defaultValues?.portfolios,
      portfolios: null,
      attachmentData: defaultValues?.attachments,
      attachments: null,
    });
    closeUserModel(false);
    // handleRefresh();
  };
  const handleUpdateForm = async () => {
    const data = handleUserData(type, formMethods.watch());
    setIsLoading(true);
    updateProfile(data)
      .then((response) => {
        if (response.status) {
          closeUserModel(false);
          handleRefresh();
          enqueueSnackbar(response.message, {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(response.message ?? response.message[0][0], {
            variant: 'error',
          });
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        const errorKey = Object.keys(error.response.data.error)[0];
        enqueueSnackbar(error.response.data.error[errorKey][0], {
          variant: 'error',
        });
      });
  };
  const handleErrorValidation = (isValidate: boolean) => {
    if (isValidate) {
      handleUpdateForm();
    } else {
      return null;
    }
  };

  const handleSave = async () => {
    let isValidate = false;
    const formData = formMethods.watch();
    switch (type) {
      case userProfileTypes.BASIC_INFO:
        isValidate = await formMethods.trigger([
          'user_name',
          'name',
          'current_designation',
          'profile_headline',
          'identity_documents',
          'identity_document_type',
          'identity_document_number',
        ]);
        break;
      case userProfileTypes.PERSONAL_INFO:
        isValidate = await formMethods.trigger([
          'email',
          'phone',
          'gender_id',
          'date_of_birth',
          // "nationality_id",
          'nationality',
          'state_id',
          'city',
          'current_location',
          'is_handicapped',
        ]);
        break;
      case userProfileTypes.PROFESSIONAL_INFO:
        isValidate = await formMethods.trigger([
          'year_of_experience',
          'work_authorisation_status_id',
          'employment_status_id',
          'notice_period',
          'current_salary',
        ]);
        break;
      case userProfileTypes.USER_DESC:
        isValidate = await formMethods.trigger(['description']);
        break;
      case userProfileTypes.SKILL_PREF:
        isValidate = await formMethods.trigger(['skill_sets']);
        break;
      case userProfileTypes.WORK_LOC:
        isValidate = await formMethods.trigger(['work_locations']);
        break;
      case userProfileTypes.WORK_SECTOR:
        isValidate = await formMethods.trigger(['work_sectors']);
        break;
      case userProfileTypes.LANGUAGE:
        isValidate = await formMethods.trigger(['languages']);
        break;
      case userProfileTypes.PORTFOLIO:
        isValidate = await formMethods.trigger(['portfolios']);
        break;
      case userProfileTypes.RESUME:
        isValidate = await formMethods.trigger(['attachments']);
        break;
      case userProfileTypes.USER_TAX_INO:
        isValidate = await formMethods.trigger([
          'pan_number',
          'gst_number',
          'trn_number',
        ]);
        break;
      case userProfileTypes.RECOMMENDATION_TAGS:
        isValidate = await formMethods.trigger(['recommendationTags']);
        break;
      case userProfileTypes.SOCIAL_MEDIA_PRESENCE:
        isValidate = await formMethods.trigger(['social_media_details']);
        break;
    }
    return handleErrorValidation(isValidate);
  };

  return (
    <CustomDialogBox
      open={openUserModel}
      title={t(userProfileTypesTitle[type]) ?? ''}
      subtext=""
      maxWidth={'md'}
      handleClose={() => handleClose()}
      dialogActions={
        <div className="w-full flex justify-end gap-4 relative">
          <CustomButton
            onClick={() => handleClose()}
            className={`secondaryButton ${isMobile ? 'flex-1' : ''}`}
            title={t('cancel_button') ?? ''}
          />
          <CustomButton
            onClick={() => {
              handleSave();
            }}
            className={`disabled:opacity-50 ${isMobile ? 'flex-1' : ''}`}
            title={t('save_button') ?? ''}
            disabled={isLoading ? true : false}
          />
        </div>
      }
    >
      {/* <UserContext.Provider
        value={{ dropData: dropData, setDropData: setDropData }}
      > */}
      <div className="flex flex-col gap-4">
        <FormProvider {...formMethods}>
          {openUserModel && (
            <SwitchComponents
              handleRefresh={handleRefresh}
              type={type}
              method={formMethods}
            />
          )}
        </FormProvider>
      </div>

      {/* </UserContext.Provider> */}
    </CustomDialogBox>
  );
}
