import { z } from "zod";

export const registerSchema = z.object({
  name_en: z.string().trim().min(1, { message: "company_name_required_femsg" }),
  name_ar: z.string().optional(),
  registration_number: z
    .string()
    .trim()
    .min(1, { message: "registration_no_required_femsg" }),
  registration_type_id: z
    .string({ required_error: "regtration_type_required_femsg" })
    .trim(),
});
export type RegisterSchema = z.infer<typeof registerSchema>;
