import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiFillEye } from "react-icons/ai";

import { getDropDownData } from "../../apis/dropdowns/dropDown.api";
import { ProfileSchema } from "../../common/userSchema";
// import { ProfileSchema } from "../../common/components/profile/userSchema";
import RadioButton from "../../customComponents/radioGroup";
import SelectField from "../../customComponents/selectfield";
import CustomInput from "../../customComponents/textfield";
import { UserContext } from "../detailComponent/dataContext";

const dropKeys = ["employment-status", "work-authorisation-status"];
type Props = {
  fromDialog?: boolean;
};
export default function PersonalInformation(props: any) {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<ProfileSchema>();

  const [state, setState] = useState("");
  const { t } = useTranslation();

  const { dropData, setDropData } = useContext(UserContext);

  const handleDropData = async (key: string) => {
    let drop = dropData;
    const { data, error } = await getDropDownData(key);
    drop = { [key]: data?.data, ...drop };

    setDropData((prevState: any) => ({
      ...prevState,
      [key]: data?.data,
    }));
  };
  useEffect(() => {
    dropKeys.forEach((item) => {
      handleDropData(item);
    });
  }, []);
  const [notice_period, setNoticePeriod] = useState<number | any>("");

  const handleChange = (e: any) => {
    const data = noticePeriod?.find((item) => item?.id === e);
    // setValue("notice_period", data?.name);
    setValue("notice_period", data?.id);

    setNoticePeriod(e);
  };
  useEffect(() => {
    const data = noticePeriod?.find(
      (item) => item?.id === getValues("notice_period")
      // (item) => item?.name === getValues("notice_period")
    );
    setNoticePeriod(data?.id ?? "");
  }, [getValues("notice_period")]);

  const noticePeriod = [
    { id: "Immediate", name: t("immediate_option" ?? "") },
    { id: "1 week", name: t("noticeperiod_1_week" ?? "") },
    { id: "2 week", name: t("noticeperiod_2_week" ?? "") },
    { id: "1 month", name: t("noticeperiod_1_month" ?? "") },
    { id: "2 month", name: t("noticeperiod_2_month" ?? "") },
    { id: "3 month", name: t("noticeperiod_3_month" ?? "") },
    { id: "> 3 months", name: t("noticeperiod_more3_month" ?? "") },
  ];

  return (
    <div className="flex flex-col gap-5">
      {!props.fromDialog && (
        <p className="text-base font-bold text-primaryText">
          {t("professional_info") ?? ""}
        </p>
      )}
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 sm:col-span-6 lg:col-span-6">
          <div>
            <CustomInput
              label={t("totalyears_exp_label") ?? ""}
              placeholder={t("example_years") ?? ""}
              register={register("year_of_experience", {
                setValueAs: (v) => (v ? parseInt(v) : null),
              })}
              error={t(errors.year_of_experience?.message ?? "")}
              type="number"
            />
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-6">
          <Controller
            control={control}
            name="work_authorisation_status_id"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <SelectField
                onChange={onChange}
                data={
                  (dropData && dropData["work-authorisation-status"]) ?? [{}]
                }
                label={t("work_authorisation_status") ?? ""}
                value={value ?? ""}
                placeholder={t("work_authorisation_status") ?? ""}
                // placeholder="Select Work Authorisation Status"
                fullWidth
                className="customSelectField"
                error={
                  t(errors.work_authorisation_status_id?.message ?? "") ?? ""
                }
              />
            )}
          />
        </div>
        <div className="col-span-12 sm:col-span-4 lg:col-span-4">
          <Controller
            control={control}
            name="employment_status_id"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <SelectField
                onChange={onChange}
                data={(dropData && dropData["employment-status"]) ?? [{}]}
                label={t("employment_status_label") ?? ""}
                value={value ?? ""}
                placeholder={t("employment_status_label") ?? ""}
                fullWidth
                className="customSelectField"
                error={t(errors.employment_status_id?.message ?? "") ?? ""}
              />
            )}
          />
        </div>
        <div className="col-span-12 sm:col-span-4 lg:col-span-4">
          <CustomInput
            label={t("current_salary_label") ?? ""}
            placeholder={t("current_salary_placeholder") ?? ""}
            // register={register("current_salary", {
            //   setValueAs: (v) => (v ? parseInt(v) : undefined),
            // })}
            register={register("current_salary")}
            error={t(errors.current_salary?.message ?? "")}
            required={true}
          />
        </div>
        <div className="col-span-12 sm:col-span-4 lg:col-span-4">
          <Controller
            control={control}
            name="notice_period"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <SelectField
                onChange={(e) => handleChange(e)}
                data={noticePeriod}
                label={t("notice_period_label") ?? ""}
                value={notice_period ?? ""}
                placeholder={t("notice_period_label") ?? ""}
                fullWidth
                className="customSelectField"
                error={t(errors.notice_period?.message ?? "") ?? ""}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
