import axios from "axios";

import { useAuthStore } from "../store/authStore";

let refreshTokenPromise: Promise<any> | null;

const handleData = () => {
  const data =
    localStorage.getItem("switched_company") &&
    JSON.parse(localStorage.getItem("switched_company") ?? "");
  return data?.state?.companyDetail;
};
const trimLang: string = (localStorage.getItem("i18nextLng") ?? "en").substring(
  0,
  2
);
const serverApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Company-id": handleData()?.id ?? null,
    "Current-lang": trimLang,
  },
});
serverApi.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          if (!refreshTokenPromise) {
            refreshTokenPromise = axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/auth/refresh_token`,
                undefined,
                {
                  headers: {
                    Authorization: localStorage.getItem("accessToken"),
                    RefreshToken: localStorage.getItem("refreshToken"),
                  },
                  withCredentials: true,
                }
              )
              .then((rs) => {
                refreshTokenPromise = null; // clear state
                localStorage.setItem(
                  "accessToken",
                  rs.headers.authorization || ""
                );
                serverApi.defaults.headers.common.Authorization = `Bearer ${rs.headers.refreshtoken}`;
                localStorage.setItem(
                  "refreshToken",
                  rs.headers.refreshtoken || ""
                );
                useAuthStore.setState({ authenticated: true });

                return serverApi;
              })
              .catch((err) => {
                if (err.response.status == 401) {
                  localStorage.clear();
                  window.open("/session-timeout", "_self");
                }
              });
          }

          return refreshTokenPromise.then((rs) => {
            err.config.headers["Authorzation"] = `Bearer ${localStorage.getItem(
              "accessToken"
            )}`;
            return serverApi(err.config);
          });
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (err.response.status === 401 && originalConfig._retry) {
        useAuthStore.setState({ authenticated: undefined });
        localStorage.removeItem("accessToken");
        return Promise.reject(err);
      } else if (err.response.status === 403) {
        window.open("/access-denied", "_self");
      }
      return Promise.reject(err);
    }
  }
);
serverApi.interceptors.request.use(
  (config: any) => {
    config.headers = config?.headers ?? {};
    config.headers["Company-id"] = handleData()?.id ?? null;
    config.headers["Current-lang"] = trimLang;
    config.headers["Content-Type"] = "multipart/form-data";
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default serverApi;
