import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { AiFillExclamationCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { emailVerification } from "../../../apis/auth/auth.api";
import CustomButton from "../../../customComponents/button/index";
import CustomDialogBox from "../../../customComponents/dialogbox/index";
import { useUserManagement } from "../../../store/authStore";
import { useAuthAction } from "../../../store/signAction";

export default function VeriFyEmail() {
  const {
    openEmailVerifyModel,
    emailToVerify,
    setOpenEmailVerifyModel,
    setOpenProfileVerification,
  } = useUserManagement();
  const style = {
    pswWrapper: "flex relative",
    pswShow: "absolute right-0.5 rtl:right-auto rtl:left-0.5 z-10",
  };

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { action } = useAuthAction();

  const handleAction = () => {
    switch (action) {
      case "sendProposal":
        return setOpenProfileVerification({
          open: true,
          actionName: "sendProposal",
          link: "",
        });
      case "hireNow":
        return setOpenProfileVerification({
          open: true,
          actionName: "hireNow",
          link: "",
        });
      case "applyJOb":
        return setOpenProfileVerification({
          open: true,
          actionName: "applyJOb",
          link: "",
        });
      default:
        return navigate("/home");
    }
  };

  const handleVeriFy = async () => {
    setOpenEmailVerifyModel(false);
    emailVerification({ email: emailToVerify })
      .then((res) => {
        enqueueSnackbar(res.message, {
          variant: "success",
        });
        handleAction();
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };
  return (
    <>
      <CustomDialogBox
        open={openEmailVerifyModel}
        title={t("email_verification_incomplete") ?? ""}
        subtext={
          t("email_verify_shorttext") ?? ""
          // "To proceed further, please complete the email verification process. Email verification is necessary to ensure the security and authenticity of your account."
        }
        // title={t("welcome_aboard") ?? ""}
        // subtext={t("verify_email_message") ?? ""}
        maxWidth={"xs"}
        handleClose={() => setOpenEmailVerifyModel(false)}
        dialogActions={
          <div className="w-full relative flex justify-between items-center gap-3">
            <Button
              variant="contained"
              onClick={() => setOpenEmailVerifyModel(false)}
              className={`text-titleColor font-medium bg-bgWhite h-10 text-sm normal-case p-2.5 rounded-md w-full shadow-none border border-[#D6D8DC] border-solid`}
            >
              {t("verify_later_btn") ?? ""}
              {/* {t("cancel_button") ?? ""} */}
            </Button>
            <CustomButton
              onClick={() => {
                handleVeriFy();
              }}
              className="w-full"
              title={t("verify_now_btn") ?? ""}
              // title={t("verify_button") ?? ""}
            />
          </div>
        }
      >
        <div className="flex flex-col gap-3.5">
          <div className="flex rounded-md p-4 gap-2 bg-activeStateBg   text-primary">
            <AiFillExclamationCircle className="w-4 h-4 mt-1" />

            <div className="flex-1 text-sm">
              <p className="">
                {t("verify_email_instruction") ?? ""}

                {/* Once you click the verify button, a verification email will be
              sent to your registered email address. Follow the instruction in
              the email to verify your email address. In the event you don't see
              the mail in your inbox, please check your spam folder. */}
              </p>
            </div>
          </div>
        </div>
      </CustomDialogBox>
    </>
  );
}
