import { Popover, Transition } from '@headlessui/react';
import { IconButton } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { AiFillCaretDown, AiFillFileText } from 'react-icons/ai';
import { HiUsers } from 'react-icons/hi';
import { HiBuildingOffice2 } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFilterDetailStore, useFilterType } from '../store/filterStore';
import { useSearchStore } from '../store/searchStore';
import useBrowserWidth from '../utilities/responsiveHook';

type Props = {
  label?: string;
  value?: string | number;
  onChange?: (e: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  search?: boolean;
  name?: string;
  type?: string;
  register?: any;
  placeholder?: string;
  disabled?: boolean;
  textarea?: boolean;
  className?: string;
  error?: any;
  direction?: string;
};
export default function SearchComponent(props: Props) {
  const {
    label,

    required,
    disabled,
    className,

    type,
  } = props;
  const { t } = useTranslation();
  const { filterType, setFilterType } = useFilterType();
  const { filterValues, setFilterValues, clearFilters } =
    useFilterDetailStore();
  {
    /* FOR PHASE2 - 29/07/2023*/
  }
  const { isBrowser, isMobile } = useBrowserWidth();

  const [isStateEmpty, setIsStateEmpty] = useState(true);
  const [filterName, setFilterName] = useState('');
  const [dropItem, setDropItem] = useState([
    // {
    //   name: "Jobs",
    //   description: "Find the job that is perfect for you",
    //   href: "##",

    //   icon: IoBagRemove,
    // },
    {
      name: 'Projects',
      description: 'Explore exciting new project opportunities',
      href: '##',
      type: 'work',
      icon: AiFillFileText,
      descriptionKey: 'explore_project_oppo',
      nameKey: 'projects_option',
    },
    {
      name: 'Talent',
      description: 'Find top rated talent for your needs',
      href: '##',
      type: 'user',
      icon: HiUsers,
      descriptionKey: 'find_top_talent',
      nameKey: 'talents_option',
    },
    {
      name: 'Company',
      description: 'Explore employers of your choice',
      href: '##',
      type: 'company',
      icon: HiBuildingOffice2,
      descriptionKey: 'explore_employers',
      nameKey: 'company_option',
    },
  ]);

  useEffect(() => {
    setFilterName('');
    headerTabVerify();
  }, []);
  const headerTabVerify = () => {
    const flag = localStorage.getItem('switched_company');
    if (flag) {
      const flagObj = JSON.parse(flag);
      let id;
      if (flagObj.state.companyDetail && flagObj.state.companyDetail.id) {
        id = flagObj.state.companyDetail.id;
        setIsStateEmpty(false);
        if (id !== '') {
          setDropItem([
            {
              name: 'Talent',
              description: 'Find top rated talent for your needs',
              href: '##',
              type: 'user',
              icon: HiUsers,
              descriptionKey: 'find_top_talent',
              nameKey: 'talents_option',
            },
            {
              name: 'Company',
              description: 'Explore employers of your choice',
              href: '##',
              type: 'company',
              icon: HiBuildingOffice2,
              descriptionKey: 'explore_employers',
              nameKey: 'company_option',
            },
          ]);
        }
      }
    }
  };

  const { searchKey, setSearchKey } = useSearchStore();

  const handleSelct = (item: any, close: any) => {
    clearFilters();
    setFilterType({ name: item.name, type: item.type, nameKey: item.nameKey });
    setFilterName(item.nameKey);
    setSearchKey('');
    setTimeout(() => {
      handleSearch('', item.type);
    }, 300);
    close();
  };
  const navigate = useNavigate();
  const handleSearch = (searchValue?: string, type?: string) => {
    navigate(`/universal-search`, {
      state: {
        search: searchValue ?? searchKey,
        filter: '',
        type: type,
      },
    });
  };
  const handleFormSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearch();
  };
  const handleSearchKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value ? e.target.value.trimStart() : '';
    setSearchKey(value);
  };
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    if (window.location.pathname !== `/universal-search`) {
      setSearchKey('');
    } else {
      setSearchKey(state?.search ?? '');
    }
  }, [window.location.pathname]);
  const handleClear = () => {
    setSearchKey(undefined);
    handleSearch('');
  };
  const handleFilterName = () => {
    if (!isStateEmpty) {
      if (filterName == '') return t('talents_option' ?? '');
      else return t(filterName);
    } else {
      if (filterName == '') return t('projects_option' ?? '');
      else return t(filterName);
    }
  };
  return (
    <>
      {label && (
        <label className="block justify-start labelText">
          {required ? `${label} *` : label}
        </label>
      )}

      <div
        className={`flex w-full relative items-center headerSearch ${
          isMobile && 'justify-center'
        }`}
      >
        <div
          className={`flex  items-center ${
            isMobile ? 'justify-center w-[350px] max-w-full' : 'w-full'
          }`}
        >
          {/* <div className="relative flex items-center">
          <select
            className={`customFieldInput min-w-[60px] max-w-[110px] pr-7 appearance-none rounded-r-none border-r-0 hover:border-formBorder hover:border-r-0 focus:border-formBorder focus:border-r-0 left-0 `}
          >
            <option>jobs</option>
            <option>Freelance</option>
          </select>
          <AiFillCaretDown className="w-4 absolute right-[8px] text-textColor" />
        </div> */}
          <div>
            <Popover className="relative ">
              {({ open, close }) => (
                <>
                  <Popover.Button
                    className={`
              ${open ? '' : 'text-opacity-90'} ${
                      isMobile ? 'max-w-auto' : 'max-w-[110px]'
                    }
              customFieldInput relative flex items-center min-w-[60px]  pr-7 rtl:pl-7 rtl:pr-2 appearance-none rounded-r-none rtl:rounded-l-none rtl:rounded-r-md border-r-0 rtl:border-r rtl:border-l-0 hover:border-formBorder hover:border-r-0 rtl:hover:border-l-0 rtl:hover:border-r focus:border-formBorder focus:border-r-0 rtl:focus:border-l-0 rtl:focus:border-r left-0 rtl:right-0 rtl:left-auto `}
                  >
                    {/* FOR PHASE2 - 29/07/2023*/}
                    <span>{handleFilterName()}</span>
                    <AiFillCaretDown
                      className={`${open ? '' : 'text-opacity-70'}
                ml-2 w-4 absolute right-[8px] rtl:left-[8px] rtl:right-auto rtl:mr-2 rtl:ml-0 text-textColor transition duration-150 ease-in-out group-hover:text-opacity-80`}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      className={`absolute z-10 mt-3   transform ${
                        isMobile ? 'left-0' : 'right-0'
                      }`}
                    >
                      <div className="overflow-hidden bg-bgWhite shadow-footerShadow rounded-lg p-2 w-[300px]">
                        <div className="relative flex flex-col">
                          {dropItem.map((item) => (
                            <a
                              key={item.name}
                              onClick={() => handleSelct(item, close)}
                              href={item.href}
                              className=" flex items-start rounded-lg p-2.5 gap-2.5 transition hover:bg-menuHover duration-150 ease-in-out  focus:outline-none "
                            >
                              <div className="flex  items-center justify-center ">
                                <item.icon
                                  className="text-primary w-4 h-4"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="flex flex-col">
                                <p className="text-sm font-medium text-primaryText">
                                  {t(item.nameKey) ?? ''}
                                </p>
                                <p className="text-xs font-medium text-textColor">
                                  {t(item.descriptionKey) ?? ''}
                                </p>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
          <form
            className={`relative ${isMobile && 'flex-1'}`}
            onSubmit={(e) => handleFormSearch(e)}
          >
            <input
              {...props}
              id={'user_name'}
              type={type ?? 'text'}
              // required={true}
              onChange={(e) => handleSearchKey(e)}
              className={`${
                isMobile && 'w-full max-w-full'
              } customFieldInput hover:border-formBorder  focus:border-formBorder  rounded-l-none rtl:rounded-l-md rtl:rounded-r-none pr-1 rtl:pl-1 rtl:pr-0 max-w-[160px] ${className}`}
              placeholder={t('search_placeholder') ?? ''}
              disabled={disabled}
              value={searchKey ?? ''}
            />
            {searchKey && (
              <AiOutlineClose
                onClick={() => handleClear()}
                className="absolute right-8 top-[12px]  cursor-pointer text-secondary"
              />
            )}
            <IconButton
              className="p-0"
              disabled={!searchKey ? true : false}
              onClick={() => handleSearch()}
            >
              <AiOutlineSearch className="absolute rtl:rotate-90 w-5 right-[8px] text-secondary" />
            </IconButton>
          </form>
        </div>
      </div>
    </>
  );
}
