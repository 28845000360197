import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import CustomButton from '../../customComponents/button';
import useBrowserWidth from '../../utilities/responsiveHook';
import { getSubscriptionHistoryDetails } from './apis/apis';

export default function AddOnView() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [addOnData, setAddOnData] = useState<any>();
  const { id } = useParams();
  const handleAddOnDetails = async () => {
    const { data } = await getSubscriptionHistoryDetails(id);
    setAddOnData(data);
  };
  const { isBrowser, isMobile } = useBrowserWidth();

  useEffect(() => {
    handleAddOnDetails();
  }, [id]);

  return (
    <div className=" flex flex-col items-center justify-center w-full gap-4">
      <div className="customCard customWidth p-0 flex flex-col gap-3">
        <div className="flex flex-col items-center justify-between gap-4 p-5 px-6">
          <div className="relative flex items-start justify-between w-full">
            <div className="flex flex-col gap-1  w-full">
              <div className="relative gap-2 w-full">
                <p className="text-lg font-bold text-primaryText inline-block">
                  {addOnData?.addon_title}
                </p>
                <span className="tagStyle primaryTag border-t border-b border-primary bg-activeStateBg ml-4 top-[0px]">
                  <span className="tag text-[10px] text-primary font-semibold">
                    {addOnData?.status}
                  </span>
                </span>
                <span className="tagStyle  border-t border-b border-formBorder bg-[#EFEFEF] ml-4 top-[0px]">
                  <span className="tag text-[10px] text-primaryText font-semibold">
                    {t('add_ons_heading') ?? ''}
                  </span>
                </span>
              </div>
              {isMobile && (
                <div className="flex">
                  <p className=" text-dark text-lg text-left font-bold ">
                    {`${addOnData?.currency} ${addOnData?.price}`}
                  </p>
                </div>
              )}
              <div className="flex gap-2 items-center">
                <span className="text-sm text-success font-medium">
                  {t('buy_addons_featur_label') ?? ''} : +
                  {addOnData?.addon?.value_text}
                </span>
                <span className="text-primaryText">|</span>
                <p className="text-sm font-medium text-primaryText">
                  {addOnData?.addon?.addon_type?.addon_category_text}
                </p>
              </div>
              <p className="text-sm  text-textColor">
                {t('order_number')}: {addOnData?.order_id ?? '- -'}
              </p>
              <p className="text-sm text-primaryText">
                {addOnData?.addon?.addon_type?.description}
              </p>
            </div>
            {isBrowser && (
              <div className="flex">
                <p className=" text-dark text-lg text-right font-bold min-w-[150px]">
                  {`${addOnData?.currency} ${addOnData?.price}`}
                </p>
              </div>
            )}
          </div>
          <div
            className={`relative flex justify-between w-full ${
              isMobile ? 'flex-col items-start gap-3' : 'items-center'
            }`}
          >
            <div className="flex gap-3 items-center">
              <CustomButton
                className="secondaryButton "
                title={t('download_invoice_button') ?? ''}
                onClick={() => console.log('test')}
                disabled
              />
            </div>
            <div className="flex items-center">
              <div className="borderItem flex flex-col">
                <p className=" text-primary text-xs font-semibold">
                  {addOnData?.start_date
                    ? moment(addOnData?.start_date).format('DD-MMM-YYYY')
                    : '- -'}
                </p>
                <p className=" text-primaryText text-xs font-medium">
                  {t('start_date_label') ?? ''}
                </p>
              </div>

              <div className="borderItem flex flex-col">
                <p className=" text-primary text-xs font-semibold">
                  {addOnData?.end_date
                    ? moment(addOnData?.end_date).format('DD-MM-YYYY')
                    : '- -'}{' '}
                </p>
                <p className=" text-primaryText text-xs font-medium">
                  {t('end_date_label') ?? ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
