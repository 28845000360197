import { t } from 'i18next';
import React, { useState } from 'react';

import CustomButton from '../../customComponents/button';
import CustomTooltip from '../../customComponents/tooltip';
import useBrowserWidth from '../../utilities/responsiveHook';
import PlanPurchaseModal from './planPurchaseModal';

type Props = {
  title: string;
  data: any;
};
export default function AddOnList(props: Props) {
  const { title, data } = props;
  const [openModal, setModalOpen] = React.useState(false);
  const [addonDetails, seAddonDetails] = useState<any>();
  const handleModalOpen = (item: any) => {
    seAddonDetails(item);
    setModalOpen(true);
  };
  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <>
      <div
        className={`featuresBox ${
          isMobile && 'overflow-x-auto flex flex-col flex-wrap'
        }`}
      >
        <div
          className={`flex items-center justify-between gap-3 border-b border-textColor ${
            isMobile && ''
          }`}
        >
          <div
            className={`relative  py-2 ${
              isMobile ? 'w-[140px] max-w-[140px]' : 'flex-1'
            }`}
          >
            <p className=" text-primaryText text-sm font-bold ">{title} </p>
          </div>
          <div className="w-[144px] py-2 pl-3 flex flex-col gap-1.5">
            <p className=" text-detailLabel text-xs font-medium">
              {t('buy_addons_featur_label') ?? ''}
            </p>
          </div>
          <div className="w-[144px] py-2 pl-3  flex flex-col gap-1.5 ">
            <p className=" text-detailLabel text-xs font-medium">
              {t('sub_amount_label') ?? ''}
            </p>
          </div>
          <div className="w-[144px] py-2 pl-3 flex flex-col gap-1.5 "></div>
        </div>
        {data?.map((item: any) => (
          <div
            key={item?.id}
            className="flex items-center justify-between gap-3 featuresBoxItem"
          >
            <div className="relative flex-1 py-3">
              <CustomTooltip
                placement={isMobile ? 'top' : 'right'}
                heading={item.addon_type.title}
                text={item?.addon_type.description}
              >
                <p className="text-xs font-medium text-detailLabel pb-1.5 border-b border-dashed border-primary inline-block">
                  {item.addon_type.title}
                </p>
              </CustomTooltip>
            </div>
            <div className="w-[144px] p-3 flex flex-col gap-1.5">
              <p className=" text-primaryText text-[13px] font-medium">
                + {item.value_text}
              </p>
            </div>
            <div className="w-[144px] p-3  flex flex-col gap-1.5 ">
              <p className=" text-primaryText text-[13px] font-medium">
                {`${item.currency ? item.currency : '- -'}
                 ${item.price ? item.price : '- -'}`}
              </p>
            </div>
            <div className="w-[144px] p-3  flex flex-col gap-1.5 ">
              <CustomButton
                className="primaryButton btnActive h-[30px]  text-primary shadow-none disabled:text-placeholderText "
                title={t('buynow_button') ?? ''}
                disabled={!item?.is_buyable}
                onClick={() => (item?.is_buyable ? handleModalOpen(item) : '')}
              />
            </div>
          </div>
        ))}
      </div>
      {openModal && (
        <PlanPurchaseModal
          open={openModal}
          from={'addon'}
          itemDetails={addonDetails}
          handleClose={setModalOpen}
          title={t('add_on_purchase') ?? ''}
          subtext={t('all_purchases_shorttext') ?? ''}
          detailedText={t('postings_bids_add_Ons') ?? ''}
          detailedSubText={t('sub_deduction_order') ?? ''}
        />
      )}
    </>
  );
}
