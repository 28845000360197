import { Snackbar, Alert } from "@mui/material";
type Props = {
  snackData: {
    open: boolean;
    handleClose: () => void;
    title?: string;
    message?: string;
  };
};
export default function NotificationPopup(props: Props) {
  const { snackData } = props;
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={snackData?.open}
      onClose={snackData?.handleClose}
      autoHideDuration={6000}
      message="I love snacks"
    >
      <Alert
        icon={false}
        onClose={snackData?.handleClose}
        severity="info"
        className="shadow-lg w-[300px] "
        sx={{ width: "100%" }}
      >
        <div className="flex flex-col gap-2">
          <p className="text-base font-semibold pb-1 border-placeholderText/40">
            {snackData?.title ?? "- -"}
          </p>
          <p className="text-sm font-semibold">{snackData?.message}</p>
        </div>
      </Alert>
    </Snackbar>
  );
}
