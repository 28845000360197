import { zodResolver } from "@hookform/resolvers/zod";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { googleLogin, login } from "../../../apis/auth/auth.api";
import CustomButton from "../../../customComponents/button";
import CustomDialogBox from "../../../customComponents/dialogbox/index";
import { useAuthStore, useUserManagement } from "../../../store/authStore";
import { useCompanySwitchStore } from "../../../store/companySwitch";
import { useAuthAction, useSendProposal } from "../../../store/signAction";
import { loginSchema, LoginSchema } from "./schema";

export default function Login() {
  const {
    openSign,
    setOpenSignModel,
    setOpenModel,

    setOpenForgotPasssword,
    setOpenProfileVerification,
  } = useUserManagement();
  const { t } = useTranslation();

  const { setAuthenticated } = useAuthStore();
  const { clearCompany } = useCompanySwitchStore();
  const { action } = useAuthAction();
  const { setSendProposal } = useSendProposal();
  const style = {
    pswWrapper: "flex relative",
    pswShow: "absolute right-0.5 rtl:right-auto rtl:left-0.5 z-10",
  };

  const {
    formState: { isValid, errors },
    handleSubmit,
    register,
    reset,
  } = useForm<LoginSchema>({
    mode: "onChange",
    defaultValues: { email: "", password: "" },
    resolver: zodResolver(loginSchema),
  });

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [hidePassword, setHidePassword] = useState(true);
  const [pswType, setPswType] = useState(true);

  const handleAction = () => {
    switch (action) {
      case "sendProposal":
        return setOpenProfileVerification({
          open: true,
          actionName: "sendProposal",
          link: "",
        });
      case "hireNow":
        return setOpenProfileVerification({
          open: true,
          actionName: "hireNow",
          link: "",
        });
      case "applyJOb":
        return setOpenProfileVerification({
          open: true,
          actionName: "applyJOb",
          link: "",
        });
      default:
        return navigate("/home");
    }
  };

  const handleKeyUp = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit(handleData)();
    }
  };

  const handleData = (data: LoginSchema) => {
    login({ email: data.email.toLocaleLowerCase(), password: data?.password })
      .then((res) => {
        setAuthenticated(true);
        clearCompany();
        localStorage.removeItem("switched_company");
        localStorage.setItem("accessToken", res?.data?.token);
        localStorage.setItem("refreshToken", res?.data?.refresh_token);
        localStorage.setItem("useData", JSON.stringify(res?.data));
        localStorage.setItem("avatar", res?.data?.profile_image);
        setOpenSignModel(false);
        handleAction();
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  const handleView = () => {
    setHidePassword(!hidePassword);
    setPswType(!pswType);
  };
  const handleClose = () => {
    setOpenSignModel(false);
    reset();
  };

  const handleSignUp = () => {
    setOpenSignModel(false);
    setOpenModel(true);
  };
  useEffect(() => {
    return () => {
      reset({});
    };
  }, []);
  const handleOpenForgot = () => {
    setOpenSignModel(false);

    setOpenForgotPasssword(true);
  };
  const handleGooleSignIn = () => {
    googleLogin()
      .then((res) => {
        console.log(res);
        window.open(res.redirectUrl, "_self");
      })
      .catch((err) => {});
  };
  const welcomeText = t("welcome_back") ?? "";
  const signInText = t("please_sign_in_") ?? "";
  const subtext = welcomeText + " " + signInText;

  const userNameText = t("username_label") ?? "";
  const emailText = t("email_placeholder") ?? "";
  const usernameOrEmailPlaceholder = userNameText + " " + "/" + " " + emailText;
  return (
    <>
      <CustomDialogBox
        open={openSign}
        title={t("sign_in_button") ?? ""}
        subtext={subtext}
        // subtext="Welcome Back! Please Sign In."
        maxWidth={"xs"}
        handleClose={() => handleClose()}
        dialogActions={
          <div className="flex flex-col gap-3.5 w-full">
            <div className="w-full relative">
              <CustomButton
                onClick={() => {
                  handleSubmit(handleData)();
                }}
                className="w-full !font-semibold"
                title={t("sign_in_button") ?? ""}
              />
            </div>
            {/* <div className="w-full relative">
              <Button
                variant="contained"
                onClick={() => handleGooleSignIn()}
                className={`text-titleColor bg-View font-semibold bg-bgWhite h-10 text-sm normal-case p-2.5 rounded-md w-full shadow-none border border-[#D6D8DC] border-solid`}
              >
                <FcGoogle className="w-4 h-4 mr-1.5" />
                {t("continue_google_button") ?? ""}
              </Button>
            </div> */}
            <div className="flex justify-center items-center mx-auto mt-4">
              <p className="text-xs lg:text-sm text-textColor font-semibold flex items-center">
                {t("no_account_text") ?? ""}
                <Button
                  className="ml-1 !text-xs lg:!text-sm text-primary  capitalize font-semibold  hover:text-primary transition-colors p-0 m-0 bg-transparent"
                  onClick={() => handleSignUp()}
                >
                  {t("sign_up_button") ?? ""}
                </Button>
              </p>
            </div>
          </div>
        }
      >
        <div className="flex flex-col gap-3.5">
          <div className="w-full relative">
            <input
              id={"email"}
              type={"email"}
              required={true}
              className="fixedInputClass"
              placeholder={usernameOrEmailPlaceholder}
              {...register("email")}
            />
            <p className="m-0 text-xs lg:text-sm text-bgError">
              {t(errors?.email?.message ?? "")}
            </p>
          </div>
          <div className="w-full relative">
            <div className={`${style.pswWrapper} mb-1`}>
              <input
                id={"password"}
                type={pswType ? "password" : "text"}
                required={true}
                className="fixedInputClass"
                placeholder={t("password_placeholder") ?? ""}
                {...register("password")}
                onKeyUp={handleKeyUp}
              />
              <IconButton
                className={style.pswShow}
                onClick={() => handleView()}
              >
                {!hidePassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </IconButton>
            </div>
            <p className="m-0 text-xs lg:text-sm text-bgError">
              {t(errors?.password?.message ?? "")}
            </p>
          </div>
          <div className="w-full relative">
            <a href="#" className="text-sm text-primary font-medium">
              <a
                className="text-xs lg:text-sm text-primary font-medium"
                onClick={() => handleOpenForgot()}
              >
                {t("forgot_password_link") ?? ""}
              </a>
            </a>
          </div>
        </div>
      </CustomDialogBox>
    </>
  );
}
