import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { BiEdit } from 'react-icons/bi';

import useBrowserWidth from '../../utilities/responsiveHook';
import { companyProfileTypes } from '../companyProfile/store';
import { userProfileTypes } from '../profile/store';
import { SocialIcons } from './userIcons';

type Props = {
  socialData: { name: string; link?: string }[];
  handleEdit: (val: string) => void;
  readOnly?: boolean;
  from: string;
};
export default function SocialMediaPresence(props: Props) {
  const { socialData, handleEdit, readOnly, from } = props;
  const { t } = useTranslation();
  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <div
      className={`flex flex-col gap-4  bg-bgWhite border-b border-formBorder ${
        isMobile ? 'p-4' : 'px-7 py-5'
      }`}
    >
      <div className="flex justify-between items-center">
        <p className="text-lg font-bold text-primaryText">
          {t('social_media_presence') ?? ''}
        </p>
        <div className="flex items-center gap-4">
          {!readOnly && (
            <IconButton
              disableRipple
              onClick={() =>
                handleEdit(
                  (from == 'company' ? companyProfileTypes : userProfileTypes)
                    .SOCIAL_MEDIA_PRESENCE
                )
              }
              className="p-0  "
            >
              <BiEdit className="p-0  text-primaryText w-5" />
            </IconButton>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-2">
        {socialData?.map((item) => (
          <>
            {item.link && (
              <div
                key={item?.name}
                className="flex justify-between gap-2 items-center   mediaList"
              >
                <div className="flex gap-2">
                  <span className="">
                    <SocialIcons type={item.name ?? ''} />
                  </span>
                  <a
                    href={item.link ?? ''}
                    target="_blank"
                    className="text-sm text-primaryText font-medium min-w-[120px]"
                    rel="noreferrer"
                  >
                    {item?.name}
                  </a>
                </div>
                {/* <div className="flex gap-4 items-center">
            <p className="text-xs text-textColor font-medium">
              Added on 12 Dec 2019
            </p>
          </div> */}
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
