import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { profileSchema, ProfileSchema } from "../../common/userSchema";
import { UserContext } from "../../components/detailComponent/dataContext";
import SocialMediaPresence from "../../components/detailComponent/socialMedia";
import AboutDescription from "../../components/profile/aboutDescription";
import BasicInformation from "../../components/profile/basicInformation";
import Education from "../../components/profile/education";
import Experience from "../../components/profile/experience";
import FileUpload from "../../components/profile/fileUpload";
import FreelancerPayment from "../../components/profile/freelancerPayment";
import PersonalInformation from "../../components/profile/personalInformation";
import ProfessionalInformation from "../../components/profile/professionalInformation";
import SkillPreference from "../../components/profile/skillPreference";
import CustomButton from "../../customComponents/button";
import useBrowserWidth from '../../utilities/responsiveHook';
import { getProfileData, updateProfile } from "./apis/api";

const totalSteps = 5;

export default function ProfileCreate(props: any) {
  const [step, setStep] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [dropData, setDropData] = useState<any>();
  const [userData, setUserData] = useState<ProfileSchema>();
  const { t } = useTranslation();
  const { isBrowser, isMobile } = useBrowserWidth();

  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<ProfileSchema>({
    mode: "onChange",
    defaultValues: {
      ...userData,
      identity_document_number:
        userData?.identity_file_details[0]?.identity_document_number,
      identity_document_type:
        userData?.identity_file_details[0]?.identity_document_type_id,
      identity_document_data: userData?.identity_documents,
      identity_documents: undefined,
      portfolioData: userData?.portfolios,
      portfolios: null,
      attachmentData: userData?.attachments,
      attachments: null,
    },
    resolver: zodResolver(profileSchema),
  });

  const handleUserData = async () => {
    const { data, error, message } = await getProfileData();
    setUserData(data);
  };

  useEffect(() => {
    handleUserData();
  }, []);
  useEffect(() => {
    formMethods.reset({
      ...userData,
      identity_document_number:
        userData?.identity_file_details[0]?.identity_document_number,
      identity_document_type:
        userData?.identity_file_details[0]?.identity_document_type_id,
      identity_document_data: userData?.identity_documents,
      identity_documents: undefined,
      portfolioData: userData?.portfolios,
      portfolios: null,
      attachmentData: userData?.attachments,
      attachments: null,
    });
  }, [userData]);

  const handleData = async (data: any, val?: boolean, preview?: boolean) => {
    setIsLoading(true);
    updateProfile(data)
      .then((response) => {
        if (response.status) {
          setIsLoading(false);
          if (!preview) {
            if (step < 5 && !val) {
              handleNext();
            } else if (step === 3) {
              navigate(`/profile/view`);
            }
            handleUserData();
          } else {
            navigate(`/profile/preview/`);
          }
          enqueueSnackbar(response.message, {
            variant: "success",
          });
        } else {
          setIsLoading(false);
          enqueueSnackbar(response.message ?? response.message[0][0], {
            variant: "error",
          });
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        const errorKey = Object.keys(error.response.data.error)[0];
        enqueueSnackbar(error.response.data.error[errorKey][0], {
          variant: "error",
        });
      });
  };

  const handleNextAndSave = async (val?: boolean, preview?: boolean) => {
    let isValidate = {};
    const formData = formMethods.watch();

    switch (step) {
      case 1:
        isValidate = await formMethods.trigger([
          "user_name",
          "name",
          "current_designation",
          "profile_headline",
          "identity_documents",
          "identity_document_type",
          "identity_document_number",
          "email",
          "phone",
          "gender_id",
          "date_of_birth",
          "year_of_experience",
          // "nationality_id",
          "nationality",
          "state_id",
          "city",
          "current_location",
          "place_id",
          "work_authorisation_status_id",
          "employment_status_id",
          "notice_period",
          "current_salary",
          "is_handicapped",
        ]);

        if (
          isValidate &&
          Object.keys(formMethods.formState.errors)?.length === 0
        ) {
          let data = {
            name: formData.name,
            user_name: formData.user_name,
            current_designation: formData.current_designation,
            profile_headline: formData.profile_headline,
            // identity_documents: formData.identity_documents,
            email: formData.email,
            phone: formData.phone,
            gender_id: formData.gender_id,
            date_of_birth: formData.date_of_birth,
            age: formData.age,
            year_of_experience: formData.year_of_experience ?? "",
            city: formData.city,
            // nationality_id: formData.nationality_id,
            nationality: formData.nationality,
            state_id: formData.state_id,
            current_location: formData.current_location,
            place_id: formData.place_id,
            work_authorisation_status_id: formData.work_authorisation_status_id,
            employment_status_id: formData.employment_status_id,
            notice_period: formData.notice_period,
            current_salary: formData.current_salary,
            is_handicapped: formData.is_handicapped,
            identity_document_type_id: formData.identity_document_type,
            identity_document_number: formData.identity_document_number,
          };

          if (
            formData.identity_documents &&
            formData.identity_documents?.length > 0
          ) {
            formData.identity_documents.forEach((item, index) => {
              data = { ...data, [`identity_documents[${index}]`]: item };
            });
            if (
              formData.identity_document_type ||
              formData.identity_document_number
            ) {
              let identity_file_details = {
                identity_document_type_id:
                  formData.identity_document_type ?? null,
                identity_document_number:
                  formData.identity_document_number ?? null,
              };
              formData.identity_documents.forEach((item, index) => {
                data = {
                  ...data,
                  [`identity_file_details[${index}]`]: JSON.stringify(
                    identity_file_details
                  ),
                };
                // JSON.stringify({ ...data, is_primary: false, bank_detail_id: null })
              });
            }
          } else {
            if (
              formData.identity_document_type ||
              formData.identity_document_number
            ) {
              let identity_file_details = {
                identity_document_type_id:
                  formData.identity_document_type ?? null,
                identity_document_number:
                  formData.identity_document_number ?? null,
                id: formData?.identity_file_details[0]?.id,
              };
              data = {
                ...data,
                [`identity_file_details[${0}]`]: JSON.stringify(
                  identity_file_details
                ),
              };
            }
          }
          handleData(data);
        }
        break;
      case 2:
        isValidate = await formMethods.trigger(["description", "skill_sets"]);
        if (isValidate) {
          let data = {
            description: formData.description,
            [`skill_sets`]:
              formData.skill_sets && formData.skill_sets?.length > 0
                ? undefined
                : "null",
          };

          if (formData.skill_sets && formData.skill_sets?.length > 0) {
            formData.skill_sets.forEach((item, index) => {
              data = { ...data, [`skill_sets[${index}]`]: item.id };
            });
          }
          handleData(data);
        }
        break;
      case 3:
        isValidate = await formMethods.trigger(["work_locations", "languages"]);
        if (isValidate) {
          let data = {
            [`languages`]:
              formData.languages && formData.languages?.length > 0
                ? undefined
                : "null",
            [`work_locations`]:
              formData.work_locations && formData.work_locations?.length > 0
                ? undefined
                : "null",
          };
          if (formData.languages && formData.languages?.length > 0) {
            formData.languages.forEach((item, index) => {
              data = { ...data, [`languages[${index}]`]: item.id };
            });
          }

          if (formData.work_locations && formData.work_locations?.length > 0) {
            formData.work_locations.forEach((item, index) => {
              data = { ...data, [`work_locations[${index}]`]: item.id };
            });
          }
          handleData(data);
        }
        break;
      case 4:
        isValidate = await formMethods.trigger([
          "portfolios",
          "attachments",
          "work_sectors",
        ]);

        if (isValidate) {
          let data = {
            [`work_sectors`]:
              formData.work_sectors && formData.work_sectors?.length > 0
                ? undefined
                : "null",
          };
          if (formData.portfolios && formData.portfolios?.length > 0) {
            formData.portfolios.forEach((item, index) => {
              data = { ...data, [`portfolios[${index}]`]: item };
            });
          }
          if (formData.attachments && formData.attachments?.length > 0) {
            formData.attachments.forEach((item, index) => {
              data = { ...data, [`attachments[${index}]`]: item };
            });
          }
          if (formData.work_sectors && formData.work_sectors?.length > 0) {
            formData.work_sectors.forEach((item, index) => {
              data = { ...data, [`work_sectors[${index}]`]: item.id };
            });
          }
          handleData(data);
        }
        break;
      case 5:
        isValidate = await formMethods.trigger([
          // "pan_number",
          // "gst_number",
          // "trn_number",
          "recommendationTags",
          "social_media_details",
        ]);
        if (isValidate) {
          let data = {
            pan_number: formData.pan_number,
            gst_number: formData.gst_number,
            trn_number: formData.trn_number,
            social_media_details: formData.social_media_details,
            [`recommendationTags`]:
              formData.recommendationTags &&
              formData.recommendationTags?.length > 0
                ? undefined
                : "null",
          };
          if (
            formData.recommendationTags &&
            formData.recommendationTags?.length > 0
          ) {
            formData.recommendationTags.forEach((item, index) => {
              data = { ...data, [`recommendationTags[${index}]`]: item.id };
            });
          }
          handleData(data, val, preview);
        }
    }
  };
  const handlePrevious = () => {
    setStep(step - 1);
  };
  const handleNext = () => {
    if (step < 5) {
      setStep(step + 1);
    } else {
      handleNextAndSave();
    }
  };
  const handleRefresh = () => {
    if (formMethods.formState.dirtyFields) {
      handleNextAndSave();
    } else {
      handleUserData();
    }
  };

  const navigate = useNavigate();

  const handlePreview = () => {
    if (Object.keys(formMethods.formState.dirtyFields)?.length > 0) {
      handleNextAndSave(false, true);
    } else {
      // navigate(`/profile/preview/`);
      window.open("/profile/preview/", "_blank");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className={`flex flex-col items-center justify-center w-full gap-4 ${
        isMobile && "pb-14"
      }`}
    >
      <div className="customCard customWidth p-5 px-6">
        <div className="flex items-center justify-between">
          <div className="relative">
            <p className="text-xl font-bold text-primaryText">
              {t("complete_your_profile") ?? ""}
            </p>
            <p className="text-sm font-semibold text-secondaryText">
              Step {step} of Step 5
            </p>
            <div className="flex gap-2 mt-2">
              {[...Array(totalSteps)]?.map((item, index) => (
                <>
                  <span
                    key={item}
                    className={`${
                      index < step ? "bg-primary" : "bg-[#EFEFEF]"
                    } w-6 h-1.5 rounded-full`}
                  ></span>
                </>
              ))}
            </div>
          </div>
          <Button
            variant="contained"
            onClick={() => handlePreview()}
            className={`primaryButton bg-[#E8EEFD] disabled:opacity-50 text-primary h-10 text-sm normal-case p-2.5 rounded-md shadow-none gap-1.5`}
          >
            <AiFillEye className="text-primary w-5 h-5" />
            <span> {t("preview_button") ?? ""}</span>
          </Button>
        </div>
      </div>
      <FormProvider {...formMethods}>
        <UserContext.Provider
          value={{ dropData: dropData, setDropData: setDropData }}
        >
          {step === 1 && (
            <>
              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <BasicInformation handleRefresh={handleUserData} />
              </div>

              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <PersonalInformation />
              </div>

              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <ProfessionalInformation />
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <AboutDescription />
              </div>

              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <SkillPreference
                  methods={formMethods}
                  dropKey="skill-set"
                  registerName="skill_sets"
                  title={"Skill Preferences"}
                  placeholder={t("preferred_skills") ?? ""}
                />
              </div>

              <div className="customCard customWidth p-5 px-6 pb-0 shadow-cardShadow">
                <Experience
                  handleRefresh={handleUserData}
                  jobDetails={userData?.job_details}
                />
              </div>
            </>
          )}
          {step == 3 && (
            <>
              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <Education
                  handleRefresh={handleUserData}
                  educationDetails={userData?.qualifications}
                />
              </div>
              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <SkillPreference
                  dropKey="language"
                  registerName="languages"
                  methods={formMethods}
                  title={t("language_proficiency_heading") ?? ""}
                  placeholder={t("language_proficiency_heading") ?? ""}
                />
              </div>
              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <SkillPreference
                  dropKey="work-location"
                  registerName="work_locations"
                  methods={formMethods}
                  title={"Work Location Preference"}
                  placeholder="Work Location Preference"
                />
              </div>
            </>
          )}
          {step == 4 && (
            <>
              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <SkillPreference
                  dropKey="work-sector"
                  registerName="work_sectors"
                  methods={formMethods}
                  title={t("preferred_job_sector_label") ?? ""}
                  placeholder={t("preferred_job_sector_placeholder") ?? ""}
                />
              </div>

              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <FileUpload
                  title={t("upload_portfolio") ?? ""}
                  registerName="portfolios"
                  handleRefresh={handleUserData}
                />
              </div>
              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <FileUpload
                  title={t("upload_resume_heading") ?? ""}
                  registerName="attachments"
                  handleRefresh={handleUserData}
                />
              </div>
            </>
          )}
          {step == 5 && (
            <>
              {/* <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <TaxInformation />
              </div> */}

              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <FreelancerPayment
                  handleRefresh={handleUserData}
                  bankdetails={formMethods.getValues("bankdetails") ?? []}
                />
              </div>

              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <SocialMediaPresence />
              </div>
              <div className="customCard customWidth p-5 px-6 shadow-cardShadow">
                <SkillPreference
                  dropKey="recommendation-tag"
                  registerName="recommendationTags"
                  methods={formMethods}
                  title={t("recommendation_tags") ?? ""}
                  placeholder={t("recommendation_tags") ?? ""}
                />
              </div>
            </>
          )}
        </UserContext.Provider>
        <div className="customCard customWidth p-5 px-6 shadow-footerShadow">
          <div className="flex justify-between gap-4">
            <CustomButton
              onClick={() => navigate("/")}
              className="secondaryButton"
              title={t("cancel_button") ?? ""}
            />
            <div className="flex gap-3">
              {step !== 1 &&
                Object.keys(formMethods.formState.dirtyFields).length === 0 && (
                  <CustomButton
                    onClick={() => handlePrevious()}
                    className="secondaryButton"
                    title={t("back_button") ?? ""}
                  />
                )}
              {formMethods.formState.isDirty &&
              Object.keys(formMethods.formState.dirtyFields)?.length > 0 ? (
                <CustomButton
                  onClick={() => handleNextAndSave()}
                  className="primaryButton disabled:opacity-50"
                  title={
                    step === 5
                      ? t("save_button") ?? ""
                      : t("save&continue_button") ?? ""
                  }
                  disabled={isLoading ? true : false}
                />
              ) : (
                <CustomButton
                  onClick={() => handleNext()}
                  className="primaryButton"
                  title={
                    step === 5
                      ? t("save_button") ?? ""
                      : t("form_button_next") ?? ""
                  }
                />
              )}
            </div>
          </div>
        </div>
      </FormProvider>
    </div>
  );
}
