import moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

type Props = {
  label?: string;
  onChange: (data: any) => void;
  value?: any;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  required?: boolean;
  error?: any;
  placeholder?: string;
};

// const CalendarContainer = ({ children }: any) => {
//   const el = document.getElementById("calendar-portal");
//   return (
//     <Portal container={el}>
//       <div className="z-50">{children}</div>
//     </Portal>
//   );
// };
function DatePickerConponent(props: Props) {
  const {
    label,
    error,
    onChange,
    value,
    maxDate,
    minDate,
    disabled,
    required,
    placeholder,
  } = props;

  const handleChange = (date: any) => {
    onChange(moment(date).format("MM/DD/YYYY"));
  };

  return (
    <div className="flex flex-col">
      {label && (
        <label className="block justify-start labelText">
          {required ? (
            <>
              {label}
              <span className="text-required"> * </span>
            </>
          ) : (
            label
          )}
        </label>
      )}

      <div className="relative">
        <DatePicker
          selected={value}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          onChange={(date) => handleChange(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          showTwoColumnMonthYearPicker
          // popperContainer={CalendarContainer}
          placeholderText={placeholder}
          className=" w-full customFieldInput"
          popperModifiers={[
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: true,
                altAxis: false,
              },
            },
          ]}
        />
        <span className=" absolute right-2 rtl:right-auto rtl:left-2 top-3 w-5 h-5 text-[#757575]">
          <svg
            className="w-4 h-4 fill-[#757575]"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="CalendarIcon"
          >
            <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
          </svg>
        </span>
      </div>
      {error && <p className="m-0  text-bgError text-xs">{error}</p>}
    </div>
  );
}

export default DatePickerConponent;
