import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCamera } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { Element, Events, Link } from 'react-scroll';

import AboutMe from '../../components/detailComponent/about';
import { UserContext } from '../../components/detailComponent/dataContext';
import DottedSelect from '../../components/detailComponent/dottedSelect';
import PersonalInformation from '../../components/detailComponent/personalInfo';
import ProfessionalInformation from '../../components/detailComponent/professionalInfo';
import SocialMediaPresence from '../../components/detailComponent/socialMediaPresence';
import TagSelect from '../../components/detailComponent/tagSelect';
import UploadResume from '../../components/detailComponent/uploadResume';
import ImageUploadModal from '../../components/imageUpload.tsx';
import RatingReview from '../../components/jobs/jobDetails/ratingReview';
import LoaderFile from '../../components/loader/loader';
import Education from '../../components/profile/education';
import Experience from '../../components/profile/experience';
import FreelancerPayment from '../../components/profile/freelancerPayment';
import { userProfileTypes, userProfileTypesTitle } from '../../components/profile/store';
import UserProfileUpdate from '../../components/profile/updateUserProfile';
import useBrowserWidth from '../../utilities/responsiveHook';
import { getProfileData, portfolioImgDelete, updateProfile } from './apis/api';

import './tabStyles.css';

type Props = {
  readOnly?: boolean;
};
export default function ProfileView(props: Props) {
  const { readOnly } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { isBrowser, isMobile } = useBrowserWidth();

  const [openModal, setModalOpen] = React.useState(false);
  const [userData, setUserData] = useState<any>();
  const [sectionType, setSectionType] =
    useState<keyof typeof userProfileTypesTitle>('Basic_Info');
  const [dropData, setDropData] = useState<any>();
  const [file, setFile] = useState<Blob | null>(null);
  const [openUserModel, setUserModel] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Events.scrollEvent.register('begin', function () {});

    Events.scrollEvent.register('end', function () {});
  }, []);

  useEffect(() => {
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const getUserDetails = async () => {
    setIsLoading(true);

    getProfileData()
      .then((res) => {
        setUserData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const handleUserDetailsEdit = (type: string) => {
    setUserModel(true);
    setSectionType(type as keyof typeof userProfileTypesTitle);
  };
  const handleSaveUserProfile = async () => {
    let fileData = { profile_image: file };

    updateProfile({ ...fileData })
      .then((res) => {
        getUserDetails();
        handleClose();
      })
      .catch((err) => {
        enqueueSnackbar(err.profile_image[0], {
          variant: 'error',
        });
      });
  };

  const handleDelete = async () => {
    const { data, error, message } = await portfolioImgDelete();
    localStorage.setItem('avatar', data?.profile_image);
    window.dispatchEvent(new Event('storage'));
    getUserDetails();
    enqueueSnackbar(message, {
      variant: 'success',
    });
  };

  const handleClose = () => {
    setModalOpen(false);
    setFile(null);
    setErrorMessage('');
  };
  if (isLoading) {
    return <LoaderFile fullWidth={isMobile && true} />;
  }
  return (
    <div
      className={`flex flex-col items-center justify-center w-full gap-4 sticky ${
        isMobile ? 'top-[55px] pb-14' : 'top-[64px]'
      }`}
    >
      <UserContext.Provider
        value={{ dropData: dropData, setDropData: setDropData }}
      >
        <div
          className={`customWidth p-0 shadow-cardShadow sticky ${
            isMobile ? 'top-[55px]' : 'top-[64px]'
          }`}
        >
          <div className="flex flex-col">
            <div
              className={`bg-bgWhite gap-2 rounded-t-lg flex flex-col items-start justify-between border-b border-formBorder sticky z-10 ${
                isMobile ? 'top-[55px]' : 'top-[64px]'
              }`}
            >
              <div
                className={`flex w-full ${
                  isMobile ? 'p-4 ' : 'flex w-full px-7 py-5'
                }`}
              >
                {(userData?.is_skilled || userData?.isSkilled) && (
                  <span className="premiumUser"> {t('expert_badge')}</span>
                )}
                {/* <span className="premiumUser">
                {t("premium_user_badge") ?? ""}
              </span> */}
                <div
                  className={`flex-1 flex ${
                    isMobile
                      ? 'flex-col items-start gap-x-3 gap-y-2'
                      : 'items-center gap-3'
                  }`}
                >
                  {/* <label
                  htmlFor="dropzone-file"
                  title="Upload company logo"
                  className="rounded-lg w-[72px] h-[72px] overflow-hidden cursor-pointer"
                >
                  <input
                    id="dropzone-file"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => handleFile(e)}
                    className="hidden"
                  />
                  {userData?.profile_image ? (
                    <img src={userData?.profile_image} className="h-full  " />
                  ) : (
                    <span className="flex justify-center items-center text-3xl border rounded-lg h-full font-extrabold">
                      {userData?.name.charAt(0).toUpperCase()}
                    </span>
                  )}
                </label> */}
                  <ImageUploadModal
                    open={openModal}
                    handleClose={() => handleClose()}
                    handleSave={handleSaveUserProfile}
                    title={t('profile_photo_header') ?? ''}
                    data={userData}
                    src={userData?.profile_image}
                    setFile={setFile}
                    file={file}
                    handleDelete={handleDelete}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    types={['jpeg', 'jpg', 'png', 'svg']}
                  >
                    <div
                      className="rounded-lg w-[72px] min-w-[72px] h-[72px] overflow-hidden cursor-pointer relative"
                      onClick={() => setModalOpen(true)}
                    >
                      {userData?.profile_image ? (
                        <img
                          src={userData?.profile_image}
                          className="h-[72px] w-full object-cover  "
                        />
                      ) : (
                        <span className="flex justify-center items-center text-3xl border rounded-lg h-full font-extrabold">
                          {userData?.name.charAt(0).toUpperCase()}
                        </span>
                      )}
                      <span className="cameraIcon">
                        <AiFillCamera className="w-4" />
                      </span>
                    </div>
                  </ImageUploadModal>
                  <div className="flex flex-col gap-0.5">
                    <div className="">
                      <span className="text-lg text-primaryText font-bold break-word">
                        {userData?.name ?? '- -'}
                      </span>
                      <span className="text-sm text-[#333333] ml-2">
                        @{userData?.user_name ?? '- -'}
                      </span>
                      {(userData?.is_skilled || userData?.isSkilled) && (
                        <span className="tagStyle successTag border-t border-b border-success bg-[#EEF7F2] ml-4 top-[2px]">
                          <span className="tag text-[10px] text-success font-semibold">
                            {/* {item?.work_status} */}
                            {t('expert_badge')}
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 gap-x-1 flex-wrap">
                      <span className=" text-sm text-primaryText font-medium ">
                        {userData?.current_designation ?? '- -'}
                      </span>
                      <span className=" text-sm text-primaryText font-medium ">
                        {/* {userData?.current_designation &&
                          userData?.profile_headline &&
                          "|"} */}
                        |
                      </span>
                      <span className=" text-sm text-primaryText font-medium ">
                        {userData?.profile_headline ?? '- -'}
                      </span>
                    </div>
                    <div className=" text-xs text-primaryText font-medium ">
                      <span className="text-primary">
                        {' '}
                        {userData?.rating ?? 0}{' '}
                      </span>{' '}
                      {t('rating_text') ?? ''} & {userData?.reviews.length ?? 0}{' '}
                      {t('reviews_text') ?? ''}
                    </div>
                  </div>
                </div>
                <div>
                  {!readOnly && (
                    <IconButton
                      disableRipple
                      onClick={() =>
                        handleUserDetailsEdit(userProfileTypes.BASIC_INFO)
                      }
                      className="p-0  "
                    >
                      <BiEdit className="p-0  text-primaryText w-5" />
                    </IconButton>
                  )}
                </div>
              </div>
              <nav className={` bg-bgWhite z-10 tabShadow navTabs w-full`}>
                <ul className="flex">
                  <li>
                    <Link
                      activeClass="active"
                      className="tabLink inline-block p-3.5 py-4 text-sm text-primaryText font-medium"
                      to="profileInfo"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={isMobile ? -315 : -265}
                    >
                      {t('profileinfo_tab') ?? ''}
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      className="tabLink inline-block p-3.5 py-4 text-sm text-primaryText font-medium "
                      to="aboutMe"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={isMobile ? -315 : -230}
                    >
                      {t('aboutme_tab') ?? ''}
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      className="tabLink inline-block p-3.5 py-4 text-sm text-primaryText font-medium"
                      to="skillsExperience"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={isMobile ? -315 : -230}
                    >
                      {t('skills&experience_tab') ?? ''}
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      className="tabLink inline-block p-3.5 py-4 text-sm text-primaryText font-medium"
                      to="education"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={isMobile ? -315 : -230}
                    >
                      {t('education_tab') ?? ''}
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      className="tabLink inline-block p-3.5 py-4 text-sm text-primaryText font-medium"
                      to="moreInfo"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={isMobile ? -315 : -230}
                    >
                      {t('moreinfo_tab') ?? ''}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div>
              <Element name="profileInfo" id="containerElement">
                <PersonalInformation
                  data={userData}
                  handleEdit={handleUserDetailsEdit}
                  readOnly={readOnly}
                />
                <ProfessionalInformation
                  data={userData}
                  handleEdit={handleUserDetailsEdit}
                  readOnly={readOnly}
                />
              </Element>

              <Element name="aboutMe" id="containerElement">
                <AboutMe
                  data={userData?.description}
                  handleEdit={handleUserDetailsEdit}
                  readOnly={readOnly}
                />
              </Element>

              <Element name="skillsExperience" id="containerElement">
                <TagSelect
                  data={userData?.skills}
                  handleEdit={handleUserDetailsEdit}
                  title={t('skills_heading') ?? ''}
                  type="skillPreference"
                  readOnly={readOnly}
                />
                <div
                  className={`flex flex-col bg-bgWhite border-b border-formBorder ${
                    isMobile ? 'p-4 gap-4' : 'px-7 py-5'
                  }`}
                >
                  <Experience
                    jobDetails={userData?.job_details ?? []}
                    handleRefresh={getUserDetails}
                    editMode={true}
                    readOnly={readOnly}
                  />
                </div>
              </Element>
              <Element name="education" id="containerElement">
                <div
                  className={`flex flex-col  bg-bgWhite border-b border-formBorder ${
                    isMobile ? 'p-4' : 'px-7 py-5'
                  }`}
                >
                  <Education
                    educationDetails={userData?.qualifications ?? []}
                    handleRefresh={getUserDetails}
                    editMode={true}
                    readOnly={readOnly}
                  />
                </div>
              </Element>
              <Element name="moreInfo" id="containerElement">
                <DottedSelect
                  data={userData?.languages}
                  handleEdit={handleUserDetailsEdit}
                  title={t('language_proficiency_heading') ?? ''}
                  type="language"
                  readOnly={readOnly}
                />
                <DottedSelect
                  data={userData?.work_sectors}
                  handleEdit={handleUserDetailsEdit}
                  title={t('preferred_job_sector_label') ?? ''}
                  type="workSector"
                  readOnly={readOnly}
                />
                <DottedSelect
                  data={userData?.work_locations}
                  handleEdit={handleUserDetailsEdit}
                  title={t('preferred_work_location_label') ?? ''}
                  type="workLocation"
                  readOnly={readOnly}
                />
                <UploadResume
                  data={userData?.attachments}
                  handleEdit={handleUserDetailsEdit}
                  handleRefresh={getUserDetails}
                  readOnly={readOnly}
                />

                <div
                  className={`flex flex-col bg-bgWhite border-b border-formBorder ${
                    isMobile ? 'p-4' : 'px-7 py-5'
                  }`}
                >
                  <FreelancerPayment
                    bankdetails={userData?.bankdetails ?? []}
                    handleRefresh={getUserDetails}
                    editMode={true}
                    readOnly={readOnly}
                  />
                </div>
                {/* <FreelanceTaxInformation
                  data={userData}
                  handleEdit={handleUserDetailsEdit}
                  readOnly={readOnly}
                /> */}
                <SocialMediaPresence
                  socialData={userData?.social_media_details}
                  handleEdit={handleUserDetailsEdit}
                  readOnly={readOnly}
                  from={'user'}
                />
                {/* <Portfolio
                  data={userData?.portfolios}
                  handleEdit={handleUserDetailsEdit}
                  readOnly={readOnly}
                /> */}
                {/* <TagSelect
                  data={userData?.recommendationTags}
                  handleEdit={handleUserDetailsEdit}
                  title={t("recommendation_tags") ?? ""}
                  type="recommendedTags"
                  readOnly={readOnly}
                /> */}
                {userData?.reviews?.length > 0 && (
                  <RatingReview data={userData} />
                )}
              </Element>
            </div>
          </div>
        </div>
        {openUserModel && (
          <UserProfileUpdate
            type={sectionType}
            openUserModel={openUserModel}
            defaultValues={userData}
            closeUserModel={setUserModel}
            handleRefresh={() => getUserDetails()}
          />
        )}
      </UserContext.Provider>
    </div>
  );
}
