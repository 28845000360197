import { z } from "zod";

export const contactUsSchema = z.object({
  first_name: z
    .string({
      required_error: "contactus_firstname_required",
      invalid_type_error: "contactus_firstname_required",
    })
    .trim()
    .min(1, { message: "contactus_firstname_required" }),
  last_name: z
    .string({
      required_error: "contactus_lastname_required",
      invalid_type_error: "contactus_lastname_required",
    })
    .trim()
    .min(1, { message: "contactus_lastname_required" }),
  email: z
    .string({ invalid_type_error: "contactus_email" })
    .trim()
    .email({ message: "contactus_email" })
    .min(1, { message: "contactus_email" }),
  phone: z
    .string({
      required_error: "mobile_number_required_femsg",
      invalid_type_error: "mobile_number_required_femsg",
    })
    .trim()
    .min(6, { message: "phone_atleast_8characters_femsg" })
    .max(15, { message: "enter_valid_mobile_number_femsg" })
    .regex(
      new RegExp(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      ),
      // "Enter a valid mobile number"
      { message: "enter_valid_mobile_number_femsg" }
    ),
  description: z
    .string({
      required_error: "home_message_validation",
      invalid_type_error: "home_message_validation",
    })
    .trim()
    .min(1, { message: "home_message_validation" }),
});

export type ContactUsSchema = z.infer<typeof contactUsSchema>;
