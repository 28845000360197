type Props = {
  fullWidth?: boolean;
  className?: string;
};
export default function LoaderFile(props: Props) {
  const { fullWidth, className } = props;
  return (
    <div
      className={`${
        fullWidth ? "w-full" : "w-4/4"
      } ${className}  space-y-4  rounded  animate-pulse  lg:p-0 dark:border-slatedark-700`}
    >
      <div className="flex items-center justify-between gap-3 shadow-sm p-2 bg-bgWhite rounded-md">
        <div className="w-2/4">
          <div className="flex items-center mt-4 space-x-3">
            <svg
              className="text-slatedark w-14 h-14 dark:text-gray-700"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <div>
              <div className="h-2.5 bg-slatedark rounded-full dark:bg-gray-700 w-32 mb-2"></div>
              <div className="w-48 h-2 bg-slatedark rounded-full dark:bg-gray-700"></div>
            </div>
          </div>
        </div>
        <div className=" w-2/4">
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
        </div>
      </div>

      <div className="flex items-center justify-between gap-3 shadow-sm p-2 bg-bgWhite rounded-md">
        <div className="w-2/4">
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-2/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
        </div>
        <div className=" w-2/4">
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
        </div>
      </div>
      <div className="flex items-center justify-between gap-3 shadow-sm p-2 bg-bgWhite rounded-md">
        <div className="w-2/4">
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-2/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
        </div>
        <div className=" w-2/4">
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
        </div>
      </div>
      <div className="flex items-center justify-between gap-3 shadow-sm p-2 bg-bgWhite rounded-md">
        <div className="w-2/4">
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-2/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
        </div>
        <div className=" w-2/4">
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
        </div>
      </div>
      <div className="flex items-center justify-between gap-3 shadow-sm p-2 bg-bgWhite rounded-md">
        <div className="w-2/4">
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-2/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>

          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
        </div>
        <div className=" w-2/4">
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-4/4 mb-2.5"></div>
        </div>
      </div>
    </div>
  );
}
