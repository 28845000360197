import { getData, postData } from "../../../apis/api.helpers";
import apiUrl from "./apiUrl";
import { dataParse } from "../../../utilities/validation";

export const getSubscriptiondetails = () =>
  getData(apiUrl.SUBSCRIPTION_DETAILS).then((res) => res);
export const getSubscriptionHistory = (data: any) =>
  getData(`${apiUrl.SUBSCRIPTION_HISTORY}${dataParse(data)}`);
export const getSubscriptionPlanDetails = (data: any) =>
  getData(`${apiUrl.SUBSCRIPTION_PALNDETAILS}/${data}`);
export const getSubscriptionPlans = () =>
  getData(`${apiUrl.SUBSCRIPTION_PALNS}`);
export const getSubscriptionAddonDetails = (data: any) =>
  getData(`${apiUrl.SUBSCRIPTION_ADDON_DETAILS}/${data}`);
export const getSubscriptionHistoryDetails = (data: any) =>
  getData(`${apiUrl.SUBSCRIPTION_HISTORY}/${data}`);
export const getAddonList = (data: any) =>
  getData(`${apiUrl.ADDON_LIST}${data}`);
export const getAddonPurchase = (data: any) =>
  postData(`${apiUrl.ADDON_PURCHASE}`, data);
export const getPlanPurchase = (data: any) =>
  postData(`${apiUrl.PLAN_PURCHASE}`, data);

