import { useEffect, useState } from 'react';

import Rating from '../../../customComponents/rating';

type Props = {
  reviewItem?: any;
};

export default function ReviewContent(props: Props) {
  const { reviewItem } = props;
  const [formattedTimeDiff, setFormattedTimeDiff] = useState('');

  useEffect(() => {
    if (reviewItem) {
      const createdDate: any = new Date(reviewItem?.created_at);
      const currentDate: any = new Date();
      const timeDiff = currentDate - createdDate;
      const yearsDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
      const monthsDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));

      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const formtimeDiff =
        yearsDiff > 0
          ? `${yearsDiff} year${yearsDiff > 1 ? 's' : ''}`
          : monthsDiff > 0
          ? `${monthsDiff} month${monthsDiff > 1 ? 's' : ''}`
          : `${daysDiff} day${daysDiff > 1 ? 's' : ''}`;

      if (daysDiff == 0 && monthsDiff == 0 && yearsDiff == 0) {
        setFormattedTimeDiff('Today');
      } else {
        setFormattedTimeDiff(formtimeDiff + ' ' + 'ago');
      }
    }
  }, [reviewItem]);

  return (
    <div className="flex flex-col reviewBlock pb-4 border-b border-formBorder">
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Rating star={reviewItem?.rating} readOnly={true} />
          {/* <p className="text-sm text-primaryText font-bold">Build me a Logo</p> */}
        </div>
        {/* <p className=" text-primaryText text-sm font-bold">AED 300</p> */}
      </div>
      <p className=" text-primaryText text-sm">
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis cras
        quam senectus rutrum in vestibulum ut. Suspendisse mi eu viverra ac mi
        blandit parturient. Metus tellus, mauris, est enim{" "} */}
        {reviewItem?.comment}
      </p>
      <div className="flex gap-3">
        <p className=" text-primary text-sm ">
          {reviewItem?.user?.name} {''} @{reviewItem?.user?.user_name}
        </p>
        {/* <p className=" text-textColor text-sm">1 year 2 months ago</p> */}
        <p className=" text-textColor text-sm">{formattedTimeDiff}</p>
      </div>
    </div>
  );
}
