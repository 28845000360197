import IconButton from "@mui/material/IconButton";
import { BiEdit } from "react-icons/bi";

import useBrowserWidth from '../../utilities/responsiveHook';
import { userProfileTypes } from "../profile/store";

type Props = {
  data?: { id: string; name: string }[];
  handleEdit: (val: string) => void;
  title: string;
  type: any;
  readOnly?: boolean;
};
export default function LanguageProficiency(props: Props) {
  const { data, handleEdit, type, title, readOnly } = props;
  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <div
      className={`flex flex-col gap-4  bg-bgWhite border-b border-formBorder ${
        isMobile ? "p-4" : "px-7 py-5"
      }`}
    >
      <div className="flex justify-between items-center">
        <p className="text-lg font-bold text-primaryText">
          {/* Language Proficiency ${type}  */}
          {title}
        </p>
        <div className="flex items-center gap-4">
          {!readOnly && (
            <IconButton
              disableRipple
              onClick={() =>
                type == "language"
                  ? handleEdit(userProfileTypes.LANGUAGE)
                  : type == "workLocation"
                  ? handleEdit(userProfileTypes.WORK_LOC)
                  : handleEdit(userProfileTypes.WORK_SECTOR)
              }
              className="p-0   "
            >
              <BiEdit className="p-0  text-primaryText w-5" />
            </IconButton>
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-2.5">
        {data?.map((item, index) => (
          <div key={index} className="textViewList">
            {item.name}
          </div>
        ))}
        {/* <div className="textViewList">Hindi</div>
        <div className="textViewList">English</div>
        <div className="textViewList">Arabic</div> */}
      </div>
    </div>
  );
}
