import { Popover, Transition } from "@headlessui/react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { AiFillBell } from "react-icons/ai";
import { IoNotificationsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";

import { clearAllNotification, clearNotification } from "../../apis/commonapis/apis";
import useBrowserWidth from "../../utilities/responsiveHook";
import EmptyComponent from "../emptyComponent";
import { parseKeysFromNotificationMessage } from "./messages";
import { routesMap, userTypeRoutes } from './store';

type Props = {
  openNotification: boolean;
  setOpen: (value: boolean) => void;
  notificationData: any;
  handleOpen: () => void;
};
export default function Notification(props: Props) {
  const { t } = useTranslation();
  const { openNotification, setOpen, handleOpen, notificationData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { isBrowser, isMobile } = useBrowserWidth();
  const navigate = useNavigate();

  const handleClear = (id: string) => {
    const notification_ids = [];
    notification_ids.push(id);
    clearNotification({ notification_ids: notification_ids })
      .then((res) => {
        enqueueSnackbar(res.message, { variant: "success" });
        handleOpen();
      })
      .catch((err) => {
        enqueueSnackbar(err.response.message, { variant: "error" });
      });
  };
  const handleClearAll = () => {
    clearAllNotification()
      .then((res) => {
        enqueueSnackbar(res.message, { variant: "success" });
        handleOpen();
      })
      .catch((err) => {
        enqueueSnackbar(err.response.message, { variant: "error" });
      });
  };

  const getParsedMessage = (data: any) => {
    if (data) {
      const parsedMessage = parseKeysFromNotificationMessage(
        data["message"],
        data["message_data"]
      );
      return parsedMessage;
    } else {
      return null;
    }
  };

  const handleContinue = (data: any) => {
    console.log("data", data);

    const baseRoute = data?.work_id
      ? `/my-${
          data.user_type.toLowerCase() === "owner" ? "posted" : "applied"
        }-projects-details/${data?.work_id}`
      : null;

    const slug = data?.notification_event?.notification_event_type?.slug;

    if (baseRoute) {
      if (routesMap[slug]) {
        return navigate(
          `${baseRoute}${routesMap[slug].route}`,
          {
            work_type:
              data.user_type === "Owner" ? "project_owner" : "applied_projects",
            ... routesMap[slug].state,
          }
         
        );
      } else if (userTypeRoutes[slug] && userTypeRoutes[slug][data.user_type]) {
        return navigate(
          `${baseRoute}/${userTypeRoutes[slug][data.user_type].routeEnd}`,
          {
            work_type:
              data.user_type === "Owner" ? "project_owner" : "applied_projects",
            ...userTypeRoutes[slug][data.user_type].state,
          }
        );
      }
    }
    return null;
  };
  
  return (
    <div className=" flex">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              // onClick={() => (fromTab ")}
              onClick={() => {
                handleOpen();
              }}
              className={`

                ${openNotification ? "" : "text-primary"}
                group flex items-center px-1 h-[30px]   rounded-md text-sm  gap-1.5  font-medium text-white hover:text-opacity-100 focus:outline-none `}
            >
              {isMobile ? (
                <AiFillBell className=" text-[#C8C9C9] w-6 h-6" />
              ) : (
                <IoNotificationsOutline className="p-0  text-2xl hover:text-primary" />
              )}
            </Popover.Button>
            {openNotification && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-2"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-2"
              >
                <Popover.Panel
                  className={`fixed right-0 z-50 ${
                    isMobile
                      ? "w-full max-w-[540px] top-[55px]"
                      : "top-[64px]  w-[540px]"
                  }`}
                >
                  <div className="flex flex-col filterHeight bg-bgWhite rounded-sm shadow-filterMenu ">
                    <div className=" h-[54px] px-5  flex-shrink-0 border-b border-formBorder">
                      <div className="flex  justify-between items-center min-h-full">
                        <p className="text-md font-bold">Notifications</p>
                        <div className="flex  justify-between gap-3 items-cente">
                          {notificationData?.data?.length > 0 && (
                            <button
                              onClick={() => handleClearAll()}
                              className="cursor-pointer h-[32px] text-sm bg-activeStateBg text-primary font-medium  min-w-[72px]  normal-case px-2.5 rounded-md"
                            >
                              Clear All
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="relative overflow-y-auto">
                      {notificationData?.data?.length === 0 && (
                        <div className="flex items-center justify-center">
                          <EmptyComponent
                            primaryText={t("emptypage_msg_title") ?? ""}
                            secondaryText=""
                          />
                        </div>
                      )}
                      {notificationData?.data?.map((item: any) => (
                        <div
                          key={item?.id}
                          className="p-2 flex gap-1 flex-col px-5 border-b border-placeholderText/50"
                        >
                          <div className="flex items-start">
                            <span className="  py-1 w-5 ">
                              {item.status === "unread" && (
                                <svg
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="8"
                                    cy="8.75977"
                                    r="4"
                                    fill="#1650E2"
                                  />
                                </svg>
                              )}
                            </span>
                            <div className="w-full">
                              <div className="flex justify-between items-center">
                                <p className="text-sm font-semibold">
                                  {item?.notification_event?.title}
                                </p>
                                <span
                                  className=" cursor-pointer"
                                  onClick={() => handleClear(item?.id)}
                                >
                                  <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4 12.7598L8 8.75977M8 8.75977L12 4.75977M8 8.75977L4 4.75977M8 8.75977L12 12.7598"
                                      stroke="#999696"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                              </div>

                              <div className="flex gap-1 flex-col">
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => handleContinue(item)}
                                >
                                  <span
                                    className="text-xs"
                                    dangerouslySetInnerHTML={{
                                      __html: getParsedMessage(
                                        item?.notification_event
                                      ),
                                    }}
                                  ></span>
                                </a>

                                <p className="text-xs">
                                  {item?.created_at
                                    ? moment(item?.created_at).format(
                                        "DD-MM-YYYY HH:mm a"
                                      )
                                    : "- -"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            )}
          </>
        )}
      </Popover>
    </div>
  );
}
