import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getDropDownData } from "../../../apis/dropdowns/dropDown.api";
import CustomButton from "../../../customComponents/button";
import CustomDialogBox from "../../../customComponents/dialogbox/index";
import SelectField from "../../../customComponents/selectfield/index";
import CustomInput from "../../../customComponents/textfield/index";
import { createCompany } from "../../../pages/companyprofile/apis";
import { useUserManagement } from "../../../store/authStore";
import { registerSchema, RegisterSchema } from "./schema";

export default function AddCompanyProfile() {
  const { openCompanyModel, setCompanyModel } = useUserManagement();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
  } = useForm<RegisterSchema>({
    mode: "onChange",
    defaultValues: {
      name_ar: "",
      name_en: "",
      registration_number: "",
    },
    resolver: zodResolver(registerSchema),
  });

  const handleClose = () => {
    setCompanyModel(false);
    reset();
  };

  const [dropData, setDropData] = useState<any>();
  const handleDropData = async (key: string) => {
    const { data, error } = await getDropDownData(key);
    if (data?.data) {
      setDropData(data.data);
    }
  };
  useEffect(() => {
    if (openCompanyModel) {
      handleDropData("registration-type");
    }
  }, [openCompanyModel]);
  const handleData = (data: RegisterSchema) => {
    setSaving(true);
    const formData = new FormData();
    formData.append("name[en]", data.name_en);
    formData.append("name[ar]", data.name_ar ?? "");
    formData.append("registration_number", data.registration_number);
    formData.append("registration_type_id", data.registration_type_id);

    createCompany(formData)
      .then((res) => {
        handleClose();
        setSaving(false);
        navigate(`/company-profile/${res.data?.id}`);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
        setSaving(false);
      });
  };

  let regTypeMsg = t(errors?.registration_type_id?.message ?? "");

  return (
    <>
      <CustomDialogBox
        open={openCompanyModel}
        title={t("add_companyprofile_button") ?? ""}
        subtext={t("create_compprofile_text") ?? ""}
        // subtext="Create a profile for your company"
        maxWidth={"xs"}
        handleClose={() => handleClose()}
        dialogActions={
          <div className="w-full relative">
            <CustomButton
              onClick={() => {
                handleSubmit(handleData)();
              }}
              disabled={saving}
              className="w-full"
              title={t("continue_button") ?? ""}
            />
          </div>
        }
      >
        <div className="flex flex-col gap-3.5">
          <div className="rounded-md bg-activeStateBg text-xs text-primary">
            <p className="p-2">
              {t("compprofile_text") ?? ""}
              {/* If you are an employer or represents a company or organisation,
              you can create a profile for your company in Dawam. You can then
              use your company profile to post jobs and freelance projects that
              are specific for your company. You will still be able to use your
              personal user profile for job hunting and freelance project
              bidding. */}
            </p>
          </div>
          <CustomInput
            type={"text"}
            required={true}
            className="fixedInputClass"
            label={t("company_name_label") + "(English)" ?? ""}
            placeholder={t("company_name_placeholder") ?? ""}
            register={register("name_en")}
            error={errors?.name_en?.message}
          />
          <CustomInput
            type={"text"}
            className="fixedInputClass"
            label={t("company_name_label") + "(" + t("arabic_label") + ")"}
            placeholder={t("company_name_placeholder") ?? ""}
            direction="rtl"
            register={register("name_ar")}
            error={errors?.name_ar?.message}
          />
          <Controller
            control={control}
            name="registration_type_id"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <SelectField
                onChange={onChange}
                label={t("type_of_registration_label") ?? ""}
                // label="Type of Registration"
                placeholder={t("type_of_registration_label") ?? ""}
                required={true}
                data={dropData ?? []}
                fullWidth
                value={value ?? ""}
                className="customSelectField"
                error={regTypeMsg ?? regTypeMsg}
              />
            )}
          />
          <CustomInput
            type={"text"}
            required={true}
            className="fixedInputClass"
            label={t("company_regnumber_label") ?? ""}
            // label="Company Registration Number"
            placeholder={t("company_regnumber_placeholder") ?? ""}
            // placeholder={"Registration Number"}
            register={register("registration_number")}
            error={t(errors?.registration_number?.message ?? "")}
          />
        </div>
      </CustomDialogBox>
    </>
  );
}
