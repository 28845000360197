import Button from "@mui/material/Button";

type Props = {
  className?: string;
  title?: string;
  disabled?: boolean;
  onClick: () => void;
};
export default function CustomButton(props: Props) {
  const { className, title, onClick, disabled } = props;
  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={`${className} ${
        !disabled && "bg-primary"
      }  text-bgWhite h-10 min-w-[72px] text-sm normal-case p-2.5 rounded-md`}
    >
      {title}
    </Button>
  );
}
