import { MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import { useState } from 'react';

import styles from './index.module.scss';

type Props = {
  className?: string;
  label?: string;
  required?: boolean;
  data?: { id: number; name: string }[] | any;
  onChange: (value: number) => void;
  value?: any;
  fullWidth?: boolean;
  register?: any;
  placeholder?: string;
  error?: string;
  dropDownKey?: string;
  disabled?: boolean;
  getData: (key?: string) => void;
  isLoading: boolean;
};

const FilterSelectBox = (props: Props) => {
  const {
    className,
    label,
    data,
    register,
    placeholder,
    required,
    onChange,
    value,
    error,
    disabled,
    getData,
    dropDownKey,
    isLoading,
  } = props;
  const [open, setOpen] = useState(false);
  const handleOpenDropDown = async () => {
    if (data.length === 0) {
      getData(dropDownKey);
    }
    setOpen(!open);
  };
  const dropData = data?.length ? data : [value];

  console.log(dropData);
  return (
    <>
      <div className="formSection selectFieldInput">
        {label && (
          <label className={`block justify-start labelText`}>
            {required ? (
              <>
                {label}
                <span className="text-required"> * </span>
              </>
            ) : (
              label
            )}{' '}
          </label>
        )}
        <div className={styles.inputGroup}>
          <Select
            {...props}
            className={`${styles.customSelect} ${className}`}
            fullWidth
            value={value ?? ''}
            {...register}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => handleOpenDropDown()}
            disabled={disabled}
            displayEmpty
            onChange={(e: object | any) => onChange(e.target.value)}
            size="small"
            renderValue={
              value !== ''
                ? undefined
                : () => <span className="text-[#aeabab]">{placeholder}</span>
            }
            label={label}
          >
            {isLoading && <div>Loading...</div>}
            {!isLoading &&
            (dropData?.length == 0 || Object.keys(dropData[0]).length == 0) ? (
              <div className="">Nothing found.</div>
            ) : (
              dropData?.length &&
              !isLoading &&
              dropData?.map((item: any) => (
                <MenuItem
                  key={item?.id}
                  value={item}
                  className={styles.textSmall}
                >
                  {item.name}
                </MenuItem>
              ))
            )}
          </Select>
          {error && <p className="m-0  text-bgError text-xs">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default FilterSelectBox;
