import { create } from "zustand";

type ProfileStore = {
  openBankInfoModel: boolean;
  setBankInfoModel: (open: boolean) => void;
  openProfileCompletionModel: boolean;
  setOpenProfileCompletionModel: (open: boolean) => void;
  openExperienceModel: boolean;
  setExperienceModel: (open: boolean) => void;
  openEducationModel: boolean;
  setEducationModel: (open: boolean) => void;
};
export const profileStore = create<ProfileStore>()((set) => ({
  openProfileCompletionModel: false,
  setOpenProfileCompletionModel: (open) =>
    set(() => ({ openProfileCompletionModel: open })),
  openBankInfoModel: false,
  setBankInfoModel: (open) => set(() => ({ openBankInfoModel: open })),
  openExperienceModel: false,
  setExperienceModel: (open) => set(() => ({ openExperienceModel: open })),
  openEducationModel: false,
  setEducationModel: (open) => set(() => ({ openEducationModel: open })),
}));

type report = {
  openReport: boolean;
  setReport: (search: boolean) => void;
};

export const useReport = create<report>()((set) => ({
  openReport: false,
  setReport: (value) => set(() => ({ openReport: value })),
}));
