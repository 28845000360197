export default function AnimateLoader() {
  return (
    <div className="flex gap-2">
      <div className=" p-1 bg-primary  w-2 h-2 rounded-full animate-pulse delay-100 "></div>
      <div className=" bg-primary/90 p-1 w-2 h-2 rounded-full animate-pulse g delay-200"></div>
      <div className=" bg-primary/80 p-1  w-2 h-2 rounded-full animate-pulse  delay-300"></div>
      <div className=" p-1 bg-primary/70  w-2 h-2 rounded-full animate-pulse delay-400 "></div>
      <div className=" bg-primary/60 p-1 w-2 h-2 rounded-full animate-pulse  delay-500"></div>
      <div className=" bg-primary/50 p-1  w-2 h-2 rounded-full animate-pulse delay-600 "></div>
      <div className=" bg-primary/40 p-1  w-2 h-2 rounded-full animate-pulse  delay-700"></div>
      <div className=" p-1 bg-primary/30  w-2 h-2 rounded-full animate-pulse delay-800 "></div>
      <div className=" bg-primary/20 p-1 w-2 h-2 rounded-full animate-pulse  delay-900"></div>
      <div className=" bg-primary/10 p-1  w-2 h-2 rounded-full animate-pulse delay-1000 "></div>
    </div>
  );
}
