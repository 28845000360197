export const companyProfileTypes = {
  BASIC_INFO: "Basic_Info",
  COMPANY_INFO: "Company_Info",
  COMPANY_DESC: "Company_Description",
  AREA_OF_EXPERTISE: "Area_Of_Expertise",
  COMPANY_PAYMENT_INFO: "Company_Payment_info",
  COMPANY_TAX_INO: "Company_Tax_Info",
  SOCIAL_MEDIA_PRESENCE: "Social_Media_Presence",
  RECOMMENDATION_TAGS: "Recommendation_Tags",
};
export const companyProfileTypesTitle = {
  Basic_Info: "company_basic_Information",
  Company_Info: "company_info",
  Company_Description: "company_description",
  Area_Of_Expertise: "area_of_expertise",
  Company_Payment_info: "Company Payment info",
  Company_Tax_Info: "company_tax__infor",
  Social_Media_Presence: "social_media_presence",
  Recommendation_Tags: "recommendation_tags",
  // Recommendation_Tags: "Recommendation Tags",
};
