import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';

import { getDropDownData } from '../../../apis/dropdowns/dropDown.api';
import AutoComplete from '../../../customComponents/AutocompleteField';
import CustomButton from '../../../customComponents/button';
import CustomDialogBox from '../../../customComponents/dialogbox/index';
import SelectField from '../../../customComponents/selectfield';
import CustomInput from '../../../customComponents/textfield';
import { updateProfile } from '../../../pages/profile/apis/api';
import { profileStore } from '../../../store/profileStore';
import useBrowserWidth from '../../../utilities/responsiveHook';
import { UserContext } from '../../detailComponent/dataContext';
import { qualificationSchema, QualificationSchema } from './schema';

const dropKeys = ['speciality-area', 'qualification'];
type Props = {
  handleRefresh: () => void;
  defaultValues: QualificationSchema;
  mode: string;
};

export default function Education(props: Props) {
  const { handleRefresh, defaultValues, mode } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { isBrowser, isMobile } = useBrowserWidth();

  const [datas, setDatas] = useState<any>([]);
  const [closePopup, setClosePopup] = useState({
    status:false,
    mode:mode
  });
  const { openEducationModel, setEducationModel } = profileStore();

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
  } = useForm<QualificationSchema>({
    mode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: zodResolver(qualificationSchema),
  });
  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues]);

  useEffect(() => {
    if (mode == 'Add') {
      reset({
        qualification_id: "",
        institution: "",
        qualification_specialisation: {},
        year_of_passing: "",
      });
    } else {
      reset({ ...defaultValues });
    }
    closePopup.status=false;
    closePopup.mode=mode;
    }, [openEducationModel]);

  const handleClose = () => {
    setEducationModel(false);
    setIsLoading(false);
    closePopup.status=true;
    closePopup.mode=mode;
  };

  const { dropData, setDropData } = useContext(UserContext);

  const handleDropData = async (key: string, searchKey?: string) => {
    let drop = dropData;
    const { data, error } = await getDropDownData(key,"",searchKey);
    drop = { [key]: data?.data, ...drop };
    // if (data) {
    //   setDatas(data?.data);
    // }
    setDropData((prevState: any) => ({
      ...prevState,
      [key]: data?.data,
    }));
  };
  useEffect(() => {
    dropKeys.forEach((item) => {
      handleDropData(item);
    });
  }, []);

  const getSpecilizationData = (key: any) => {
    handleDropData("speciality-area", key);
  };

  const handleData = async (data: QualificationSchema) => {
    setIsLoading(true);

    if (defaultValues.id && mode == 'Edit') {
      data.id = defaultValues.id;
    }
    const form = new FormData();
    form.append('qualifications', JSON.stringify(data));

    updateProfile(form)
      .then((response) => {
        if (response.status) {
          setIsLoading(false);
          handleRefresh();
          setEducationModel(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        const errorKey = Object.keys(error.response.data.error)[0];
        enqueueSnackbar(error.response.data.error[errorKey][0], {
          variant: 'error',
        });
      });
  };

  return (
    <div className="flex">
      <CustomDialogBox
        open={openEducationModel}
        title={mode == 'Add' ? t('add_education') : t('edit_education')}
        maxWidth={'md'}
        handleClose={() => handleClose()}
        className="customFormDialog"
        dialogActions={
          <div className="flex justify-end gap-3 w-full">
            <CustomButton
              className={`secondaryButton ${isMobile ? 'flex-1' : ''}`}
              title={t('cancel_button') ?? ''}
              onClick={() => handleClose()}
            />
            <CustomButton
              className={`primaryButton disabled:opacity-50 ${
                isMobile ? 'flex-1' : ''
              }`}
              title={t('save_button') ?? ''}
              disabled={isLoading ? true : false}
              onClick={() => {
                handleSubmit(handleData)();
              }}
            />
          </div>
        }
      >
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <Controller
              control={control}
              name="qualification_id"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SelectField
                  onChange={onChange}
                  data={(dropData && dropData['qualification']) ?? []}
                  label={t('qualification_name_label') ?? ''}
                  value={value ?? ''}
                  placeholder={t('qualification_name_placeholder') ?? ''}
                  fullWidth
                  className="customSelectField"
                  error={t(errors.qualification_id?.message ?? '') ?? ''}
                  required={true}
                />
              )}
            />
            {/* <CustomInput
              label={t("qualification_name_label") ?? ""}
              required={true}
              placeholder={t("qualification_name_placeholder") ?? ""}
              register={register("qualification_name")}
              error={errors.qualification_name?.message}
            /> */}
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <CustomInput
              label={t('schoolcollege_label') ?? ''}
              placeholder={t('schoolcollege_placeholder') ?? ''}
              register={register('institution')}
              error={t(errors.institution?.message ?? '')}
              required={true}
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <Controller
              control={control}
              name="qualification_specialisation"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AutoComplete
                value={value ?? []}
                // inputValue={""}
                labelName={t("specialisation_label") ?? ""}
                closePopup={closePopup}
                getData={getSpecilizationData}
                // getData={handleDropData}
                // handleChange={(e) => handleNationalityChange(e)}
                handleChange={onChange}
                startIcon={<AiOutlineSearch />}
                placeholder={t("specialisation_label") ?? ""}
                required={true}
                error={ (errors?.qualification_specialisation?.name?.message || errors?.qualification_specialisation?.message ) && t('specialation_required_femsg') }
                data={
                  dropData &&
                  (dropData['speciality-area'])?.map((data: any) => {
                    return {
                      name: data?.name,
                      id: data?.id,
                    };
                  })
                }
              />
              )}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <CustomInput
              label={t('passing_year_label') ?? ''}
              placeholder={t('passing_year_placeholder') ?? ''}
              register={register('year_of_passing')}
              error={t(errors.year_of_passing?.message ?? '')}
              required={true}
            />
          </div>
        </div>
      </CustomDialogBox>
    </div>
  );
}
