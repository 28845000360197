import { zodResolver } from "@hookform/resolvers/zod";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import {
  googleLogin,
  registerApi,
  verifyUserName,
} from "../../../apis/auth/auth.api";
import CustomButton from "../../../customComponents/button";
import CustomDialogBox from "../../../customComponents/dialogbox/index";
import { useAuthStore, useUserManagement } from "../../../store/authStore";
import { useCompanySwitchStore } from "../../../store/companySwitch";
import { registerSchema, RegisterSchema } from "./schema";

export default function Signup() {
  const {
    openModel,
    setOpenModel,
    setOpenSignModel,
    setEmailToVerify,
    setOpenEmailVerifyModel,
  } = useUserManagement();
  const { t } = useTranslation();
  const style = {
    pswWrapper: "flex relative",
    pswShow: "absolute right-0.5 rtl:right-auto rtl:left-0.5 z-10",
  };
  const {
    formState: { isValid, errors },
    handleSubmit,
    register,
    setError,
    clearErrors,
    reset,
  } = useForm<RegisterSchema>({
    mode: "onChange",
    defaultValues: {
      name: "",
      user_name: "",
      phone: "",
      email: "",
      password: "",
    },
    resolver: zodResolver(registerSchema),
  });

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { setAuthenticated } = useAuthStore();
  const [hidePassword, setHidePassword] = useState(true);
  const [pswType, setPswType] = useState(true);
  const { clearCompany } = useCompanySwitchStore();
  const handleView = () => {
    setHidePassword(!hidePassword);
    setPswType(!pswType);
  };

  const handleClose = () => {
    setOpenModel(false);
    reset();
  };

  const handleSignIn = () => {
    setOpenSignModel(true);
    setOpenModel(false);
  };

  const handleData = (data: RegisterSchema) => {
    registerApi(data)
      .then((res) => {
        clearCompany();
        setAuthenticated(true);
        setEmailToVerify(data.email);
        localStorage.removeItem("switched_company");
        localStorage.setItem("accessToken", res?.data?.token);
        localStorage.setItem("refreshToken", res?.data?.refresh_token);
        localStorage.setItem("useData", JSON.stringify(res?.data));
        enqueueSnackbar(res.message, {
          variant: "success",
        });
        setOpenModel(false);
        setOpenEmailVerifyModel(true);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };
  const handleDuplicate = (e: any) => {
    if (e.target.value) {
      verifyUserName(e.target.value)
        .then((res) => {
          clearErrors("user_name");
        })
        .catch((err: any) => {
          setError("user_name", {
            type: "custom",
            message: "username_taken_femsg",
          });
        });
    }
  };
  useEffect(() => {
    return () => {
      reset({});
    };
  }, []);
  const handleGooleSignIn = () => {
    googleLogin()
      .then((res) => {
        console.log(res);
        window.open(res.redirectUrl, "_self");
      })
      .catch((err) => {});
  };
  return (
    <>
      <CustomDialogBox
        open={openModel}
        title={t("sign_up_button") ?? ""}
        subtext={t("signin_longtext") ?? ""}
        maxWidth={"xs"}
        handleClose={() => handleClose()}
        dialogActions={
          <div className="flex flex-col gap-3.5 w-full">
            <div className="w-full relative">
              <CustomButton
                onClick={() => {
                  handleSubmit(handleData)();
                }}
                className="w-full !font-semibold"
                title={t("sign_up_button") ?? ""}
              />
            </div>
            {/* <div className="w-full relative">
              <Button
                variant="contained"
                onClick={() => handleGooleSignIn()}
                className={`text-titleColor bg-View font-semibold bg-bgWhite h-10 text-xs lg:text-sm normal-case p-2.5 rounded-md w-full shadow-none border border-[#D6D8DC] border-solid`}
              >
                <FcGoogle className="w-4 h-4 mr-1.5" />
                {t("continue_google_button") ?? ""}
              </Button>
            </div> */}
            <div className="flex justify-center items-center mx-auto mt-4">
              <p className="text-xs lg:text-sm text-textColor font-semibold flex items-center">
                {t("already_have_account") ?? ""}
                <Button
                  className="ml-1 text-xs lg:text-sm text-primary font-semibold capitalize  hover:text-primary transition-colors p-0 m-0 bg-transparent"
                  onClick={() => {
                    handleSignIn();
                  }}
                >
                  {t("sign_in_button") ?? ""}
                </Button>
              </p>
            </div>
          </div>
        }
      >
        <div className="flex flex-col gap-3.5">
          <div className="w-full relative">
            <input
              id={"Name"}
              type={"text"}
              required={true}
              className="fixedInputClass"
              placeholder={t("full_name_plcaeholder") ?? ""}
              {...register("name")}
            />
            <p className="m-0 text-xs lg:text-sm text-bgError">
              {t(errors?.name?.message ?? "")}
            </p>
          </div>
          <div className="w-full relative">
            <input
              id={"user_name"}
              type={"text"}
              required={true}
              className="fixedInputClass"
              placeholder={t("username_label") ?? ""}
              {...register("user_name", { onBlur: (e) => handleDuplicate(e) })}
            />
            <p className="m-0 text-xs lg:text-sm text-bgError">
              {t(errors?.user_name?.message ?? "")}
            </p>
          </div>
          <div className="w-full  relative">
            {/* <div className="flex"> */}
            {/* <div className="numAdornment">+ 91</div> */}
            <input
              id={"phone"}
              type={"text"}
              required={true}
              // className="fixedNumInput"
              className="fixedInputClass"
              placeholder={t("mobile_number_placeholder") ?? ""}
              {...register("phone")}
            />
            {/* </div> */}

            <p className="m-0 text-xs lg:text-sm text-bgError">
              {t(errors?.phone?.message ?? "")}
            </p>

            {/* <TextField
              type={"text"}
              required={true}
              className="fixedInputClass"
              placeholder={"Mobil Number"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="numEndAdornment">+ 91</span>
                  </InputAdornment>
                ),
              }}
            /> */}
          </div>
          <div className="w-full relative">
            <input
              id={"email"}
              type={"text"}
              required={true}
              className="fixedInputClass"
              placeholder={t("email_placeholder") ?? ""}
              {...register("email", {
                setValueAs: (value) => value.toLowerCase(),
              })}
            />
            <p className="m-0 text-xs lg:text-sm text-bgError">
              {t(errors?.email?.message ?? "")}
            </p>
          </div>
          <div className="w-full relative">
            <div className={`${style.pswWrapper} mb-1`}>
              <input
                id={"password"}
                type={pswType ? "password" : "text"}
                required={true}
                className="fixedInputClass"
                placeholder={t("password_placeholder") ?? ""}
                {...register("password")}
              />
              <IconButton
                className={style.pswShow}
                onClick={() => handleView()}
              >
                {!hidePassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </IconButton>
            </div>
            <p className="m-0 text-xs lg:text-sm text-bgError">
              {t(errors?.password?.message ?? "")}
            </p>
          </div>
        </div>
      </CustomDialogBox>
    </>
  );
}
