import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { forgotPassword } from "../../../apis/commonapis/apis";
import CustomButton from "../../../customComponents/button";
import CustomDialogBox from "../../../customComponents/dialogbox/index";
import { useAuthStore, useUserManagement } from "../../../store/authStore";
import { loginSchema, LoginSchema } from "./schema";

export default function ForgotPassword() {
  const { openForgotPassword, setOpenForgotPasssword, setOpenModel } =
    useUserManagement();
  const { t } = useTranslation();

  const { setAuthenticated } = useAuthStore();
  const style = {
    pswWrapper: "flex relative",
    pswShow: "absolute right-0.5 rtl:right-auto rtl:left-0.5 z-10",
  };

  const {
    formState: { isValid, errors },
    handleSubmit,
    register,
    reset,
  } = useForm<LoginSchema>({
    mode: "onChange",
    defaultValues: { email: "" },
    resolver: zodResolver(loginSchema),
  });

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [hidePassword, setHidePassword] = useState(true);
  const [pswType, setPswType] = useState(true);

  const handleData = (data: LoginSchema) => {
    forgotPassword({ email: data.email.toLocaleLowerCase() })
      .then((res) => {
        setOpenForgotPasssword(false);
        enqueueSnackbar(res.message, {
          variant: "success",
        });
        reset();
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  const handleView = () => {
    setHidePassword(!hidePassword);
    setPswType(!pswType);
  };
  const handleClose = () => {
    setOpenForgotPasssword(false);
    reset();
  };

  useEffect(() => {
    return () => {
      reset({});
    };
  }, []);
  return (
    <>
      <CustomDialogBox
        open={openForgotPassword}
        title={t("forgot_password_link") ?? ""}
        subtext={t("forgot_password_message") ?? ""}
        maxWidth={"xs"}
        handleClose={() => handleClose()}
        dialogActions={
          <div className="w-full relative">
            <CustomButton
              onClick={() => {
                handleSubmit(handleData)();
              }}
              className="w-full"
              title={t("submit_button") ?? ""}
            />
          </div>
        }
      >
        <div className="flex flex-col gap-3.5">
          <div className="w-full relative">
            <input
              id={"email"}
              type={"email"}
              required={true}
              className="fixedInputClass"
              placeholder={t("email_placeholder") ?? ""}
              {...register("email")}
            />
            <p className="m-0 text-xs lg:text-xs text-bgError">
              {t(errors?.email?.message ?? "")}
            </p>
          </div>
        </div>
      </CustomDialogBox>
    </>
  );
}
