import { IconButton } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlinePlus } from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { AiFillDelete } from "react-icons/ai";
import { HiBuildingOffice2 } from "react-icons/hi2";

import { ProfileSchema } from "../../common/userSchema";
import { profileStore } from "../../store/profileStore";
import AddEducation from "./addEducation";
import { QualificationSchema } from "./addEducation/schema";
import DeleteModal from '../../components/dialogComponents/deleteComponent';
import { deleteUserEducation } from '../../pages/profile/apis/api';
import { useSnackbar } from 'notistack';

type Props = {
  handleRefresh: () => void;
  educationDetails: QualificationSchema[] | any[];
  editMode?: boolean;
  readOnly?: boolean;
};

export default function Education(props: any) {
  const { handleRefresh, educationDetails, editMode, readOnly } = props;
  const [educationDetailsData, seteducationDetailsData] = useState<any>([]);
  const { openEducationModel, setEducationModel } = profileStore();
  const [mode, setMode] = useState<any>("Add");
  const [deleteModal, setDeleteModal] = useState(false);
  const [educationId, setEducationId] = useState<string>('');

  const handleOpen = () => {
    setEducationModel(true);
    setMode("Add");
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = (id: string) => {
    const data =
      educationDetails &&
      educationDetails?.find((item: any) => item?.id === id);
    seteducationDetailsData(data);
    setMode("Edit");
    setEducationModel(true);
  };

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setEducationId(id);
  };
  const deleteEducationData = async () => {
    deleteUserEducation(educationId)
      .then((res) => {
        props.handleRefresh();
        setDeleteModal(false);
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: 'error',
        });
      });
  };

  const { t } = useTranslation();

  return (
    <>
      {!editMode ? (
        <div className="flex items-center gap-5 justify-between">
          <p className="text-base font-bold text-primaryText">
            {t("education_tab") ?? ""}
          </p>

          <IconButton onClick={(e) => handleOpen()}>
            <AiOutlinePlus className="text-primary" />
          </IconButton>
        </div>
      ) : (
        <div className="flex justify-between items-center mb-4">
          <p className="text-lg font-bold text-primaryText">
            {t("education_tab") ?? ""}
          </p>
          <div className="flex items-center gap-4">
            {!readOnly && (
              <IconButton
                disableRipple
                onClick={(e) => handleOpen()}
                className="p-0  "
              >
                <BiPlus className="p-0  text-primaryText w-5" />
              </IconButton>
            )}
          </div>
        </div>
      )}

      {educationDetails &&
        educationDetails?.length > 0 &&
        educationDetails?.map((item: any) => (
          <div
            key={item.id}
            className="flex items-stretch justify-between relative EducationCard gap-8"
          >
            <div className="flex items-start gap-3 pb-7 ">
              <div className="w-10 h-10 bg-[#F7F7F7] border border-[#E8E8E8] rounded-lg flex items-center justify-center">
                <HiBuildingOffice2 className="text-[#A9A8A8]" />
              </div>
              <div className="flex-1">
                <div className="flex items-center gap-2">
                  <p className="text-sm text-primaryText font-bold">
                    {item.institution ?? "institution"}
                  </p>
                </div>
                <div className="flex flex-wrap gap-2">
                  <span className="text-sm text-primaryText">
                    {item?.qualification?.name ?? "company"}
                  </span>
                  <span className="text-sm text-primaryText">|</span>
                  <span className="text-sm text-primaryText">
                    {item.specialisationName ?? "specialisation"}
                  </span>
                  <span className="text-sm text-primaryText dotBlock"></span>
                  <span className="text-sm text-primaryText">
                    {item.year_of_passing ?? "year_of_passing"}
                  </span>
                </div>
                {/* <div className="flex flex-wrap gap-2 mb-1">
              <span className="text-sm text-secondaryText">
                // Location 
                {item.location ?? "location"}
              </span>
            </div> */}
                <div className="flex flex-wrap gap-2">
                  <span className="text-sm text-secondaryText">
                    {item.job_role_description}
                  </span>
                </div>
              </div>
            </div>
            <div className="relative pb-7 ">
              {!readOnly && (
                <IconButton
                  disableRipple
                  onClick={() => handleEdit(item.id)}
                  className="p-0 w-5"
                >
                  <HiOutlinePencilAlt className="p-0  text-primaryText w-4" />
                </IconButton>
              )}
              {!readOnly && (
                <IconButton
                  disableRipple
                  onClick={() => handleDelete(item.id)}
                  className="p-0 w-5"
                >
                  <AiFillDelete className="p-0  text-primaryText w-4" />
                </IconButton>
              )}
            </div>
          </div>
        ))}
      <AddEducation
        handleRefresh={handleRefresh}
        defaultValues={educationDetailsData}
        mode={mode}
      />
      <DeleteModal
        open={deleteModal}
        setOpen={setDeleteModal}
        title={t("delete_education") ?? ""}
        subTitle={t("delete_education_text") ?? ""}
        description={t("delete_education_description") ?? ""}
        handleRefresh={handleRefresh}
        handleDelete={deleteEducationData}
      />
    </>
  );
}
