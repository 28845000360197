import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../customComponents/button';
import CustomDialogBox from '../../../customComponents/dialogbox/index';
import CustomInput from '../../../customComponents/textfield/index';
import { updateProfile } from '../../../pages/profile/apis/api';
import { profileStore } from '../../../store/profileStore';
import useBrowserWidth from '../../../utilities/responsiveHook';
import { bankAccountSchema, BankAccountSchema } from './schema';

type Props = {
  // detailId: string;
  handleRefresh: () => void;
  defaultValues: BankAccountSchema;
  mode: string;
};
export default function AddBankDetails(props: Props) {
  const { defaultValues, mode } = props;
  const { openBankInfoModel, setBankInfoModel } = profileStore();
  const [isLoading, setIsLoading] = useState(false);
  const { isBrowser, isMobile } = useBrowserWidth();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<BankAccountSchema>({
    mode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: zodResolver(bankAccountSchema),
  });
  const handleClose = () => {
    setBankInfoModel(false);
  };
  const handleCancel = () => {
    reset();
    setIsLoading(false);
    setBankInfoModel(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleData = async (data: BankAccountSchema) => {
    setIsLoading(true);

    if (defaultValues.id && mode == 'Edit') {
      data.id = defaultValues.id;
    }
    const form = new FormData();
    form.append(
      'bank_details[0]',
      JSON.stringify({
        ...data,
        is_primary: defaultValues?.is_primary ?? false,
        bank_detail_id: null,
      })
    );
    updateProfile(form)
      .then((res) => {
        if (res.status) {
          setIsLoading(false);
          enqueueSnackbar(res.message, {
            variant: 'success',
          });
          setBankInfoModel(false);
          props.handleRefresh();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: 'error',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (mode == 'Add') {
      reset({
        bank_name: '',
        branch_name: '',
        account_number: '',
        account_holder_name: '',
        bank_address: '',
        swift_code: '',
        IBAN_number: '',
      });
    } else {
      reset({ ...defaultValues });
    }
  }, [openBankInfoModel]);
  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues]);
  return (
    <>
      <CustomDialogBox
        open={openBankInfoModel}
        title={t('freelance_payment_information_headings') ?? ''}
        subtext=""
        maxWidth={'md'}
        handleClose={() => handleCancel()}
        dialogActions={
          <div className="w-full flex justify-end gap-4 ">
            <CustomButton
              onClick={() => {
                handleCancel();
              }}
              className={`secondaryButton ${isMobile ? 'flex-1' : ''}`}
              title={t('cancel_button') ?? ''}
            />
            <CustomButton
              onClick={() => {
                handleSubmit(handleData)();
              }}
              className={`disabled:opacity-50 ${isMobile ? 'flex-1' : ''}`}
              title={t('save_button') ?? ''}
              disabled={isLoading ? true : false}
            />
          </div>
        }
      >
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <CustomInput
              label={t('bank_name_label') ?? ''}
              register={register('bank_name')}
              required={true}
              // error={errors?.bank_name && errors?.bank_name?.message}
              error={t(errors?.bank_name?.message ?? '')}
              placeholder={t('bank_name_placeholder') ?? ''}
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <CustomInput
              label={t('branch_name_label') ?? ''}
              register={register('branch_name')}
              required={true}
              placeholder={t('branch_name_placeholder') ?? ''}
              error={t(errors?.branch_name?.message ?? '')}
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <CustomInput
              label={t('account_number_label') ?? ''}
              register={register('account_number')}
              required={true}
              placeholder={t('account_number_placeholder') ?? ''}
              error={t(errors?.account_number?.message ?? '')}
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <CustomInput
              label={t('name_in_account_label') ?? ''}
              register={register('account_holder_name')}
              required={true}
              placeholder={t('name_in_account_placeholder') ?? ''}
              error={t(errors?.account_holder_name?.message ?? '')}
            />
          </div>
          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <CustomInput
              label={t('swift_code_label') ?? ''}
              register={register('swift_code')}
              required={true}
              placeholder={t('swift_code_placeholder') ?? ''}
              error={t(errors?.swift_code?.message ?? '')}
            />
          </div>
          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <CustomInput
              label={t('iban_number_label') ?? ''}
              register={register('IBAN_number')}
              // register={register("IBAN_number", {
              //   setValueAs: (v) => v ?? "",
              // })}
              // required={true}
              placeholder={t('iban_number_placeholder') ?? ''}
              error={t(errors?.IBAN_number?.message ?? '')}
              tooltip={{
                // heading:"",
                text: t('please_enter_ibanorswift_message') ?? '',
              }}
            />
          </div>
          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <CustomInput
              label={t('branch_address_label') ?? ''}
              register={register('bank_address')}
              placeholder={t('branch_address_placeholder') ?? ''}
              error={t(errors?.bank_address?.message ?? '')}
            />
          </div>
        </div>
      </CustomDialogBox>
    </>
  );
}
