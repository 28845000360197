import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AiOutlineShareAlt } from "react-icons/ai";

import { addBookmark } from "../../apis/commonapis/apis";
import { useAuthStore, useUserManagement } from "../../store/authStore";

type Props = {
  item: any;
  onClick?: () => void;
  handleRefresh?: () => void;
};
export default function CompanyCardComponent(props: Props) {
  const { item, onClick, handleRefresh } = props;
  const { t } = useTranslation();
  const { setOpenShare } = useUserManagement();
  const { authenticated } = useAuthStore();

  const AddFavourite = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const { data, error, message } = await addBookmark({
      favourited_id: item.id,
      favourited_type: "Company",
    });
    handleRefresh && handleRefresh();
  };

  const handleShareFlag = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: any
  ) => {
    e.stopPropagation();
    const currentUrl = window.location.href;
    setOpenShare({
      open: true,
      link: `${currentUrl}/${id}`,
    });
  };
  return (
    <div
      className="jobCard flex items-stretch gap-3 relative cursor-pointer"
      // className="jobCard premiumCard flex items-stretch gap-3 relative cursor-pointer"
      onClick={() => onClick && onClick()}
    >
      {/* <span className="premiumUser">{t("premium_user_badge") ?? ""}</span> */}
      <div className="w-10 h-10 relative flex justify-center rounded-lg  border border-bgWhite">
        {item?.logo_path ? (
          <img src={item?.actual_logo_Path} className="w-full  " />
        ) : (
          <span className="flex justify-center w-full items-center text-3xl border rounded-lg h-full font-extrabold">
            {item?.name?.charAt(0).toUpperCase()}
          </span>
        )}
        <span className="absolute bottom-[-6px]">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2534_33962)">
              <path
                d="M6.84654 8.82228C7.04181 9.01754 7.35839 9.01754 7.55365 8.82228L10.188 6.18797C10.3051 6.07081 10.4951 6.07081 10.6122 6.18797C10.7294 6.30512 10.7294 6.49507 10.6122 6.61223L7.41223 9.81223C7.29507 9.92939 7.10512 9.92939 6.98797 9.81223L5.38797 8.21223C5.27081 8.09507 5.27081 7.90512 5.38797 7.78796C5.50512 7.67081 5.69507 7.67081 5.81223 7.78796L6.84654 8.82228ZM6.08505 1.80541C5.77233 2.07191 5.38351 2.23296 4.97394 2.26565C3.52865 2.38098 2.38098 3.52865 2.26565 4.97394C2.23296 5.38351 2.07191 5.77233 1.80541 6.08505C0.864994 7.18857 0.864994 8.81162 1.80541 9.91514C2.07191 10.2279 2.23296 10.6167 2.26565 11.0263C2.38098 12.4715 3.52865 13.6192 4.97394 13.7345C5.38351 13.7672 5.77233 13.9283 6.08505 14.1948C7.18857 15.1352 8.81162 15.1352 9.91514 14.1948C10.2279 13.9283 10.6167 13.7672 11.0263 13.7345C12.4715 13.6192 13.6192 12.4715 13.7345 11.0263C13.7672 10.6167 13.9283 10.2279 14.1948 9.91514C15.1352 8.81162 15.1352 7.18857 14.1948 6.08505C13.9283 5.77233 13.7672 5.38351 13.7345 4.97394C13.6192 3.52865 12.4715 2.38098 11.0263 2.26565C10.6167 2.23296 10.2279 2.07191 9.91514 1.80541C8.81162 0.864994 7.18857 0.864994 6.08505 1.80541Z"
                fill="#1650E2"
                stroke="white"
                stroke-linecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2534_33962">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </div>
      <div className="flex-1 gap-1 flex flex-col">
        <div className="flex items-center gap-2">
          <p className="text-base font-bold text-[#333]">
            {item?.name}
            {/* <span className="tagStyle warningTag border-t border-b border-warning bg-[#FBF0F0] ml-4 top-[2px]">
              <span className="tag text-[10px] text-warning font-semibold"> */}
            {/* {item?.work_status} */}
            {/* {t("recommended_menu") ?? ""}
              </span>
            </span> */}
          </p>
        </div>
        <div className="flex flex-wrap gap-2">
          <span className="text-sm text-primaryText">
            {item?.registrationType?.name}
          </span>
          <span className="text-sm text-primaryText">
            {/* {item?.registrationType?.name && item?.industry?.name && "|"} */}
            |
          </span>
          <span className="text-sm text-primaryText">
            {item?.industry?.name}
          </span>
        </div>
        <div className="flex flex-wrap gap-2">
          {/* {item?.work_locations?.map((item: any) => (
            <span className="text-sm text-secondaryText">{item?.name}
            </span>
          ))} */}
          <span className="text-sm text-secondaryText">
            {`${item?.city ?? "- -"} , ${item.state_name ?? "- -"}`}
          </span>
        </div>
        <div className="flex flex-wrap gap-2">
          {typeof item?.rating !== "object" && (
            <span className="text-xs text-primary font-semibold jobDotList">
              {t("rating_text") ?? ""}
              {item?.rating ?? "0"}/5
            </span>
          )}
          {/* <span className="text-xs text-primary font-semibold jobDotList">
            {item?.no_of_views ?? "0"} {t("views_info") ?? ""}{" "}
          </span> */}
          <span className="text-xs text-primary font-semibold jobDotList">
            {item?.no_of_employees
              ? `${item?.no_of_employees} ${t("employees_label" ?? "")}`
              : "- -"}
          </span>
          {/* <span className="text-xs text-primary font-semibold jobDotList">
             Last date {item?.hiring_end_date} 
            {item?.follow_count} {t("followers_label") ?? "Followers"}
          </span> */}
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <div className="flex items-end">
          <IconButton
            disableRipple
            onClick={(e) => handleShareFlag(e, item.id)}
            className="p-0  w-10 h-10 "
          >
            <AiOutlineShareAlt className="p-0  text-primaryText w-5 rtl:rotate-180" />
          </IconButton>
          {/* commented for phase-1 */}

          {/* {authenticated && (
            <IconButton
              disableRipple
              onClick={(e) => AddFavourite(e)}
              className="p-0  w-10 h-10 "
            >
              <BsBookmark
                className={`p-0   w-5 ${
                  item?.is_bookmarked ? "text-primary" : "text-primaryText"
                } `}
              />
            </IconButton>
          )}
          */}
        </div>
        {/* <div className="flex items-end">
          <Button
            variant="contained"
            onClick={() => console.log("test")}
            type="button"
            className={`secondaryButton bg-primary text-bgWhite h-8 min-w-[72px] text-sm normal-case font-semibold p-2.5 rounded-md`}
          >
            {t("follow_button") ?? ""}
          </Button>
        </div> */}
      </div>
    </div>
  );
}
