import moment from "moment";
import { string, z } from "zod";

const currentYear =  moment().year();

export const qualificationSchema = z.object({
  id: z.string().optional(),
  qualification_id: string().trim().min(1, {
    message: "qualification_name_required_femsg",
  }),
  institution: string().trim().min(1, { message: "schoolcollege_required_femsg" }),
  // specialisation: string().trim().min(1, {
  //   message: "specialation_required_femsg",
  // }),
  qualification_specialisation: z
  .object({ id: z.string(), name: z.string({ required_error: "specialation_required_femsg", invalid_type_error: "nationality_required_femsg" }) }),
  // .optional().nullable(),
  year_of_passing: 
  string()
  // z.number({ invalid_type_error: "Enter only numbers" })
  // .trim()
  .min(4, {
    message: "passing_year_required_femsg",})
  // .max(5, {
  //     message: "Invalid year",})
  //  
  .regex(
    new RegExp(
      /^\d+$/
    ),
    {message: "enter_a_valid_year_femsg"}
  )
  .refine(
    (val) =>  
    parseInt(val)  <= currentYear,
    {message: "enter_a_valid_year_femsg"}
  ),
});
export type QualificationSchema = z.infer<typeof qualificationSchema>;
