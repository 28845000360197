import { useTranslation } from "react-i18next";

type Props = {
  searchKey: string;
};
export default function EmptySearch(props: Props) {
  const { searchKey } = props;
  const { t } = useTranslation();
  return (
    <div className="customCard w-4/4 lg:w-3/4 2xl:w-3/5 p-10 ">
      <div className="flex flex-col justify-center items-center gap-4 mb-5">
        <span className="flex flex-col rounded-full p-8 bg-activeStateBg">
          <img src="/assets/no-results.png" />
        </span>
        <div className="flex flex-col justify-center items-center">
          <p className="text-md font-bold">
            {searchKey && searchKey.length > 0
              ? ` ${t("noresult_msg_filter") ?? ""} ‘ ${searchKey} ’`
              : t("no_results_found_filter") ?? ""}
          </p>
          <p className="text-center">
            {t("search_try_again_no_result") ?? ""}{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
