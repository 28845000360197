import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProfileSchema } from '../../common/userSchema';
import config from '../../config';
import CustomSwitch from '../../customComponents/switch';
import CustomInput from '../../customComponents/textfield/index';
import useBrowserWidth from '../../utilities/responsiveHook';

type SocailType = {
  name: string;
  link?: string | null;
  id: string;
  icon: any;
  checked?: boolean;
  placeholder?: string;
}[];
type Props = {
  fromDialog?: boolean;
};
export default function SocialMediaPresence(props: Props) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ProfileSchema>();
  const { t } = useTranslation();
  const { isBrowser, isMobile } = useBrowserWidth();

  const values = getValues('social_media_details');
  const [count, setCount] = useState(1);
  const [socialMedia, setSocialMedia] = useState<SocailType>([
    {
      name: 'LinkedIn',
      icon: config.linkedin,
      id: '1',
      link: '',
      placeholder: 'https://in.linkedin.com',
      checked: false,
    },
    {
      name: 'Facebook',
      icon: config.facebook,
      placeholder: 'https://www.facebook.com',

      id: '2',
      link: '',
      checked: false,
    },
    {
      name: 'Instagram',
      icon: config.instagram,
      placeholder: 'https://www.instagram.com',
      id: '3',
      link: '',
      checked: false,
    },
    {
      name: 'Behance',
      icon: config.behance,
      id: '4',
      placeholder: 'https://www.behance.net',

      link: '',
      checked: false,
    },
    {
      name: 'Twitter',
      icon: config.twitter,
      placeholder: 'https://twitter.com',

      id: '5',
      link: '',
      checked: false,
    },
  ]);
  const handleChange = (items: any, index: number, value: string) => {
    const social = socialMedia;
    social[index]['link'] = value ? value.toLowerCase() : '';
    setValue(`social_media_details`, social, {
      shouldValidate: true,
      shouldDirty: true,
    });
    // setValue(`social_media_details.${index}.name`, items.name);
  };
  const handleSocialData = () => {
    const defaultValue = getValues('social_media_details');
    const social = socialMedia;
    social.forEach((item) => {
      if (defaultValue) {
        defaultValue.forEach((val) => {
          if (item.name === val.name) {
            item['link'] = val.link;
            item['checked'] = val.link ? true : false;
          }
        });
      }
    });
    setSocialMedia(social);
    setCount(count + 1);
  };
  useEffect(() => {
    handleSocialData();
  }, []);
  const handleSwitch = (val: boolean, index: number) => {
    // const social = socialMedia;
    // social[index]["checked"] = val;
    // if (!val) {
    //   const social = socialMedia;
    //   social[index]["link"] = "";
    //   setValue(`social_media_details`, social, {
    //     shouldValidate: true,
    //     shouldDirty: true,
    //   });
    // }

    // setSocialMedia(social);
    // setCount(count + 1);

    const social = socialMedia;
    social[index]['checked'] = val;
    social[index]['link'] = '';
    setValue(`social_media_details`, social, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setSocialMedia(social);
    setCount(count + 1);
  };
  return (
    <div className="flex flex-col gap-5">
      {!props.fromDialog && (
        <p className="text-base font-bold text-primaryText">
          {t('social_media_presence') ?? ''}
        </p>
      )}
      <div className="flex flex-col gap-4">
        {socialMedia?.map((items, index) => (
          <>
            {isMobile ? (
              <div className="flex flex-col gap-2 pb-4 border-b border-formBorder last:border-b-0 last:pb-0">
                <div
                  className="flex gap-2 items-center justify-between"
                  key={items.id}
                >
                  <div className="flex items-center gap-2 flex-1">
                    <span className="">
                      <img className=" w-auto" src={items.icon} />
                    </span>
                    <span className="text-sm text-primaryText font-medium min-w-[120px]">
                      {items.name}
                    </span>
                  </div>
                  <div className="flex items-end">
                    <CustomSwitch
                      value={items.checked}
                      onChange={(val) => handleSwitch(val, index)}
                    />
                  </div>
                </div>
                <div className="flex-1 w-full">
                  <Controller
                    control={control}
                    name={`social_media_details.${index}.link`}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <CustomInput
                        className={`customFieldInput w-full `}
                        placeholder={`${items.placeholder}`}
                        disabled={!items?.checked}
                        value={
                          items?.checked
                            ? items.link !== null
                              ? items.link
                              : ''
                            : ''
                        }
                        error={
                          errors?.social_media_details &&
                          errors?.social_media_details[index]?.link?.message
                        }
                        onChange={(e) =>
                          handleChange(items, index, e.target.value)
                        }
                      />
                    )}
                  />
                </div>
              </div>
            ) : (
              <div className="flex gap-2 items-center " key={items.id}>
                <div className="flex items-center gap-2 lg:min-w-[160px]">
                  <span className="">
                    <img className=" w-auto" src={items.icon} />
                  </span>
                  <span className="text-sm text-primaryText font-medium min-w-[120px]">
                    {items.name}
                  </span>
                </div>
                <div className="flex flex-1 gap-4 items-center">
                  <CustomSwitch
                    value={items.checked}
                    onChange={(val) => handleSwitch(val, index)}
                  />
                  <div className="flex-1 w-full">
                    <Controller
                      control={control}
                      name={`social_media_details.${index}.link`}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <CustomInput
                          className={`customFieldInput w-full `}
                          placeholder={`${items.placeholder}`}
                          disabled={!items?.checked}
                          value={
                            items?.checked
                              ? items.link !== null
                                ? items.link
                                : ''
                              : ''
                          }
                          error={
                            errors?.social_media_details &&
                            errors?.social_media_details[index]?.link?.message
                          }
                          onChange={(e) =>
                            handleChange(items, index, e.target.value)
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
