import IconButton from '@mui/material/IconButton';
import { BiEdit } from 'react-icons/bi';

import useBrowserWidth from '../../utilities/responsiveHook';
import { userProfileTypes } from '../profile/store';

type Props = {
  data?: { id: string; name: string }[];
  handleEdit: (val: string) => void;
  title: string;
  type: any;
  readOnly?: boolean;
};
export default function Skills(props: Props) {
  const { data, handleEdit, type, title, readOnly } = props;
  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <div
      className={`flex flex-col gap-4 bg-bgWhite border-b border-formBorder ${
        isMobile ? 'p-4' : 'px-7 py-5'
      }`}
    >
      <div className="flex justify-between items-center">
        <p className="text-lg font-bold text-primaryText"> {title} </p>
        <div className="flex items-center gap-4">
          {!readOnly && (
            <IconButton
              disableRipple
              onClick={() =>
                type == 'skillPreference'
                  ? handleEdit(userProfileTypes.SKILL_PREF)
                  : handleEdit(userProfileTypes.RECOMMENDATION_TAGS)
              }
              className="p-0   "
            >
              <BiEdit className="p-0  text-primaryText w-5" />
            </IconButton>
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-2">
        {data?.map((item, index) => (
          <div key={index} className="skillsTag">
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}
