import { Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BiPurchaseTagAlt, BiUserCircle } from 'react-icons/bi';
import { IoExitOutline } from 'react-icons/io5';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { TfiAngleLeft } from 'react-icons/tfi';
import { useNavigate } from 'react-router-dom';

import ProfileImage from '../components/profileimage';
import CustomButton from '../customComponents/button';
import { useAuthStore, useUserManagement } from '../store/authStore';
import { useCompanySwitchStore } from '../store/companySwitch';
import useBrowserWidth from '../utilities/responsiveHook';

type Props = {
  anchorEl: any;
  handleClose: () => void;
  handleMenuClose: () => void;
  useData: any;
  switchedProfileId: string;
  setSwitchedProfileId: (switchedProfileId: string) => void;
};
const paths = ['my-posted-projects', 'my-applied-projects'];

export default function HeaderMenu(props: Props) {
  const {
    anchorEl,
    handleClose,
    handleMenuClose,
    useData,
    switchedProfileId,
    setSwitchedProfileId,
  } = props;
  const [switchProfile, setSwitchProfile] = React.useState(false);
  const { setAuthenticated, authenticated } = useAuthStore();
  const { setCompanyModel, setOpenEmailVerifyModel } = useUserManagement();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState(localStorage.getItem('avatar'));
  const { companyDetail, setCompanyDetail, clearCompany } =
    useCompanySwitchStore();
  const { isBrowser, isMobile } = useBrowserWidth();

  const handleLogout = () => {
    handleClose();
    handleMenuClose();
    localStorage.clear();
    localStorage.removeItem('switched_company');
    setAuthenticated(false);
    clearCompany();
    window.open('/home', '_self');
  };
  const handleOpenCompany = () => {
    handleClose();
    handleMenuClose();

    setCompanyModel(true);
  };
  const handleSwitch = () => {
    setSwitchProfile(!switchProfile);
  };
  const handleBack = () => {
    setSwitchProfile(!switchProfile);
  };
  const handleCompanySwitch = (items: any) => {
    setSwitchedProfileId(items?.id ?? '');
    setTimeout(() => {
      setCompanyDetail(items);
      setSwitchProfile(!switchProfile);
      window.open('/', '_self');
    }, 1000);
  };
  const handleNavigateProfile = () => {
    handleClose();
    handleMenuClose();
    setTimeout(() => {
      companyDetail?.id
        ? navigate(`/company-profile-view/${companyDetail?.id}`)
        : navigate('/profile/view');
    }, 100);
  };
  const handleNavigateSubscription = () => {
    handleClose();
    handleMenuClose();
    setTimeout(() => {
      navigate('/subscription');
    }, 100);
  };

  useEffect(() => {
    const handleStorage = () => {
      setAvatar(localStorage.getItem('avatar'));
    };

    window.addEventListener('storage', () => handleStorage());
    return () => window.removeEventListener('storage', () => handleStorage());
  });
  useEffect(() => {
    const temp = useData?.companies?.find(
      (item: any) => item?.id === companyDetail?.id
    );
    temp && setCompanyDetail(temp);
  }, [useData]);
  const handleVerifyEmail = () => {
    handleClose();
    handleMenuClose();
    setOpenEmailVerifyModel(true);
  };

  const trimLang: string = (
    localStorage.getItem('i18nextLng') ?? 'en'
  ).substring(0, 2);
  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={Boolean(anchorEl) ?? false}
      onClose={handleClose}
      className={isMobile ? 'customDropdowm mobileDropdown' : 'customDropdowm'}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      dir={trimLang === 'Ar' || trimLang === 'ar' ? 'rtl' : ''}
    >
      {!switchProfile ? (
        <>
          {isMobile && (
            <div className="flex items-center  justify-start bg-bgWhite h-10 z-40 customWidth ">
              <IconButton
                className="p-0 w-7 h-7 absolute left-2 rtl:right-2 rtl:left-auto"
                onClick={handleClose}
              >
                <TfiAngleLeft className="w-4  text-primaryText rtl:rotate-180" />
              </IconButton>
              <p className=" text-primaryText font-medium text-base flex-1 text-center">
                Profile
              </p>
            </div>
          )}
          <MenuList className="!p-0">
            <div className="flex justify-between items-center">
              {companyDetail ? (
                <div
                  className={`flex items-center justify-start gap-2 ${
                    isMobile ? 'p-2 pl-0' : 'p-2'
                  }`}
                >
                  <IconButton
                    disableRipple
                    // onClick={(e) => {
                    //   handleClick(e);
                    // }}
                    className="p-0  bg-[#FEEDB2] w-10 h-10 rounded-full !border !border-[#D6D8DC]"
                  >
                    <ProfileImage
                      imageUrl={companyDetail?.actual_logo_Path}
                      profileName={companyDetail?.name}
                    />
                  </IconButton>
                  <div className="relative">
                    <p className="text-sm font-medium text-primaryText">
                      {/* {companyDetail?.name} test */}
                      {companyDetail?.name}
                    </p>
                    <p className="text-xs text-secondaryText font-medium">
                      {companyDetail?.company_relation}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className={`flex items-center justify-start gap-2 ${
                    isMobile ? 'p-2 pl-0' : 'p-2'
                  }`}
                >
                  <IconButton
                    disableRipple
                    // onClick={(e) => {
                    //   handleClick(e);
                    // }}
                    className="p-0  w-10 h-10 rounded-full !border !border-[#D6D8DC]"
                  >
                    <ProfileImage
                      imageUrl={
                        companyDetail?.id
                          ? companyDetail?.actual_logo_Path
                          : useData?.profile_image
                      }
                      profileName={
                        companyDetail?.id ? companyDetail?.name : useData?.name
                      }
                    />
                  </IconButton>
                  <div className="relative">
                    <p className="text-sm font-medium text-primaryText">
                      {useData?.name}
                    </p>
                    <p className="text-xs text-secondaryText font-medium">
                      {t('main_account') ?? ''}
                    </p>
                  </div>
                </div>
              )}

              {useData?.companies && useData?.companies?.length > 0 && (
                <button
                  onClick={() => handleSwitch()}
                  className="text-sm flex gap-1 items-center font-semibold min-w-[99px] text-primary px-2 py-1 rounded-md mr-2 bg-activeStateBg"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.33325 1.33203V5.4987H1.81785M14.615 7.16536C14.2049 3.87677 11.3996 1.33203 7.99992 1.33203C5.2021 1.33203 2.80683 3.05551 1.81785 5.4987M1.81785 5.4987H5.49992M14.6666 14.6654V10.4987H14.182M14.182 10.4987C13.193 12.9419 10.7977 14.6654 7.99992 14.6654C4.60024 14.6654 1.79491 12.1206 1.38483 8.83203M14.182 10.4987H10.4999"
                      stroke="#1650E2"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {t('switchto_button') ?? ''}
                </button>
              )}
            </div>
            {(!useData?.companies || useData?.companies?.length === 0) && (
              <div className="px-2">
                <CustomButton
                  className="secondaryButton w-full font-bold !h-8"
                  title={t('add_companyprofile_button') ?? ''}
                  onClick={() => handleOpenCompany()}
                />
              </div>
            )}
            <div className="px-2 relative">
              <Divider className="my-2" />
            </div>
            <MenuItem
              onClick={() => handleNavigateProfile()}
              className="hover:bg-menuHover"
            >
              <ListItemIcon>
                <BiUserCircle className="p-0  text-primaryText w-5 h-5" />
              </ListItemIcon>
              <ListItemText>
                <span className="text-sm">
                  {t('manage_profile_link') ?? ''}
                </span>
              </ListItemText>
            </MenuItem>
            {!useData?.is_email_verified && authenticated && (
              <MenuItem
                className="hover:bg-menuHover"
                onClick={() => handleVerifyEmail()}
              >
                <ListItemIcon>
                  <MdOutlineMarkEmailRead className="p-0  text-primaryText w-5 h-5" />
                </ListItemIcon>
                <ListItemText>
                  <span className="text-sm">
                    {t('email_verification_description') ?? ''}
                  </span>
                </ListItemText>
              </MenuItem>
            )}
            <MenuItem
              className="hover:bg-menuHover"
              onClick={() => handleNavigateSubscription()}
            >
              <ListItemIcon>
                <BiPurchaseTagAlt className="p-0  text-primaryText w-5 h-5" />
              </ListItemIcon>
              <ListItemText>
                <span className="text-sm">{t('subscriptions_link') ?? ''}</span>
              </ListItemText>
            </MenuItem>
            {/* <MenuItem className="hover:bg-menuHover">
            <ListItemIcon>
              <BsGear className="p-0  text-primaryText w-4 h-4" />
            </ListItemIcon>
            <ListItemText>
              <span className="text-sm">
                {t("account_settings_link") ?? ""}
              </span>
            </ListItemText>
          </MenuItem> */}
            <div className="px-2 relative">
              <Divider className="my-2" />
            </div>
            {/* <MenuItem className="hover:bg-menuHover">
            <ListItemIcon>
              <IoHelpBuoyOutline className="p-0  text-primaryText w-4 h-4" />
            </ListItemIcon>
            <ListItemText>
              <span className="text-sm">{t("help_link") ?? ""}</span>
            </ListItemText>
          </MenuItem> */}
            <MenuItem
              className="hover:bg-menuHover"
              onClick={() => handleLogout()}
            >
              <ListItemIcon>
                <IoExitOutline className="p-0  text-warning w-4 h-4" />
              </ListItemIcon>
              <ListItemText>
                <span className="text-sm text-warning">
                  {t('sign_out_button') ?? ''}
                </span>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </>
      ) : (
        <MenuList className={isMobile ? 'px-0 py-0' : 'px-2 py-0'}>
          <div className="flex flex-col ">
            <div className="flex items-center  justify-start bg-bgWhite h-10 z-40 customWidth ">
              <div
                className={`flex-wrap flex items-center gap-2 cursor-pointer ${
                  isMobile && 'absolute left-0 rtl:right-0 rtl:left-auto'
                }`}
                onClick={() => handleBack()}
              >
                <IconButton
                  className={`p-0 h-7 ${isMobile ? 'w-7' : 'w-auto'}`}
                >
                  <TfiAngleLeft className="w-4  text-primaryText rtl:rotate-180" />
                </IconButton>
                {isBrowser && (
                  <p className="text-sm">{t('back_button') ?? ''}</p>
                )}
              </div>
              {isMobile && (
                <p className=" text-primaryText font-medium text-base flex-1 text-center">
                  Select Profile
                </p>
              )}
            </div>
            <div className="px-0 relative">
              <Divider className="my-2" />
            </div>
            <div className="flex flex-col gap-2">
              <div
                className={`flex items-center justify-between gap-2 p-2 hover:bg-menuHover rounded-lg cursor-pointer`}
                onClick={() => handleCompanySwitch(undefined)}
              >
                <IconButton
                  disableRipple
                  // onClick={(e) => {
                  //   handleClick(e);
                  // }}
                  className={`p-0 rounded-full !border !border-[#D6D8DC] ${
                    isMobile ? 'w-7 h-7' : 'w-10 h-10'
                  }`}
                >
                  <ProfileImage
                    imageUrl={useData?.profile_image}
                    profileName={useData?.name}
                  />
                </IconButton>
                <div className="relative flex-1">
                  <p className="text-sm font-medium text-primaryText">
                    {useData?.name}
                  </p>
                  <p className="text-xs text-secondaryText font-medium">
                    {t('main_account') ?? ''}
                  </p>
                </div>
                {!companyDetail ? (
                  <AiFillCheckCircle
                    className={`w-4 text-success transition duration-150 ease-in-out group-hover:text-opacity-80`}
                    aria-hidden="true"
                  />
                ) : (
                  ''
                )}
              </div>
              {useData?.companies &&
                useData?.companies.map((item: any) => (
                  <button
                    key={item.id}
                    onClick={() => handleCompanySwitch(item)}
                    className="flex items-center cursor-pointer justify-between gap-2  p-2 hover:bg-menuHover rounded-lg"
                  >
                    <ProfileImage
                      imageUrl={item?.actual_logo_Path}
                      profileName={item?.name}
                    />
                    <div className="relative flex-1 text-left rtl:text-right">
                      <p className="text-sm font-medium text-primaryText">
                        {item?.name}
                      </p>
                      <p className="text-xs text-secondaryText font-medium">
                        {item?.company_relation}
                      </p>
                    </div>
                    {companyDetail?.id === item?.id ? (
                      <AiFillCheckCircle
                        className={`w-4 text-success transition duration-150 ease-in-out group-hover:text-opacity-80`}
                        aria-hidden="true"
                      />
                    ) : (
                      ''
                    )}
                  </button>
                ))}
            </div>
          </div>
        </MenuList>
      )}
    </Menu>
  );
}
