import { Popover, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle, AiFillQuestionCircle } from "react-icons/ai";
import { TfiAngleDown } from "react-icons/tfi";

import { ProfileVerification } from "../../common/types";
import ProfileImage from "../profileimage";

type Props = {
  selectedData?: ProfileVerification;
  dropData?: ProfileVerification[];
  handleMenuClick: (item: ProfileVerification, index: number) => void;
};
export default function ChooseProfileDropdown(props: Props) {
  const { selectedData, dropData, handleMenuClick } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const handleClick = (item: ProfileVerification, index: number) => {
    setMenuOpen(false);
    handleMenuClick(item, index);
  };
  return (
    <div>
      <Popover className="relative ">
        {({ open }) => (
          <>
            {dropData && dropData?.length > 1 && (
              <div className="mb-2 flex text-xs items-center gap-1 labelText ">
                {dropData && dropData?.length > 1 ?
                t("choose_your_profile") :
                t("home_post_project_btn")} 
                {/* <AiFillQuestionCircle /> */}
              </div>
            )}
            <Popover.Button
              onClick={() => setMenuOpen(true)}
              className={`relative w-full px-3 py-5 border border-formBorder flex items-center justify-between rounded-lg gap-1.5 focus:border-[#333] outline-0`}
            >
              <div className="w-10 h-10 overflow-hidden rounded-full  flex items-center justify-center">
                <ProfileImage
                  imageUrl={
                    selectedData?.profile_image ?? selectedData?.logo_path
                  }
                  profileName={selectedData?.name}
                />
              </div>
              <div className="flex flex-col flex-1 items-start justify-start">
                <p className=" text-sm font-medium text-primaryText text-left">
                  {selectedData?.name}
                </p>
                {selectedData?.is_user && (
                  <p className=" text-xs font-medium text-textColor">
                    {t("main_account") ?? ""}
                  </p>
                )}
              </div>
              {dropData && dropData?.length > 1 && (
                <TfiAngleDown
                  className={`w-4 text-textColor transition duration-150 ease-in-out group-hover:text-opacity-80`}
                  aria-hidden="true"
                />
              )}
            </Popover.Button>
            {menuOpen && dropData && dropData?.length > 1 && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute w-full left-0 z-10 mt-3   transform ">
                  <div className="overflow-hidden bg-bgWhite shadow-footerShadow rounded-lg p-2 w-full">
                    <div className="relative flex flex-col gap-2">
                      {dropData?.map((item, ind: number) => (
                        <a
                          key={item?.name}
                          onClick={() => handleClick(item, ind)}
                          className=" flex items-center cursor-pointer rounded-lg p-2 gap-1.5 transition hover:bg-menuHover duration-150 ease-in-out  focus:outline-none "
                        >
                          <div className="w-10 h-10 overflow-hidden rounded-full bg-bgWhite border-formBorder flex items-center justify-center">
                            <ProfileImage
                              imageUrl={item?.profile_image ?? item?.logo_path}
                              profileName={item?.name}
                            />
                          </div>
                          <div className="flex flex-col flex-1 items-start justify-start">
                            <p className=" text-sm font-medium text-primaryText">
                              {item?.name}
                            </p>
                            <p className=" text-xs font-medium text-textColor">
                              {item?.is_user ? t("main_account") ?? "" : ""}
                            </p>
                          </div>
                          {item?.active ? (
                            <AiFillCheckCircle
                              className={`w-4 text-success transition duration-150 ease-in-out group-hover:text-opacity-80`}
                              aria-hidden="true"
                            />
                          ) : (
                            ""
                          )}
                        </a>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            )}
          </>
        )}
      </Popover>
    </div>
  );
}
