import { Button, IconButton } from '@mui/material';
import { TfiAngleLeft } from 'react-icons/tfi';
import { useNavigate } from 'react-router-dom';

import CompanyCardComponent from '../../components/cardComponents/companyCard';
import FreelanceCardComponent from '../../components/cardComponents/freelanceCard';
import FreelanceTalentCard from '../../components/cardComponents/freelanceTalentCard';
import AnimateLoader from '../../customComponents/animateLoader';
import { useMyworkStore } from '../../store/myworkStore';
import useBrowserWidth from '../../utilities/responsiveHook';
import { useObserveIntersection } from '../../utilities/useObjerveInterSection';
import { SEARCHTYPE } from './store';

type Props = {
  jobData?: any;
  type?: string;
  value?: string;
  handleNext: () => void;
  handleRefresh: () => void;
  total: any;
  currentSize: number;
  filterValue?: string;
};
export default function SearchCardContainer(props: Props) {
  const {
    jobData,
    type,
    value,
    handleNext,
    filterValue,
    total,
    handleRefresh,
  } = props;
  const { ref } = useObserveIntersection({ onAppear: handleNext });
  const navigate = useNavigate();
  const { setMyWorkStatus } = useMyworkStore();
  const { isBrowser, isMobile } = useBrowserWidth();

  const handleNavigate = (item: any) => {
    if (item?.has_proposal) {
      setMyWorkStatus({ workType: 'applied_projects', jobStatus: 'Published' });
      navigate(`/my-applied-projects-details/${item?.id}/details`, {
        state: { work_type: 'applied_projects', status: 'Published' },
      });
    } else {
      navigate(`/project-detail/view/${item?.id}`);
    }
  };
  return (
    <div
      className={`${
        isMobile ? 'w-full justify-center' : 'sm:w-2/4 md:w-4/4 mb-4  lg:2/4'
      } flex flex-col gap-4  `}
    >
      <div className="customCard w-full p-4">
        <div
          className={`flex justify-between items-center gap-4 ${
            isMobile ? 'mb-2' : 'mb-5'
          }`}
        >
          <div className="flex-1 flex gap-2">
            <IconButton className="p-0 w-7 h-7" onClick={() => navigate(-1)}>
              <TfiAngleLeft className="w-4  text-primaryText" />
            </IconButton>
            {filterValue && (
              <p
                className={` flex items-center text-primaryText font-bold ${
                  isMobile ? 'text-base' : 'text-xl'
                }`}
              >
                {filterValue}
                <span className="px-1 text-placeholderText">{`(${total?.totalCount})`}</span>
              </p>
            )}
            {value && (
              <div className="">
                <span className="text-xl  text-primaryText font-bold ">
                  Search results for '{value}'
                </span>
                <span className="px-1 text-xl text-textColor font-normal ">
                  {`(${total?.totalCount})`}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="jobList flex flex-col gap-3">
          {jobData?.map((items: any) => (
            <>
              {type === SEARCHTYPE?.COMPANYL && (
                <CompanyCardComponent
                  item={items}
                  onClick={() => navigate(`/company-view-mode/${items?.id}`)}
                  handleRefresh={handleRefresh}
                />
              )}

              <>
                {type === SEARCHTYPE?.TALENT && (
                  <FreelanceTalentCard
                    item={items}
                    handleRefresh={handleRefresh}
                    onClick={() => navigate(`/find-talent/${items?.id}`)}
                  />
                )}
              </>

              {type === SEARCHTYPE?.PROJECT && (
                <FreelanceCardComponent
                  handleRefresh={handleRefresh}
                  item={items}
                  onClick={() => handleNavigate(items)}
                />
              )}
            </>
          ))}
          {total.totalCount &&
            total.upto &&
            total.upto !== total.totalCount && (
              <Button
                className="secondaryButton border-0 border-bgWhite"
                ref={ref}
                onClick={() => handleNext()}
              >
                <AnimateLoader />
              </Button>
            )}
        </div>
      </div>
    </div>
  );
}
