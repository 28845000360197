import { Combobox, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';

import useBrowserWidth from '../../utilities/responsiveHook';

type Props = {
  data: {
    id?: string | number;
    name: string;
  }[];
  value: any;
  setSelected: (data: any) => void;
  keyName?: string;
  fetchData?: (val?: string) => void;
  placeholder?: string;
  dropDownKey?: string;
  label?: string;
};
type Item = { id: string; [k: string]: string };

export default function FilterSearchField(props: Props) {
  const {
    data,
    value,
    setSelected,
    fetchData,
    keyName,
    placeholder,
    dropDownKey,
    label,
  } = props;
  const [query, setQuery] = useState('');
  const { isBrowser, isMobile } = useBrowserWidth();

  useEffect(() => {
    const timerId = setTimeout(() => {
      data?.length == 0 && fetchData && fetchData(dropDownKey);
    }, 350);
    return () => clearTimeout(timerId);
  }, [query]);
  const filteredData =
    query === ''
      ? data
      : data.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  return (
    <div className="filterBlock">
      <div className="flex justify-between items-center gap-1">
        <p className="filterTitle"> {label}</p>
        {/* <a className="filterClear" href="#">
          Clear filter
        </a> */}
      </div>
      <div className="grid grid-cols-2 gap-1">
        <div className="col-span-2">
          <Combobox value={value ?? ''} onChange={setSelected}>
            <div className="relative mb-1">
              <div className="relative w-full cursor-default  bg-white text-left">
                <span className="absolute left-[8px] top-[13px]">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 13L9 9M10.3333 5.66667C10.3333 8.244 8.244 10.3333 5.66667 10.3333C3.08934 10.3333 1 8.244 1 5.66667C1 3.08934 3.08934 1 5.66667 1C8.244 1 10.3333 3.08934 10.3333 5.66667Z"
                      stroke="#313131"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <Combobox.Input
                  className="customFieldInput w-full pl-8   text-[13px] text-primaryText font-medium"
                  displayValue={(item: Item | null) =>
                    item ? item[keyName || 'name'] || '' : ''
                  }
                  placeholder={placeholder}
                  onChange={(event) => setQuery(event.target.value)}
                />
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options
                  className={`absolute bg-bgWhite z-10 mt-1 w-full overflow-auto rounded-lg bg-white py-1 shadow-filterMenu ${
                    isMobile ? 'max-h-40 bottom-[calc(100%+8px)]' : 'max-h-64'
                  } `}
                >
                  {filteredData.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none text-sm py-2 px-4 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    filteredData.map((item) => (
                      <Combobox.Option
                        key={item.id}
                        className={({ active }) =>
                          `relative cursor-pointer select-none p-2.5  `
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          // <div className="flex gap-1.5">
                          //   <div className="w-9 h-9 overflow-hidden">
                          //     <img src="/assets/rockstar.png" />
                          //   </div>
                          //   <div className="flex flex-col">
                          <p className=" text-sm text-primaryText font-medium">
                            {item.name}
                          </p>
                          //     <p className="text-xs text-textColor font-medium">
                          //       Company｜Computer games
                          //     </p>
                          //   </div>
                          // </div>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </div>
    </div>
  );
}
