import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { getUniversalSearchData } from '../../apis/commonapis/apis';
import { getDropDownData } from '../../apis/dropdowns/dropDown.api';
import FilterHead from '../../components/filterComponents/filterHeader';
import LoaderFile from '../../components/loader/loader';
import SearchComponent from '../../customComponents/elasticSearchComponent';
import {
  useFilter,
  useFilterDetailStore,
  useFilterType,
} from '../../store/filterStore';
import { useLoaderStore } from '../../store/loaderStore';
import useBrowserWidth from '../../utilities/responsiveHook';
import SearchCardContainer from './cardContainer';
import EmptySearch from './emptySearch';

const filterPicklist = ['industry', 'work-location'];

export default function UniversalSearch(props: any) {
  const { filterValues, setFilterValues } = useFilterDetailStore();
  const { openFilter, setOpenFilter } = useFilter();
  const { isLoading, setIsLoading } = useLoaderStore();
  const [searchedData, setSearchedData] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();
  const location = useLocation();
  const { state } = location;
  const { filterType } = useFilterType();
  const params = useParams();
  const [formSearch, setFormSearch] = useState<any>({
    index: filterType?.type,
    search: state?.search,
    filter: state?.filter,
    size: 15,
    page: 1,
  });
  const { isBrowser, isMobile } = useBrowserWidth();

  const { t } = useTranslation();
  const handleuniversalSearch = async () => {
    let searchData = searchedData ?? [];
    setIsLoading(true);

    getUniversalSearchData({ ...handleFilterby(), ...formSearch })
      .then((res) => {
        if (formSearch?.page !== 1) {
          searchData.push(...res.data[filterType?.type as string]?.data);
        } else {
          searchData = res.data[filterType?.type as string]?.data;
        }
        setSearchedData(searchData);
        const total = res.data && res.data[filterType?.type ?? 'work']?.total;
        const upto = res.data && res.data[filterType?.type ?? 'work']?.to;

        setTotalCount({ totalCount: total, upto: upto });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleNext = () => {
    setFormSearch({ ...formSearch, page: formSearch.page + 1 });
  };
  const handleFilterData = (key?: string, searchKey?: string) => {
    const filterDropDowns = filterValues;
    getDropDownData(key as string, '', searchKey).then((res) => {
      filterDropDowns[filterType.type]?.forEach((item) => {
        if (item.name === key) {
          item['data'] = res?.data?.data;
        }
      });
    });
    setFilterValues({ ...filterDropDowns });
  };

  useEffect(() => {
    handleuniversalSearch();
  }, [formSearch]);

  const handleFilterby = () => {
    let result = {};
    const data = filterValues[filterType.type];
    data?.forEach((item) => {
      if (typeof item.value === 'string') {
        result = { ...result, [item.keyName]: item.value };
      } else if (item.type === 'dateRange') {
        result = {
          ...result,
          [item.keyName]: item.value
            ? item.value[0] &&
              item.value[1] &&
              `${moment(item.value[0]).format('YYYY-MM-DD')} - ${moment(
                item.value[1]
              ).format('YYYY-MM-DD')}`
            : '',
        };
      } else {
        result = { ...result, [item.keyName]: item.value?.id };
      }
    });
    return result;
  };
  const handleUpdateFilterSearch = () => {
    setFormSearch({
      ...formSearch,
      page: 1,
      ...handleFilterby(),
    });
  };
  const handleFilterValues = (data: any) => {
    setFilterValues({ ...filterValues, [filterType.type]: data });
    handleUpdateFilterSearch();
  };
  const handleClearFilter = (data: any) => {
    setFilterValues(data);

    setTimeout(() => {
      setFormSearch({
        index: filterType?.type,
        search: state?.search,
        filter: state?.filter,
        size: 15,
        page: 1,
      });
    }, 1000);
  };
  useEffect(() => {
    setFormSearch({
      ...handleFilterby(),
      ...formSearch,
      search: state?.search,
      filter: state?.filter,
      index: filterType?.type,
      page: 1,
    });
  }, [state, filterType?.type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contents relative">
      {isMobile && (
        <div className="fixed top-0 w-full bg-bgWhite flex items-center justify-center h-14 z-50">
          <SearchComponent />
        </div>
      )}
      {filterValues[filterType.type]?.length > 0 && (
        <FilterHead
          key={filterType.type + 'universal'}
          filterValues={filterValues[filterType.type ?? 'work']}
          fetchData={handleFilterData}
          filterTypeRef={filterType}
          handleClearFilter={handleClearFilter}
          setFilterValues={handleFilterValues}
        />
      )}

      <div
        className={`
        items-start relative  justify-center w-full gap-4 sm:block md:flex  lg:flex ${
          isMobile && 'pb-14'
        }`}
      >
        {isLoading && formSearch?.page == 1 ? (
          <LoaderFile className="w-full md:w-2/4 lg:w-2/4" />
        ) : (
          <>
            {searchedData && searchedData?.length > 0 ? (
              <SearchCardContainer
                jobData={searchedData}
                type={filterType?.type}
                total={totalCount}
                currentSize={formSearch.size}
                handleNext={handleNext}
                value={state?.search}
                filterValue={state?.filterName}
                handleRefresh={handleuniversalSearch}
              />
            ) : (
              <EmptySearch searchKey={state?.search} />
            )}
          </>
        )}
        {/* {isLoading ? (
          <div className="customCard p-5 sm:w-1/4 md:w-4/4 mb-4 lg:1/4 mt-4  rounded  animate-pulse  md:p-6 dark:border-slatedark-700">
            <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
            <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
            <div className="h-2.5 bg-slatedark rounded-full dark:bg-white w-3/4 mb-2.5"></div>
          </div>
        ) : (
          <div className=" customCard p-4 sm:w-1/4 md:w-4/4 mb-4 lg:1/4">
            <p className="text-xl text-primaryText mb-4">
              See 1 jobs in featured Companies
            </p>
            <p className="text-primary text-sm">{t("know_more_link") ?? ""}</p>
          </div>
        )} */}
      </div>
    </div>
  );
}
