import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  jobFilterData,
  talentFilterData,
  companyFilterData,
} from "./filterDataStore";

type FilterData = {
  id: string;
  type: string;
  data: any[];
  name: string;
  value: any;
  keyName: string;
  isBasic?: boolean;
  placeholder: string;
};
type filterCategory = {
  work: FilterData[] | [];
  user: FilterData[] | [];
  company: FilterData[] | [];
};
type FilterTypeInt = {
  name: string;
  type: "work" | "user" | "company";
  nameKey: string;
};
type FilterDetails = {
  filterValues: filterCategory;
  setFilterValues: (data: filterCategory) => void;
  clearFilters: () => void;
};

export const useFilterDetailStore = create<FilterDetails>()(
  persist(
    (set) => ({
      filterValues: { work: [], user: [], company: [] },
      setFilterValues: (data) => set(() => ({ filterValues: data })),
      clearFilters: () =>
        set(() => ({
          filterValues: {
            work: jobFilterData(),
            user: talentFilterData(),
            company: companyFilterData(),
          },
        })),
    }),
    { name: "universal_filter_values" }
  )
);
type filterType = {
  filterType: FilterTypeInt;
  setFilterType: (data: FilterTypeInt) => void;
  clearFilterType: () => void;
};

export const useFilterType = create<filterType>()(
  persist(
    (set) => ({
      filterType: {
        name: "Projects",
        type: "work",
        nameKey: "projects_option",
      },
      setFilterType: (data) => set(() => ({ filterType: data })),
      clearFilterType: () =>
        set(() => ({
          filterType: {
            name: "Project",
            type: "work",
            nameKey: "projects_option",
          },
        })),
    }),
    { name: "universal_filter_type" }
  )
);
type Filter = {
  openFilter: string;
  setOpenFilter: (filter: string) => void;
};
export const useFilter = create<Filter>()((set) => ({
  openFilter: "none",
  setOpenFilter: (filter) => set(() => ({ openFilter: filter })),
}));
