import { useTranslation } from "react-i18next";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import CustomTooltip from "../../customComponents/tooltip/index";

type Props = {
  label?: string;
  value?: string | number | undefined;
  onChange?: (e: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  search?: boolean;
  name?: string;
  type?: string;
  register?: any;
  placeholder?: string;
  disabled?: boolean;
  textarea?: boolean;
  className?: string;
  error?: any;
  direction?: string;
  tooltip?: {
    isMobile?: boolean;
    heading?: string;
    text?: string;
  };
};
export default function CustomInput(props: Props) {
  const {
    label,
    value,
    onChange,
    name,
    register,
    required,
    fullWidth,
    disabled,
    textarea,
    className,
    direction,
    error,
    placeholder,
    type,
    tooltip,
  } = props;
  const { t } = useTranslation();

  return (
    <div>
      {label && (
        <label
          className={`block justify-start labelText ${
            direction == "rtl" && "text-right flex flex-row-reverse gap-0.5"
          }`}
        >
          {tooltip ? (
            <span className="text-xs flex items-center gap-x-1">
              {label} {required && <span className="text-required"> * </span>}
              <CustomTooltip
                placement={tooltip.isMobile ? "top" : "right"}
                heading={tooltip.heading}
                text={tooltip.text}
                icon
              >
                <span className="text-primaryText">
                  {" "}
                  <AiOutlineQuestionCircle />{" "}
                </span>
              </CustomTooltip>
            </span>
          ) : (
            <>
              {label} {required && <span className="text-required"> * </span>}
            </>
          )}
        </label>
      )}

      <div className="w-full relative">
        <input
          {...props}
          id={"user_name"}
          type={type ?? "text"}
          required={true}
          className={`customFieldInput w-full ${className}`}
          placeholder={placeholder}
          disabled={disabled}
          dir={direction ?? ""}
          {...register}
        />
        {error && <p className="m-0  text-bgError text-xs">{t(error) ?? ""}</p>}
      </div>
    </div>
  );
}
