import { ProfileSchema } from "../../../common/userSchema";
import { userProfileTypes } from "../store";

export const handleUserData = (type: string, formData: ProfileSchema) => {
  let data = {};

  switch (type) {
    case userProfileTypes.BASIC_INFO:
      data = {
        name: formData.name,
        user_name: formData.user_name,
        current_designation: formData.current_designation,
        profile_headline: formData.profile_headline,
        identity_document_type_id: formData.identity_document_type,
        identity_document_number: formData.identity_document_number,
      };
      if (
        formData.identity_documents &&
        formData.identity_documents?.length > 0
      ) {
        formData.identity_documents.forEach((item: any, index: number) => {
          data = { ...data, [`identity_documents[${index}]`]: item };
        });
        if (
          formData.identity_document_type ||
          formData.identity_document_number
        ) {
          let identity_file_details = {
            identity_document_type_id: formData.identity_document_type ?? null,
            identity_document_number: formData.identity_document_number ?? null,
          };
          formData.identity_documents.forEach((item: any, index: number) => {
            data = {
              ...data,
              [`identity_file_details[${index}]`]: JSON.stringify(
                identity_file_details
              ),
            };
          });
        }
      } else {
        if (
          formData.identity_document_type ||
          formData.identity_document_number
        ) {
          let identity_file_details = {
            identity_document_type_id: formData.identity_document_type ?? null,
            identity_document_number: formData.identity_document_number ?? null,
            id: formData?.identity_file_details[0]?.id,
          };
          data = {
            ...data,
            [`identity_file_details[${0}]`]: JSON.stringify(
              identity_file_details
            ),
          };
        }
      }
      break;
    case userProfileTypes.PERSONAL_INFO:
      data = {
        email: formData.email,
        phone: formData.phone,
        gender_id: formData.gender_id,
        date_of_birth: formData.date_of_birth,
        age: formData.age,
        city: formData.city,
        // nationality_id: formData.nationality_id,
        nationality: formData.nationality,
        state_id: formData.state_id,
        is_handicapped: formData.is_handicapped,
        current_location: formData.current_location,
        place_id: formData.place_id,
      };

      break;

    case userProfileTypes.PROFESSIONAL_INFO:
      data = {
        year_of_experience: formData.year_of_experience ?? "",
        work_authorisation_status_id: formData.work_authorisation_status_id,
        employment_status_id: formData.employment_status_id,
        notice_period: formData.notice_period,
        current_salary: formData.current_salary,
      };

      break;
    case userProfileTypes.USER_DESC:
      data = {
        description: formData.description,
      };
      break;

    case userProfileTypes.SKILL_PREF:
      if (formData.skill_sets && formData.skill_sets?.length > 0) {
        formData.skill_sets.forEach((item, index) => {
          data = { ...data, [`skill_sets[${index}]`]: item.id };
        });
      } else {
        data = {
          [`skill_sets`]:
            formData.skill_sets && formData.skill_sets?.length > 0
              ? undefined
              : "null",
        };
      }
      break;
    case userProfileTypes.WORK_LOC:
      if (formData.work_locations && formData.work_locations?.length > 0) {
        formData.work_locations.forEach((item, index) => {
          data = { ...data, [`work_locations[${index}]`]: item.id };
        });
      } else {
        data = {
          [`work_locations`]:
            formData.work_locations && formData.work_locations?.length > 0
              ? undefined
              : "null",
        };
      }

      break;
    case userProfileTypes.WORK_SECTOR:
      if (formData.work_sectors && formData.work_sectors?.length > 0) {
        formData.work_sectors.forEach((item, index) => {
          data = { ...data, [`work_sectors[${index}]`]: item.id };
        });
      } else {
        data = {
          [`work_sectors`]:
            formData.work_sectors && formData.work_sectors?.length > 0
              ? undefined
              : "null",
        };
      }

      break;
    case userProfileTypes.LANGUAGE:
      if (formData.languages && formData.languages?.length > 0) {
        formData.languages.forEach((item, index) => {
          data = { ...data, [`languages[${index}]`]: item.id };
        });
      } else {
        data = {
          [`languages`]:
            formData.languages && formData.languages?.length > 0
              ? undefined
              : "null",
        };
      }

      break;
    case userProfileTypes.PORTFOLIO:
      if (formData.portfolios && formData.portfolios?.length > 0) {
        formData.portfolios.forEach((item, index) => {
          data = { ...data, [`portfolios[${index}]`]: item };
        });
      } else {
        data = { ...data, [`portfolios[0]`]: null };
      }
      break;

    case userProfileTypes.RESUME:
      if (formData.attachments && formData.attachments?.length > 0) {
        formData.attachments.forEach((item, index) => {
          data = { ...data, [`attachments[${index}]`]: item };
        });
      } else {
        data = { ...data, [`attachments[0]`]: null };
      }
      break;
    case userProfileTypes.USER_TAX_INO:
      data = {
        pan_number: formData.pan_number,
        gst_number: formData.gst_number,
        trn_number: formData.trn_number,
      };
      break;
    case userProfileTypes.RECOMMENDATION_TAGS:
      // -        if (formData.recommendationTags &&  formData.recommendationTags?.length > 0) {
      if (
        formData.recommendationTags &&
        formData.recommendationTags?.length > 0
      ) {
        formData.recommendationTags.forEach((item, index) => {
          data = { ...data, [`recommendationTags[${index}]`]: item.id };
        });
      } else {
        data = {
          [`recommendationTags`]:
            formData.recommendationTags &&
            formData.recommendationTags?.length > 0
              ? undefined
              : "null",
        };
      }
      break;
    case userProfileTypes.SOCIAL_MEDIA_PRESENCE:
      data = {
        social_media_details: formData.social_media_details,
      };

      break;
  }
  return data;
};
