import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDropDownData } from '../../apis/dropdowns/dropDown.api';
import FilterSearchField from '../../customComponents/AutocompleteField/filerSearchField';
import FilterAutoComplete from '../../customComponents/AutocompleteField/filterAutoComplete';
import DateRangePickerComponent from '../../customComponents/datepicker/dateRangePIcker';
import FilterSelectBox from '../../customComponents/selectfield/filterSelectBox';
import useBrowserWidth from '../../utilities/responsiveHook';

type Props = {
  type: string;
  placeholder?: string;
  fetchData: (val?: string, search?: string) => void;
  data: any;
  value: any;
  name?: string;
  keyIndex: number;
  label?: string;
  handleValues: (value: any, index: number) => void;
};
export default function SwitchFilterComponent(props: Props) {
  const {
    type,
    placeholder,
    keyIndex,
    fetchData,
    handleValues,
    data,
    value,
    label,
    name,
  } = props;
  const { isMobile } = useBrowserWidth();

  const { t } = useTranslation();
  const onChange = (value: any) => {
    handleValues(value, keyIndex);
  };
  const [results, setResults] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFilterData = async (key?: string, searchKey?: string) => {
    setIsLoading(true);
    getDropDownData(key as string, '', searchKey).then((res) => {
      setResults(res?.data?.data);
      setIsLoading(false);
    });
  };
  switch (type) {
    case 'selectBox':
      return (
        <FilterAutoComplete
          data={results}
          setSelected={onChange}
          label={t(label ?? '') ?? ''}
          value={value ?? null}
          isLoading={isLoading}
          placeholder={t(placeholder ?? '') ?? ''}
          dropDownKey={name}
          fetchData={handleFilterData}
        />
      );
    case 'detailSelectBox':
      return (
        <FilterSearchField
          data={data}
          setSelected={onChange}
          label={t(label ?? '') ?? ''}
          value={value ?? null}
          placeholder={t(placeholder ?? '') ?? ''}
          dropDownKey={name}
          fetchData={fetchData}
        />
      );
    case 'selectBoxDynamic':
      return (
        <FilterSelectBox
          onChange={onChange}
          getData={handleFilterData}
          dropDownKey={name}
          data={results}
          placeholder={t(placeholder ?? '') ?? ''}
          value={value ?? null}
          isLoading={isLoading}
        />
      );
    case 'dateRange':
      return (
        <DateRangePickerComponent
          onChange={onChange}
          placeholder={t(placeholder ?? '') ?? ''}
          value={value ?? null}
          className={isMobile ? 'h-10' : 'h-[30px] '}
        />
      );
    default:
      return null;
  }
}
