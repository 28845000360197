import { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { AiOutlineSearch } from "react-icons/ai";

import { getDropDownData } from "../../apis/dropdowns/dropDown.api";
import AutocompleteField from "../../customComponents/AutocompleteField";
import CustomAutoCompleteField from "../../customComponents/AutocompleteField/CustomAutoComplete";
import { UserContext } from "../detailComponent/dataContext";

type Props = {
  title: string;
  methods: any;
  dropKey: string;
  registerName: string;
  fromDialog?: boolean;
  placeholder?:string;
  required?:boolean;
};

export default function SkillPreference(props: Props) {
  const { methods, title, registerName, dropKey, placeholder, required } = props;
  const dropKeys = [dropKey];
  const [datas, setDatas] = useState<any>([]);

  const {
    control,
    formState: { errors },
    getValues
  } = methods;

  const { dropData, setDropData } = useContext(UserContext);

  const handleDropData = async (searchKey:string) => {
    let drop = dropData;

    if(searchKey !== undefined){
      const { data, error } = await getDropDownData(dropKey,"",searchKey);
      
    drop = { [dropKey]: data?.data, ...drop };
    if (data) {
      setDatas(data?.data);
    }
    }
  };
  useEffect(() => {
      handleDropData("");
  }, []);

   let dataList = datas &&
   getValues(registerName) &&
   datas.filter(
     ({ id }: any) => !getValues(registerName).some((e: any) => e.id == id)
   );

  return (
    <div className="flex flex-col gap-5">
      {!props.fromDialog && (
        <p className="text-base font-bold text-primaryText">{title}</p>
      )}
      <div className="grid grid-cols-1 gap-4">
        <div className="col-span-1">
          <Controller
            control={control}
            name={registerName}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomAutoCompleteField
                value={value ?? []}
                getData={handleDropData}
                handleChange={onChange}
                startIcon={<AiOutlineSearch />}
                type="tagSelect"
                placeholder={`Select ${placeholder}`}
                required={required}
                error={errors.registerName?.message}
                data={
                  datas &&
                  (dataList ?? datas)?.map((data: any) => {
                    return {
                      name: data?.name,
                      id: data?.id,
                    };
                  })
                }
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
