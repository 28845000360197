import Autocomplete from "react-google-autocomplete";

type Props = {
  value: any;
  setValue: (data: any) => void;
  label?: string;
  required?: boolean;
  placeholer?: string;
};
export default function LocationAutoComplete(props: Props) {
  const { value, setValue, required, label, placeholer } = props;
  return (
    <div>
      {label && (
        <label className="block justify-start labelText">
          {required ? (
            <>
              {label}
              <span className="text-required"> * </span>
            </>
          ) : (
            label
          )}
        </label>
      )}
      <Autocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        style={{ width: "100%", zIndex: 999 }}
        className="customFieldInput"
        onPlaceSelected={(place) => {
          setValue(place);
        }}
        options={{
          types: ["(regions)"],
        }}
        defaultValue={value ?? ""}
        placeholder={placeholer}
      />
    </div>
  );
}
