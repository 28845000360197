import { AiOutlinePlus } from "react-icons/ai";

import config from "../config";

type Props = {
  primaryText: string;
  secondaryText: string;
  fullwidth?: boolean;
  type?: string;
  buttonTitle?: string;
  buttonClick?: () => void;
  buttonType?: string;
};
export default function EmptyComponent(props: Props) {
  const {
    primaryText,
    secondaryText,
    fullwidth,
    type,
    buttonTitle,
    buttonType,
    buttonClick,
  } = props;
  return (
    <div
      className={`${
        fullwidth ? "w-full" : "sm:w-3/4 md:w-3/4   lg:2/4"
      } rounded-md bg-bgWhite p-14 flex flex-col items-center justify-center h-auto gap-4  `}
    >
      <div className="w-32 h-32">
        {type === "permission" ? (
          <img src={config.NO_PERMISSION} />
        ) : (
          <img src={config.EMPTY_IMAGE} />
        )}
      </div>
      <div className="flex flex-col gap-2 items-center justify-center">
        <p className="text-md font-bold">{primaryText}</p>
        <p className="text-sm text-center mx-11 ">{secondaryText} </p>
        {buttonTitle && (
          <button
            className={`${
              buttonType === "warning" && "bg-warning"
            } bg-primary shadow-md rounded-md p-2 text-bgWhite text-sm font-medium flex gap-1 items-center justify-center`}
            onClick={() => buttonClick && buttonClick()}
          >
            {buttonType == "CreateNew" ? (
              <>
                <AiOutlinePlus /> {buttonTitle}
              </>
            ) : (
              <>{buttonTitle}</>
            )}
          </button>
        )}
      </div>
    </div>
  );
}
