import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getUserProfileVerification } from "../../apis/commonapis/apis";
import { ProfileVerification } from "../../common/types";
import CustomButton from "../../customComponents/button";
import CustomDialogBox from "../../customComponents/dialogbox/index";
import { useUserManagement } from "../../store/authStore";
import { useCompanySwitchStore } from "../../store/companySwitch";
import { profileStore } from "../../store/profileStore";
import { useSendProposal } from "../../store/signAction";
import { CheckIcon, CrossIcon } from "./chooseIcons";
import ChooseProfileDropdown from "./chooseProfileDropdown";
import { handleProfileValidation } from "./profilecompletionValidation";

export default function ChooseProfile() {
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState<ProfileVerification>();
  const [count, setCount] = useState(1);
  const {
    openProfileVerification,
    setOpenProfileVerification,
    setOpenEmailVerifyModel,
  } = useUserManagement();
  const { openProposal, setSendProposal } = useSendProposal();
  const { setBankInfoModel } = profileStore();

  const handleClose = () => setOpenProfileVerification({ open: false });
  const { t } = useTranslation();
  const { companyDetail, setCompanyDetail } = useCompanySwitchStore();
  const [dropData, setDropData] = useState<ProfileVerification[]>();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);

  const handleProfileVerificationData = () => {
    setLoading(true);
    getUserProfileVerification()
      .then((res) => {
        setDropData(res?.data?.users);
        setUserData(res?.data?.users[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (openProfileVerification?.open) handleProfileVerificationData();
  }, [openProfileVerification?.open]);
  const handleMenuClick = (item: ProfileVerification, index: number) => {
    const data = dropData;
    data?.forEach((item, ind) => {
      if (ind === index) {
        item["active"] = true;
      } else {
        item["active"] = false;
      }
    });
    if (item.is_user) {
      setCompanyDetail(null);
    } else {
      setCompanyDetail(item);
    }
    setDropData(data);
    setSelectedData(item);
  };
  const handleContinue = () => {
    let actionName = openProfileVerification?.actionName;
    setOpenProfileVerification({ open: false });

    setCount(count + 1);
    switch (actionName) {
      case "postProject":
        return handleValidation()
          ? navigate("/post-project", { state: { isValid: true } })
          : null;
      case "postjob":
        return handleValidation()
          ? navigate("/post-job", { state: { isValid: true } })
          : null;
      case "sendProposal":
        return handleValidation() ? setSendProposal(true) : null;
      default:
        return null;
    }
  };
  const handleValidation = () => {
    return handleProfileValidation(
      selectedData as ProfileVerification,
      openProfileVerification?.actionName,
      openProfileVerification.is_Expert
    );
  };
  const handleSelectedData = () => {
    if (dropData && dropData?.length > 0) {
      let isUser = true;
      dropData?.forEach((item) => {
        if (companyDetail && companyDetail?.id == item?.id) {
          setSelectedData(item);
          isUser = false;
        }
      });
      if (isUser) {
        const userData = dropData.find((item) => item?.is_user);
        setSelectedData(userData ?? dropData[0]);
      }
    }
  };
  useEffect(() => {
    if (dropData && dropData?.length > 0) {
      handleSelectedData();
    }
  }, [dropData]);
  const handleEmailValidation = () => {
    setOpenEmailVerifyModel(true);
    handleClose();
  };
  const handleManageProfile = (from: string) => {
    handleClose();
    if (from == "updateBankDetails") {
      setBankInfoModel(true);
    }
    if (selectedData?.is_user) {
      navigate(`/profile/view`);
    } else {
      navigate(`/company-profile-view/${selectedData?.id}`);
    }
  };
  const handleNavigation = () => {
    handleClose();
    navigate(`/subscription`);
  };
  const handleNavigationToAddon = () => {
    handleClose();
    navigate(`/subscription/addon`);
  };
  return (
    <>
      <CustomDialogBox
        open={openProfileVerification.open}
        title={
          openProfileVerification?.actionName == "sendProposal"
            ? t("send_proposal_button") ?? ""
            : dropData && dropData?.length > 1 ?
            t("choose_your_profile") ?? ""
            : t("home_post_project_btn") ?? ""

        }
        subtext={
          openProfileVerification?.actionName == "sendProposal"
            ? ""
            : dropData && dropData?.length > 1
            ? openProfileVerification.actionName === "postProject"
              ? t("choose_comp_person") ?? ""
              : t("cannot_post_project_message") ?? ""
            : ""
        }
        // subtext={
        //   dropData && dropData?.length > 1
        //     ? openProfileVerification.actionName === "postProject"
        //       ? t("choose_comp_person") ?? ""
        //       : t("cannot_post_project_message") ?? ""
        //     : ""
        // }
        maxWidth={"xs"}
        handleClose={() => handleClose()}
        className={`${loading && "opacity-90"} chooseProfileModal`}
        dialogActions={
          <div className="flex items-center justify-between gap-3 w-full">
            <CustomButton
              title={t("cancel_button") ?? ""}
              // title="Cancel"
              className="flex-1 secondaryButton"
              onClick={() => setOpenProfileVerification({ open: false })}
            />
            <CustomButton
              title={t("continue_button") ?? ""}
              // title="Continue"
              disabled={(selectedData && !handleValidation()) || loading}
              className="flex-1 primaryButton"
              onClick={() => handleContinue()}
            />
          </div>
        }
      >
        <div className="flex flex-col gap-5">
          <ChooseProfileDropdown
            selectedData={selectedData}
            dropData={
              openProfileVerification?.actionName == "sendProposal"
                ? userData
                : dropData
            }
            handleMenuClick={handleMenuClick}
          />

          <>
            <div className="flex items-center gap-3  justify-between rounded-lg">
              <div className="flex flex-col">
                <p className="text-sm font-medium">
                  {t("profile_completion") ?? ""}
                  {` ${selectedData?.profile_completion_percentage}%`}
                </p>
                {!selectedData?.is_profile_completed && (
                  <p className="text-xs font-medium">
                    {/* Update details from{" "} */}
                    <a
                      className="cursor-pointer text-primary"
                      onClick={() => handleManageProfile("updateProfile")}
                    >
                      {/* ‘Manage Profile’ */}
                      {t("update_profile_link") ?? ""}
                    </a>
                  </p>
                )}
              </div>
              <div>
                {selectedData?.is_profile_completed ? (
                  <CheckIcon />
                ) : (
                  <CrossIcon />
                )}
              </div>
            </div>
            {/* commented for phase-1 */}
            {/* <div className="flex items-center gap-2.5  justify-between rounded-lg">
              <div className="flex flex-col">
                <p className="text-sm font-medium">
                  {t("mandatory_fields") ?? ""}
                </p>
                {!selectedData?.is_mandatory_fields && (
                  <p className="text-xs font-medium">
                    <a
                      className="cursor-pointer text-primary"
                      onClick={() => handleManageProfile()}
                    >
                      {t("update_profile_link") ?? ""}
                    </a>
                  </p>
                )}
              </div>
              <div>
                {selectedData?.is_mandatory_fields ? (
                  <CheckIcon />
                ) : (
                  <CrossIcon />
                )}
              </div>
            </div> */}
            <div className="flex items-center gap-2.5  justify-between rounded-lg">
              <div className="flex flex-col">
                <p className="text-sm font-medium">
                  {t("email_verification") ?? ""}
                </p>
                {!selectedData?.is_email_verified && (
                  <p className="text-xs flex font-medium">
                    {/* Verify your email from{" "} */}
                    <a
                      className="cursor-pointer text-primary"
                      onClick={() => handleEmailValidation()}
                    >
                      {t("email_verification_description") ?? ""}
                    </a>
                  </p>
                )}
              </div>
              <div>
                {selectedData?.is_email_verified ? (
                  <CheckIcon />
                ) : (
                  <CrossIcon />
                )}
              </div>
            </div>
            {openProfileVerification.actionName === "postProject" && (
              <div className="flex items-center gap-2.5  justify-between rounded-lg">
                <div className="flex flex-col">
                  <p className="text-sm font-medium">
                    {t("active_addon_plans") ?? ""}
                  </p>
                  {!selectedData?.is_valid_plan && (
                    <p
                      onClick={() => handleNavigation()}
                      className="text-xs font-medium cursor-pointer text-primary"
                    >
                      {t("active_addon_plans_des") ?? ""}
                    </p>
                  )}
                </div>
                <div>
                  {selectedData?.is_valid_plan ? <CheckIcon /> : <CrossIcon />}
                </div>
              </div>
            )}
            {openProfileVerification.actionName === "sendProposal" && (
              <div className="flex items-center gap-2.5  justify-between rounded-lg">
                <div className="flex flex-col">
                  <p className="text-sm font-medium">
                    {t("active_addon_plans") ?? ""}
                  </p>
                  {!selectedData?.has_remaining_apply_project_count && (
                    <p
                      onClick={() => handleNavigation()}
                      className="text-xs font-medium cursor-pointer text-primary"
                    >
                      {t("active_addon_plans_des") ?? ""}
                    </p>
                  )}
                </div>
                <div>
                  {selectedData?.has_remaining_apply_project_count ? (
                    <CheckIcon />
                  ) : (
                    <CrossIcon />
                  )}
                </div>
              </div>
            )}
            {openProfileVerification.is_Expert &&
              openProfileVerification.actionName === "sendProposal" && (
                <div className="flex items-center gap-2.5  justify-between rounded-lg">
                  <div className="flex flex-col">
                    <p className="text-sm font-medium">
                      {t("eligible_to_apply") ?? ""}
                    </p>
                    {!selectedData?.is_project_expert &&
                      (selectedData?.is_skilled || selectedData?.isSkilled) && (
                        <p
                          onClick={() => handleNavigationToAddon()}
                          className="text-xs font-medium cursor-pointer text-primary"
                        >
                          {t("buy_addon_header") ?? ""}
                        </p>
                      )}
                  </div>
                  <div>
                    {selectedData?.is_project_expert ? (
                      <CheckIcon />
                    ) : (
                      <CrossIcon />
                    )}
                  </div>
                </div>
              )}
            {openProfileVerification.actionName !== "postProject" && (
              <div className="flex items-center gap-2.5  justify-between rounded-lg">
                <div className="flex flex-col">
                  <p className="text-sm font-medium">
                    {t("deal_breakers_criteria") ?? ""}
                  </p>
                  {!selectedData?.is_deal_breaker && (
                    <p className="text-xs font-medium">
                      Minimum profile criteria should be met for applying
                    </p>
                  )}
                </div>
                <div>
                  {selectedData?.is_deal_breaker ? (
                    <CheckIcon />
                  ) : (
                    <CrossIcon />
                  )}
                </div>
              </div>
            )}
            <div className="flex items-center gap-2.5  justify-between rounded-lg">
              <div className="flex flex-col">
                <p className="text-sm font-medium">
                  {t("profile_bank_details") ?? ""}
                </p>
                {!selectedData?.is_bank_details && (
                  <p className="text-xs font-medium">
                    {/* Update details from{" "} */}
                    <a
                      className="cursor-pointer text-primary"
                      onClick={() => handleManageProfile("updateBankDetails")}
                    >
                      {t("profile_bank_details_des") ?? ""}
                    </a>
                  </p>
                )}
              </div>
              <div>
                {selectedData?.is_bank_details ? <CheckIcon /> : <CrossIcon />}
              </div>
            </div>
          </>
        </div>
      </CustomDialogBox>
    </>
  );
}
