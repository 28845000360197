import { getData, postData, deleteData } from "../../apis/api.helpers";
import apiUrl from "./apiUrl";

export const createCompany = (data: any) =>
  postData(apiUrl.COMPANY_URL, data).then((res) => res);
export const updateCompany = (data: any) =>
  postData(apiUrl.COMPANY_UPDATE_URL, data).then((res) => res);
export const getCompanyData = (id: string) =>
  getData(`${apiUrl.COMPANY_URL}/${id}`).then((res) => res);
export const deleteBankDetails = (id: string) =>
  deleteData(`${apiUrl.COMPANY_BANK_DETAILS_DELETE}/${id}`).then((res) => res);
export const documentDelete = (id: string) =>
  deleteData(`${apiUrl.REGISTRATION_DOCUMENT_DELETE}/${id}`).then((res) => res);
export const logoDelete = (id?: string) =>
  deleteData(`${apiUrl.LOGO_DELETE}/${id}`).then((res) => res);
