import MainRoutes from './routes/mainRoutes';
import { Suspense, useEffect } from 'react';

import './App.css';
import './styles/styles.scss';

interface Props {
  comp: JSX.Element;
}
function App() {
  const lang: string = localStorage.getItem('i18nextLng') ?? 'en';
  // const lang: string = (localStorage.getItem("i18nextLng") ?? "en").substring(2);

  const trimLang: string = (
    localStorage.getItem('i18nextLng') ?? 'en'
  ).substring(0, 2);

  var time: any = '';

  // const resetTimer = (date: Date) => {
  //
  //   time = date;
  //   return false;
  // };
  // window.onload = handleMouseMove;
  // document.onmousemove = handleMouseMove;
  // document.onkeydown = handleMouseMove;
  // function handleMouseMove() {
  //   resetTimer(new Date());
  // }
  useEffect(() => {}, []);
  return (
    <div
      className="App"
      dir={trimLang === 'Ar' || trimLang === 'ar' ? 'rtl' : ''}
    >
      <Suspense fallback={<h3> Loading</h3>}>
        <MainRoutes />
      </Suspense>
    </div>
  );
}

export default App;
