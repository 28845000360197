export const validation = (
  required?: { key: string; title: string }[],
  data?: any
) => {
  const errors: any = {};
  required?.forEach((req) => {
    if (!data[req.key]) {
      errors[req.key] = `${req.title} is required`;
    }
  });
  return errors;
};

export const dataParse = (data: any) => {
  let result = "";
  data &&
    Object.keys(data)?.forEach((item: any) => {
      if (data[item])
        result = `${result}${result ? "&" : "?"}${item}=${data[item]}`;
    });
  return result;
};
