export const parseKeysFromNotificationMessage = (template:any, messageData:any) => {
  var r = template?.match(/\%[\w]+\%/g);

  r &&
    r.forEach((state:any) => {
      var regex = new RegExp(state, "g");
      var stateItem = state.split(/%|%/g)[1];

      // const linkHTML = `<a href='#'>${messageData[stateItem]["value"]}</a>`;
      if (messageData.hasOwnProperty(stateItem)) {
        template = template.replace(regex,  messageData[stateItem]?.name ?? messageData[stateItem]);
      } else {
        template = template.replace(regex, stateItem);
      }
      // template = template.replace(regex, linkHTML);
    });

  return template ?? "";
};