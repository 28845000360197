import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Layout from "../../layout/userLayout";
import { useAuthStore } from "../../store/authStore";

type Props = {
  children: React.ReactNode;
};

const UserRoute = ({ children }: Props) => {
  const authenticated = useAuthStore((state) => state.authenticated);

  return authenticated ? (
    <Layout> {children}</Layout> || <Outlet />
  ) : (
    <Navigate to="/home" replace={true} />
  );
};

export default UserRoute;
