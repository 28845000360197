import useBrowserWidth from '../utilities/responsiveHook';

type Props = {
  imageUrl?: string;
  profileName?: string;
};
export default function ProfileImage(props: Props) {
  const { imageUrl, profileName } = props;
  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <>
      {imageUrl && imageUrl !== 'null' && imageUrl !== 'undefined' ? (
        <img
          src={imageUrl}
          className={`!border !border-[#D6D8DC] flex rounded-full p-0 items-center justify-center text-lg font-semibold  bg-activeStateBg ${
            isMobile ? 'w-7 h-7' : 'w-10 h-10'
          }`}
        />
      ) : profileName ? (
        <span
          className={`!border !border-[#D6D8DC] flex rounded-full p-0 items-center justify-center text-lg font-semibold  bg-activeStateBg ${
            isMobile ? 'w-7 h-7' : 'w-10 h-10'
          }`}
        >
          {profileName?.charAt(0).toUpperCase()}
        </span>
      ) : (
        <img
          src="/assets/avatar-default.png"
          className={`!border !border-[#D6D8DC] flex rounded-full p-0 items-center justify-center text-lg font-semibold  bg-activeStateBg ${
            isMobile ? 'w-7 h-7' : 'w-10 h-10'
          } `}
        />
      )}
    </>
  );
}
