import { z } from "zod";

export const experienceSchema = z
  .object({
    id: z.string().optional(),
    designation: z.string().trim().min(1, { message: "designation_required_femsg" }),
    company: z.string().trim().min(1, { message: "company_name_required_femsg" }),
    employment_type: z.string().optional().nullable(),
    start_date: z.string({required_error: "start_date_required_femsg", invalid_type_error: "start_date_required_femsg"}).trim().min(1, { message: "start_date_required_femsg" }),
    end_date: z.string({required_error: "end_date_required_femsg", invalid_type_error: "end_date_required_femsg"}).trim().min(1, { message: "end_date_required_femsg" }),
    location: z.string().optional().nullable(),
    job_role_description: z.string().optional().nullable(),
    industry: z
    .object({ id: z.string().optional().nullable(), name: z.string().optional().nullable() })
    .optional().nullable(),
    // {required_error: "nationality_required_femsg",  invalid_type_error: "nationality_required_femsg" }
    // industry_id: z.string().optional().nullable(),
    total_experience: z.string().optional().nullable(),
  })
  .superRefine((data, ctx) => {
    if (data.start_date && data.end_date) {
      if (Date.parse(data?.end_date) < Date.parse(data?.start_date)) {
        ctx.addIssue({
          message: "end_date_no_greater_start_date_femsg",
          code: z.ZodIssueCode.custom,
          path: ["end_date"],
        });
      }
    }
  });
export type ExperienceSchema = z.infer<typeof experienceSchema>;
