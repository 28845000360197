import { useEffect, useState } from 'react';

import { FilterTypeInt } from '../../common/types';
import { companyFilterData, jobFilterData, talentFilterData } from '../../store/filterDataStore';
import { useFilterDetailStore, useFilterType } from '../../store/filterStore';
import useBrowserWidth from '../../utilities/responsiveHook';
import FilterDetailPopup from './filterDetailPopup';
import SwitchFilterComponent from './switchFilterComponent';

type FilterItem = {
  type: string;
  data: any[] | null;
  placeholder: string;
  value: any;
  id: string | number;
  isBasic?: boolean;
};
type Props = {
  filterValues: FilterItem[];
  fetchData: (keyName?: string) => void;
  fromTab?: boolean;
  setFilterValues: (data: FilterItem[]) => void;
  filterTypeRef: FilterTypeInt;
  handleClearFilter?: (data: any) => void;
};
export default function FilterHead(props: Props) {
  const {
    filterValues,
    setFilterValues,
    fetchData,
    handleClearFilter,
    fromTab,
    filterTypeRef,
  } = props;
  const { isBrowser, isMobile } = useBrowserWidth();

  const { filterType } = useFilterType();
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [results, setResults] = useState<any>([]);

  const { clearFilters, filterValues: coreFilterValues } =
    useFilterDetailStore();
  const [detailFilter, setDetailFilter] = useState<FilterItem[]>([]);
  const [count, setCount] = useState(1);
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const handleValues = (value: any, ind: number) => {
    if (value) {
      setClearFilter(true);
    }
    const datas = [...filterValues];
    if (datas) {
      datas[ind].value = value;
      setFilterValues(datas);
    }
  };
  const handleDetailValues = (value: any, ind: number) => {
    if (value) {
      setClearFilter(true);
    }
    const datas = detailFilter;
    if (datas) {
      datas[ind].value = value;
      setDetailFilter(datas);
    }
    setCount(count + 1);
  };
  useEffect(() => {
    setDetailFilter(filterValues);
  }, [filterValues]);
  const handleNavigation = () => {
    // setClearFilter(false);
    setFilterValues(detailFilter);
    setOpenDetail(false);
  };
  const handlefilterClear = () => {
    switch (filterType?.type) {
      case 'work':
        return jobFilterData();
      case 'company':
        return companyFilterData();
      case 'user':
        return talentFilterData();
      default:
        return jobFilterData();
    }
  };
  const handleClear = () => {
    setClearFilter(false);
    clearFilters();
    setTimeout(() => {
      if (handleClearFilter) {
        handleClearFilter({
          work: jobFilterData(),
          company: companyFilterData(),
          user: talentFilterData(),
        });
      }
    });
    setDetailFilter(filterValues);
    setOpenDetail(false);
    setCount(count + 1);
  };

  return (
    <>
      <div
        className={`filterHead fixed w-full shadow-xs z-40  py-3 bg-[#fff] h-[54px] float-left ${
          isMobile ? 'top-[55px]' : 'top-[64px]'
        }`}
      >
        <div
          className={`relative w-4/5 lg:w-3/4 2xl:w-2/3 flex items-center  mx-auto gap-2 ${
            isMobile ? ' justify-center' : 'justify-start'
          }`}
        >
          {isBrowser ? (
            <>
              {filterValues?.map((items, index: number) => (
                <>
                  {items?.isBasic && (
                    <div key={items.id} className="filterFields relative  ">
                      <SwitchFilterComponent
                        key={items.id}
                        keyIndex={index}
                        fetchData={fetchData}
                        handleValues={handleValues}
                        {...items}
                        type={items.type}
                      />
                    </div>
                  )}
                </>
              ))}
            </>
          ) : (
            ''
          )}
          {filterValues.some((item) => item.isBasic == false) && (
            <div className="rtl:min-w-[130px]">
              <FilterDetailPopup
                key={filterTypeRef?.type}
                filterData={detailFilter}
                openDetail={openDetail}
                setOpenDetail={setOpenDetail}
                handleClear={handleClear}
                fetchData={fetchData}
                handleNavigation={handleNavigation}
                handleValues={handleDetailValues}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
                fromTab={fromTab}
                filterTypeRef={filterTypeRef}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
