import { create } from "zustand";
import { persist } from "zustand/middleware";

type AuthStoreType = {
  authenticated: boolean | undefined;
  setAuthenticated: (authenticated: boolean | undefined) => void;
  clearAuthenticated: () => void;
};

export const useAuthStore = create<AuthStoreType>()(
  persist(
    (set) => ({
      authenticated: undefined,
      setAuthenticated: (is_authenticated) =>
        set(() => ({ authenticated: is_authenticated })),
      clearAuthenticated: () => set(() => ({ authenticated: undefined })),
    }),
    { name: "authenticated" }
  )
);

type UserModel = {
  openModel: boolean;
  setOpenModel: (authenticated: boolean) => void;
  openEmailVerifyModel: boolean;
  setOpenEmailVerifyModel: (authenticated: boolean) => void;
  openSign: boolean;
  setOpenSignModel: (authenticated: boolean) => void;
  openCompanyModel: boolean;
  setCompanyModel: (authenticated: boolean) => void;
  emailToVerify: string;
  setEmailToVerify: (email: string) => void;
  openForgotPassword: boolean;
  setOpenForgotPasssword: (open: boolean) => void;
  openProfileVerification: {
    open: boolean;
    actionName: string;
    link: string;
    is_Expert?: boolean;
  };
  setOpenProfileVerification: (value: any) => void;
  openShare: { open: boolean; link: string };
  setOpenShare: (value: any) => void;
  openContactUs: { open: boolean };
  setOpenContactUs: (value: any) => void;
};
export const useUserManagement = create<UserModel>()((set) => ({
  openEmailVerifyModel: false,
  setOpenEmailVerifyModel: (open) =>
    set(() => ({ openEmailVerifyModel: open })),
  openModel: false,
  setOpenModel: (open) => set(() => ({ openModel: open })),
  openCompanyModel: false,
  setCompanyModel: (open) => set(() => ({ openCompanyModel: open })),
  openSign: false,
  setOpenSignModel: (open) => set(() => ({ openSign: open })),
  emailToVerify: "",
  setEmailToVerify: (email) => set(() => ({ emailToVerify: email })),
  openForgotPassword: false,
  setOpenForgotPasssword: (open) => set(() => ({ openForgotPassword: open })),
  openProfileVerification: {
    open: false,
    actionName: "",
    link: "",
    is_Expert: false,
  },
  setOpenProfileVerification: (value) =>
    set(() => ({ openProfileVerification: value })),
  openShare: { open: false, link: "" },
  setOpenShare: (value) => set(() => ({ openShare: value })),
  openContactUs: { open: false },
  setOpenContactUs: (value) => set(() => ({ openContactUs: value })),
}));
