export const CheckIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 16.1323C12.6683 16.1323 16.25 12.5506 16.25 8.13232C16.25 3.71405 12.6683 0.132324 8.25 0.132324C3.83172 0.132324 0.25 3.71405 0.25 8.13232C0.25 12.5506 3.83172 16.1323 8.25 16.1323ZM11.9571 6.83943C12.3476 6.44891 12.3476 5.81574 11.9571 5.42522C11.5666 5.03469 10.9334 5.03469 10.5429 5.42522L7.25 8.71811L5.95711 7.42522C5.56658 7.03469 4.93342 7.03469 4.54289 7.42522C4.15237 7.81574 4.15237 8.44891 4.54289 8.83943L6.54289 10.8394C6.93342 11.23 7.56658 11.23 7.95711 10.8394L11.9571 6.83943Z"
        fill="#299957"
      />
    </svg>
  );
};
export const CrossIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 16.1323C12.6683 16.1323 16.25 12.5506 16.25 8.13232C16.25 3.71405 12.6683 0.132324 8.25 0.132324C3.83172 0.132324 0.25 3.71405 0.25 8.13232C0.25 12.5506 3.83172 16.1323 8.25 16.1323ZM6.95711 5.42522C6.56658 5.03469 5.93342 5.03469 5.54289 5.42522C5.15237 5.81574 5.15237 6.44891 5.54289 6.83943L6.83579 8.13232L5.54289 9.42522C5.15237 9.81574 5.15237 10.4489 5.54289 10.8394C5.93342 11.23 6.56658 11.23 6.95711 10.8394L8.25 9.54654L9.54289 10.8394C9.93342 11.23 10.5666 11.23 10.9571 10.8394C11.3476 10.4489 11.3476 9.81574 10.9571 9.42522L9.66421 8.13232L10.9571 6.83943C11.3476 6.44891 11.3476 5.81574 10.9571 5.42522C10.5666 5.03469 9.93342 5.03469 9.54289 5.42522L8.25 6.71811L6.95711 5.42522Z"
        fill="#C63A3A"
      />
    </svg>
  );
};
