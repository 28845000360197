import { create } from "zustand";
import { persist } from "zustand/middleware";

type AuthStoreType = {
  currentToken: string | undefined;
  setCurrentToken: (currentToken: string | undefined) => void;
};

export const usePaymentStore = create<AuthStoreType>()(
  persist(
    (set) => ({
      currentToken: undefined,
      setCurrentToken: (token) => set(() => ({ currentToken: token })),
    }),
    { name: "payment__token__" }
  )
);
