import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';

import { getDropDownData } from '../../../apis/dropdowns/dropDown.api';
import AutoComplete from '../../../customComponents/AutocompleteField';
import CustomButton from '../../../customComponents/button';
import MonthPicker from '../../../customComponents/datepicker/customMonthPicker';
import CustomDialogBox from '../../../customComponents/dialogbox/index';
import SelectField from '../../../customComponents/selectfield';
import CustomInput from '../../../customComponents/textfield';
import TextareaInput from '../../../customComponents/textfield/textarea';
import { updateProfile } from '../../../pages/profile/apis/api';
import { profileStore } from '../../../store/profileStore';
import useBrowserWidth from '../../../utilities/responsiveHook';
import { UserContext } from '../../detailComponent/dataContext';
import { experienceSchema, ExperienceSchema } from './schema';

const dropKeys = ['industry', 'employment-status'];
type Props = {
  handleRefresh: () => void;
  defaultValues: ExperienceSchema;
  mode: string;
  detailsId: string;
};

export default function AddExperience(props: Props) {
  const { handleRefresh, defaultValues, mode, detailsId } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { isBrowser, isMobile } = useBrowserWidth();

  const { openExperienceModel, setExperienceModel } = profileStore();

  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [closePopup, setClosePopup] = useState({
    status: false,
    mode: mode,
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    watch,
  } = useForm<ExperienceSchema>({
    mode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: zodResolver(experienceSchema),
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues]);

  useEffect(() => {
    handleReset();
    closePopup.status = false;
    closePopup.mode = mode;
  }, [openExperienceModel]);

  const handleReset = () => {
    if (mode == 'Add') {
      reset({
        designation: '',
        company: '',
        employment_type: '',
        start_date: undefined,
        end_date: undefined,
        location: '',
        job_role_description: '',
        // industry_id: null,
        industry: null,
        total_experience: null,
      });
      // setStartDate("");
      // setEndDate("");
      setValue('total_experience', '');
    } else {
      reset({ ...defaultValues });
    }
    setStartDate("");
    setEndDate("");
  };

  const { dropData, setDropData } = useContext(UserContext);

  const handleDropData = async (key: string, searchKey?: string) => {
    let drop = dropData;
    const { data, error } = await getDropDownData(key, '', searchKey);
    drop = { [key]: data?.data, ...drop };

    setDropData((prevState: any) => ({
      ...prevState,
      [key]: data?.data,
    }));
  };
  useEffect(() => {
    dropKeys.forEach((item) => {
      handleDropData(item);
    });
  }, []);

  const getIndustryData = (key: any) => {
    handleDropData('industry', key);
  };
  const handleData = async (data: ExperienceSchema) => {
    setIsLoading(true);

    if (detailsId && mode == 'Edit') {
      data.id = detailsId;
    }
    // data.start_date = moment(data.start_date).format("DD/MM/YYYY");
    // data.end_date = moment(data.end_date).format("DD/MM/YYYY")

    const form = new FormData();
    form.append('job_details', JSON.stringify(data));
    if (Object.keys(errors)?.length === 0) {
      setIsLoading(true);

      updateProfile(form)
        .then((response) => {
          if (response.status) {
            setIsLoading(false);
            handleRefresh();
            setExperienceModel(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error: any) => {
          setIsLoading(false);
          const errorKey = Object.keys(error.response.data.error)[0];
          enqueueSnackbar(error.response.data.error[errorKey][0], {
            variant: 'error',
          });
        });
    }
  };
  const [empType, setEmpType] = useState<number | any>('');

  const handleChange = (e: any) => {
    const data = employmentType?.find((item) => item?.id === e);
    // setValue("employment_type", data?.name);
    setValue('employment_type', data?.id);
    setEmpType(e);
  };
  useEffect(() => {
    const data = employmentType?.find(
      (item) => item?.id === getValues('employment_type')
      // (item) => item?.name === getValues("employment_type")
    );
    setEmpType(data?.id ?? '');
  }, [getValues('employment_type')]);
  const employmentType = [
    { id: 'Full Time', name: t('option_full_time_experience' ?? '') },
    { id: 'Part Time', name: t('option_part_time_experience') ?? '' },
    { id: 'Freelance', name: t('option_freelance_experience') ?? '' },
    { id: 'Internship', name: t('option_intership_experience') ?? '' },
    { id: 'Training', name: t('option_training_experience') ?? '' },
    { id: 'Volunteer', name: t('option_volunteer_experience') ?? '' },
    {
      id: 'Short term contract',
      name: t('option_short_term_contract_experience') ?? '',
    },
    {
      id: 'Entrepreneurship',
      name: t('option_entrepreneurship_experience') ?? '',
    },
  ];
  const handleClose = () => {
    setExperienceModel(false);
    setIsLoading(false);
    // setStartDate(undefined);
    // setEndDate(undefined);
    reset({
      start_date: undefined,
      end_date: undefined,
      total_experience: '',
      industry: null,
    });
    closePopup.status = true;
    closePopup.mode = mode;
    setStartDate("");
    setEndDate("");
  };

  useEffect(() => {
    if (getValues('start_date') && getValues('end_date')) {
      var startDateConvert: any = moment(getValues('start_date'), 'MM-DD-YYYY');
      var endDateConvert: any = moment(getValues('end_date'), 'MM-DD-YYYY');

      var monthDiff = endDateConvert.diff(startDateConvert, 'months');

      let yearsDiff = endDateConvert.diff(startDateConvert, 'years');
      if (
        Date.parse(getValues('end_date')) >= Date.parse(getValues('start_date'))
      ) {
        setValue(
          'total_experience',
          ` ${yearsDiff} ${t('years_placeholder') ?? ''} ${monthDiff % 12} ${
            t('months_text') ?? ''
          }`
        );
        trigger(['start_date', 'end_date']);
      } else {
        trigger(['start_date', 'end_date']);
        setValue('total_experience', defaultValues.total_experience ?? '');
      }
    }
  }, [startDate, endDate]);

  const handleChangeDate = (date: any) => {
    setValue('start_date', date);
    setStartDate(date);
  };

  const handleChangeEndDate = (date: any) => {
    setValue('end_date', date);
    setEndDate(date);
  };

  let industryMsg = t(errors?.industry?.message ?? '');
  return (
    <div className="flex">
      <CustomDialogBox
        open={openExperienceModel}
        title={
          mode == 'Add' ? t('add_experience_heading') : t('edit_experience')
        }
        maxWidth={'md'}
        handleClose={() => handleClose()}
        className="customFormDialog"
        dialogActions={
          <div className="flex justify-end gap-3 w-full">
            <CustomButton
              className={`secondaryButton ${isMobile ? 'flex-1' : ''}`}
              title={t('cancel_button') ?? ''}
              onClick={() => handleClose()}
            />
            <CustomButton
              className={`primaryButton disabled:opacity-50 ${
                isMobile ? 'flex-1' : ''
              }`}
              title={t('save_button') ?? ''}
              onClick={() => {
                handleSubmit(handleData)();
              }}
              disabled={isLoading ? true : false}
            />
          </div>
        }
      >
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <CustomInput
              label={t('designation_label') ?? ''}
              required={true}
              placeholder={t('designation_placeholder') ?? ''}
              register={register('designation')}
              error={t(errors.designation?.message ?? '')}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <CustomInput
              label={t('company_name_label') ?? ''}
              placeholder={t('company_name_placeholder') ?? ''}
              register={register('company')}
              error={t(errors.company?.message ?? '')}
              required={true}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <Controller
              control={control}
              name="employment_type"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SelectField
                  onChange={(e) => handleChange(e)}
                  data={employmentType}
                  value={empType ?? ''}
                  label={t('employment_type_label') ?? ''}
                  placeholder={t('employment_type_label') ?? ''}
                  // placeholder="Select Employment Type"
                  fullWidth
                  className="customSelectField"
                  error={t(errors.employment_type?.message ?? '') ?? ''}
                />
              )}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <div className="grid grid-cols-2 gap-3">
              <div>
                <MonthPicker
                  label={t('start_date_label') ?? ''}
                  value={
                    startDate ? moment(startDate).toDate() 
                      : (getValues('start_date')
                      ? moment(getValues('start_date')).toDate()
                      : null)
                  }
                  required
                  // onChange={(date) => onChange(date)}
                  onChange={(date) => handleChangeDate(date)}
                  error={t(errors?.start_date?.message ?? '') ?? ''}
                  placeholder="MONTH/YYYY"
                  maxDate={moment().toDate()}
                />
              </div>
              <div>
                <MonthPicker
                  label={t('end_date_label') ?? ''}
                  value={
                    endDate ? moment(endDate).toDate() 
                      : (getValues('end_date')
                      ? moment(getValues('end_date')).toDate()
                      : null)
                  }
                  required
                  onChange={(date) => handleChangeEndDate(date)}
                  // onChange={(date) => onChange(date)}
                  error={t(errors?.end_date?.message ?? '') ?? ''}
                  placeholder="MONTH/YYYY "
                  maxDate={moment().toDate()}
                />
              </div>
            </div>
          </div>
          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <CustomInput
              label={t('years_of_experience_label') ?? ''}
              placeholder={t('totalyears_exp_placeholder') ?? ''}
              register={register('total_experience')}
              error={t(errors.total_experience?.message ?? '')}
              disabled={true}
            />
          </div>
          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <Controller
              control={control}
              name="industry"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AutoComplete
                  value={value ?? []}
                  labelName={t('industry_label') ?? ''}
                  closePopup={closePopup}
                  // getData={() => handleDropData("industry")}
                  getData={getIndustryData}
                  // handleChange={(e) => handleIndustryChange(e)}
                  handleChange={onChange}
                  startIcon={<AiOutlineSearch />}
                  // type="tagSelect"
                  placeholder={t('industry_label') ?? ''}
                  //required={true}
                  // error={ (errors?.industry?.name?.message || errors?.industry?.message ) && t('specialation_required_femsg') }
                  // error={industryMsg ?? ""}
                  data={
                    dropData &&
                    dropData['industry']?.map((data: any) => {
                      return {
                        name: data?.name,
                        id: data?.id,
                      };
                    })
                  }
                />
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <CustomInput
              label={t('location_label') ?? ''}
              placeholder={t('location_placeholder') ?? ''}
              register={register('location')}
              error={t(errors.location?.message ?? '')}
            />
          </div>
          <div className="col-span-6">
            <TextareaInput
              label={t('description_jobrole_label') ?? ''}
              placeholder={t('description_jobrole_placeholder') ?? ''}
              textarea={true}
              className="customTextarea"
              register={register('job_role_description')}
              error={t(errors.job_role_description?.message ?? '')}
            />
          </div>
        </div>
      </CustomDialogBox>
    </div>
  );
}
