import DatePicker from "react-datepicker";

// import { Portal } from 'react-overlays';

import "react-datepicker/dist/react-datepicker.css";

type Props = {
  label?: string;
  onChange: (data: any) => void;
  value?: any;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  error?: any;
  placeholder?: string;
  className?: string;
};

// const CalendarContainer = ({ children }: any) => {
//   const el = document.getElementById('calendar-portal');
//   return <Portal container={el}>{children}</Portal>;
// };
function DateRangePickerComponent(props: Props) {
  const {
    label,
    error,
    onChange,
    value,
    maxDate,
    minDate,
    disabled,
    placeholder,
    className,
  } = props;

  return (
    <div className="flex flex-col">
      {label && (
        <div className="text-xs text-white font-light  mb-2 text-left">
          {label}
        </div>
      )}

      <DatePicker
        selected={value && value[0]}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(date) => onChange(date)}
        // popperContainer={CalendarContainer}
        dateFormat={"dd/MM/yyyy"}
        startDate={value && value[0]}
        endDate={value && value[1]}
        placeholderText={placeholder}
        selectsRange
        className={`${className} w-full customFieldInput text-xs 2xl:text-sm `}
      />
    </div>
  );
}

export default DateRangePickerComponent;
