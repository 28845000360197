import { useAuthStore } from "../../store/authStore";
import { Navigate } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

const PublicRoute = ({ children }: Props) => {
  const authenticated = useAuthStore((state) => state.authenticated);

  return !authenticated ? (
    <>{children}</>
  ) : (
    <Navigate to="/home" replace={true} />
  );
};

export default PublicRoute;
