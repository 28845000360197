import * as React from "react";
import { useTranslation } from "react-i18next";

import CustomButton from "../../customComponents/button";
import CustomDialogBox from "../../customComponents/dialogbox";

type Props = {
  open: boolean;
  title: string;
  subtext?: string;
  handleClose: () => void;
  handleSave: () => void;
  handleDelete: () => void;
  setFile: (file: any) => void;
  file: any;
  className?: string;
  children: React.ReactNode;
  data: any;
  src: string;
  errorMessage: string;
  setErrorMessage: (errorMessage: string) => void;
  types?: any;
};

export default function ImageUploadModal(props: Props) {
  const {
    open,
    handleClose,
    handleSave,
    setFile,
    file,
    types,
    errorMessage,
    setErrorMessage,
    title,
    data,
    subtext,
    className,
    children,
    src,
    handleDelete,
  } = props;
  const { t } = useTranslation();
  const handleFile = (e: any) => {
    setErrorMessage("");
    const tempFile = e?.target?.files[0];
    const maxSize = 5 * 1024 * 1024;

    const filePath = tempFile?.name?.split(".");

    const fileExtension = filePath[filePath.length - 1];

    const allowedExtensions = types ?? [
      "jpeg",
      "jpg",
      "png",
      "gif",
      "tiff",
      "svg",
    ];
    const formattedTypes = [
      types.slice(0, -1).join(", "),
      types.slice(-1)[0],
    ].join(types.length < 2 ? "" : " and ");
    if (allowedExtensions.indexOf(fileExtension) === -1) {
      if (
        formattedTypes == "jpeg, jpg, png and svg" ||
        formattedTypes == "jpeg, jpg and png"
      ) {
        setErrorMessage(t("profile_pic_format_validation_msg" ?? ""));
      } else {
        setErrorMessage(
          `Only ${
            types ? formattedTypes : "jpg and png"
          } formats can be uploaded`
        );
      }
    } else {
      if (tempFile.size > maxSize) {
        setErrorMessage("max_file_size_is_5mb_femsg");
      } else {
        setFile(e?.target?.files[0]);
      }
    }

    // handleChange(e?.target?.files[0])
  };

  const dropItem = [
    {
      name: "Rubiya Ahmed",
      desc: "Main Account",
      href: "##",
      active: "true",
    },
    {
      name: "Upfin",
      desc: "Project manager",
      href: "##",
    },
  ];

  return (
    <>
      {children}
      <CustomDialogBox
        open={open}
        title={title}
        maxWidth={"xs"}
        handleClose={() => handleClose()}
        className="chooseProfileModal"
        dialogActions={
          <div className="flex items-center justify-between gap-3 w-full">
            <div>
              <CustomButton
                title={t("delete_button") ?? ""}
                className=" secondaryButton  disabled:opacity-50"
                onClick={() => handleDelete()}
                disabled={src ? false : true}
              />
            </div>
            <div className="flex items-center justify-end gap-3">
              {file && !errorMessage ? (
                <>
                  <CustomButton
                    title={t("cancel_button") ?? ""}
                    className=" secondaryButton"
                    onClick={() => handleClose()}
                  />
                  <CustomButton
                    className="primaryButton"
                    title={t("save_button") ?? "Save"}
                    // disabled={!jobData?.can_send_proposal}
                    onClick={() => handleSave()}
                  />
                </>
              ) : (
                <label
                  htmlFor="dropzone-file"
                  title={t("upload_company_logo_message") ?? ""}
                  className="uploadButton"
                >
                  <input
                    id="dropzone-file"
                    type="file"
                    accept="image/png, image/svg, image/jpeg"
                    onChange={(e) => handleFile(e)}
                    className="hidden"
                  />
                  <span>{t("profile_upload_photo") ?? ""}</span>
                </label>
              )}
            </div>
          </div>
        }
      >
        <div className="flex flex-col">
          <div className="rounded-lg w-[200px] h-[200px] overflow-hidden mx-auto my-2">
            {src || file ? (
              <img
                src={file ? URL.createObjectURL(file) : src}
                className="h-full  "
              />
            ) : (
              <span className="flex justify-center items-center text-3xl border rounded-3xl h-full font-extrabold">
                {data?.name?.charAt(0).toUpperCase()}
              </span>
            )}
          </div>
          <div className="m-0  text-bgError text-xs">{errorMessage}</div>
        </div>
      </CustomDialogBox>
    </>
  );
}
