import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { VscFilePdf } from 'react-icons/vsc';

import MenuDropDown from '../../customComponents/menudropdown/index';
import { resumeDelete, setAsPrimary } from '../../pages/profile/apis/api';
import useBrowserWidth from '../../utilities/responsiveHook';
import DeleteModal from '../dialogComponents/deleteComponent';
import { userProfileTypes } from '../profile/store';

type Props = {
  data?: any;
  handleEdit: (val: string) => void;
  handleRefresh: () => void;
  readOnly?: boolean;
};
export default function UploadResume(props: Props) {
  const { data, handleEdit, handleRefresh, readOnly } = props;
  const [anchorEl, setAnchorel] = useState<any>(null);
  const [fileId, setFileId] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { isBrowser, isMobile } = useBrowserWidth();

  const menuData = [
    { title: 'set_primary_option', id: '1', action: 'setPrimary' },
    { title: 'delete_button', id: '2', action: 'delete', divider: true },
  ];

  const primaryMenuData = [
    { title: 'delete_button', id: '2', action: 'delete', divider: true },
  ];
  const [menu, setMenu] = useState(menuData);

  const handleClose = () => {
    setAnchorel(null);
  };
  const handleOpenMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    is_primary: boolean
  ) => {
    setAnchorel(e.currentTarget);
    setFileId(id);
    if (is_primary) {
      setMenu(primaryMenuData);
    } else {
      setMenu(menuData);
    }
  };

  const handleMenuActions = (type: string) => {
    handleClose();
    switch (type) {
      case 'setPrimary':
        return handleSetPrimary();
      case 'delete':
        return setConfirmOpen(true);
      default:
        return null;
    }
  };
  const handleDelete = async () => {
    const { data, error } = await resumeDelete(fileId);
    handleRefresh();
  };

  const handleSetPrimary = async () => {
    const { data, error } = await setAsPrimary(fileId);
    handleRefresh();
  };

  return (
    <div
      className={`flex flex-col gap-4 bg-bgWhite border-b border-formBorder ${
        isMobile ? 'p-4' : 'px-7 py-5'
      }`}
    >
      <div className="flex justify-between items-center">
        <p className="text-lg font-bold text-primaryText">
          {t('talent_resume_heading') ?? ''}
          {/* {t("upload_resume_heading") ?? ""} */}
        </p>
        <div className={`flex items-center gap-4`}>
          {!readOnly && (
            <IconButton
              disableRipple
              onClick={() => handleEdit(userProfileTypes.RESUME)}
              className="p-0  "
            >
              <BiPlus className="p-0  text-primaryText w-5" />
            </IconButton>
          )}
        </div>
      </div>
      <div className={`grid grid-cols-1 ${isMobile ? 'gap-2' : 'gap-4'}`}>
        {data?.map((item: any, index: number) => (
          <div className="bg-[#F9F9F9] px-2.5 py-3 flex justify-between items-center rounded-md group ">
            <div className="flex items-start gap-1.5">
              <div className="relative top-[4px]">
                <VscFilePdf className="w-4" />
              </div>
              <div>
                <a
                  onClick={() => window.open(item.actual_file_path)}
                  className="text-sm text-primaryText font-medium cursor-pointer group-hover:text-primary"
                >
                  {item?.actual_file_name ?? '- -'}
                </a>
                <p className="text-xs text-primaryText ">
                  {item?.actual_file_name ?? '- -'}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              {item.is_primary && (
                <div className="tagStyle primaryTag border-t border-b border-primary bg-activeStateBg">
                  <span className="tag text-[10px] text-primaryText font-semibold">
                    {t('primary_badge') ?? ''}
                  </span>
                </div>
              )}
              {!readOnly && (
                <IconButton
                  disableRipple
                  onClick={(e) => handleOpenMenu(e, item.id, item.is_primary)}
                >
                  <BsThreeDotsVertical className="p-0  text-primaryText w-4 h-4" />
                </IconButton>
              )}
            </div>
          </div>
        ))}

        <MenuDropDown
          anchorEl={anchorEl}
          handleClose={handleClose}
          menuData={menu}
          handleMenuActions={handleMenuActions}
        />
        <DeleteModal
          open={confirmOpen}
          setOpen={setConfirmOpen}
          title={t('delete_file_heading') ?? ''}
          subTitle={t('sure_to_delete_file_message') ?? ''}
          description={t('cannot_retrieve_file_msg') ?? ''}
          // handleRefresh={handleRefresh}
          handleDelete={() => handleDelete()}
        />
      </div>
    </div>
  );
}
