

export const routesMap: { [key: string]: { route: string; state?: any } } = {
    proposal_send: {
      route: "/proposals",
    },
    proposal_received: {
      route: "/proposals",
      state: {  status: "Published" },
    },
    project_published: {
      route: "/details",
      state: { status: "Completed" },
    },
    project_awarded_owner: {
      route: "/details",
    },
    project_awarded_consultant: {
      route: "/details",
      state: {  status: "Completed" },
    },
    project_nomilestone_cancel: {
      route: "/details",
      state: {  status: "Cancelled"},
    },
    project_onemilestone_cancel: {
      route: "/disputed",
      state: {  openModal: true },
    },
    project_onemilepay_cancel: {
      route: "/disputed",
      state: {  openModal: true },
    },
    accept_project_off: {
      route: "/details",
      state: { status: "On Going" },
    },
    milestone_create: {
      route: "/requested",
      state: {  status: "On Going" },
    },
    milestone_deposit_consultant: {
      route: "/transactions",
      state: { status: "On Going" },
    },
    milestone_deposit_owner: {
      route: "/transactions",
      state: { status: "On Going" },
    },
    addon_purchase: {
      route: "/subscription",
    },
    job_created: {
      route: "/details",
    },
    plan_purchase: {
      route: "/subscription"
    },
  };


  export const userTypeRoutes: {
    [key: string]: { [userType: string]: { routeEnd: string; state?: any } };
  } = {
    project_complete: {
      Owner: {
        routeEnd: "ratings",
        state: { status: "Completed"  },
      },
      Talent: {
        routeEnd: "ratings",
        state: { status: "Completed" },
      },
    },
    project_cancel: {
      Owner: {
        routeEnd: "details",
        state: { status: "Cancelled" },
      },
      Talent: {
        routeEnd: "details",
        state: { status: "Cancelled"  },
      },
    },
    dispute_raised: {
      Owner: {
        routeEnd: "disputed",
      },
      Talent: {
        routeEnd: "disputed",
      },
    },
    milestone_cancel: {
      Owner: {
        routeEnd: "accepted",
        state: {  status: "On Going" },
      },
      Talent: {
        routeEnd: "accepted",
        state: { status: "On Going" },
      },
    },
    milestone_accept: {
      Owner: {
        routeEnd: "accepted",
        state: {  status: "On Going" },
      },
      Talent: {
        routeEnd: "accepted",
        state: { status: "On Going" },
      },
    },
    milestone_reject: {
      Owner: {
        routeEnd: "requested",
        state: { status: "On Going"  },
      },
      Talent: {
        routeEnd: "created",
        state: {  status: "On Going"  },
      },
    },
    milestone_start: {
      Owner: {
        routeEnd: "accepted",
        state: {  status: "On Going" },
      },
      Talent: {
        routeEnd: "accepted",
        state: { status: "On Going" },
      },
    },
    milestone_complete: {
      Owner: {
        routeEnd: "accepted",
        state: {  status: "On Going" },
      },
      Talent: {
        routeEnd: "accepted",
        state: { status: "On Going" },
      },
    },
    addon_apply: {
      Owner: {
        routeEnd: "addons",
        state: {  status: "On Going"  },
      },
      Talent: {
        routeEnd: "addons",
        state: { status: "On Going" },
      },
    },
    payout_request: {
      Owner: {
        routeEnd: "accepted",
        state: {  status: "On Going"  },
      },
      Talent: {
        routeEnd: "accepted",
        state: { status: "On Going" },
      },
    },
    payout_cancel: {
      Owner: {
        routeEnd: "accepted",
        state: {  status: "On Going"  },
      },
      Talent: {
        routeEnd: "accepted",
        state: { status: "On Going" },
      },
    },
    payout_accept: {
      Owner: {
        routeEnd: "accepted",
        state: {  status: "On Going"  },
      },
      Talent: {
        routeEnd: "accepted",
        state: { status: "On Going" },
      },
    },
    payout_decline: {
      Owner: {
        routeEnd: "accepted",
        state: {  status: "On Going"  },
      },
      Talent: {
        routeEnd: "accepted",
        state: { status: "On Going" },
      },
    },
    payment_settle: {
      Owner: {
        routeEnd: "transactions",
        state: {  status: "On Going"  },
      },
      Talent: {
        routeEnd: "transactions",
        state: { status: "On Going" },
      },
    },

  };
