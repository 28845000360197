import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

type Props = {
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
  value?: any;
  handleDateChange: (date: unknown) => void;
  register?: any;
  placeholder?: string;
  name?: string;
  error?: any;
  views?: boolean;
  className?: string;
  MonthYearDateType?: boolean;
};
export default function CustomDatePicker(props: Props) {
  const {
    label,
    required,
    register,
    value,
    name,
    error,
    views,
    className,
    placeholder,
    MonthYearDateType,
  } = props;
  const handleDateChange = (newValue: unknown) => {
    props.handleDateChange(newValue);
  };
  const styles = {
    class: "customDateInput",
  };
  return (
    <div className="customDatePicker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {label && (
          <label className="block justify-start labelText">
            {" "}
            {/* {required ? `${label}*` : label} */}
            {required ? `${label}` : label}
            {required ? <span className="text-required"> * </span> : ""}
          </label>
        )}
        <div className={`${className} w-full relative `}>
          <DatePicker
            disableFuture
            inputFormat={
              MonthYearDateType
                ? !value
                  ? "Month/YYYY"
                  : "MMMM YYYY"
                : "DD/MM/YYYY"
            }
            views={views && ["year", "month"]}
            {...register}
            value={value ?? null}
            name={name}
            onChange={(newValue) => {
              handleDateChange(newValue);
            }}
            classes={{ root: styles.class }}
            renderInput={(params) => (
              <TextField
                {...props}
                {...params}
                value={value ?? null}
                // inputProps={{ readOnly: true }}
              />
            )}
          />
          {error && <p className="m-0  text-bgError text-xs">{error}</p>}
        </div>
      </LocalizationProvider>
    </div>
  );
}
