import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import * as React from "react";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "#313131",
    maxWidth: 360,
    padding: "16px",
    borderRadius: "4px",
    filter: "drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.15))",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
  },
}));

type Props = {
  children: React.ReactNode;
  heading?: string;
  text?: string;
  placement?: any;
  icon?: boolean;

};

export default function CustomTooltip(props: Props) {
  const { children, heading, text, placement, icon } = props;
  return (
    <>
      <HtmlTooltip
        placement={placement}
        title={
          <div className="flex flex-col gap-1">
            <h4 className=" text-base font-bold text-primaryText">{heading}</h4>
            <p className=" text-textColor text-sm ">{text}</p>
          </div>
        }
      >
        {icon ? (
          <span className="cursor-pointer"> {children} </span>
        ) : (
          <Button className="tooltipBtn flex justify-start">{children}</Button>
        )}

      </HtmlTooltip>
    </>
  );
}
