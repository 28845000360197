const PROFILE = "/get-userprofile";
const PROFILE_UPDATE_URL = "/update-userprofile";
const USER_BANK_DETAILS_DELETE = "/user/bank-details/delete";
const IDENTITY_DOCUMENT_DELETE = "identity-document/delete";

const PROFILE_IMG_DELETE = "/profile-image/delete";
const PORTFOLIO_DELETE = "/portfolio/delete";
const RESUME_DELETE = "/attachment/delete";
const SET_AS_PRIMARY = "/attachment/set-primary";
const QUALIFICATION_DELETE = "/qualification/delete";
const EXPERIENCE_DELETE = "/experience/delete";

const apiUrl = {
  PROFILE,
  IDENTITY_DOCUMENT_DELETE,
  PROFILE_IMG_DELETE,
  PORTFOLIO_DELETE,
  RESUME_DELETE,
  PROFILE_UPDATE_URL,
  USER_BANK_DETAILS_DELETE,
  SET_AS_PRIMARY,
  QUALIFICATION_DELETE,
  EXPERIENCE_DELETE
};

export default apiUrl;
