import { create } from "zustand";
import { persist } from "zustand/middleware";
type Status = { workType: string; jobStatus: string };
type AuthStoreType = {
  myworkStatus: Status;
  setMyWorkStatus: (data: Status) => void;
  clearMyworkStatus: () => void;
};

export const useMyworkStore = create<AuthStoreType>()(
  persist(
    (set) => ({
      myworkStatus: { workType: "", jobStatus: "" },
      setMyWorkStatus: (data) => set(() => ({ myworkStatus: data })),
      clearMyworkStatus: () => set(() => ({ myworkStatus: undefined })),
    }),
    { name: "my__works__status" }
  )
);
