import { createContext } from "react";

interface AppContextInterface {
  dropData: any;
  setDropData: (arg0: any) => void;
}

export const UserContext = createContext<AppContextInterface>({
  dropData: "",
  setDropData: () => {},
});
