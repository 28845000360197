export const userProfileTypes = {
  BASIC_INFO: "Basic_Info",
  PERSONAL_INFO: "Personal_Info",
  PROFESSIONAL_INFO: "Professional_Info",
  USER_DESC: "User_Description",
  SKILL_PREF: "Skill_Preference",
  EXPERIENCE: "Experience",
  EDUCATION: "Education",
  WORK_LOC: "Work_Location",
  WORK_SECTOR: "Work_Sector",
  LANGUAGE: "Language",
  PORTFOLIO: "Portfolio",
  USER_PAYMENT_INFO: "User_Payment_info",
  USER_TAX_INO: "User_Tax_Info",
  SOCIAL_MEDIA_PRESENCE: "Social_Media_Presence",
  RECOMMENDATION_TAGS: "Recommendation_Tags",
  RESUME: "Resume",
};

export const userProfileTypesTitle = {
  Basic_Info: "basic_information",
  Personal_Info: "personal_info",
  Professional_Info: "professional_info",
  User_Description: "aboutme_tab",
  Skill_Preference: "skills_heading",
  Experience: "Experience",
  Education: "Education",
  Work_Location: "preferred_work_location_label",
  Work_Sector: "preferred_job_sector_label",
  Language: "language_proficiency_heading",
  Portfolio: "upload_portfolio",
  User_Payment_info: "Freelance Bank Account Info",
  User_Tax_Info: "tax_information",
  Social_Media_Presence: "social_media_presence",
  Recommendation_Tags: "recommendation_tags",
  // Resume: "upload_resume_heading",
  Resume: "talent_resume_heading",
};


