import moment from "moment";
import { useContext, useEffect, useState } from "react";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";

import { getState } from "../../apis/commonapis/apis";
import { getDropDownData } from "../../apis/dropdowns/dropDown.api";
import { ProfileSchema } from "../../common/userSchema";
import AutoComplete from "../../customComponents/AutocompleteField";
import DatePicker from "../../customComponents/datepicker";
import LocationAutoComplete from "../../customComponents/googleAutoComplete";
import RadioButton from "../../customComponents/radioButton";
import SelectField from "../../customComponents/selectfield";
import CustomInput from "../../customComponents/textfield";
import { UserContext } from "../detailComponent/dataContext";

const dropKeys = ["nationality", "state", "gender"];
type Props = {
  fromDialog?: boolean;
};

interface PlaceType {
  formatted_address?: string | null;
  place_id?: string | null;
}
export default function PersonalInformation(props: any) {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    trigger,
  } = useFormContext<ProfileSchema>();
  const [mapValue, setMapValue] = React.useState<PlaceType | null>(null);
  const [stateListData, setStateListData] = useState<any>([{}]);
  const [stateList, setStateList] = useState<any>("");
  const [nationalityList, setNationalityList] = useState<any>("");
  // const [searchKey, setSearchKey] = useState<any>("");

  const { dropData, setDropData } = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (mapValue?.formatted_address !== undefined) {
      setValue("current_location", mapValue?.formatted_address);
      setValue("place_id", mapValue?.place_id);
    }
  }, [mapValue]);

  useEffect(() => {
    setMapValue({
      formatted_address: getValues("current_location"),
      place_id: getValues("place_id"),
    });
  }, [getValues("current_location")]);

  const handleDropData = async (key: string,searchKey?:string) => {
    let drop = dropData;
    const { data, error } = await getDropDownData(key,"",searchKey);
    drop = { [key]: data?.data, ...drop };

    setDropData((prevState: any) => ({
      ...prevState,
      [key]: data?.data,
    }));
  };

  const handleStateChange = (id: any) => {
    setStateList(id);
    setValue("state_id", id);
    trigger(["state_id"]);
  };

  const getNationalityData = (key: any) => {
    handleDropData("nationality",key)
  }

  const handleNationalityChange = (country: any) => {
    setNationalityList(country.id);
    setValue("nationality", country);
    setValue("state_id", "");
    getStateList(country.id);
    trigger(["nationality"]);
  };

  const getStateList = (id?: any) => {
    getState(id ?? getValues("nationality")?.id)
      .then((res) => {
        setStateListData(res.data);
      })
      .catch((err) => {
        setStateListData([{}]);
      });
  };

  useEffect(() => {
    // getStateList();
    setStateList(getValues("state_id"));
  }, [getValues("state_id")]);

  useEffect(() => {
    if (Object.keys(stateListData[0]).length == 0) {
      getStateList(getValues("nationality")?.id);
    }
    setNationalityList(getValues("nationality"));
  }, [getValues("nationality")]);

  useEffect(() => {
    dropKeys.forEach((item) => {
      handleDropData(item);
    });
  }, []);

  useEffect(() => {
    const getdate = getValues("date_of_birth");
    const Dateformat = "DD/MM/YYYY";
    const dateConvert = moment(getdate, Dateformat).format();
    setDate(dateConvert);
  }, [getValues("date_of_birth")]);

  const [date, setDate] = useState<Date | unknown>();

  const handleDateChange = (value: any) => {
    if (value) {
      const date = new Date(value);
      let Dateformat = moment(date).format("DD/MM/YYYY");
      setDate(value);
      const now = moment();
      let result = value?.diff(now, "months");

      setValue("date_of_birth", Dateformat);
      setValue("age", Math.round(-(result / 12)));
    } else {
      setValue("date_of_birth", value);
    }
    trigger(["date_of_birth"]);
  };
  const datas: any = watch();
  return (
    <div>
      <div className="flex flex-col gap-5">
        {!props.fromDialog && (
          <p className="text-base font-bold text-primaryText">
            {t("personal_info") ?? ""}
            {/* <span className="text-xs text-primary font-medium">
              ({t("private_to_you") ?? ""})
            </span> */}
          </p>
        )}
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <CustomInput
              label={t("email_placeholder") ?? ""}
              required={true}
              placeholder={t("email_placeholder") ?? ""}
              register={register("email")}
              disabled={datas?.is_email_verified}
              error={t(errors.email?.message ?? "")}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <CustomInput
              label={t("mobile_number_placeholder") ?? ""}
              placeholder={t("mobile_number_placeholder") ?? ""}
              register={register("phone")}
              error={t(errors.phone?.message ?? "")}
              required={true}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <Controller
              control={control}
              name="gender_id"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SelectField
                  onChange={onChange}
                  data={(dropData && dropData["gender"]) ?? [{}]}
                  label={t("gender_label") ?? ""}
                  value={value ?? ""}
                  placeholder={t("gender_label") ?? ""}
                  fullWidth
                  className="customSelectField"
                  error={t(errors.gender_id?.message ?? "") ?? ""}
                />
              )}
            />
            {/* <p className="m-0 ml-1 text-sm text-bgError">
              {errors?.gender?.message}
            </p> */}
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <div>
              <DatePicker
                label={t("dob_label") ?? ""}
                placeholder="mm/dd/yyyy"
                value={date}
                handleDateChange={(val) => handleDateChange(val)}
                fullWidth
                register={register("date_of_birth")}
                error={t(errors.date_of_birth?.message ?? "")}
                className={errors.date_of_birth ? "dateError" : ""}
                required={true}
              />
            </div>
          </div>
          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <Controller
              control={control}
              name="nationality"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                // <SelectField
                //   // onChange={onChange}
                //   onChange={(e) => handleNationalityChange(e)}
                //   data={(dropData && dropData["nationality"]) ?? [{}]}
                //   label={t("country_label") ?? ""}
                //   // value={getValues("nationality_id") ?? ""}
                //   value={nationalityList ?? ""}
                //   placeholder={t("select_country_placeholder") ?? ""}
                //   fullWidth
                //   className="customSelectField"
                //   error={errors.nationality_id?.message}
                //   required={true}
                // />

                <AutoComplete
              value={value ?? []}
              labelName={t("country_label") ?? ""}
              getData={getNationalityData}
              handleChange={(e) => handleNationalityChange(e)}
              startIcon={<AiOutlineSearch />}
              // type="tagSelect"
              placeholder={t("select_country_placeholder") ?? ""}
              required={true}
              error={ (errors?.nationality?.name?.message || errors?.nationality?.message ) && t('nationality_required_femsg') }
              // error={errors?.nationality?.name?.message ?? errors?.nationality?.message}
              data={
                dropData &&
                dropData["nationality"]?.map((data: any) => {
                  return {
                    name: data?.name,
                    id: data?.id,
                  };
                })
              }
            />
              )}
            />
          </div>
          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <Controller
              control={control}
              name="state_id"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <>
                  <SelectField
                    // onChange={onChange}
                    onChange={(e) => handleStateChange(e)}
                    data={stateListData ?? [{}]}
                    // value={value ?? ""}
                    value={stateList ?? ""}
                    label={t("stategovernorate_label") ?? ""}
                    placeholder={t("stategovernorate_placeholder") ?? ""}
                    fullWidth
                    className="customSelectField"
                    error={t(errors.state_id?.message ?? "") ?? ""}
                  />
                </>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <CustomInput
              label={t("cityarea_label") ?? ""}
              placeholder={t("cityarea_placeholder") ?? ""}
              register={register("city")}
              error={t(errors.city?.message ?? "")}
              // required={true}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
            <LocationAutoComplete
              value={getValues("current_location")}
              setValue={setMapValue}
              label={t("current_location_label") ?? ""}
              placeholer={t("current_location_placeholder" ?? "") ?? ""}
            />
          </div>
          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <Controller
              control={control}
              name="is_handicapped"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <RadioButton
                  onChange={onChange}
                  label={t("handicapped_qn_label") ?? ""}
                  value={getValues("is_handicapped")}
                  className="radioBtnText"
                />
              )}
            />
            <p className="m-0 ml-1 text-sm text-bgError">
              {t(errors?.is_handicapped?.message ?? "")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
