const LOGIN_URL = "login";
const REGISTER = "register";
const FORGOTPASSWORD = "forgot-password";
const DUPLICATE_USER = "check-duplicate-user?user_name=";
const PROFILE = "/get-userprofile";
const VERIFY_EMAIL = "/send-verify-mail";
const UNIVERSAL_SEARCH = "search";
const PROFILE_CALCULATION = "/user/percentage-calculation";
const COMPANY_CALCULATION = "/company/percentage-calculation";
const VERIFY_EMAIL_ACCOUNT = "/verify-account";
const RESET_PASSWORD = "/reset-password";
const USER_PROFILE_VERIFICATION = "/get-profile";
const BOOKMARKED = "/favourited";
const RESET_TOKEN_VERIFY = "/verify-token";
const REPORT = "/report";
const STATE_LIST = "/country";
const NOTIFICATION_LIST = "/notification/notifications?user_id=";
const NOTIFICATION_CLEAR = "/notifications/clear-notification";
const NOTIFICATION_CLEAR_ALL = "/notifications/clear-all";
const GOOGLLOGIN = "/auth/google";
const GOOGLECALLBACK = "/callback/google";
const CONTACTUS = "/contact-us"
const apiUrl = {
  LOGIN_URL,
  REGISTER,
  PROFILE,
  DUPLICATE_USER,
  FORGOTPASSWORD,
  VERIFY_EMAIL,
  UNIVERSAL_SEARCH,
  PROFILE_CALCULATION,
  COMPANY_CALCULATION,
  VERIFY_EMAIL_ACCOUNT,
  RESET_PASSWORD,
  USER_PROFILE_VERIFICATION,
  BOOKMARKED,
  RESET_TOKEN_VERIFY,
  REPORT,
  STATE_LIST,
  NOTIFICATION_LIST,
  NOTIFICATION_CLEAR,
  GOOGLLOGIN,
  NOTIFICATION_CLEAR_ALL,
  GOOGLECALLBACK,
  CONTACTUS,
};

export default apiUrl;
