import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

type Props = {
  onAppear: () => void;
};

export const useObserveIntersection = (props: Props) => {
  const { onAppear } = props;

  const { inView, ref } = useInView();

  useEffect(() => {
    if (inView) {
      onAppear();
    }
  }, [inView]);

  return { ref };
};
