import { Button, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { AiFillFilePdf, AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai';

import DeleteModal from '../../components/dialogComponents/deleteComponent';
import useBrowserWidth from '../../utilities/responsiveHook';
import styles from './index.module.scss';

type Props = {
  className?: string;
  fileType?: string;
  file: any;
  size?: string;
  disabled?: boolean;
  setFile: (file: any) => void;
  buttonText?: string;
  multiple?: boolean;
  error?: any;
  uploadedFile?: any;
  handleRemoveUploadedFile?: (id: string, deleteData?: number) => void;
  fileInfo?: string;
  required?: boolean;
  updateFile?: number;
  disableDelete?: boolean;
};

type Accept = {
  [key: string]: string[];
};

function Dropzone(props: Props) {
  const {
    file,
    setFile,
    fileType,
    size,
    error,
    buttonText,
    multiple,
    uploadedFile,
    handleRemoveUploadedFile,
    disabled,
    fileInfo,
    required,
    updateFile,
    disableDelete,
  } = props;
  const [previoiseFiles, setPreviousFiles] = useState<any>(uploadedFile);
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteData, setDeleteData] = useState<number>();
  const [deleteId, setDeleteId] = useState('');
  const [count, setCount] = useState(1);
  const { isBrowser, isMobile } = useBrowserWidth();

  const onDrop = useCallback((acceptedFiles: any) => {
    if (multiple) {
      acceptedFiles.map((item: any) => (
        <>{setFile((prev: any) => (prev ? [item, ...prev] : [item]))}</>
      ));
    } else {
      setFile([...acceptedFiles]);
    }
  }, []);

  const fileTypes: Accept =
    fileType == 'DOC, DOCX, PDF'
      ? {
          'application/pdf': [],
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            [],
        }
      : fileType == 'SVG, PNG, PDF, Doc, Word, Excel'
      ? {
          'image/*': ['.jpeg', '.png', '.svg'],
          'application/pdf': [],
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            [],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            [],
        }
      : fileType == 'PNG, PDF, Doc, Word, Excel'
      ? {
          'image/*': ['.png'],
          'application/pdf': [],
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            [],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            [],
        }
      : {
          'image/*': ['.jpeg', '.png'],
          'application/pdf': [],
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            [],
        };

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: multiple,
    accept: fileTypes,
    maxSize: 5242880,
  });

  let fileRejectionItems = fileRejections.map(({ file, errors }) => {
    if (errors) {
      if (file.size > 5242880) {
        return `( ${t('max_placeholder') ?? ''} ${size})`;
      } else {
        if (fileType == 'DOC, DOCX, PDF') {
          return (
            t('useridenity_doc_formats_femsg') ??
            `Only ${fileType} files are accepted`
          );
          // return `Only ${fileType} files are accepted`;
        }
      }
    } else return undefined;
  });

  const handleDelete = (index: number, id?: string) => {
    setDeleteData(index);
    if (id) {
      setDeleteId(id);
      setDeleteModal(true);
    } else {
      handleRemoveFile();
    }
  };

  const handleRemoveFile = () => {
    if (file && file?.length > 1) {
      const tempArray = file?.filter((item: any, i: any) => {
        return i !== deleteData;
      });
      setFile(tempArray);
    } else {
      setFile(null);
    }
    if (deleteId) {
      handleRemoveUploadedFile &&
        handleRemoveUploadedFile(deleteId, deleteData);
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (!multiple) {
      setPreviousFiles(file?.length > 0 ? file : uploadedFile);
    } else {
      let datas = [];
      if (file) {
        datas = [...file, ...uploadedFile];
      } else {
        datas = [...uploadedFile];
      }
      setPreviousFiles(datas);
      setCount(count + 1);
    }
  }, [file, uploadedFile, updateFile]);
  const handleOpenFile = (file: any) => {
    const imageName = file?.name;

    const a = document.createElement('a');
    let url = '';
    if (file.id) {
      url = file.path;
    } else {
      url = URL.createObjectURL(file);
    }
    a.href = url;
    a.target = '_blank';
    a.download = imageName;
    a.click();
  };
  return (
    <>
      <div
        className={` bg-dropzoneBg border-dashed border border-formBorder rounded-lg w-full p-4 flex
        items-center ${
          isMobile ? 'flex-col justify-center gap-2' : 'justify-between gap-7'
        }`}
      >
        <div {...getRootProps({ className: 'dropzone flex-1' })}>
          <div
            className={`flex items-center flex-1 justify-between ${
              isMobile ? 'flex-col text-center gap-2' : 'gap-7'
            }`}
          >
            <div className="flex flex-col flex-1">
              <p className="text-sm font-bold text-primaryText">
                {/* {t("drag_and_drop") ?? ""} */}
                {t(fileInfo ?? 'drag_and_drop')}{' '}
                {required ? <span className="text-required"> * </span> : ''}
              </p>
              <p className="text-xs ">{fileType}</p>
              <p className="text-xs font-medium text-primary">
                ({t('max_placeholder') ?? ''} {size})
              </p>
            </div>
            <div className="text-[#A9A8A8] text-sm font-bold">
              {t('drag_and_drop') ?? ''}
            </div>
            <div className="text-secondaryText text-sm font-bold">
              {t('or_label') ?? ''}
            </div>
            <input className="input-zone" {...getInputProps()} />
          </div>
        </div>
        <div className={styles.dropzoneButton}>
          <Button
            variant="contained"
            disabled={disabled}
            onClick={open}
            className={`primaryButton bg-primary text-primary h-10 text-sm normal-case p-2.5 rounded-md shadow-none gap-1.5`}
          >
            <AiOutlineCloudUpload className="text-bgWhite w-5 h-5" />
            <span className="text-bgWhite"> {buttonText}</span>
          </Button>
        </div>
      </div>

      <div className="mt-4">
        <div>
          <ul className="w-full flex flex-col gap-4">
            {previoiseFiles?.map((item: any, index: number) => (
              <li
                key={`File_${index}`}
                className={`flex items-center justify-between gap-1.5 cursor-pointer rounded-md bg-[#F9F9F9] group ${
                  isMobile ? 'p-2' : 'p-3'
                }`}
              >
                <div
                  className="flex items-center gap-1.5"
                  onClick={() => (item?.path ? handleOpenFile(item) : '')}
                >
                  <span>
                    <AiFillFilePdf className="w-4 text-primaryText" />
                  </span>
                  <span
                    className={` text-primaryText font-medium flex-1 group-hover:text-primary break-all ${
                      isMobile ? 'text-xs' : 'text-sm'
                    }`}
                  >
                    {item.name}
                  </span>
                </div>
                {disableDelete ? (
                  <span className="text-sm">
                    <button
                      className="px-2 py-1 text-xs font-semibold bg-primary text-bgWhite rounded-lg"
                      disabled={disabled}
                      onClick={open}
                    >
                      {t('change_button_data') ?? ''}
                    </button>
                  </span>
                ) : (
                  <span className="text-sm">
                    <IconButton
                      className="p-0"
                      disabled={disabled}
                      onClick={(e) => handleDelete(index, item?.id)}
                    >
                      <AiOutlineClose className="w-4 text-primaryText" />
                    </IconButton>
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
        <DeleteModal
          open={deleteModal}
          setOpen={setDeleteModal}
          title="Delete File"
          subTitle="Are you sure to delete this file?"
          description="Deleted file cannot be retrieved"
          // handleRefresh={handleRefresh}
          handleDelete={handleRemoveFile}
        />
      </div>
      {(fileRejectionItems || error) && (
        <p className="m-0  text-bgError text-xs">
          {fileRejectionItems?.length === 0 ? t(error) : fileRejectionItems}
        </p>
      )}
    </>
  );
}
export default Dropzone;
