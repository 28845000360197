import { useTranslation } from "react-i18next";
import { AiFillExclamationCircle } from "react-icons/ai";

import CustomButton from "../../customComponents/button/index";
import CustomDialogBox from "../../customComponents/dialogbox/index";

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  handleRefresh?: () => void;
  title: string;
  subTitle?: string;
  description: string;
  handleAction: () => void;
};
export default function DeleteProposal(props: Props) {
  const {
    open,
    setOpen,
    handleRefresh,
    title,
    subTitle,
    description,
    handleAction,
  } = props;
  const { t } = useTranslation();

  return (
    <CustomDialogBox
      open={open}
      title={title}
      subtext={subTitle}
      maxWidth={"xs"}
      handleClose={() => setOpen(false)}
      className="chooseProfileModal"
      dialogActions={
        <div className="w-full relative flex justify-between items-center gap-3">
          <CustomButton
            onClick={() => {
              setOpen(false);
            }}
            className="secondaryButton w-full"
            title={t("cancel_button") ?? ""}
          />
          <CustomButton
            onClick={() => {
              handleAction();
            }}
            className=" w-full primaryButton disabled:opacity-50"
            title={t("go_to_dawamjobs_button") ?? ""}
          />
        </div>
      }
    >
        <div className="flex flex-col gap-3.5">
          <div className="flex rounded-md p-4 gap-2 bg-activeStateBg   text-primary">
            <AiFillExclamationCircle className="w-4 h-4 mt-1" />

            <div className="flex-1 text-sm">
              <p className="">
                {description}
              </p>
            </div>
          </div>
        </div>
    </CustomDialogBox>
  );
}
