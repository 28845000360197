import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlus } from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import LanguageSwitcher from '../customComponents/languageSwitcher';
import { useAuthStore } from '../store/authStore';
import { useCompanySwitchStore } from '../store/companySwitch';
import useBrowserWidth from '../utilities/responsiveHook';

type Props = {
  anchorEl: any;
  postProject: () => void;
  profileDropdown: (e: any) => void;
  handleClose: () => void;
  useData: any;
};

export default function HeaderMobileMenu(props: Props) {
  const { anchorEl, handleClose, useData, postProject, profileDropdown } =
    props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState(localStorage.getItem('avatar'));
  const { companyDetail, setCompanyDetail, clearCompany } =
    useCompanySwitchStore();
  const { authenticated } = useAuthStore();
  const { isBrowser, isMobile } = useBrowserWidth();

  useEffect(() => {
    const handleStorage = () => {
      setAvatar(localStorage.getItem('avatar'));
    };

    window.addEventListener('storage', () => handleStorage());
    return () => window.removeEventListener('storage', () => handleStorage());
  });
  useEffect(() => {
    const temp = useData?.companies?.find(
      (item: any) => item?.id === companyDetail?.id
    );
    temp && setCompanyDetail(temp);
  }, [useData]);
  const postAction = () => {
    postProject();
    handleClose();
  };
  const trimLang: string = (
    localStorage.getItem('i18nextLng') ?? 'en'
  ).substring(0, 2);
  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className={isMobile ? 'customDropdowm mobileDropdown' : 'customDropdowm'}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      dir={trimLang === 'Ar' || trimLang === 'ar' ? 'rtl' : ''}
    >
      <MenuList className="!p-0">
        {authenticated && (
          <MenuItem
            onClick={(e) => profileDropdown(e)}
            className="hover:bg-menuHover"
          >
            <ListItemIcon>
              <BiUserCircle className="p-0  text-primaryText w-5 h-5" />
            </ListItemIcon>
            <ListItemText>
              <span className="text-sm">{t('Profile') ?? ''}</span>
            </ListItemText>
          </MenuItem>
        )}
        {authenticated && (
          <MenuItem className="hover:bg-menuHover" onClick={() => postAction()}>
            <ListItemIcon>
              <AiOutlinePlus className="p-0  text-primaryText w-5 h-5" />
            </ListItemIcon>
            <ListItemText>
              <span className="text-sm">
                {t('home_post_project_btn') ?? ''}
              </span>
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem className="hover:bg-menuHover">
          <ListItemText>
            <div className="flex gap-2 items-center">
              <LanguageSwitcher />
            </div>
          </ListItemText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
