import { create } from "zustand";
import { persist } from "zustand/middleware";

type AuthStoreType = {
  companyDetail: any | undefined;
  setCompanyDetail: (authenticated: any | undefined) => void;
  clearCompany: () => void;
};

export const useCompanySwitchStore = create<AuthStoreType>()(
  persist(
    (set) => ({
      companyDetail: undefined,
      setCompanyDetail: (companyData) =>
        set(() => ({ companyDetail: companyData })),
      clearCompany: () => set(() => ({ companyDetail: undefined })),
    }),
    { name: "switched_company" }
  )
);
