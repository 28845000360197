import { z } from "zod";

export const registerSchema = z.object({
  name: z
    .string()
    .min(3, { message: "name_least_characters_femsg" }),
  password: z
    .string()
    .regex(new RegExp(/^[^\s]*$/), {message:"space_not_allowed_femsg"})
    .regex(new RegExp(".*[A-Z].*"), {message:"password_validation_femsg"})
    .regex(new RegExp(".*[a-z].*"), {message:"password_validation_femsg"})
    .regex(new RegExp(".*\\d.*"), {message:"password_validation_femsg"})
    .regex(
      new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"),
      {message:"password_validation_femsg"}
    )
    .min(8, {message: "musthav8_characters_femsg"}),
  user_name: z.string().min(1, { message: "please_enter_username_femsg" }),
  phone: z
    .string()
    .regex(
      new RegExp(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      ),
      "enter_valid_mobile_number_femsg"
    ).min(8, { message: "phone_atleast_8characters_femsg" })
    .max(15, { message: "enter_valid_mobile_number_femsg" }),
  email: z
    .string()
    .email({ message: "enter_a_valid_email_femsg" })
    .min(1, { message: "email_no_empty_femsg" }),
});

export type RegisterSchema = z.infer<typeof registerSchema>;
