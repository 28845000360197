import { ProfileVerification } from "../../common/types";

const handleCheckPostajob = (data: ProfileVerification, count: string) => {
  let checkFlag = true;
  const datas = {
    is_deal_breaker: data?.is_deal_breaker,
    is_profile_completed: data?.is_profile_completed,
    is_mandatory_fields: data?.is_mandatory_fields,
    is_email_verified: data?.is_email_verified,
    is_valid_plan: data?.is_valid_plan,
  };
  Object.keys(datas).forEach((key) => {
    if (!datas[key as keyof typeof datas]) {
      checkFlag = false;
    }
  });
  if (checkFlag && data[count as keyof typeof data]) {
    return true;
  } else {
    return false;
  }
};

const handleCheckProject = (data: ProfileVerification, count: string) => {
  let checkFlag = true;

  const datas = {
    is_deal_breaker: data?.is_deal_breaker,
    is_profile_completed: data?.is_profile_completed,
    is_mandatory_fields: data?.is_mandatory_fields,
    is_bank_details: data?.is_bank_details,
    is_email_verified: data?.is_email_verified,
    is_valid_plan: data?.is_valid_plan,
  };
  Object.keys(datas).forEach((key) => {
    if (!datas[key as keyof typeof datas]) {
      checkFlag = false;
    }
  });

  if (checkFlag && data.post_project_count > 0) {
    return true;
  } else {
    return false;
  }
};
const handleCheckApplyProject = (
  data: ProfileVerification,
  count: string,
  is_Expert?: boolean
) => {
  let checkFlag = true;

  const datas = {
    is_profile_completed: data?.is_profile_completed,
    is_mandatory_fields: data?.is_mandatory_fields,
    is_bank_details: data?.is_bank_details,
    is_email_verified: data?.is_email_verified,
    has_remaining_apply_project_count: data?.has_remaining_apply_project_count,
    is_project_expert: is_Expert ? data?.is_project_expert : true,
  };
  Object.keys(datas).forEach((key) => {
    if (!datas[key as keyof typeof datas]) {
      checkFlag = false;
    }
  });

  if (checkFlag && data.apply_project_count > 0) {
    return true;
  } else {
    return false;
  }
};
export const handleProfileValidation = (
  data: ProfileVerification,
  validationType: string,
  is_Expert?: boolean
) => {
  switch (validationType) {
    case "postProject":
      return handleCheckProject(data, "post_project_count");
    case "postJob":
      return handleCheckPostajob(data, "post_job_count");
    case "sendProposal":
      return handleCheckApplyProject(data, "apply_project_count", is_Expert);
    case "applyJob":
      return handleCheckProject(data, "apply_job_count");
    default:
      return false;
  }
};
