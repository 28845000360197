import { z } from "zod";

export const bankAccountSchema = z.object({
  bank_name: z.string().trim().min(1, { message: "bank_name_required_femsg" }),
  branch_name: z
    .string()
    .trim()
    .min(1, { message: "branch_name_required_femsg" }),
  account_number: z
    .string({ required_error: "account_number_required_femsg" })
    .trim()
    .min(8, { message: "account_no_betwn_message" })
    .max(20, { message: "account_no_betwn_message" }),
  account_holder_name: z
    .string()
    .min(1, { message: "name_in_account_femsg" })
    .max(35, { message: "less_than_36char_femsg" })
    .regex(new RegExp(/^[A-Za-z0-9 ]+$/), { message: "no_special_char_femsg" }),
  bank_address: z.string().optional(),
  id: z.string().optional(),
  is_primary: z.boolean().optional(),
  IBAN_number: z

    .string()
    // .string({ invalid_type_error: "iban_number_required_femsg" })
    // .min(1, { message: "iban_number_required_femsg" })
    // .regex(
    //   new RegExp(
    //     /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/
    //   ),
    //   { message: "invalid_iban_code_femsg" }
    // )
    
    .optional()
    .transform((e) => (e === "" ? undefined : e))
    .nullable(),
  swift_code: z
    .string({ invalid_type_error: "swift_number_required_femsg" })
    .min(1, { message: "swift_number_required_femsg" })
    .regex(
      new RegExp(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/),
      "invalid_swift_code_femsg"
    ),
})
.superRefine((value, ctx) => {
  if (value?.IBAN_number) {
    if ( !(/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(value.IBAN_number)) ) {
        ctx.addIssue({
          message: "invalid_iban_code_femsg",
          code: z.ZodIssueCode.custom,
          path: ["IBAN_number"],
        });
    }
  }
})
;
export type BankAccountSchema = z.infer<typeof bankAccountSchema>;
