import { create } from "zustand";

type SearchStore = {
  searchKey: string | undefined;
  setSearchKey: (search: string | undefined) => void;
};

export const useSearchStore = create<SearchStore>()((set) => ({
  searchKey: undefined,
  setSearchKey: (search) => set(() => ({ searchKey: search })),
}));
