import { useTranslation } from "react-i18next";
import { AiFillExclamationCircle } from "react-icons/ai";

import CustomButton from "../../customComponents/button/index";
import CustomDialogBox from "../../customComponents/dialogbox/index";

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  handleRefresh?: () => void;
  title: string;
  subTitle: string;
  description: string;
  handleDelete: () => void;
};
export default function DeleteProposal(props: Props) {
  const {
    open,
    setOpen,
    handleRefresh,
    title,
    subTitle,
    description,
    handleDelete,
  } = props;
  const { t } = useTranslation();

  return (
    <CustomDialogBox
      open={open}
      title={title}
      subtext={subTitle}
      maxWidth={"xs"}
      handleClose={() => setOpen(false)}
      className="chooseProfileModal"
      dialogActions={
        <div className="w-full relative flex justify-between items-center gap-3">
          <CustomButton
            onClick={() => {
              setOpen(false);
            }}
            className="secondaryButton w-full"
            title={t("cancel_button") ?? ""}
          />
          <CustomButton
            onClick={() => {
              handleDelete();
            }}
            className=" w-full bg-warning disabled:opacity-50"
            title={t("delete_button") ?? ""}
          />
        </div>
      }
    >
      <div className="flex flex-col gap-3.5 ">
        <div className="flex rounded-md p-4 gap-2 bg-warningBg text-xs  text-warning ">
          <AiFillExclamationCircle className="  text-2xl w-10 justify-start" />

          <p className=" gap-2  text-sm">{description}</p>
        </div>
      </div>{" "}
    </CustomDialogBox>
  );
}
