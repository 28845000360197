import { IconButton } from '@mui/material';
import { useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { HiBuildingOffice2 } from 'react-icons/hi2';

import { profileStore } from '../../store/profileStore';
import useBrowserWidth from '../../utilities/responsiveHook';
import { UserContext } from '../detailComponent/dataContext';
import AddExperience from './addExperience';
import { ExperienceSchema } from './addExperience/schema';
import { AiFillDelete } from "react-icons/ai";
import DeleteModal from '../../components/dialogComponents/deleteComponent';
import { deleteUserExperience } from '../../pages/profile/apis/api';
import { useSnackbar } from 'notistack';

type Props = {
  handleRefresh: () => void;
  jobDetails: ExperienceSchema[] | any[];
  editMode?: boolean;
  readOnly?: boolean;
};

export default function Experience(props: any) {
  const { handleRefresh, jobDetails, editMode, readOnly } = props;
  const [jobDetailsData, setJobDetailsData] = useState<any>([]);
  const { openExperienceModel, setExperienceModel } = profileStore();
  const [mode, setMode] = useState<any>('Add');
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const [deleteModal, setDeleteModal] = useState(false);
  const [experienceId, setExperienceId] = useState<string>('');
  const { t } = useTranslation();

  const { dropData, setDropData } = useContext(UserContext);

  const handleOpen = () => {
    setExperienceModel(true);
    setMode('Add');
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleEdit = (id: string) => {
    const data = jobDetails && jobDetails?.find((item: any) => item?.id === id);
    setMode('Edit');
    setJobDetailsData(data);
    setExperienceModel(true);
  };

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setExperienceId(id);
  };
  const deleteExperienceData = async () => {
    deleteUserExperience(experienceId)
      .then((res) => {
        props.handleRefresh();
        setDeleteModal(false);
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: 'error',
        });
      });
  };

  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <>
      {!editMode ? (
        <div className="flex items-center mb-4 justify-between">
          <p className="text-base font-bold text-primaryText">
            {t('experience_heading') ?? ''}
          </p>

          <IconButton className="p-0 w-5" onClick={(e) => handleOpen()}>
            <BiPlus className="p-0  text-primaryText w-5" />
          </IconButton>
        </div>
      ) : (
        <div
          className={`flex justify-between items-center ${
            isMobile ? 'mb-0' : 'mb-4'
          }`}
        >
          <p className="text-lg font-bold text-primaryText">
            {t('experience_heading') ?? ''}
          </p>
          <div className="flex items-center gap-4">
            {!readOnly && (
              <IconButton
                disableRipple
                onClick={(e) => handleOpen()}
                className="p-0 w-5  "
              >
                <BiPlus className="p-0  text-primaryText w-5" />
              </IconButton>
            )}
          </div>
        </div>
      )}
      {jobDetails &&
        jobDetails?.length > 0 &&
        jobDetails?.map((item: any) => (
          <>
            {isMobile ? (
              <div
                key={item.id}
                className="flex items-stretch relative experienceCard gap-4"
              >
                <div className="flex flex-1 items-start gap-3 ">
                  <div className="w-10 h-10 bg-[#F7F7F7] border border-[#E8E8E8] rounded-lg flex items-center justify-center">
                    <HiBuildingOffice2 className="text-[#A9A8A8]" />
                  </div>
                  <div className="flex-1">
                    <div className="">
                      <span className="text-sm text-primaryText font-bold ">
                        {item.designation ?? 'designation'}
                      </span>
                      <span className="tagStyle normalTag  border-t border-b border-[#D6D8DC] !bg-[#EFEFEF] ml-4 top-[2px]">
                        <span className="tag text-[10px] text-primaryText font-semibold">
                          {item.employment_type ??
                            t('employment_type_label') ??
                            ''}
                        </span>
                      </span>
                    </div>
                    <div className="flex flex-wrap gap-x-2">
                      <div className="flex flex-wrap gap-x-2">
                        <span className="text-sm text-primaryText font-medium">
                          {item.company ?? 'company'}
                        </span>
                        <span className="text-sm text-primaryText">|</span>
                        <span className="text-sm text-primaryText font-medium">
                          {item.industry?.name ?? t('industry_label') ?? ''}
                        </span>
                        <span className="text-sm text-primaryText dotBlock"></span>
                      </div>
                      <div className="flex flex-wrap gap-x-2">
                        <span className="text-sm text-primaryText ">
                          {item.total_experience}
                        </span>
                        <span className="text-sm text-primaryText dotBlock"></span>
                        <span className="text-sm text-primaryText ">
                          {` ${month[parseInt(item.start_month, 10) - 1]} ${
                            item.start_year
                          } -`}
                          {` ${month[parseInt(item.end_month, 10) - 1]} ${
                            item.end_year
                          }`}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2 mb-1">
                      <span className="text-sm text-secondaryText">
                        {item.location ?? 'location'}
                      </span>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      <span className="text-sm text-secondaryText">
                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Venenatis cras quam senectus rutrum in vestibul u m ut. Suspen
                disse mi eu viverra ac mi blandit parturient. Metus tellus,
                mauris, est enim */}
                        {item.job_role_description}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="relative ">
                  {!readOnly && (
                    <IconButton
                      disableRipple
                      onClick={() => handleEdit(item.id)}
                      className="p-0 w-5"
                    >
                      <HiOutlinePencilAlt className="p-0  text-primaryText w-4" />
                    </IconButton>
                    )}
                    {!readOnly && (
                  
                    <IconButton
                    disableRipple
                    onClick={() => handleDelete(item.id)}
                    className="p-0 w-5"
                  >
                    <AiFillDelete className="p-0  text-primaryText w-4" />
                  </IconButton>
                )}
                </div>
              </div>
            ) : (
              <div
                key={item.id}
                className="flex items-stretch relative experienceCard gap-8"
              >
                <div className="flex flex-col min-w-[130px] pb-7 ">
                  <p className="text-primaryTextext text-sm font-medium">
                    {` ${month[parseInt(item.start_month, 10) - 1]} ${
                      item.start_year
                    } -`}
                    {` ${month[parseInt(item.end_month, 10) - 1]} ${
                      item.end_year
                    }`}

                    {/* { item.end_date} */}
                  </p>
                  <p className="text-secondaryText text-sm">
                    {/* 1 year 2 months */}
                    {item.total_experience}
                    {/* {` ${item.total_experience} ${
                  item.total_experience > 1 ? "years" : "year"
                }` ?? "Total experience"} */}
                  </p>
                </div>
                <div className="dividerSection relative flex justify-center pb-7 ">
                  <span className="bg-[#D6D8DC] w-3 h-3 rounded-full"></span>
                </div>
                <div className="flex flex-1 items-start gap-3 pb-7 ">
                  <div className="w-10 h-10 bg-[#F7F7F7] border border-[#E8E8E8] rounded-lg flex items-center justify-center">
                    <HiBuildingOffice2 className="text-[#A9A8A8]" />
                  </div>
                  <div className="flex-1">
                    <div className=" gap-2">
                      <p className="text-sm text-primaryText font-bold inline-block">
                        {item.designation ?? 'designation'}
                        {item.employment_type && (
                          <span className="tagStyle normalTag  border-t border-b border-[#D6D8DC] !bg-[#EFEFEF] ml-4 top-[1px]">
                            <span className="tag text-[10px] text-primaryText font-semibold ">
                              {item.employment_type ??
                                t('employment_type_label') ??
                                ''}
                            </span>
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      <span className="text-sm text-primaryText font-medium">
                        {item.company ?? 'company'}
                      </span>

                      <span className="text-sm text-primaryText">|</span>
                      <span className="text-sm text-primaryText font-medium">
                        {item.industry?.name ?? '- -' ?? ''}
                      </span>
                    </div>
                    <div className="flex flex-wrap gap-2 mb-1">
                      <span className="text-sm text-secondaryText">
                        {item.location ?? 'location'}
                      </span>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      <span className="text-sm text-secondaryText">
                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Venenatis cras quam senectus rutrum in vestibul u m ut. Suspen
                disse mi eu viverra ac mi blandit parturient. Metus tellus,
                mauris, est enim */}
                        {item.job_role_description}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="relative pb-7 ">
                  {!readOnly && (
                    <IconButton
                      disableRipple
                      onClick={() => handleEdit(item.id)}
                      className="p-0 w-5"
                    >
                      <HiOutlinePencilAlt className="p-0  text-primaryText w-4" />
                    </IconButton>
                  )}
                  {!readOnly && (
                    <IconButton
                    disableRipple
                    onClick={() => handleDelete(item.id)}
                    className="p-0 w-5"
                  >
                    <AiFillDelete className="p-0  text-primaryText w-4" />
                  </IconButton>
                  )}
                </div>
              </div>
            )}
          </>
        ))}

      <AddExperience
        handleRefresh={handleRefresh}
        defaultValues={mode == 'Add' ? {} : jobDetailsData}
        mode={mode}
        detailsId={jobDetailsData?.id ?? ''}
      />
      <DeleteModal
        open={deleteModal}
        setOpen={setDeleteModal}
        title={t("delete_experience") ?? ""}
        subTitle={t("delete_experience_text") ?? ""}
        description={t("delete_experience_description") ?? ""}
        handleRefresh={handleRefresh}
        handleDelete={deleteExperienceData}
      />
    </>
  );
}
