import { create } from "zustand";

type Action = {
  action: string;
  setAction: (val: string) => void;
};
export const useAuthAction = create<Action>()((set) => ({
  action: "none",
  setAction: (val) => set(() => ({ action: val })),
}));

type sendProposal = {
  openProposal: boolean;
  setSendProposal: (search: boolean) => void;
  workId: string;
  setWorkId: (val: string) => void;
};

export const useSendProposal = create<sendProposal>()((set) => ({
  openProposal: false,
  workId: "",
  setSendProposal: (value) => set(() => ({ openProposal: value })),
  setWorkId: (id) => set(() => ({ workId: id })),
}));
