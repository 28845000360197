import { useAuthStore } from "../../store/authStore";
import Layout from "../../layout/userLayout";
import { useLoaderStore } from "../../store/loaderStore";

type Props = {
  children: React.ReactNode;
};

const GuestRoute = ({ children }: Props) => {
  const { isLoading } = useLoaderStore();
  const authenticated = useAuthStore((state) => state.authenticated);

  return (
    <Layout>
      <>{children}</>
    </Layout>
  );
};

export default GuestRoute;
