import { useTranslation } from "react-i18next";

import CustomButton from "../../customComponents/button";
import CustomDialogBox from "../../customComponents/dialogbox";

type Props = {
  openMessage: boolean;
  flag: boolean;
  setFlag?: (t: any) => void;
  setOpenMessage?: (t: any) => void;
};

export default function MessagePopUp(props: Props) {
  const { t } = useTranslation();

  const handleClose = () => {
    if (props.setOpenMessage) {
      props.setOpenMessage(false);
    }
  };

  return (
    <>
      {props.flag ? (
        <CustomDialogBox
          open={props.openMessage}
          title={t("Contactus_success") ?? ""}
          maxWidth={"xs"}
          children={undefined}
          handleClose={handleClose}
          subtext={t("contactus_success_msg") ?? ""}
          dialogActions={
            <div className="flex items-center justify-between gap-3 w-full">
              <CustomButton
                title={t("ok_button") ?? ""}
                className=" primaryButton w-full "
                onClick={() => handleClose()}
              />
            </div>
          }
        />
      ) : (
        <CustomDialogBox
          open={props.openMessage}
          title={t("Contactus_failed") ?? ""}
          maxWidth={"xs"}
          children={undefined}
          handleClose={handleClose}
          dialogActions={
            <div className="flex items-center justify-between gap-3 w-full">
              <CustomButton
                title={t("ok_button") ?? ""}
                className=" w-full bg-warning"
                onClick={() => handleClose()}
              />
            </div>
          }
          subtext={t("contactus_failed_msg") ?? ""}
        />
      )}
    </>
  );
}
