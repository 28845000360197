const SUBSCRIPTION_DETAILS = "/current_subscription_details";
const SUBSCRIPTION_PALNDETAILS = "/plan";
const SUBSCRIPTION_PALNS = "/plan-list";

const SUBSCRIPTION_ADDON_DETAILS = "/addon";
const SUBSCRIPTION_HISTORY = "/purchase_history";
const ADDON_LIST = "/addon-list?type=";
const ADDON_PURCHASE = "/addon-checkout";
const PLAN_PURCHASE = "/plan-checkout";
const APPLY_ADDON = "/apply-addon-to-work";
const apiUrl = {
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTION_HISTORY,
  SUBSCRIPTION_PALNDETAILS,
  SUBSCRIPTION_ADDON_DETAILS,
  ADDON_LIST,
  ADDON_PURCHASE,
  SUBSCRIPTION_PALNS,
  PLAN_PURCHASE,
  APPLY_ADDON,
};

export default apiUrl;
