import { Combobox, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';

import useBrowserWidth from '../../utilities/responsiveHook';

type Props = {
  data: {
    id?: string | number;
    name: string;
  }[];
  value: any;
  setSelected: (data: any) => void;
  keyName?: string;
  fetchData?: (val?: string, search?: string) => void;
  placeholder?: string;
  dropDownKey?: string;
  label?: string;
  isLoading: boolean;
};
type Item = { id: string; [k: string]: string };
export default function FilterAutoComplete(props: Props) {
  const {
    data,
    value,
    setSelected,
    fetchData,
    keyName,
    placeholder,
    dropDownKey,
    label,
    isLoading,
  } = props;
  const [query, setQuery] = useState('');
  const { isBrowser, isMobile } = useBrowserWidth();

  useEffect(() => {
    const timerId = setTimeout(() => {
      // data?.length == 0 &&
      fetchData && fetchData(dropDownKey, query);
    }, 350);
    return () => clearTimeout(timerId);
  }, [query]);
  const filteredPeople =
    query === ''
      ? data
      : data.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const handleClear = (e: any) => {
    e.stopPropagation();
    setSelected('');
  };
  return (
    <div className="flex flex-col">
      {label && <label className="text-sm my-1">{label}</label>}
      <Combobox value={value} onChange={setSelected}>
        <div className="relative">
          <div className="relative w-full border border-formBorder cursor-default  overflow-hidden rounded-md text-left  ">
            <Combobox.Input
              className={`w-full	 py-1 pl-2 rounded-md pr-7 rtl:pr-2 rtl:pl-7 text-xs 2xl:text-sm   text-gray-900 outline-none ${
                isMobile ? ' h-10' : ''
              }`}
              displayValue={(item: Item | null) =>
                item ? item[keyName || 'name'] || '' : ''
              }
              placeholder={placeholder}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button
              className="absolute inset-y-0 right-0 rtl:left-0 rtl:right-auto flex items-center pr-2 rtl:pl-2 rtl:pr-0"
              onClick={(e) => (value ? handleClear(e) : console.log(''))}
            >
              <span className=" text-textColor">
                {value ? (
                  <MdClear />
                ) : (
                  <svg
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8334 1.5L7.00008 7.33333L1.16675 1.5"
                      stroke="#585858"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </span>
            </Combobox.Button>
          </div>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="filterDropdownMenu absolute mt-1 max-h-60 z-10 w-full  overflow-auto shadow-lg rounded-md bg-bgWhite py-1 text-base s sm:text-sm">
              {isLoading ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700 z-10">
                  Loading...
                </div>
              ) : (
                <>
                  {filteredPeople.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700 z-10">
                      Nothing found.
                    </div>
                  ) : (
                    filteredPeople.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-2 pr-4 text-sm bg-bgWhite text-sm${
                            active ? '  text-white bg-bgWhite' : 'text-gray-900'
                          }`
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {person.name}
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </>
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
