import { FormControlLabel, MenuItem, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
  label?: string;
  data?: { id: number; label: string }[] | any;
  value?: boolean | null;
  fullWidth?: boolean;
  register?: any;
  placeholder?: string;
  onChange: (val: boolean) => void;
};

const RadioButton = (props: Props) => {
  const { label, className , value, onChange} = props;
  const [val, setValue] = useState("Yes");
  const handleChange = (val: string) => {
    setValue(val);
    const boolValue = val === "Yes" ? true : false;
    onChange(boolValue);
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="formSection customRadioGroup">
        {label && (
          <label className={`block justify-start labelText`}>{label}</label>
        )}
        <div className="">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            className="flex gap-3"
          >
            <FormControlLabel
              value={value}
              checked={value == null ? undefined : value}
              control={<Radio onChange={() => handleChange("Yes")} />}
              label={t("yes_option") ?? ""}
              className={className}
            />
            <FormControlLabel
              value={value}
              checked={value == null ? undefined : !value}
              control={<Radio onChange={() => handleChange("No")} />}
              label={t("no_option") ?? ""}
              className={className}
            />
          </RadioGroup>
        </div>
      </div>
    </>
  );
};

export default RadioButton;
