import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import * as React from 'react';

type Props = {
  star: any;
  readOnly?: boolean;
};
export default function BasicRating(props: Props) {
  const { star, readOnly } = props;
  const [value, setValue] = React.useState<number | null>(star);

  return (
    <Box
      sx={{
        '& > legend': { mt: 2 },
      }}
    >
      <Rating
        name="simple-controlled"
        size="small"
        value={value}
        readOnly={readOnly ?? false}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
    </Box>
  );
}
