import { debounce, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";

import { Nodes } from "../../common/types";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  name?: string;
  placeholder?: string;
  labelName?: string;
  inputValue?: string;
  disabled?: boolean;
  startIcon?: any;
  optionLabel?: string | "";
  value: any;
  required?: boolean;
  getData: (value: string) => void;
  data?: Nodes[];
  handleChange: (value: object) => void;
  error?: object | any;
  emptyString?: string;
  readOnly?: boolean | any;
  type?: string;
  closePopup?:any;
};
type func = {
  event: any;
  newInputValue: string;
  reason: any;
};
type handleProps = {
  event: any;
  value: any;
  reason: string;
  details?: string;
};

const AutocompleteField = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [inputkey, setInputKey] = useState('');

  const {
    className,
    name,
    labelName,
    disabled,
    emptyString,
    readOnly,
    placeholder,
    startIcon,
    type,
    closePopup,
    required
  } = props;

  const handleInputChange = (values: func) => {
    if (values.reason === 'input') {
      setInputKey(values.newInputValue);
      getDatas(values.newInputValue);
    }
  };

  const handleChange = (e: any) => {
    props.handleChange({ ...e });
    if(e && e !== null){
      setInputKey(e.name);
    }
    else{
      setInputKey('');
    }
  };
  const getDatas = debounce((newValue: string) => {
    props.getData(newValue);
  }, 2000);

  useEffect(() => {
      setInputKey('');
  }, [closePopup?.status]);

  return (
    <div className="formSection autoComplete">
      {labelName && (
          <label className={`block justify-start labelText`}>
            {required ? (
              <>
                {labelName}
                <span className="text-required"> * </span>
              </>
            ) : (
              labelName
            )}{" "}
          </label>
        )}
      <div className={styles.inputGroup}>
        {startIcon ? <span className="startAdornment">{startIcon}</span> : ""}
        <Autocomplete
          id="combo-box-demo"
          readOnly={readOnly}
          open={open}
          // inputValue={props.value.name ?? ""}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          disabled={disabled}
          onChange={(event, newValue) => handleChange(newValue)}
          value={props.value ? props.value : ""}
          onInputChange={(event, newInputValue, reason) => {
            handleInputChange({ event, newInputValue, reason });
          }}
          noOptionsText={emptyString ?? "No options found"}
          // inputValue={props.inputValue}
          inputValue={inputkey !== "" ? inputkey : props.value.name ?? "" }
          getOptionLabel={(option: any) => option.name ?? ""}
          options={props.data ?? []}
          disablePortal={true}
          renderInput={(params) => (
            <div>
              {readOnly ? (
                <TextField
                  {...params}
                  label=""
                  inputProps={{ readOnly: true }}
                  className={className}
                  helperText={props.error}
                  error={props.error ? true : false}
                  name={name}
                  placeholder={placeholder}
                />
              ) : (
                <TextField
                  {...params}
                  label=""
                  className={className}
                  helperText={props.error}
                  error={props.error ? true : false}
                  name={name}
                  placeholder={placeholder}
                />
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default AutocompleteField;
