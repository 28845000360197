import { useEffect, useState } from 'react';

type Props = {
  isMobile: boolean;
  isBrowser: boolean;
  inlineWidth: number;
};
const useBrowserWidth = (): Props => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = (): void => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  let viewMode = { isBrowser: true, isMobile: false, inlineWidth: width };
  if (width < 990) {
    viewMode = { isBrowser: false, isMobile: true, inlineWidth: width };
  }
  return viewMode;
};

export default useBrowserWidth;
