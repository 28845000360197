import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';

import useBrowserWidth from '../../utilities/responsiveHook';
import { userProfileTypes } from '../profile/store';

type Props = {
  data?: any;
  handleEdit: (val: string) => void;
  readOnly?: boolean;
};
export default function PersonalInformation(props: Props) {
  const { data, handleEdit, readOnly } = props;
  const [viewMode, setViewMode] = useState({
    email: false,
    phone: false,
    gender: false,
    age: false,
  });
  const { t } = useTranslation();
  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <div
      className={`flex flex-col gap-4 bg-bgWhite border-b border-formBorder ${
        isMobile ? 'p-4' : 'px-7 py-5'
      }`}
    >
      {isMobile ? (
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-start">
            <p className="text-lg font-bold text-primaryText">
              {t('personal_info') ?? ''}
            </p>
            {/* <span className="text-xs text-primary font-medium">
              ({t("private_to_you") ?? ""})
            </span> */}
            <div className="tagStyle border-t w-auto border-b border-tagGreyBorder bg-taggreyBg mt-2">
              <span className="tag text-[10px] text-primaryText font-semibold">
                {t('joined_on') ?? ''}
                {` ${moment(data?.created_at).format('DD/MM/YYYY')}`}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-4">
            {!readOnly && (
              <IconButton
                disableRipple
                onClick={() => handleEdit(userProfileTypes.PERSONAL_INFO)}
                className="p-0   "
              >
                <BiEdit className="p-0  text-primaryText w-5" />
              </IconButton>
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-between items-center">
          <p className="text-lg font-bold text-primaryText">
            {t('personal_info') ?? ''}
            {/* <span className="text-xs text-primary font-medium">
              ({t("private_to_you") ?? ""})
            </span> */}
          </p>
          <div className="flex items-center gap-4">
            <div className="tagStyle border-t border-b border-tagGreyBorder bg-taggreyBg">
              <span className="tag text-[10px] text-primaryText font-semibold">
                {t('joined_on') ?? ''}
                {` ${moment(data?.created_at).format('DD/MM/YYYY')}`}
              </span>
            </div>
            {!readOnly && (
              <IconButton
                disableRipple
                onClick={() => handleEdit(userProfileTypes.PERSONAL_INFO)}
                className="p-0   "
              >
                <BiEdit className="p-0  text-primaryText w-5" />
              </IconButton>
            )}
          </div>
        </div>
      )}

      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-3 md:col-span-2  lg:col-span-2 ">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {viewMode.email ? data?.email ?? '- -' : '######.com'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('email_placeholder') ?? ''}
            <span
              className="cursor-pointer"
              onClick={() =>
                setViewMode({ ...viewMode, email: !viewMode.email })
              }
            >
              {viewMode.email ? (
                <AiFillEye className="w-4 h-4 text-[#A9A8A8]" />
              ) : (
                <AiFillEyeInvisible className="w-4 h-4 text-[#A9A8A8]" />
              )}
            </span>
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2 ">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {viewMode.phone ? data?.phone ?? '- -' : '######'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('mobile_number_placeholder') ?? ''}
            <span
              className="cursor-pointer"
              onClick={() =>
                setViewMode({ ...viewMode, phone: !viewMode.phone })
              }
            >
              {viewMode.phone ? (
                <AiFillEye className="w-4 h-4 text-[#A9A8A8]" />
              ) : (
                <AiFillEyeInvisible className="w-4 h-4 text-[#A9A8A8]" />
              )}
            </span>
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2 ">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {viewMode.gender ? data?.gender?.name ?? '- -' : '######'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('gender_label') ?? ''}
            <span
              className="cursor-pointer"
              onClick={() =>
                setViewMode({ ...viewMode, gender: !viewMode.gender })
              }
            >
              {viewMode.gender ? (
                <AiFillEye className="w-4 h-4 text-[#A9A8A8]" />
              ) : (
                <AiFillEyeInvisible className="w-4 h-4 text-[#A9A8A8]" />
              )}
            </span>
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2 ">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {viewMode.age ? data?.age ?? '- -' : '######'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('age_label') ?? ''}
            <span
              className="cursor-pointer"
              onClick={() => setViewMode({ ...viewMode, age: !viewMode.age })}
            >
              {viewMode.age ? (
                <AiFillEye className="w-4 h-4 text-[#A9A8A8]" />
              ) : (
                <AiFillEyeInvisible className="w-4 h-4 text-[#A9A8A8]" />
              )}
            </span>
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2 ">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {data?.nationality?.name ?? '- -'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('country_label') ?? ''}
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2 ">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {data?.state_name ?? '- -'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('stategovernorate_label') ?? ''}
          </label>
        </div>

        <div className="col-span-3 md:col-span-2  lg:col-span-2 ">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {data?.city ?? '- -'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('cityarea_label') ?? ''}
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2 ">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {data?.current_location ?? '- -'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('current_location_label') ?? ''}
          </label>
        </div>
        <div className="col-span-3 md:col-span-2  lg:col-span-2 ">
          <div className="w-full relative">
            <p className="text-sm text-primaryText font-medium">
              {data?.is_handicapped == true
                ? t('yes_option')
                : data?.is_handicapped == false
                ? t('no_option')
                : '- -'}
            </p>
          </div>
          <label className="labelText detailsLabel">
            {t('handicapped_label') ?? ''}
          </label>
        </div>
      </div>
    </div>
  );
}
