import { useTranslation } from 'react-i18next';

import useBrowserWidth from '../../../utilities/responsiveHook';
import ReviewContent from './reviewContent';

type Props = {
  data?: any;
};
export default function RatingReview(props: Props) {
  const { data } = props;
  const { t } = useTranslation();
  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <div
      className={`flex flex-col gap-4 bg-bgWhite border-b border-formBorder ${
        isMobile ? 'p-4' : 'px-7 py-5'
      }`}
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <p className="text-lg font-bold text-primaryText">
            {t('rating_reviews') ?? ''}
          </p>
          <p className="text-sm text-textColor">
            {data?.average_rating_value ?? 0}/5 , {data?.reviews.length ?? 0}{' '}
            {t('rating_text') ?? ''} & {data?.reviews.length ?? 0}{' '}
            {t('reviews_text') ?? ''}
          </p>
        </div>
        {/* <div className="flex items-center gap-4">
          <Button
            variant="contained"
            className={`secondaryButton h-10 text-sm normal-case p-2.5 rounded-md shadow-none gap-1.5`}
          >
            <BiPlus className=" text-primaryText w-5 h-5" />
            <span className="text-primaryText">
              {t("add_review_button") ?? ""}
            </span>
          </Button>
        </div> */}
      </div>
      <div className="flex flex-col gap-4">
        {data?.reviews?.map((item: any) => (
          <>
            <ReviewContent reviewItem={item} />
          </>
        ))}
        {/* <ReviewContent /> */}
      </div>
      {/* <div className="flex items-center justify-between">
        <div className="customPagination">
          <BasePagination reviewsCount={data?.reviews?.length} />
        </div>
        <p className=" text-sm text-textColor">
          Showing 1-5 out of {data?.reviews?.length} {t("reviews_text") ?? ""}
        </p>
      </div> */}
    </div>
  );
}
