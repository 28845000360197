import { Popover, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillHeart } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMyworkStore } from '../store/myworkStore';
import useBrowserWidth from '../utilities/responsiveHook';

const postedPaths = 'my-posted-projects';
const appliedPath = 'my-applied-projects';
const paths = ['my-posted-projects', 'my-applied-projects'];

export default function HeaderDropdown() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { myworkStatus, setMyWorkStatus } = useMyworkStore();
  const { isBrowser, isMobile } = useBrowserWidth();

  const handleNavigate = (navUrl: string, close: any) => {
    close();
    setMyWorkStatus({ jobStatus: 'Applied', workType: 'applied_projects' });

    navigate(navUrl, {
      state: { filter: 'Applied' },
    });
  };
  const handleNavigatePostedProjects = (close: any) => {
    close();
    setMyWorkStatus({ jobStatus: 'Published', workType: 'project_owner' });
    navigate('/my-posted-projects/Published', {
      state: { filter: 'Published' },
    });
  };
  const { pathname } = useLocation();

  const [isStateEmpty, setIsStateEmpty] = useState(true);

  const headerTabVerify = () => {
    const flag = localStorage.getItem('switched_company');
    if (flag) {
      const flagObj = JSON.parse(flag);
      const isStateEmpty = Object.keys(flagObj.state).length === 0;
      let id;
      if (flagObj.state.companyDetail && flagObj.state.companyDetail.id) {
        id = flagObj.state.companyDetail.id;
        setIsStateEmpty(false);
        return false;
      }
      setIsStateEmpty(true);
      return true;
    }
    return true;
  };
  useEffect(() => {
    headerTabVerify();
  }, [isStateEmpty]);

  return (
    <>
      <Popover className="relative ">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`relative border-0 outline-0  flex items-center gap-2.5 ${
                isMobile ? 'p-0' : 'p-4 px-2.5'
              } `}
            >
              {isMobile ? (
                <AiFillHeart
                  className={`${
                    paths.find((a) => pathname.includes(a)) && 'fill-primary'
                  } text-[#C8C9C9] w-6 h-6`}
                />
              ) : (
                <>
                  <span className="  inline-block cursor-pointer  border-transparent rounded-t-lg hover:text-gray-600  dark:hover:text-primary ">
                    {t('my_project_link') ?? ''}
                  </span>
                  <span className="inline-block hover:text-gray-600  dark:hover:text-primary">
                    <BsChevronDown className="w-4 h-4" />
                  </span>
                </>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={` transform ${
                  isMobile
                    ? 'fixed left-0 z-50 top-[55px] h-[calc(100%-111px)] w-full'
                    : 'absolute right-0 z-10 mt-2.5'
                }`}
              >
                <div
                  className={`overflow-hidden bg-bgWhite shadow-footerShadow p-2 ${
                    isMobile
                      ? 'w-full h-full rounded-none'
                      : 'w-[200px]  rounded-lg'
                  }`}
                >
                  <div className="relative flex flex-col">
                    <a
                      onClick={() => handleNavigatePostedProjects(close)}
                      className={`${
                        pathname.includes(postedPaths) && 'bg-menuHover'
                      } cursor-pointer flex items-start rounded-lg p-2.5 gap-2.5 transition hover:bg-menuHover duration-150 ease-in-out  focus:outline-none `}
                    >
                      <div className="flex flex-col">
                        <p className="text-sm font-medium text-primaryText">
                          {t('my_posted_pro_optn') ?? ''}
                        </p>
                      </div>
                    </a>
                    {headerTabVerify() && (
                      <a
                        onClick={() =>
                          handleNavigate('/my-applied-projects/Applied', close)
                        }
                        className={`${
                          pathname.includes(appliedPath) && 'bg-menuHover'
                        } cursor-pointer flex items-start rounded-lg p-2.5 gap-2.5 transition hover:bg-menuHover duration-150 ease-in-out  focus:outline-none `}
                      >
                        <div className="flex flex-col">
                          <p className="text-sm font-medium text-primaryText">
                            {t('my_applied_project') ?? ''}
                          </p>
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
