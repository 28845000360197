import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  label?: string;
  required?: boolean;
  data?: { id: number; name: string }[] | any;
  onChange: (value: number) => void;
  value?: string | null;
  fullWidth?: boolean;
  register?: any;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
};

const SelectField = (props: Props) => {
  const {
    className,
    label,
    data,
    register,
    placeholder,
    required,
    onChange,
    value,
    error,
    disabled,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="formSection selectFieldInput">
        {label && (
          <label className={`block justify-start labelText`}>
            {required ? (
              <>
                {label}
                <span className="text-required"> * </span>
              </>
            ) : (
              label
            )}{" "}
          </label>
        )}
        <div className={styles.inputGroup}>
          <Select
            {...props}
            className={`${styles.customSelect} ${className}`}
            fullWidth
            value={value ?? ""}
            {...register}
            disabled={disabled}
            displayEmpty
            onChange={(e: object | any) => onChange(e.target.value)}
            size="small"
            renderValue={
              value !== ""
                ? undefined
                : () => <span className="text-[#aeabab]">{placeholder}</span>
            }
            label={label}
          >
            {
              (data?.length == 0 || Object.keys(data[0]).length == 0) ?
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700 z-10">
              Nothing found.
            </div>
            :
            data?.map((item: any) => (
              <MenuItem
                key={item?.id}
                value={item.id}
                className={styles.textSmall}
              >
                {item.name}
              </MenuItem>
            ))
            }
          </Select>
          {error && <p className="m-0  text-bgError text-xs">{t(error)}</p>}
        </div>
      </div>
    </>
  );
};

export default SelectField;
