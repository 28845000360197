import { dataParse } from "../../utilities/validation";
import { getData, postData } from "../api.helpers";
import apiUrl from "../api.url";

export const getProfileData = () =>
  getData(`${apiUrl.PROFILE}`).then((res) => res);
export const getUniversalSearchData = (data?: any) =>
  getData(`${apiUrl.UNIVERSAL_SEARCH}${dataParse(data)}`).then((res) => res);
export const getCompanyPercentage = (data?: any) =>
  getData(`${apiUrl.COMPANY_CALCULATION}/${data}`).then((res) => res);
export const getProfilePercentage = (data?: any) =>
  getData(`${apiUrl.PROFILE_CALCULATION}`).then((res) => res);
export const forgotPassword = (data?: any) =>
  postData(`${apiUrl.FORGOTPASSWORD}${dataParse(data)}`, data).then(
    (res) => res
  );
export const verifyEmailAccount = (data?: any) =>
  postData(`${apiUrl.VERIFY_EMAIL_ACCOUNT}`, data).then((res) => res);
export const verifyResetPasswordlink = (data?: any) =>
  getData(`${apiUrl.RESET_TOKEN_VERIFY}/${data}`).then((res) => res);
export const getUserProfileVerification = () =>
  getData(`${apiUrl.USER_PROFILE_VERIFICATION}`).then((res) => res);

export const addBookmark = (data: any) =>
  postData(apiUrl.BOOKMARKED, data).then((res) => res);

export const reportApi = (data: any) =>
  postData(apiUrl.REPORT, data).then((res) => res);

export const getState = (id: string) =>
  getData(`${apiUrl.STATE_LIST}/${id}/states`).then((res) => res);
export const getNotification = (id: string) =>
  getData(`${apiUrl.NOTIFICATION_LIST}`).then((res) => res);
export const clearNotification = (data: any) =>
  postData(apiUrl.NOTIFICATION_CLEAR, data).then((res) => res);
export const clearAllNotification = () =>
  getData(`${apiUrl.NOTIFICATION_CLEAR_ALL}`).then((res) => res);
