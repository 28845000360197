const COMPANY_URL = "company";
const COMPANY_UPDATE_URL = "company/update";
const COMPANY_BANK_DETAILS_DELETE = "/company/bank-details/delete";
const REGISTRATION_DOCUMENT_DELETE = "/registration-document/delete";
const LOGO_DELETE = "/logo/delete";

const apiUrl = {
  COMPANY_URL,
  COMPANY_UPDATE_URL,
  COMPANY_BANK_DETAILS_DELETE,
  REGISTRATION_DOCUMENT_DELETE,
  LOGO_DELETE
};

export default apiUrl;
