import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiEdit } from 'react-icons/bi';

import useBrowserWidth from '../../utilities/responsiveHook';
import { userProfileTypes } from '../profile/store';

type Props = {
  data?: string | null;
  handleEdit?: (val: string) => void;
  readOnly?: boolean;
  title?: string;
};
export default function AboutMe(props: Props) {
  const { data, handleEdit, readOnly, title } = props;
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };
  const { t } = useTranslation();
  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <div
      className={`flex flex-col gap-4 bg-bgWhite border-b border-formBorder ${
        isMobile ? 'p-4' : 'px-7 py-5'
      }`}
    >
      <div className="flex justify-between items-center">
        <p className="text-lg font-bold text-primaryText">
          {title ?? t('aboutme_tab') ?? ''}
        </p>
        <div className="flex items-center gap-4">
          {!readOnly && (
            <IconButton
              disableRipple
              onClick={() =>
                handleEdit && handleEdit(userProfileTypes.USER_DESC)
              }
              className="p-0  "
            >
              <BiEdit className="p-0  text-primaryText w-5" />
            </IconButton>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <div className="">
          <div className="w-full relative">
            <p
              className={`text-sm text-primaryText break-words ${
                !isActive && 'line-clamp-3'
              }`}
            >
              {data ?? '- -'}
            </p>

            {data && data?.length > 350 && (
              <button
                onClick={() => toggleClass()}
                className="text-primary cursor-pointer text-sm "
              >
                {isActive ? t('readless_link') ?? '' : t('readmore_link') ?? ''}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
