const PROJECT_NAME = "WDAWAM";
const PROJECT_LOGO = "/assets/logo.png";
const PROJECT_DESC = "";
const uberLogo = "../jobs/uber.png";
const samsungLogo = "../jobs/samsung.png";
const microsoftLogo = "../jobs/microsoft.png";
const linkedin = "/assets/icons/linkedin.png";
const facebook = "/assets/icons/facebook.png";
const instagram = "/assets/icons/instagram.png";
const behance = "/assets/icons/behance.png";
const twitter = "/assets/icons/twitter.png";
const EMPTY_IMAGE = "/assets/empty.png"
const NO_PERMISSION="/assets/permission.png"
const config = {
  PROJECT_DESC,
  PROJECT_LOGO,
  PROJECT_NAME,
  uberLogo,
  samsungLogo,
  microsoftLogo,
  linkedin,
  facebook,
  instagram,
  behance,
  EMPTY_IMAGE,
  NO_PERMISSION,
  twitter
};
export default config;
