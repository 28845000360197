import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import CustomButton from "../../customComponents/button";
import CustomDialogBox from "../../customComponents/dialogbox";
import SelectField from "../../customComponents/selectfield";
import CustomInput from "../../customComponents/textfield";
import TextareaInput from "../../customComponents/textfield/textarea";
import { useUserManagement } from "../../store/authStore";
import { sendContactUs } from "./api";
import { contactUsSchema, ContactUsSchema } from "./contactUsSchema";
import MessagePopUp from "./messagePopUp";

export default function ContactUs() {
  const { t } = useTranslation();
  const { setOpenContactUs, openContactUs } = useUserManagement();
  const [openMessage, setOpenMessage] = useState(false);
  const [flag, setFlag] = useState(false);
  const {
    formState: { errors },
    register,
    reset,
    watch,
    handleSubmit,
  } = useForm<ContactUsSchema>({
    mode: "onChange",
    resolver: zodResolver(contactUsSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      description: "",
    },
  });
  const handleClose = () => {
    setOpenContactUs(false);
    reset();
  };
  const onSubmit = (data: ContactUsSchema) => {
    const result = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone: data?.phone,
      description: data?.description,
    };
    sendContactUs(result)
      .then((res) => {
        handleClose();
        setFlag(true);
        setOpenMessage(true);
      })
      .catch((error: any) => {
        handleClose();
        setFlag(false);
        setOpenMessage(true);
      });
  };
  return (
    <>
      <CustomDialogBox
        open={openContactUs.open}
        title={t("Contactus_Home") ?? ""}
        maxWidth={"xs"}
        handleClose={handleClose}
        dialogActions={
          <div className="flex items-center justify-between gap-3 w-full">
            <CustomButton
              title={t("cancel_button") ?? ""}
              className=" secondaryButton w-full"
              onClick={() => handleClose()}
            />
            <CustomButton
              title={t("submit_button") ?? ""}
              className=" primaryButton w-full "
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
            />
          </div>
        }>
        <div className="col-span-6 sm:col-span-3 mt-3 ">
          <CustomInput
            label={t("Contactus_fname") ?? ""}
            register={register("first_name")}
            required={true}
            placeholder={t("Contactus_fname") ?? ""}
            error={t(errors.first_name?.message ?? "")}
          />
        </div>
        <div className="col-span-6 sm:col-span-3 mt-3 ">
          <CustomInput
            label={t("Contactus_lname") ?? ""}
            register={register("last_name")}
            required={true}
            placeholder={t("Contactus_lname") ?? ""}
            error={t(errors.last_name?.message ?? "")}
          />
        </div>
        <div className="col-span-6 sm:col-span-3 mt-3 ">
          <CustomInput
            label={t("email_placeholder") ?? ""}
            register={register("email")}
            required={true}
            error={errors.email && errors.email.message}
            placeholder={t("email_placeholder") ?? ""}
          />
        </div>
        <div className="col-span-6 sm:col-span-3 mt-3 ">
          <CustomInput
            label={t("mobile_number_placeholder") ?? ""}
            placeholder={t("mobile_number_placeholder") ?? ""}
            required={true}
            error={errors.phone && errors.phone.message}
            register={register("phone")}
          />
        </div>
        <div className="col-span-6 sm:col-span-3 mt-3 ">
          <TextareaInput
            label={t("Cntactus-msg") ?? ""}
            placeholder={t("type_a_message_placeholder") ?? ""}
            textarea={true}
            required
            className="customTextarea"
            register={register("description")}
            error={t(errors.description?.message ?? "") ?? ""}
          />
        </div>
      </CustomDialogBox>

      <MessagePopUp
        openMessage={openMessage}
        flag={flag}
        setOpenMessage={setOpenMessage}
        setFlag={setFlag}
      />
    </>
  );
}
