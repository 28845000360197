import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterTypeInt } from '../../common/types';
import CustomButton from '../../customComponents/button/index';
import useBrowserWidth from '../../utilities/responsiveHook';
import SwitchFilterComponent from './switchFilterComponent';

import './content/filterStyle.css';

type Props = {
  fromTab?: boolean;
  filterTypeRef?: FilterTypeInt;
  filterData: any;
  fetchData: (keyName?: string) => void;
  handleValues: (value: any, index: number) => void;
  handleNavigation: () => void;
  handleClear: () => void;
  openDetail: boolean;
  clearFilter: boolean;
  setOpenDetail: (value: boolean) => void;
  setClearFilter: (value: boolean) => void;
};
export default function FilterDetailPopup(props: Props) {
  const {
    filterData,
    fetchData,
    handleValues,
    handleNavigation,
    handleClear,
    setOpenDetail,
    setClearFilter,
    clearFilter,
    openDetail,
  } = props;
  const { isBrowser, isMobile } = useBrowserWidth();

  const { t } = useTranslation();
  const handleClearCheck = () => {
    let result = false;
    if (filterData) {
      filterData.map((item: any) => {
        if (item.value) {
          result = true;
        }
      });
    }
    return result;
  };
  return (
    <div className=" ">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              // onClick={() => (fromTab ")}
              onClick={() => setOpenDetail(true)}
              className={`

                ${open ? '' : 'text-opacity-90'}
                group flex items-center px-2 h-[30px] min-w-[120px] w-full justify-center  py-1.5 rounded-md bg-[#EFEFEF] text-xs 2xl:text-sm  gap-1.5  font-medium text-white hover:text-opacity-100 focus:outline-none `}
            >
              <span>{t('more_filters_button') ?? ''}</span>
              <span className=" text-textColor">
                <svg
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8334 1.5L7.00008 7.33333L1.16675 1.5"
                    stroke="#585858"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Popover.Button>
            {openDetail && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-2"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-2"
              >
                <Popover.Panel
                  className={` z-50  fixed   ${
                    isMobile
                      ? ' right-auto w-full left-0 top-[108px]'
                      : ' right-0 w-[540px] top-[64px]'
                  }`}
                >
                  <div
                    className={`flex flex-col filterHeight bg-bgWhite rounded-sm shadow-filterMenu ${
                      isMobile
                        ? '!min-h-[calc(100vh-164px)] !max-h-[calc(100vh-164px)]'
                        : ''
                    }`}
                  >
                    <div className=" h-[54px] px-5 border-b border-formBorder">
                      <div className="flex  justify-between items-center min-h-full">
                        <p className="text-md font-bold">
                          {t('filter_by_label') ?? ''}
                        </p>
                        <div className="flex  justify-between gap-3 items-cente">
                          <button
                            onClick={() => handleClear()}
                            className={`bg-activeStateBg disabled:opacity-50 text-primary font-medium  min-w-[72px]  normal-case px-2.5 rounded-md ${
                              isMobile
                                ? ' !h-[26px] !text-xs'
                                : 'h-[32px] text-sm'
                            }`}
                            disabled={!handleClearCheck() ? true : false}
                          >
                            {t('filterclear_filters_label') ?? ''}
                          </button>

                          <CustomButton
                            onClick={() => handleNavigation()}
                            title={t('filter_apply_label') ?? ''}
                            className={isMobile ? ' btnView' : 'h-[32px]'}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex flex-col px-5 py-2 overflow-y-auto ${
                        isMobile ? '' : 'min-h-[calc(100vh-120px)]'
                      }`}
                    >
                      {filterData?.map((items: any, index: number) => (
                        <>
                          {isMobile ? (
                            <div
                              key={items.id}
                              className="filterFields relative filterBlock !px-0"
                            >
                              <SwitchFilterComponent
                                key={items.id}
                                keyIndex={index}
                                fetchData={fetchData}
                                label={items.label}
                                handleValues={handleValues}
                                {...items}
                                type={items.type}
                              />
                            </div>
                          ) : (
                            <>
                              {!items?.isBasic && (
                                <div
                                  key={items.id}
                                  className="filterFields relative "
                                >
                                  <SwitchFilterComponent
                                    key={items.id}
                                    keyIndex={index}
                                    fetchData={fetchData}
                                    label={items.label}
                                    handleValues={handleValues}
                                    {...items}
                                    type={items.type}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            )}
          </>
        )}
      </Popover>
    </div>
  );
}
