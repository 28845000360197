import { IconButton } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineShareAlt } from 'react-icons/ai';

import { addBookmark } from '../../apis/commonapis/apis';
import { useAuthStore, useUserManagement } from '../../store/authStore';
import useBrowserWidth from '../../utilities/responsiveHook';

type Props = {
  item: any;
  onClick?: () => void;
  handleRefresh?: () => void;
};
export default function FreelanceCardComponent(props: Props) {
  const { item, onClick, handleRefresh } = props;
  const { t } = useTranslation();
  const date1: any = new Date();
  const date2: any = new Date(item?.created_at);
  const diffTime = Math.abs(date1 - date2);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const { authenticated } = useAuthStore();

  const [isActive, setActive] = useState(false);

  const { setOpenShare } = useUserManagement();

  const AddFavourite = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const { data, error, message } = await addBookmark({
      favourited_id: item.id,
      favourited_type: 'Work',
    });
    handleRefresh && handleRefresh();
  };
  const handleShareFlag = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: any
  ) => {
    e.stopPropagation();

    const origin = window.location.origin;
    const navUrl = `${origin}/project-detail/view/${id}`;
    setOpenShare({ open: true, link: navUrl });
  };
  const toggleClass = () => {
    setActive(!isActive);
  };
  const { isBrowser, isMobile } = useBrowserWidth();

  return (
    <div
      className={`${
        (item?.is_promoted || item?.isPromoted) && 'premiumCard'
      } ${
        (item?.is_expired ) && 'expiredCard'
      } jobCard  flex cursor-pointer items-stretch gap-3 relative`}
      onClick={() => onClick && onClick()}
    >
    
      {' '}
      {/* cursor-pointer */}
      {(item?.is_promoted || item?.isPromoted) && (
        <span className="premiumUser">{t('promoted_badge') ?? ''}</span>
      )}
      {/* <div onClick={() => onClick && onClick()} className="flex-1"> */}
      <div className="flex-1">
        <div className="flex items-center gap-2 mb-1 cursor-pointer">
          <p
            className={`text-base font-bold text-[#333] ${
              isMobile && 'flex-1'
            }`}
          >
            {item?.title}
            <span className="tagStyle primaryTag border-t border-b border-primary bg-activeStateBg ml-4 top-[2px]">
              <span className="tag text-[10px] text-primaryText font-semibold">
                {t('verified_badge') ?? ''}
              </span>
            </span>
            {(item?.is_experts_only || item?.isExpertsOnly) && (
              <span className="tagStyle successTag border-t border-b border-success bg-[#f6f5f5] ml-4 top-[2px]">
                <span className="tag text-[10px] text-success font-semibold">
                  {t('experts_only_badge') ?? ''}
                </span>
              </span>
            )}
          </p>
          {isMobile && (
            <div className="flex gap-3">
              <IconButton
                disableRipple
                onClick={(e) => handleShareFlag(e, item.id)}
                className="p-0   "
              >
                <AiOutlineShareAlt className="p-0  text-primaryText w-4 rtl:rotate-180" />
              </IconButton>
              {/* commented for phase-1 */}

              {/* {authenticated && (
                <IconButton
                  disableRipple
                  onClick={(e) => AddFavourite(e)}
                  className="p-0   "
                >
                  <BsBookmark
                    className={`p-0   w-4 ${
                      item?.is_bookmarked ? "text-primary" : "text-primaryText"
                    } `}
                  />
                </IconButton>
              )} */}
            </div>
          )}
        </div>
        <div className="flex flex-wrap gap-x-2 mb-2">
          <span className="text-sm text-primaryText">
            {t('budget_label') ?? ''}
            {` ${item?.currency?.currency_code ?? '- -'} ${
              item?.minimum_budget ?? 0
            } -  ${item?.currency?.currency_code ?? '- -'} ${
              item?.maximum_budget ?? 0
            }`}
          </span>
          <span className="text-sm text-primaryText">|</span>
          {typeof item?.rating === 'object' && (
            <span className="text-sm text-primaryText">
              {/* ${item?.rating?.type ?? "- -"}  */}
              {`
            
            ${t('rating_text') ?? ''}
             ${item?.rating?.average_rating ?? '0'}/5`}
            </span>
          )}
        </div>
        <div className="flex flex-wrap gap-x-2 mb-2">
          {/* <span className="text-sm text-secondaryText">{item?.summary}</span> */}
          <span className="text-sm text-secondaryText">
            {item?.summary?.length > 200
              ? item.summary.substring(0, 116) + '...'
              : item?.summary}
          </span>
          {/* <p
            className={`text-sm text-secondaryText break-words ${
              !isActive && "line-clamp-3"
            }`}
          >
            {item?.summary}
          </p> */}
          {/* {item?.summary && item?.summary?.length > 220 && (
            <button
              onClick={() => toggleClass()}
              className="text-primary cursor-pointer text-sm "
            >
              {isActive ? t("readless_link") ?? "" : t("readmore_link") ?? ""}
            </button>
          )} */}
        </div>
        <div className="flex flex-wrap gap-x-2 gap-y-1 mb-2">
          {/* {item?.skills &&
            item?.skills?.map((skill: any) => (
              <div className="jobTags">{skill.name}</div>
            ))} */}

          <div className="flex flex-wrap gap-x-2 gap-y-1 mb-2">
            {item?.skills &&
              item?.skills
                .slice(0, 3)
                .map((skill: any) => (
                  <div className="jobTags">{skill.name}</div>
                ))}

            {item?.skills && item?.skills?.length > 3 && (
              <div className="jobTags">{`+ ${item?.skills?.length - 3}`}</div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-x-2.5 gap-y-1">
          <span className="text-xs text-primary font-semibold jobDotList">
            {/* {diffDays} {t("days_ago") ?? ""} */}
            {diffDays === 1
              ? `0 ${t('days_ago') ?? ''}`
              : `${diffDays} ${t('days_ago') ?? ''}`}
          </span>
          {/* <span className="text-xs text-primary font-semibold jobDotList">
            {item?.no_of_views ?? "0"} {t("views_info") ?? ""}
          </span> */}

          <span className="text-xs text-primary font-semibold jobDotList">
            {item?.applicantCount ?? '0'} {t('bids_info') ?? ''}
          </span>

          <span className="text-xs text-primary font-semibold jobDotList">
            {t('last_date') ?? ''}{' '}
            {item?.last_date_for_project
              ? moment(item?.last_date_for_project).format('DD-MM-YYYY')
              : '- -'}
          </span>
        </div>
        {isMobile && (
          <div className="flex flex-col mt-2">
            <p className="text-sm text-primaryText font-bold text-left">
              {item?.average_bid_value
                ? `${item?.currency?.currency_code}  ${item?.average_bid_value}`
                : '--'}
            </p>
            <p className="text-xs text-textColor font-medium text-left">
              {t('average_bid_value') ?? ''}
            </p>
          </div>
        )}
      </div>
      {isBrowser && (
        <div className="flex flex-col justify-between items-end">
          <div className="flex">
          {!item?.is_expired &&(
            <IconButton
              disableRipple
              onClick={(e) => handleShareFlag(e, item.id)}
              className="p-0  w-10 h-10 "
            >
              <AiOutlineShareAlt className="p-0  text-primaryText w-5 rtl:rotate-180" />
            </IconButton>
             )}
            {/* commented for phase-1 */}

            {/* {authenticated && (
              <IconButton
                disableRipple
                onClick={(e) => AddFavourite(e)}
                className="p-0  w-10 h-10 "
              >
                <BsBookmark
                  className={`p-0   w-5 ${
                    item?.is_bookmarked ? "text-primary" : "text-primaryText"
                  } `}
                />
              </IconButton>
            )} */}
          </div>
          <div className="flex flex-col">
            <p className="text-sm text-primaryText font-bold text-right">
              {item?.average_bid_value
                ? `${item?.currency?.currency_code}  ${item?.average_bid_value}`
                : '--'}
            </p>
            <p className="text-xs text-textColor font-medium text-right">
              {t('average_bid_value') ?? ''}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
