import { debounce, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nodes } from '../../common/types';
import styles from './index.module.scss';

type Props = {
  className?: string;
  name?: string;
  placeholder?: string;
  labelName?: string;
  disabled?: boolean;
  startIcon?: any;
  optionLabel?: string | '';
  value: any | any[];
  required?: boolean;
  // getData: (value?: string) => void;
  getData: any;
  data?: Nodes[];
  handleChange: (value: object) => void;
  error?: object | any;
  emptyString?: string;
  readOnly?: boolean | any;
  type?: string;
  multiple?: boolean;
};
type func = {
  event: any;
  newInputValue: string;
  reason: any;
};
type handleProps = {
  event: any;
  value: any;
  reason: string;
  details?: string;
};

const CustomAutoCompleteField = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [inputkey, setInputKey] = useState('');

  const {
    className,
    name,
    value,
    labelName,
    disabled,
    emptyString,
    readOnly,
    placeholder,
    startIcon,
    required,
    type,
    error,
    multiple,
  } = props;
  const handleInputChange = (values: func) => {
    if (values.reason === 'input') {
      setInputKey(values.newInputValue);
      values.newInputValue && getDatas(values.newInputValue);
    }
  };

  const { t } = useTranslation();

  const handleChange = (event: any, val: any) => {
    let uniqueObject: any = {};
    let newArray = [];
    for (let i in val) {
      const objTitle = val[i]['id'];
      uniqueObject[objTitle] = val[i];
    }
    for (let i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    setInputKey('');
    props.handleChange(newArray);
  };
  const getDatas = debounce((newValue?: string) => {
    props.getData(newValue);
  }, 1000);
  return (
    <div className="formSection autoComplete">
      {labelName && (
        <label className={`block justify-start labelText`}>
          {required ? (
            <>
              {labelName}
              <span className="text-required"> * </span>
            </>
          ) : (
            labelName
          )}{' '}
        </label>
      )}
      <div className={styles.inputGroup}>
        {startIcon && !value ? (
          <span className="startAdornment">{startIcon}</span>
        ) : (
          ''
        )}
        <Autocomplete
          multiple
          id="combo-box-dasaemo"
          options={props.data ?? []}
          getOptionLabel={(option) => option.name}
          value={value}
          defaultValue={[]}
          selectOnFocus={true}
          freeSolo={false}
          disabled={disabled}
          onOpen={() => getDatas()}
          autoComplete={false}
          autoHighlight={true}
          onChange={(event, newValue) => handleChange(event, newValue)}
          onInputChange={(event, newInputValue, reason) => {
            handleInputChange({ event, newInputValue, reason });
          }}
          inputValue={inputkey}
          renderInput={(params) => (
            <TextField
              {...params}
              className={className}
              helperText={t(error)}
              error={error ? true : false}
              name={name}
              value={inputkey}
              placeholder={t('search_and_select_placeholder' ?? '') ?? ''}
              // placeholder="Search and Select"
            />
          )}
        />
      </div>

      {/* {type == "tagSelect" && (
        <div className="flex gap-2 mt-5">
          {props.value?.map((item: any) => (
            <div className="rounded-md bg-primary px-1.5 py-0.5 flex items-center gap-1">
              <span className="text-bgWhite text-xs font-medium">
                {item.name}{" "}
              </span>
              <span className="cursor-pointer">
                <AiOutlineClose className="text-bgWhite w-3" />
              </span>
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
};

export default CustomAutoCompleteField;
