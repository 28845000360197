import { useTranslation } from "react-i18next";

type Props = {
  label?: string;
  value?: string | number;
  onChange?: (e: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  search?: boolean;
  name?: string;
  type?: string;
  register?: any;
  placeholder?: string;
  disabled?: boolean;
  textarea?: boolean;
  className?: string;
  error?: any;
  direction?: string;
};
export default function TextareaInput(props: Props) {
  const {
    label,
    value,
    onChange,
    name,
    register,
    required,
    fullWidth,
    disabled,
    textarea,
    className,
    error,
    placeholder,
    direction,
  } = props;
  const { t } = useTranslation();
  return (
    <div>
      {label && (
        <label
          className={`block justify-start labelText ${
            direction == "rtl" && "text-right flex flex-row-reverse gap-0.5"
          }`}
        >
          {required ? (
            <>
              {label}
              <span className="text-required"> * </span>
            </>
          ) : (
            label
          )}{" "}
        </label>
      )}

      <div className="w-full relative">
        <textarea
          id={"user_name"}
          type={"text"}
          {...props}
          required={true}
          className={`customFieldInput w-full ${className}`}
          placeholder={placeholder}
          disabled={disabled}
          dir={direction ?? ""}
          {...register}
        />
        {error && <p className="m-0 text-xs text-bgError">{t(error ?? "")}</p>}
      </div>
    </div>
  );
}
