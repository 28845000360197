import { useTranslation } from "react-i18next";

import SocialMediaPresence from "../../detailComponent/socialMedia";
import AboutDescription from "../aboutDescription";
import BasicInformation from "../basicInformation";
import Education from "../education";
import Experience from "../experience";
import FileUpload from "../fileUpload";
import PersonalInformation from "../personalInformation";
import ProfessionalInformation from "../professionalInformation";
import SkillPreference from "../skillPreference";
import { userProfileTypes } from "../store";
import TaxInformation from "../taxInformation";

type Props = {
  type: string;
  handleRefresh: (value?: any) => void;
  method: any;
};
export default function SwitchCompanyComponents(props: Props) {
  const { type, handleRefresh, method } = props;
  const { t } = useTranslation();

  switch (type) {
    case userProfileTypes.BASIC_INFO:
      return (
        <BasicInformation fromDialog={true} handleRefresh={handleRefresh} />
      );
    case userProfileTypes.PERSONAL_INFO:
      return <PersonalInformation fromDialog={true} />;
    case userProfileTypes.PROFESSIONAL_INFO:
      return <ProfessionalInformation fromDialog={true} />;
    case userProfileTypes.USER_DESC:
      return <AboutDescription fromDialog={true} />;
    case userProfileTypes.SKILL_PREF:
      return (
        <SkillPreference
          methods={method}
          dropKey="skill-set"
          registerName="skill_sets"
          title={"Skill Preferences"}
          fromDialog={true}
        />
      );
    case userProfileTypes.EXPERIENCE:
      return <Experience fromDialog={true} handleRefresh={handleRefresh} />;
    case userProfileTypes.EDUCATION:
      return <Education fromDialog={true} handleRefresh={handleRefresh} />;
    case userProfileTypes.WORK_LOC:
      return (
        <SkillPreference
          methods={method}
          dropKey="work-location"
          registerName="work_locations"
          title={"Work Location Preference"}
          fromDialog={true}
        />
      );
    case userProfileTypes.WORK_SECTOR:
      return (
        <SkillPreference
          methods={method}
          dropKey="work-sector"
          registerName="work_sectors"
          title={t("preferred_job_sector_label") ?? ""}
          fromDialog={true}
        />
      );
    case userProfileTypes.LANGUAGE:
      return (
        <SkillPreference
          methods={method}
          dropKey="language"
          registerName="languages"
          title={t("language_proficiency_heading") ?? ""}
          fromDialog={true}
        />
      );
    case userProfileTypes.PORTFOLIO:
      return (
        <FileUpload
          title={t("upload_portfolio") ?? ""}
          registerName="portfolios"
          fromDialog={true}
          handleRefresh={handleRefresh}
        />
      );

    case userProfileTypes.RESUME:
      return (
        <FileUpload
          title={t("upload_resume_heading") ?? ""}
          registerName="attachments"
          fromDialog={true}
          handleRefresh={handleRefresh}
        />
      );

    case userProfileTypes.USER_TAX_INO:
      return <TaxInformation fromDialog={true} />;

    case userProfileTypes.SOCIAL_MEDIA_PRESENCE:
      return <SocialMediaPresence fromDialog={true} />;
    case userProfileTypes.RECOMMENDATION_TAGS:
      return (
        <SkillPreference
          methods={method}
          dropKey="recommendation-tag"
          registerName="recommendationTags"
          title={t("recommendation_tags") ?? ""}
          fromDialog={true}
        />
      );
    default:
      return null;
  }
}
