import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import ContactUs from "../pages/contactUs";
import ProfileCreate from "../pages/profile/create";
import ProfileView from "../pages/profile/view";
import SubscriptionAddOn from "../pages/subscription/addOn";
import AddOnView from "../pages/subscription/addOnDetails";
import UniversalSearch from "../pages/universalSearch";
import GuestRoute from "./components/guestRoute";
import PublicRoute from "./components/publicRoute";
import UserRoute from "./components/userRoute";

const Dashboard = lazy(() => import("../pages/home"));
const TermsConditions = lazy(() => import("../pages/termsandconditions"));
const PrivacyPolicy = lazy(() => import("../pages/privacypolicy"));
const ErrorDepositPaymentPage = lazy(
  () => import("../pages/myworks/ErrorPaymentPage")
);
const PaymentDepositSuccess = lazy(
  () => import("../pages/myworks/paymentSuccess")
);

const Jobs = lazy(() => import("../pages/jobs"));
const Talent = lazy(() => import("../pages/talent"));
const TalentDetails = lazy(
  () => import("../pages/talent/components/detailView")
);
const Subscription = lazy(() => import("../pages/subscription/index"));
const SubscriptionUpgrade = lazy(() => import("../pages/subscription/upgrade"));
const SubscriptionView = lazy(
  () => import("../pages/subscription/planDetails")
);
const CompanyProfile = lazy(() => import("../pages/companyprofile/index"));
const CompanyView = lazy(() => import("../pages/companyprofile/view"));
const FreelanceJobs = lazy(() => import("../pages/jobs/freelanceJobs"));
const JobDetails = lazy(() => import("../pages/jobs/view"));
const PostJobComponent = lazy(() => import("../pages/jobs/postJob/index"));
const ProjectDetaills = lazy(() => import("../pages/jobs/projectView"));
const VeriFyEmailAccount = lazy(() => import("../pages/verifyEmailAccount"));
const MyProjects = lazy(() => import("../pages/myworks"));
const MyProjectDetails = lazy(
  () => import("../components/myprojects/myproject")
);

const VerifyResetPassword = lazy(
  () => import("../pages/resetpassword/verifyResetPassLink")
);

const ErrorPaymentPage = lazy(
  () => import("../pages/subscription/ErrorPaymentPage")
);

const ResetPassword = lazy(
  () => import("../pages/resetpassword/resetpassword")
);
const PaymentSuccess = lazy(
  () => import("../pages/subscription/paymentSuccess")
);
const MyProjectDetailsComponent = lazy(
  () => import("../pages/myworks/projectDetails")
);
const MyProjectSettings = lazy(
  () => import("../components/myprojects/settings")
);
const ProjectProposals = lazy(
  () => import("../components/myprojects/projectProposals")
);
const ProposalsDetails = lazy(
  () => import("../components/myprojects/proposalDetailView")
);
const MyProposalDetailView = lazy(
  () => import("../pages/myworks/proposalDetailView")
);
const ProjectAddonDetails = lazy(
  () => import("../components/myprojects/projectAddons")
);
const ProjectTransactions = lazy(
  () => import("../components/myprojects/projectTransactions")
);

const ProjectRequested = lazy(
  () => import("../components/myprojects/projectRequested")
);
const ProjectAccepted = lazy(
  () => import("../components/myprojects/projectAccepted")
);
const ProjectFileUpload = lazy(
  () => import("../components/myprojects/projectFileUpload")
);
const ProjectCommunication = lazy(
  () => import("../components/myprojects/projectCommunications")
);
const ProjectRatings = lazy(
  () => import("../components/myprojects/projectRating")
);
const RequestedMileStoneDetails = lazy(
  () => import("../pages/myworks/requestedMilestone")
);
const AcceptedMileStoneDetails = lazy(
  () => import("../pages/myworks/acceptedMilestone")
);
const MileStoneCreated = lazy(
  () => import("../components/myprojects/milestoneCreated")
);
const MileStoneAccepted = lazy(
  () => import("../components/myprojects/milestoneAccepted")
);
const ProjectListContainer = lazy(
  () => import("../components/myprojects/listContainer")
);
const MileStoneDispute = lazy(
  () => import("../components/myprojects/milestoneDisputeList")
);
const AccessDenied = lazy(() => import("../pages/accessDenied"));
const PaymentAddonSuccess = lazy(
  () => import("../pages/myworks/paymentAddonSuccess")
);
const GoogleCallback = lazy(
  () => import("../components/userManagement/signIn/callback")
);

type Props = {
  authenticated?: boolean;
};
export default function MainRoutes(props: Props) {
  return (
    <Routes>
      <Route
        element={
          <GuestRoute>
            <TermsConditions />
          </GuestRoute>
        }
        path="/terms-and-conditions"
        key="/terms-and-conditions"
      />
      <Route
        element={
          <GuestRoute>
            <PrivacyPolicy />
          </GuestRoute>
        }
        path="/privacypolicy"
        key="/privacypolicy"
      />
      <Route
        element={
          <GuestRoute>
            <Dashboard />
          </GuestRoute>
        }
        path="/home"
        key="/home"
      />
      <Route
        element={
          // <GuestRoute>
          <UserRoute>
            <Jobs />
          </UserRoute>
          //  </GuestRoute>
        }
        path="/find-works"
        key="/find-works"
      />
      <Route
        element={
          // <GuestRoute>
          <UserRoute>
            <FreelanceJobs />
          </UserRoute>
          // </GuestRoute>
        }
        path="/find-works/freelance"
        key="/find-works/freelance"
      />
      <Route
        element={
          <PublicRoute>
            <GoogleCallback />
          </PublicRoute>
        }
        path="/auth-callback"
        key="/auth-callback"
      />
      <Route
        element={
          <UserRoute>
            <JobDetails />
          </UserRoute>
        }
        path="/find-works/view/:id"
        key="/find-works/view/:id"
      />
      <Route
        element={
          <UserRoute>
            <JobDetails />
          </UserRoute>
        }
        path="/find-works/view-mode/:id"
        key="/find-works/view-mode/:id"
      />
      <Route
        element={
          // <GuestRoute>
          <UserRoute>
            <ProjectDetaills />
          </UserRoute>
          // </GuestRoute>
        }
        path="/project-detail/view/:id"
        key="/project-detail/view/:id"
      />
      <Route
        element={
          // <GuestRoute>
          <UserRoute>
            <Talent />
          </UserRoute>
          // </GuestRoute>
        }
        path="/find-talent"
        key="/find-talent"
      />
      <Route
        element={
          // <GuestRoute>
          <UserRoute>
            <TalentDetails publicAccess={true} />
          </UserRoute>
          // </GuestRoute>
        }
        path="/find-talent/:id"
        key="/find-talent/:id"
      />
      <Route
        element={
          <UserRoute>
            <MyProjects work_type="project_owner" key="project_owner" />
          </UserRoute>
        }
        path="/my-posted-projects"
        key="/my-posted-projects">
        <Route
          element={<ProjectListContainer work_type="project_owner" />}
          index={true}
          path=":details"
          key="details"
        />
      </Route>
      <Route
        element={
          <UserRoute>
            <MyProjectDetailsComponent work_type="project_owner" />
          </UserRoute>
        }
        path="/my-posted-projects-details/:id"
        key="/my-posted-projects-details/:id">
        <Route
          element={<MyProjectDetails work_type="project_owner" />}
          index={true}
          path=""
          key=""
        />
        <Route
          element={<MyProjectDetails work_type="project_owner" />}
          index={true}
          path="details"
          key="details"
        />
        <Route
          element={<MyProjectSettings work_type="project_owner" />}
          path="settings"
          key="settings"
        />
        <Route
          element={<ProjectProposals work_type="project_owner" />}
          path="proposals"
          key="proposals"
        />
        <Route
          element={<ProjectAddonDetails work_type="project_owner" />}
          path="addons"
          key="addons"
        />
        <Route
          element={<ProjectRequested work_type="project_owner" />}
          path="requested"
          key="requested"
        />
        <Route
          element={<MileStoneDispute work_type="project_owner" />}
          path="disputed"
          key="disputed"
        />

        <Route
          element={<ProjectAccepted work_type="project_owner" />}
          path="accepted"
          key="accepted"
        />

        <Route
          element={<ProjectFileUpload work_type="project_owner" />}
          path="files"
          key="files"
        />

        <Route
          element={<ProjectCommunication work_type="project_owner" />}
          path="communications"
          key="communications"
        />

        <Route
          element={<ProjectRatings work_type="project_owner" />}
          path="ratings"
          key="ratings"
        />

        <Route
          element={<ProjectTransactions work_type="project_owner" />}
          path="transactions"
          key="transactions"
        />
      </Route>
      <Route
        element={
          <UserRoute>
            <MyProjectDetailsComponent work_type="applied_projects" />
          </UserRoute>
        }
        path="/my-applied-projects-details/:id"
        key="/my-applied-projects-details/:id">
        <Route
          element={<MyProjectDetails work_type="applied_projects" />}
          index={true}
          path=""
          key=""
        />

        <Route
          element={<MyProjectDetails work_type="applied_projects" />}
          index={true}
          path="details"
          key="details"
        />
        <Route
          element={<MyProjectSettings work_type="applied_projects" />}
          path="settings"
          key="settings"
        />
        <Route
          element={<ProjectAddonDetails work_type="applied_projects" />}
          path="addons"
          key="addon"
        />
        <Route element={<ProposalsDetails />} path="proposals" key="proposal" />
        <Route
          element={<MileStoneCreated work_type="applied_projects" />}
          path="created"
          key="created"
        />
        <Route
          element={<MileStoneDispute work_type="applied_projects" />}
          path="disputed"
          key="disputed"
        />

        <Route
          element={<MileStoneAccepted work_type="applied_projects" />}
          path="accepted"
          key="accepted"
        />

        <Route
          element={<ProjectFileUpload work_type="applied_projects" />}
          path="files"
          key="files"
        />

        <Route
          element={<ProjectCommunication work_type="applied_projects" />}
          path="communications"
          key="communications"
        />

        <Route
          element={<ProjectRatings work_type="applied_projects" />}
          path="ratings"
          key="ratings"
        />

        <Route
          element={<ProjectTransactions work_type="applied_projects" />}
          path=":transactions"
          key="transactions"
        />
      </Route>
      <Route
        element={
          <UserRoute>
            <MyProposalDetailView work_type="project_owner" />
          </UserRoute>
        }
        path="/proposal-detail-view/:id"
        key="/proposal-detail-view/:id"
      />
      <Route
        element={
          <UserRoute>
            <RequestedMileStoneDetails />
          </UserRoute>
        }
        path="/milestone-detail-view/:id"
        key="/milestone-detail-view/:id"
      />
      <Route
        element={
          <UserRoute>
            <AcceptedMileStoneDetails />
          </UserRoute>
        }
        path="/milestone-details-view/:id"
        key="/milestone-details-view/:id"
      />
      <Route
        element={
          <UserRoute>
            <MyProjects work_type="applied_projects" key="applied_projects" />
          </UserRoute>
        }
        path="/my-applied-projects"
        key="/my-applied-projects">
        <Route
          element={<ProjectListContainer work_type="applied_projects" />}
          index={true}
          path=":details"
          key="details"
        />
      </Route>
      <Route
        element={
          <UserRoute>
            <ProfileCreate />
          </UserRoute>
        }
        path="/profile/create"
        key="/profile/create"
      />
      <Route
        element={
          <UserRoute>
            <CompanyProfile />
          </UserRoute>
        }
        path="/company-profile/:id"
        key="/company-profile/:id"
      />
      <Route
        element={
          <UserRoute>
            <PostJobComponent jobType="full_time" />
          </UserRoute>
        }
        path="/post-job"
        key="/post-job"
      />
      <Route
        element={
          <UserRoute>
            <PostJobComponent jobType="gig" />
          </UserRoute>
        }
        path="/post-project"
        key="/post-project"
      />
      <Route
        element={
          <UserRoute>
            <PostJobComponent jobType="gig" />
          </UserRoute>
        }
        path="/update-post-project/:id"
        key="/update-post-project/:id"
      />
      <Route
        element={
          <UserRoute>
            <PostJobComponent jobType="full_time" />
          </UserRoute>
        }
        path="/update-post-job/:id"
        key="/update-post-job/:id"
      />
      <Route
        element={
          <UserRoute>
            <ProfileView />
          </UserRoute>
        }
        path="/profile/view"
        key="/profile/view"
      />
      <Route
        element={
          <UserRoute>
            <ProfileView readOnly={true} />
          </UserRoute>
        }
        path="/profile/preview"
        key="/profile/preview"
      />
      <Route
        element={
          <UserRoute>
            <Subscription />
          </UserRoute>
        }
        path="/subscription"
        key="/subscription"
      />

      <Route
        element={
          <UserRoute>
            <SubscriptionUpgrade />
          </UserRoute>
        }
        path="/subscription/upgrade"
        key="/subscription/upgrade"
      />
      <Route
        element={
          <UserRoute>
            <SubscriptionView />
          </UserRoute>
        }
        path="/subscription/view/:id"
        key="/subscription/view/:id"
      />
      <Route
        element={
          <UserRoute>
            <SubscriptionAddOn />
          </UserRoute>
        }
        path="/subscription/addon"
        key="/subscription/addon"
      />
      <Route
        element={
          <UserRoute>
            <AddOnView />
          </UserRoute>
        }
        path="/subscription/addon-view/:id"
        key="/subscription/addon-view/:id"
      />
      <Route
        element={
          <UserRoute>
            <ErrorPaymentPage />
          </UserRoute>
        }
        path="/payment-error/:id"
        key="/payment-error/:id"
      />
      <Route
        element={
          <UserRoute>
            <PaymentSuccess />
          </UserRoute>
        }
        path="/payment-success/:id"
        key="/payment-success/:id"
      />
      <Route
        element={
          <UserRoute>
            <ErrorDepositPaymentPage />
          </UserRoute>
        }
        path="/payment-deposit-error/:id"
        key="/payment-deposit-error/:id"
      />
      <Route
        element={
          <UserRoute>
            <PaymentDepositSuccess />
          </UserRoute>
        }
        path="/payment-deposit-success/:id"
        key="/payment-deposit-success/:id"
      />
      <Route
        element={
          <UserRoute>
            <PaymentAddonSuccess />
          </UserRoute>
        }
        path="/addon-payment-success/:id"
        key="/addon-payment-success/:id"
      />
      <Route
        element={
          <GuestRoute>
            <ProfileView readOnly={true} />
          </GuestRoute>
        }
        path="/talent/view/:id"
        key="/talent/view/:id"
      />
      <Route
        element={
          <GuestRoute>
            <AccessDenied />
          </GuestRoute>
        }
        path="/access-denied"
        key="denied"
      />
      <Route
        element={
          <UserRoute>
            <CompanyView />
          </UserRoute>
        }
        path="/company-profile-view/:id"
        key="/company-profile-view/:id"
      />
      <Route
        element={
          <GuestRoute>
            <CompanyView readOnly={true} />
          </GuestRoute>
        }
        path="/company-profile-view-mode/:id"
        key="/company-profile-view-mode/:id"
      />
      <Route
        element={
          <GuestRoute>
            <CompanyView readOnly={true} publicAccess={true} />
          </GuestRoute>
        }
        path="/company-view-mode/:id"
        key="/company-view-mode/:id"
      />
      <Route
        element={
          <GuestRoute>
            <UniversalSearch />
          </GuestRoute>
        }
        path="/universal-search"
        key="/universal-search"
      />
      <Route
        element={
          <GuestRoute>
            <VeriFyEmailAccount />
          </GuestRoute>
        }
        path="/verify-account/:id"
        key="/verify-account/:id"
      />
      <Route
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
        path="/reset-password"
        key="/reset-password"
      />
      <Route
        element={
          <PublicRoute>
            <VerifyResetPassword />
          </PublicRoute>
        }
        path="/reset-invalid-token"
        key="/reset-invalid-token"
      />

      <Route
        element={
          <GuestRoute>
            <Dashboard />
          </GuestRoute>
        }
        path="/*"
        key="/*"
      />
    </Routes>
  );
}
