// import { useFormContext } from "../../common/components/profile/userSchema";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ProfileSchema } from "../../common/userSchema";
import CustomInput from "../../customComponents/textfield";

type Props = {
  fromDialog?: boolean;
};
export default function TaxInformation(props: any) {
  const {
    register,
    formState: { errors },
  } = useFormContext<ProfileSchema>();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-5">
      {!props.fromDialog && (
        <p className="text-base font-bold text-primaryText">
          {t("tax_information") ?? ""}{" "}
        </p>
      )}
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-4">
          <CustomInput
            label={t("pan_number_label") ?? ""}
            placeholder={t("pan_number_placeholder") ?? ""}
            register={register("pan_number")}
            error={errors.pan_number?.message}
            // required={true}
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <CustomInput
            label={t("gst_number_label") ?? ""}
            placeholder={t("gst_number_placeholder") ?? ""}
            register={register("gst_number")}
            // required={true}
            error={errors.gst_number?.message}
          />
        </div>

        <div className="col-span-12 md:col-span-4">
          <CustomInput
            label={t("trn_number_label") ?? ""}
            placeholder={t("trn_number_placeholder") ?? ""}
            register={register("trn_number")}
            error={errors.trn_number?.message}
            // required={true}
          />
        </div>
      </div>
    </div>
  );
}
