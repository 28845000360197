import IconButton from "@mui/material/IconButton";
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillHome, AiOutlineSearch } from "react-icons/ai";
import { AiOutlineProfile } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { BiLogInCircle } from "react-icons/bi";
import { BsBriefcaseFill, BsFillGrid3X3GapFill } from "react-icons/bs";
import { GoBriefcase } from "react-icons/go";
import { HiMenu, HiUsers } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

import { getNotification, getProfileData } from "../apis/commonapis/apis";
import ChooseProfile from "../components/chooseProfile";
import AddCompanyProfile from "../components/companyProfile/addCompanyProfile/index";
import ConfirmPromt from "../components/dialogComponents/confirmPromt";
import Notification from "../components/notification/notification";
import ProfileImage from "../components/profileimage";
import ShareDetails from "../components/shareComponent";
import ForgotPassword from "../components/userManagement/forgotPassword/index";
import Login from "../components/userManagement/signIn";
import Signup from "../components/userManagement/signUp";
import VeriFyEmail from "../components/userManagement/signUp/verifyEmail";
import config from "../config";
import CustomButton from "../customComponents/button";
import SearchComponent from "../customComponents/elasticSearchComponent";
import LanguageSwitcher from "../customComponents/languageSwitcher";
import NotificationPopup from "../customComponents/notificationpopup";
import ContactUs from "../pages/contactUs";
import { useAuthStore, useUserManagement } from "../store/authStore";
import { useCompanySwitchStore } from "../store/companySwitch";
import { useFilterDetailStore, useFilterType } from "../store/filterStore";
import useBrowserWidth from "../utilities/responsiveHook";
import HeaderDropdown from "./headerDropdown";
import HeaderMenu from "./headerMenu";
import HeaderMobileMenu from "./headerMobileMenu";
import { authHeaderTabs, unauthHeaderTabs } from "./store";

const paths = ["my-posted-projects", "my-applied-projects"];
export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMobileView, setMobileView] = React.useState<null | HTMLElement>(
    null
  );
  let notificationupdateKey = 0;
  const wsurl = process.env.REACT_APP_WS_URL;
  const baseurl = process.env.REACT_APP_BASE_URL;
  const port = process.env.REACT_APP_WS_PORT;
  const ws_app = process.env.REACT_APP_WS_APP;

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [snackData, setSnackData] = useState({ title: "", message: "" });
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [notificationData, setNotiFicationData] = useState<any>([]);
  const [useData, setUserData] = React.useState<any>();
  const { authenticated } = useAuthStore();
  const { t } = useTranslation();
  const { filterValues, clearFilters } = useFilterDetailStore();
  const [avatar, setAvatar] = useState(localStorage.getItem("avatar"));
  const [switchedProfileId, setSwitchedProfileId] = React.useState("");
  const { filterType, setFilterType } = useFilterType();
  const [loading, setLaoding] = useState(false);
  const navigate = useNavigate();
  const { companyDetail } = useCompanySwitchStore();
  const [modifyHeaderTabs, setModifyHeaderTabs] = useState(
    authenticated ? authHeaderTabs : unauthHeaderTabs
  );
  const {
    setOpenSignModel,
    openSign,
    openModel,
    openProfileVerification,
    setOpenProfileVerification,
    openCompanyModel,
  } = useUserManagement();

  useEffect(() => {
    const handleStorage = () => {
      setAvatar(localStorage.getItem("avatar"));
      handleProfileData();
    };

    window.addEventListener("storage", () => handleStorage());
    return () => window.removeEventListener("storage", () => handleStorage());
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setLaoding(true);
    handleProfileData();
    setAnchorEl(event.currentTarget);
  };
  const handleMobilemenu = (event: React.MouseEvent<HTMLElement>) => {
    setLaoding(true);
    handleProfileData();
    setMobileView(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClose = () => {
    setMobileView(null);
  };
  const handleProfileData = () => {
    setLaoding(true);
    getProfileData()
      .then((res) => {
        localStorage.setItem("currentuserid", res.data.id);
        setUserData(res.data);
        setLaoding(false);
      })
      .catch((err) => {
        setLaoding(false);
      });
  };

  useEffect(() => {
    if (authenticated) {
      handleProfileData();
    }
  }, [authenticated]);
  const handleOpenLogin = () => {
    setOpenSignModel(true);
  };
  const handleFilterData = () => {
    clearFilters();
  };
  const handleNavigation = (item: any) => {
    handleFilterData();
    navigate(item.path);
  };
  const { pathname } = useLocation();
  useEffect(() => {
    handleFilterData();
  }, []);
  const handelDisabled = () => {
    if (window.location?.pathname === "/post-project") {
      return true;
    } else {
      return false;
    }
  };
  const handleCheckSelectionCondition = (item: any) => {
    if (pathname === item.path) {
      return true;
    } else if (
      item?.slug_name === filterType.type &&
      pathname === `/universal-search`
    ) {
      return true;
    } else {
      return false;
    }
  };
  // const initNotifications = () => {
  //   window.Pusher = Pusher;
  //   window.Echo = new Echo({
  //     broadcaster: 'pusher',
  //     key: ws_app,
  //     cluster: 'mt1',
  //     forceTLS: false,
  //     encrypted: false,
  //     wsHost: wsurl,
  //     enabledTransports: ['ws', 'wss'],
  //     wsPort: port,
  //     authEndpoint: `${baseurl}/broadcasting/auth`,
  //     auth: {
  //       headers: {
  //         Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
  //         Accept: 'application/json',
  //       },
  //     },
  //   });

  //   window.Echo.private('broadcast').listen('.common', (data: any) => {
  //
  //     setSnackData({ title: '', message: '' });
  //     setOpenPopup(true);
  //   });

  //   window.Echo.private('user-' + useData?.id).listen(
  //     '.common',
  //     (data: any) => {
  //       console.log('rumman');
  //       console.log(data);
  //       setSnackData({ title: '', message: '' });
  //       setOpenPopup(true);
  //     }
  //   );
  // };
  useEffect(() => {
    headerTabVerify();
    // if (authenticated && useData?.id) {
    //   initNotifications();
    // }
  }, [authenticated, useData]);
  const handleOpen = () => {
    const user = localStorage.getItem("userData") as any;
    getNotification(user?.id)
      .then((res) => {
        setNotiFicationData(res.data);
        setNotificationOpen(true);
      })
      .catch((err) => {});
  };
  const [isStateEmpty, setIsStateEmpty] = useState(true);
  {
    /* FOR PHASE2 - 29/07/2023*/
  }
  const headerTabVerify = () => {
    authenticated
      ? setModifyHeaderTabs(authHeaderTabs)
      : setModifyHeaderTabs(unauthHeaderTabs);
    const flag = localStorage.getItem("switched_company");
    if (flag) {
      const flagObj = JSON.parse(flag);
      let id;
      if (flagObj.state.companyDetail && flagObj.state.companyDetail.id) {
        id = flagObj.state.companyDetail.id;
        setIsStateEmpty(false);
      }
      if (!isStateEmpty) {
        setModifyHeaderTabs(
          modifyHeaderTabs.filter((tab) => tab.title !== "Find Works")
        );
      }
    }
  };
  const handleRedirect = () => {
    window.open("https://jobs.wdawam.com");
  };
  const { isBrowser, isMobile, inlineWidth } = useBrowserWidth();
  let location = useLocation();
  const [lastPart, setLastPart] = useState("");

  useEffect(() => {
    const parts = location.pathname.split("/");
    const lastPart = parts[parts.length - 1];
    setLastPart(lastPart);
  }, [window.location?.pathname]);
  return (
    <>
      {isMobile && (
        <>
          <header
            className={` fixed top-0 w-screen z-50 shadow-3xl px-4 bg-[#fff] float-left h-14 flex items-center justify-center`}>
            <IconButton
              className="p-0"
              // onClick={() => window.open("https://jobs.wdawam.com")}
              onClick={() => setConfirmOpen(true)}>
              <BsFillGrid3X3GapFill className=" text-primaryText w-5 h-5" />
            </IconButton>
            <div className="flex flex-1 justify-center  cursor-pointer ">
              <div
                className="h-8 flex justify-center items-center"
                onClick={() => navigate("/home")}>
                <img className="h-full" src={config.PROJECT_LOGO} />
              </div>
            </div>
            <IconButton
              className="p-0"
              onClick={() => navigate("/universal-search")}>
              <AiOutlineSearch className=" text-primaryText w-5 h-5" />
            </IconButton>
          </header>
          <div className="fixed bottom-0  bg-bgWhite h-14 w-screen z-50 shadow-3xl flex justify-center items-center gap-7 sm:gap-8 md:gap-12">
            {authenticated && (
              <IconButton className="p-0" onClick={() => navigate("/home")}>
                <AiFillHome
                  className={`${
                    (pathname === "/home" || lastPart == "") && "fill-primary"
                  } text-[#C8C9C9] w-6 h-6 `}
                />
              </IconButton>
            )}
            {!authenticated && (
              <IconButton
                className="p-0"
                onClick={() => navigate("home/about")}>
                <AiFillHome
                  className={`${
                    (pathname === "/home/about" || lastPart == "") &&
                    "fill-primary"
                  } text-[#C8C9C9] w-6 h-6 `}
                />
              </IconButton>
            )}
            {authenticated && (
              <IconButton
                className="p-0"
                onClick={() => navigate("/find-works")}>
                <BsBriefcaseFill
                  className={`${
                    pathname === "/find-works" && "fill-primary"
                  } text-[#C8C9C9] w-6 h-6 `}
                />
              </IconButton>
            )}
            {authenticated && (
              <IconButton
                className="p-0"
                onClick={() => navigate("/find-talent")}>
                <HiUsers
                  className={`${
                    pathname === "/find-talent" && "fill-primary"
                  } text-[#C8C9C9] w-6 h-6 `}
                />
              </IconButton>
            )}
            {/* {authenticated && (
            <IconButton className="p-0" onClick={() => navigate("/home")}>
              <AiFillBell className=" text-[#C8C9C9] w-6 h-6" />
            </IconButton>
          )} */}
            {authenticated && (
              <IconButton className="p-0">
                <HeaderDropdown />
              </IconButton>
            )}
            {authenticated && (
              <Notification
                key={notificationupdateKey}
                notificationData={notificationData}
                handleOpen={handleOpen}
                openNotification={notificationOpen}
                setOpen={setNotificationOpen}
              />
            )}
            {!authenticated && (
              <IconButton
                className="p-0"
                onClick={() => navigate("/home/jobs-gateway")}>
                {/* <AiOutlineProfile className=" text-[#C8C9C9] w-6 h-6" /> */}
                <AiOutlineProfile
                  className={`${
                    pathname === "/home/jobs-gateway" && "fill-primary"
                  } text-[#C8C9C9] w-6 h-6 `}
                />
              </IconButton>
            )}
            {!authenticated && (
              <IconButton
                className="p-0"
                onClick={() => navigate("/home/expert")}>
                <GoBriefcase
                  className={`${
                    pathname === "/home/expert" && "fill-primary"
                  } text-[#C8C9C9] w-6 h-6 `}
                />
              </IconButton>
            )}
            {!authenticated && (
              <IconButton
                className="p-0"
                onClick={() => navigate("/home/contactus")}>
                {/* <AiOutlineMail className=" text-[#C8C9C9] w-6 h-6" /> */}
                <AiOutlineMail
                  className={`${
                    pathname === "/home/contactus" && "fill-primary"
                  } text-[#C8C9C9] w-6 h-6 `}
                />
                {/*contact us */}
              </IconButton>
            )}
            <IconButton
              className="p-0"
              onClick={(e) => {
                handleMobilemenu(e);
              }}>
              <HiMenu className=" text-[#C8C9C9] w-6 h-6" />
            </IconButton>
            {!authenticated && (
              <IconButton className="p-0" onClick={handleOpenLogin}>
                <BiLogInCircle className=" text-[#C8C9C9] w-6 h-6" />
              </IconButton>
            )}
          </div>
        </>
      )}
      {isBrowser && (
        <header
          className={` fixed top-0 w-screen z-50 shadow-3xl p-0 bg-[#fff] float-left h-16 flex items-center`}>
          <div
            className={`${
              inlineWidth < 1300 ? "w-2/2" : "w-4/5 lg:w-3/4 2xl:w-2/3"
            }  flex items-center justify-between mx-auto gap-2.5`}>
            <div className="flex gap-3 items-center ">
              <div
                className="flex flex-1 h-10 cursor-pointer"
                onClick={() => navigate("/home")}>
                <img src={config.PROJECT_LOGO} />
              </div>
              <div className="flex items-center gap-2.5 sm:gap-0 h-16">
                <div className="text-sm res-text-size lg:text-sm md:text-xs xs:text-xs font-medium text-center text-gray-500 dark:text-gray-400 h-full">
                  <ul className="flex flex-wrap gap-2 sm:gap-0 items-stretch h-full">
                    {modifyHeaderTabs.map((item) => (
                      <>
                        {item.is_authenticated ? (
                          <>
                            {authenticated && item.slug_name == "myworks" && (
                              <li
                                className={` ${
                                  paths.find((a) => pathname.includes(a)) &&
                                  " border-b-2 border-primary  text-primary "
                                } hover:border-b-2 hover:border-primary border-b-2 border-bgWhite hover:text-primary flex items-center transition`}
                                key={item.id}>
                                <HeaderDropdown />
                              </li>
                            )}
                          </>
                        ) : lastPart == "" && item.path == "/home" ? (
                          <li
                            className=" text-primary border-b-2 border-primary border-b-2 border-bgWhite hover:text-primary flex items-center transition"
                            key={item.id}>
                            <a
                              onClick={() => handleNavigation(item)}
                              className="inline-block p-4 px-2.5 cursor-pointer border-transparent rounded-t-lg hover:text-gray-600  dark:hover:text-primary">
                              {t(item.key) ?? ""}
                            </a>
                          </li>
                        ) : (
                          <li
                            className={` ${
                              handleCheckSelectionCondition(item) &&
                              " border-b-2 border-primary  text-primary "
                            } hover:border-b-2 hover:border-primary border-b-2 border-bgWhite hover:text-primary flex items-center transition`}
                            key={item.id}>
                            <a
                              onClick={() => handleNavigation(item)}
                              className="inline-block p-4 px-2.5 cursor-pointer border-transparent rounded-t-lg hover:text-gray-600  dark:hover:text-primary">
                              {t(item.key) ?? ""}
                            </a>
                          </li>
                        )}
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex gap-2.5 items-center justify-items-end justify-end w-3/6  ">
              <div className="w-full flex gap-2.5 items-center justify-items-end justify-end ">
                <div>
                  <SearchComponent />
                </div>
                <div>
                  {!authenticated ? (
                    <CustomButton
                      title={t("sign_in_button") ?? ""}
                      onClick={handleOpenLogin}
                    />
                  ) : (
                    <CustomButton
                      // title={t("post_a_project") ?? ""}
                      title={t("home_post_project_btn") ?? ""}
                      disabled={handelDisabled()}
                      className="!min-w-[110px] res-size lg:text-sm md:text-xs xs:text-xs"
                      onClick={() =>
                        setOpenProfileVerification({
                          open: true,
                          actionName: "postProject",
                          link: "post-project",
                        })
                      }
                    />
                  )}
                </div>
                {authenticated && (
                  <Notification
                    key={notificationupdateKey}
                    notificationData={notificationData}
                    handleOpen={handleOpen}
                    openNotification={notificationOpen}
                    setOpen={setNotificationOpen}
                  />
                )}
                <LanguageSwitcher />
                <IconButton
                  className="p-0"
                  onClick={() => setConfirmOpen(true)}>
                  <BsFillGrid3X3GapFill className=" text-primaryText w-5 h-5" />
                </IconButton>
                {authenticated && (
                  <div className="flex items-center px-3 h-16 border-x border-[#D6D8DC]">
                    <IconButton
                      disableRipple
                      onClick={(e) => {
                        handleClick(e);
                      }}
                      className="p-0  w-10 h-10 rounded-full !border !border-[#D6D8DC]">
                      <ProfileImage
                        imageUrl={
                          companyDetail?.id
                            ? companyDetail?.actual_logo_Path
                            : useData?.profile_image
                        }
                        profileName={
                          companyDetail?.id
                            ? companyDetail?.name
                            : useData?.name
                        }
                      />
                    </IconButton>
                  </div>
                )}
              </div>
              {/* {<LinearProgress />} */}
            </div>
          </div>
        </header>
      )}
      {anchorEl && !loading && (
        <HeaderMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleMenuClose={handleMenuClose}
          useData={useData}
          switchedProfileId={switchedProfileId}
          setSwitchedProfileId={setSwitchedProfileId}
        />
      )}
      {openSign && <Login />}
      {openModel && <Signup />}
      <VeriFyEmail />
      <ForgotPassword />
      <ShareDetails />
      <ContactUs />
      {openProfileVerification?.open && <ChooseProfile />}
      {openPopup && (
        <NotificationPopup
          snackData={{
            open: openPopup,
            handleClose: () => setOpenPopup(false),
          }}
        />
      )}
      {openCompanyModel && <AddCompanyProfile />}
      {anchorMobileView && (
        <HeaderMobileMenu
          anchorEl={anchorMobileView}
          handleClose={handleMenuClose}
          postProject={() =>
            setOpenProfileVerification({
              open: true,
              actionName: "postProject",
              link: "post-project",
            })
          }
          profileDropdown={(e) => {
            handleClick(e);
          }}
          useData={useData}
        />
      )}
      <ConfirmPromt
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title={t("dawam_jobs_heading") ?? ""}
        // subTitle={t("jobs_gateway_heading") ?? ""}
        description={t("go_to_job_gateway_message") ?? ""}
        // handleRefresh={handleRefresh}
        handleAction={() => handleRedirect()}
      />
    </>
  );
}
