export function jobFilterData() {
  return [
    {
      id: "1",
      type: "selectBox",
      data: [],
      name: "work-location",
      value: "",
      keyName: "location",
      isBasic: true,
      placeholder: "location_label",
    // placeholder: "location",
    },
    {
      id: "2",
      type: "dateRange",
      data: [],
      name: "work-location",
      value: "",
      keyName: "last_date_for_project",
      isBasic: true,
      placeholder: "closing_date_label",
    // placeholder: "Apply till Date (closing date)",
    },
    {
      id: "3",
      type: "selectBox",
      data: [],
      name: "skill-set",
      value: "",
      keyName: "skill",
      isBasic: true,
      placeholder: "skill_label",
    // placeholder: "skill",
    },
    {
      id: "4",
      type: "selectBox",
      data: [],
      name: "qualification",
      value: "",
      keyName: "qualification",
      isBasic: true,
      placeholder: "preferred_qualification",
    // placeholder: "qualification",
    },
    {
      id: "5",
      type: "selectBox",
      data: [],
      name: "nationality",
      value: "",
      keyName: "nationality",
      isBasic: true,
      placeholder: "nationality_label",
    },
    {
      id: "6",
      type: "selectBox",
      data: [],
      name: "industry",
      value: "",
      keyName: "industry",
      isBasic: true,
      placeholder: "industry_label",
    },
    {
      id: "7",
      type: "selectBox",
      data: [],
      name: "category",
      value: "",
      keyName: "work_category",
      isBasic: true,
      placeholder: "work_category",
    },

    {
      id: "8",
      type: "selectBoxDynamic",
      data: [],
      name: "gender",
      value: "",
      keyName: "gender",
      isBasic: true,
      placeholder: "gender_label",
    },
    {
      id: "8",
      type: "detailSelectBox",
      data: [],
      name: "language",
      value: "",
      keyName: "language",
      label: "language_filter_label",
      isBasic: false,
      placeholder: "preferred_languages",
    // placeholder: "language",
    },
  ];
}
export function companyFilterData() {
  return [
    {
      id: "1",
      type: "selectBox",
      data: [],
      name: "area-of-expertise",
      value: "",
      keyName: "skill",
      isBasic: true,
      placeholder: "area_of_expertise",
    },

    {
      id: "2",
      type: "selectBox",
      data: [],
      name: "country",
      value: "",
      keyName: "country",
      isBasic: true,
      placeholder: "select_country_placeholder",
    },
    {
      id: "3",
      type: "selectBox",
      data: [],
      name: "industry",
      value: "",
      keyName: "industry",
      isBasic: true,
      placeholder: "industry_label",
    },
    {
      id: "4",
      type: "selectBox",
      data: [],
      name: "registration-type",
      value: "",
      keyName: "registration_type",
      isBasic: true,
      placeholder: "type_of_registration_label",
    // placeholder: "Registration Type",
    },
  ];
}
export function talentFilterData() {
  return [
    {
      id: "1",
      type: "selectBox",
      data: [],
      name: "work-location",
      value: "",
      keyName: "location",
      isBasic: true,
      placeholder: "location_label",
    },
    {
      id: "2",
      type: "selectBox",
      data: [],
      name: "skill-set",
      value: "",
      keyName: "skill",
      isBasic: true,
      placeholder: "skill_label",
    },
    {
      id: "3",
      type: "selectBox",
      data: [],
      name: "qualification",
      value: "",
      keyName: "qualification",
      isBasic: true,
      placeholder: "preferred_qualification",
    },
    {
      id: "4",
      type: "selectBox",
      data: [],
      name: "country",
      value: "",
      keyName: "country",
      isBasic: true,
      placeholder: "select_country_placeholder",
    },
    {
      id: "5",
      type: "selectBox",
      data: [],
      name: "industry",
      value: "",
      keyName: "industry",
      isBasic: true,
      placeholder: "industry_label",
    },
    {
      id: "6",
      type: "detailSelectBox",
      data: [],
      name: "language",
      value: "",
      keyName: "language",
      label: "language_filter_label",
      isBasic: false,
      placeholder: "preferred_languages",
    },

    {
      id: "7",
      type: "selectBoxDynamic",
      data: [],
      name: "gender",
      value: "",
      keyName: "gender",
      isBasic: true,
      placeholder: "gender_label",
    },
  ];
}
enum Direction {
  work = "work",
  user = "user",
  company = "company",
}
export const projectFilterType = {
  name: "Project",
  nameKey: "projects_option",
  type: Direction.work,
};
export const userFilterTypeInt = {
  name: "Talent",
  nameKey: "talents_option",
  type: Direction.user,
};
