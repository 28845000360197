import config from "../../config";
import {
  AiFillBehanceSquare,
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";

type Props = {
  type: string;
};
export const SocialIcons = (props: Props) => {
  const { type } = props;
  switch (type) {
    case "LinkedIn":
      return <img className="w-5 h-5" src={config.linkedin} />;
    case "Facebook":
      return <img className="w-5 h-5" src={config.facebook} />;
    case "Behance":
      return <img className="w-5 h-5" src={config.behance} />;
    case "Instagram":
      return <img className="w-5 h-5" src={config.instagram} />;
    case "Twitter":
      return <img className="w-5 h-5" src={config.twitter} />;
    default:
      return null;
  }
};
