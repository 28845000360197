import { create } from "zustand";

type AuthStoreType = {
  isLoading: boolean | undefined;
  setIsLoading: (authenticated: boolean | undefined) => void;
};

export const useLoaderStore = create<AuthStoreType>()((set) => ({
  isLoading: false,
  setIsLoading: (loader) => set(() => ({ isLoading: loader })),
}));
